import "./index.css";

import React, { Component } from "react";
import { Link } from "react-router-dom";

/* Components */
import Page from "../../../components/Page";
import NavigationBar from "../../../components/NavigationBar";

class AboutUs extends Component {

    componentDidMount() {
        return window.scrollTo(0, 0);
    }

    render() {

        return(
            <Page match={this.props.match} hideNavbar={true}>
                <div className="page-intro-linear-container">
                    <NavigationBar { ...this.props } />
                    <div style={{ height: 30 }}></div>
                    <h4>About Us</h4>
                </div>
                <div style={{height: 75}}></div>
                <div className="about-us-team-header-container">
                    <p>Yachting is in our DNA</p>
                </div>
                <div className="about-us-text">
                    <p style={{ textAlign: "center" }}>J4Crew is a family run business run by two brothers Jacob and Chay and first formed over 20 years ago by their father Joe, appearing on the scene as just4engineers in 1999.</p>
                </div>
                <div className="about-us-text">
                    <p style={{ textAlign: "center" }}>Jacob and Chay who are both trained Marine Officers in their own right have arrived with commercial and yachting experience bringing our own ideas and perspectives to fruition. Although Joe is no longer part of the business, our values of partnership and trust have remained the same.</p>
                </div>
                <div className="about-us-text">
                    <p style={{ textAlign: "center" }}>We have established ourselves as an industry leader and have built long term relationships with our clients and crew. We are committed to maintaining the highest standards and focus on providing quality over quantity.</p>
                </div>
                <div style={{height: 100}}></div>
                <img className="about-us-separator-image" src={require("../../../assets/images/fam.webp")} alt="" />
                <div style={{height: 100}}></div>
                <div className="about-us-team-member-container">
                    <div className="about-us-team-member-image-container">
                        <img src={require("../../../assets/images/chay.webp")} alt="" />
                    </div>
                    <div className="about-us-team-member-info-container">
                        <p className="about-us-team-member-name">Chay Hodgson</p>
                        <p className="about-us-team-member-position">Director and Recruitment Consultant</p>
                        <div className="about-us-text">
                            <p>Chay is a qualified Engineer OOW with years of yachting experience. Joining J4Crew in 2017, he has helped many crew forge their career in the industry.</p>
                        </div>
                        <div className="about-us-text">
                            <p>Chay started his yachting career aged 17 working as a deckhand and learning the ropes as an engineer. He then went on to complete an engineering cadetship at Warsash Maritime College working on various cruise ships. Coming back into the industry he was able to secure a position as 2nd engineer on a prestigious yacht based in the IYCA where he stayed for a number of years.</p>
                        </div>
                        <div className="about-us-text">
                            <p>He has a keen interest in modern technology which has translated into J4Crew, modernising and improving our candidate portal, and making incremental improvements as time permits. He has a passion for helping others achieve their dreams and is always happy to help advise on career development.</p>
                        </div>
                        <div className="about-us-text">
                            <p>Contact him directly with the following details:</p>
                            <p>Email: <a href="mailto:chay@j4crew.com" target="_blank" rel="noopener noreferrer">chay@j4crew.com</a></p>
                            <p>Phone: <a href="https://wa.me/447857138274" target={"_blank"} rel="noopener noreferrer">+44 7857 138 274</a> (WhatsApp)</p>
                        </div>
                        <div className="web-page-home-about-us-cell-row-socials-container">
                            <a href={"https://www.linkedin.com/in/chayhodgson/"} target={"_blank"} rel="noopener noreferrer">
                                <img src={require("../../../assets/svgs/linkedin.svg")} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="about-us-team-member-container">
                    <div className="about-us-team-member-image-container">
                        <img src={require("../../../assets/images/jacob.webp")} alt="" />
                    </div>
                    <div className="about-us-team-member-info-container">
                        <p className="about-us-team-member-name">Jacob Hodgson</p>
                        <p className="about-us-team-member-position">Director and Recruitment Consultant</p>
                        <div className="about-us-text">
                            <p>Jacob is a qualified Deck OOW with both commercial and yachting experience. He joined in 2018 and continues to diligently match clients and crew.</p>
                        </div>
                        <div className="about-us-text">
                            <p>He grew up in both England and the South of France so was exposed to the yachting industry from a young age, holding an OOW ticket and a foundation degree in Marine Operations obtained through the Trinity House cadetship scheme. In his seagoing career he has served as Mate on both offshore vessels and super yachts.</p>
                        </div>
                        <div className="about-us-text">
                            <p>He enjoys his work and very rarely takes a day off! In his spare time he takes a keen interest in all things sports. In particular, football and is a keen footballer himself, playing a few times a week to a competitive standard.</p>
                        </div>
                        <div className="about-us-text">
                            <p>Contact him directly with the following details:</p>
                            <p>Email: <a href="mailto:jacob@j4crew.com" target="_blank" rel="noopener noreferrer">jacob@j4crew.com</a></p>
                            <p>Phone: <a href="https://wa.me/447598382232" target={"_blank"} rel="noopener noreferrer">+44 7598 382 232</a>. (WhatsApp)</p>
                        </div>                        
                        <div className="web-page-home-about-us-cell-row-socials-container">
                            <a href={"https://www.linkedin.com/in/jacob-hodgson-259b8b120/"} target={"_blank"} rel="noopener noreferrer">
                                <img src={require("../../../assets/svgs/linkedin.svg")} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="web-page-footer-socials-container">
                    <a href={"https://twitter.com/J4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/twitter-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.facebook.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/facebook-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.instagram.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/instagram-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.linkedin.com/company/5267269"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/linkedin-footer.svg")} alt="" />
                    </a>
                </div>
                <div className="web-page-footer-links-container" style={{ borderBottom: "1px solid rgb(225, 225, 225)" }}>
                    <p><Link to={"/terms-and-conditions"}>Terms</Link><Link to={"/privacy-policy"}>Privacy Policy</Link><Link to={"/contact-us"}>Get in Touch</Link><Link to={"/about-us"}>About Us</Link><Link to={"/mlc-2006"}>MLC 2006</Link></p>
                </div>
                <div style={{height: 10}}></div>
                <div className="web-page-footer-links-container">
                    <p>Copyright ©{new Date().getFullYear()} J4Crew LTD</p>
                </div>
                <div style={{height: 30}}></div>
            </Page>
        );

    }

}

export default AboutUs;