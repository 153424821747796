import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import InitialSetupBase from "../../../../components/InitialSetupBase";

/* API requests */
import { fastLoadVessels, getAllVessels } from "../../../../api-requests/vessel";

/* Actions */
import { updateUserProfileYacht } from "../../../../redux/actions/user";

class YachtDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            vessels: [],
            suggestions: [],
        };

    }

    componentDidMount() {

        return fastLoadVessels()
            .then(result => {
                return result
            })
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ vessels: result.data }, () => {
                    return getAllVessels()
                        .then(result => {
                            if (result.status !== 201) return;
                            return this.setState({ vessels: [...this.state.vessels, ...result.data] });
                        });
                });
            });

    }

    handleSuggestions = (value, selected) => {

        this.props.updateUserProfileYacht([{ propName: "name", value: value }]);

        return this.setState({ show: false }, () => {
            if (value.length !== 0) {
                const selectedIDs = selected.map(select => select._id);
                const suggestions = this.state.vessels.filter(vessel => {
                    return !selectedIDs.includes(vessel._id) && (vessel.name.includes(value) || vessel.name.toLowerCase().includes(value));
                }); 

                return this.setState({ suggestions: suggestions });
            } else {
                return this.setState({ suggestions: [] });
            }
        });

    }

    renderDropdown = (list, selected) => {

        const selectedIDs = selected.map(select => select._id);
        list = list.filter(item => !selectedIDs.includes(item._id));

        return list.map((item, index) => {
            return(
                <div key={index} className="yacht-details-dropdown-list-item-container" style={{border: "none"}} onClick={() => this.selectVessel(item)}>
                    <p>{item.name} {item.age && item.size ? `(${item.age}, ${item.size}m)`: `(${item.age})`}</p>
                </div>
            );
        });

    }

    selectVessel = (vessel) => {

        return this.setState({ show: false, suggestions: [] }, () => {
            return this.props.updateUserProfileYacht([
                { propName: "name", value: vessel.name },
                { propName: "yachtType", value: vessel.yachtType },
                { propName: "size", value: vessel.size },
                { propName: "age", value: vessel.age },
                { propName: "yachtBuilderName", value: vessel.yachtBuilderName },
                { propName: "yachtBuilderCountry", value: vessel.yachtBuilderCountry },
            ]);
        });

    }

    render() {

        return(
            <InitialSetupBase title={"Initial Set Up - Yacht Details"} description={"Please let us know the basic details about the vessel."}>
                <div className="yacht-details-dropdown-master-container margin-top-20px">
                    <div className={this.state.show || this.state.suggestions.length !== 0 ? "yacht-details-dropdown-container yacht-details-dropdown-open-container" : "yacht-details-dropdown-container"}>
                        <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                            <div className="yacht-details-dropdown-main-item-title-long-container">
                                <p className="yacht-details-dropdown-main-label">Yacht Name (Confidential and not revealed to candidates)</p>
                            </div>
                            <div className="yacht-details-dropdown-main-item-value-container">
                                <input className="yacht-details-value-input" onChange={(e) => this.handleSuggestions(e.target.value, [this.props.user?.data?.profile?.yacht])} value={this.props.user.data.profile.yacht.name || ""} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                            </div>
                        </div>
                        <div className="yacht-details-dropdown-icon-container" onClick={() => this.setState({ show: !this.state.show, suggestions: [] })}>
                            <img src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                        </div>
                    </div>
                    {this.state.suggestions.length !== 0 &&
                        <div className="yacht-details-dropdown-list-container">
                            {this.renderDropdown(this.state.suggestions, [this.props.user?.data?.profile?.yacht])}
                        </div>
                    }
                    {this.state.show &&
                        <div className="yacht-details-dropdown-list-container">
                            {this.renderDropdown(this.state.vessels, [this.props.user?.data?.profile?.yacht])}
                        </div>
                    }
                </div>
                <div className="toggle-button-master-container margin-top-20px">
                    <div className="toggle-button-title-container">
                        <p>Yacht Type</p>
                    </div>
                    <div className="toggle-button-container">
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.yachtType === "Motor Yacht" ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "yachtType", value: "Motor Yacht" }])}>
                            <p className="toggle-button-item-text" style={{color: this.props.user.data.profile.yacht.yachtType === "Motor Yacht" ? "white" : "#333333"}}>Motor Yacht</p>
                        </div>
                        <div className="toggle-button-items-separator"></div>
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.yachtType === "Sailing Yacht" ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "yachtType", value: "Sailing Yacht" }])}>
                            <p className="toggle-button-item-text" style={{color: this.props.user.data.profile.yacht.yachtType === "Sailing Yacht" ? "white" : "#333333"}}>Sailing Yacht</p>
                        </div>
                    </div>
                </div>
                <div className="yacht-details-dropdown-master-container margin-top-20px">
                    <div className="yacht-details-dropdown-container">
                        <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                            <div className="yacht-details-dropdown-main-item-title-container">
                                <p className="yacht-details-dropdown-main-label">Yacht Length</p>
                            </div>
                            <div className="yacht-details-dropdown-main-item-value-container">
                                <input className="yacht-details-value-input" value={this.props.user.data.profile.yacht.size || ""} onChange={(e) => this.props.updateUserProfileYacht([{ propName: "size", value: e.target.value }])} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                                <div className="yacht-details-value-unit-container">
                                    <span>metres</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="yacht-details-dropdown-master-container margin-top-20px">
                    <div className="yacht-details-dropdown-container">
                        <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                            <div className="yacht-details-dropdown-main-item-title-container">
                                <p className="yacht-details-dropdown-main-label">Yacht Build Year</p>
                            </div>
                            <div className="yacht-details-dropdown-main-item-value-container">
                                <input className="yacht-details-value-input" value={this.props.user.data.profile.yacht.age || ""} onChange={(e) => this.props.updateUserProfileYacht([{ propName: "age", value: e.target.value }])} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                                <div className="yacht-details-value-unit-container">
                                    <span>year</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="yacht-details-dropdown-master-container margin-top-20px">
                    <div className="yacht-details-dropdown-container">
                        <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                            <div className="yacht-details-dropdown-main-item-title-container">
                                <p className="yacht-details-dropdown-main-label">Yacht Build</p>
                            </div>
                            <div className="yacht-details-dropdown-main-item-value-container">
                                <input className="yacht-details-value-input" value={this.props.user.data.profile.yacht.yachtBuilderName || ""} onChange={(e) => this.props.updateUserProfileYacht([{ propName: "yachtBuilderName", value: e.target.value }])} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="yacht-details-dropdown-master-container margin-top-20px">
                    <div className="yacht-details-dropdown-container">
                        <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                            <div className="yacht-details-dropdown-main-item-title-container">
                                <p className="yacht-details-dropdown-main-label">Yacht Build Country</p>
                            </div>
                            <div className="yacht-details-dropdown-main-item-value-container">
                                <input className="yacht-details-value-input" value={this.props.user.data.profile.yacht.yachtBuilderCountry || ""} onChange={(e) => this.props.updateUserProfileYacht([{ propName: "yachtBuilderCountry", value: e.target.value }])} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                            </div>
                        </div>
                    </div>
                </div>
            </InitialSetupBase>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfileYacht }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(YachtDetails);