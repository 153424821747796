import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./index.css";

/* Actions */
import { setYachts } from "../../../../redux/actions/admin";

/* API requests */
import { getAllYachts, searchYachts } from "../../../../api-requests/yacht";
import { handleYachtIcon } from "../../../../helpers/job";

class Yachts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: "",
            searchResults: [],
            isFetchingSearchResults: false,
        };

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        return getAllYachts()
            .then(yachts => {
                return this.props.setYachts(yachts);
            });

    }

    handleSearch = (keyword) => {

        return this.setState({ keyword }, () => {
            if (keyword.length >= 3) {
                return this.setState({ isFetchingSearchResults: true }, () => {
                    return searchYachts(keyword)
                        .then(result => {
                            return this.setState({ searchResults: result, isFetchingSearchResults: false });
                        });
                });
            }
            return this.setState({ searchResults: [], isFetchingSearchResults: false });
        });

    }

    renderPlaceholders = (placeholders) => {

        return placeholders.map((placeholder) => {
            return(
                <div className="yachts-list-item-container margin-top-20px" key={placeholder}>
                    <div className="yachts-list-item-icon-container">
                        <div className="yachts-list-item-small-placeholder"></div>
                    </div>
                    <div className="yachts-list-item-details-container">
                        <div className="yachts-list-item-long-placeholder"></div>
                    </div>
                    <div className="yachts-list-item-col-container">
                        <div className="yachts-list-item-small-placeholder"></div>
                    </div>
                    <div className="yachts-list-item-col-container">
                        <div className="yachts-list-item-small-placeholder"></div>
                    </div>
                    <div className="yachts-list-item-col-container">
                        <div className="yachts-list-item-small-placeholder"></div>
                    </div>
                </div>
            );
        });

    }

    renderYachts = (yachts) => {

        return yachts.map((yacht, index) => {
            return(

                <Link to={"/yachts/edit/"+yacht._id} className="yachts-list-item-container margin-top-20px" key={index}>
                    <div className="yachts-list-item-icon-container">
                        <img src={handleYachtIcon(yacht.size, yacht.yachtType)} alt="" />
                    </div>
                    <div className="yachts-list-item-details-container">
                        <p>{yacht.yachtType === "Motor Yacht" ? "M/Y" : "S/Y"} {yacht.name}</p>
                    </div>
                    <div className="yachts-list-item-col-container y-col-3">
                        <p>{yacht.age ? yacht.age : "N/A"}</p>
                    </div>
                    <div className="yachts-list-item-col-container y-col-4">
                        <p>{yacht.yachtBuilderName ? yacht.yachtBuilderName : "N/A"}</p>
                    </div>
                    <div className="yachts-list-item-col-container y-col-5">
                        <p>{yacht.size ? yacht.size+"m" : "N/A"}</p>
                    </div>
                </Link>
            );
        });

    }

    handleYachtsList = (yachts, searchResults, keyword) => {

        if (searchResults.length > 0 && keyword.length >= 3) return searchResults;
        return yachts;

    }

    render() {

        let yachts = this.handleYachtsList(this.props.admin.yachts, this.state.searchResults, this.state.keyword);

        return(
            <div className="yachts-container">
                <div className="yachts-header-container">
                    <div className="yachts-header-items-container">
                        <p className="yachts-header-items-title">Local Yacht Accounts</p>
                    </div>
                    <div className="yachts-header-search-container">
                        {this.state.isFetchingSearchResults && 
                            <div className="sign-ups-header-search-loading-container">
                                <img src={require("../../../../assets/animations/blue-spinner.svg")} alt="" />
                            </div>
                        }
                        <div className="yachts-header-search-input-container">
                            <input type={"text"} placeholder={"Start typing..."} value={this.state.keyword} onChange={(e) => this.handleSearch(e.target.value)} />
                        </div>
                        <div className="yachts-header-search-icon-container">
                            <img src={require("../../../../assets/svgs/search.svg")} alt="" />
                        </div>
                    </div>
                </div>
                <div className="yachts-button-container">
                    <Link className="yachts-button" to={"/yachts/create"}>
                        <p>Create a Local Yacht</p>
                    </Link>
                </div>
                <div className="yachts-list-header-container">
                    <div className="yachts-list-header-long-item-container">
                        <p>Basic Details</p>
                    </div>
                    <div className="yachts-list-header-item-container y-col-3">
                        <p>Build Year</p>
                    </div>
                    <div className="yachts-list-header-item-container y-col-4">
                        <p>Builder</p>
                    </div>
                    <div className="yachts-list-header-item-container y-col-5">
                        <p>Length</p>
                    </div>
                </div>
                <div className="yachts-list-container">
                    {!this.props.admin.isFetchingYachts ?
                        this.renderYachts(yachts)
                    :
                        this.renderPlaceholders([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
                    }
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setYachts }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Yachts);