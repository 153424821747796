import React from "react";
import DashboardSectionBase from "../../../components/DashboardSectionBase";
import Toggle from "../Toggle";
import TextBox from "../TextBox";
import Footer from "../Footer";
import Title from "../Title";

export default ({ title, full, steps, step, updateState, selected, extra, state, completed }) => {

    return(
        <>
            <Title title={title} />
            <DashboardSectionBase title={"Performance"} steps={steps} step={step} overflow={true}>
                <div className="margin-top-20px">
                    <Toggle title={"How would you rate his/her overall performance?"} options={["Excellent", "Good", "Poor"]} updateState={updateState} selected={selected} propName={"performance"} />
                </div>
                <div className="margin-top-20px">
                    <TextBox title={"Any further details you would like to add (Optional)"} updateState={updateState} propName={"performanceExtra"} value={extra} />
                </div>
            </DashboardSectionBase>
            <div className="margin-top-20px">
                <Footer title={full ? "Save & Continue" : "Save & Complete"} icon={full ? require("../../../assets/svgs/continue.svg") : require("../../../assets/svgs/tick-white.svg")} back={true} updateState={updateState} step={step} state={state} propNames={["performance"]} completed={completed} />
            </div>
        </>
    );

}