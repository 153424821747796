import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom"
import Routes from "./routes";
import configureStore from "./redux/configureStore";

const store = configureStore();

class App extends Component {

  render() {

    return(
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    );
    
  }

}

export default App;