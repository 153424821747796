import React from "react";
import "./index.css";

export default ({ items, propName, updateUserProfile, admin, qualIndex, changeQualIndex, deleteAlternative }) => {

    if (propName !== "moreQualifications") {
        return(
            <div className="selected-items-container">
                {renderSelected(items, propName, updateUserProfile, admin)}
            </div>
        );
    } else {
        if (items.length > 1 || items[0].length > 0) {
            return(
                <div>
                    {items.map((item, rowIndex) => {
                        return(
                            <div key={rowIndex} className="selected-items-combination-container">
                                <div className="selected-items-combination-header-container">
                                    <p onClick={() => changeQualIndex(rowIndex)} style={qualIndex !== rowIndex ? { color: "#333333" } : { color: "#1377FF" }}>{rowIndex+1}. Alternative</p>
                                    <span onClick={() => deleteAlternative(rowIndex, items.length)}>Delete</span>
                                </div>
                                <div className="selected-items-container">
                                    {renderSelected(item, propName, updateUserProfile, admin, qualIndex, rowIndex)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <div></div>;
        }        
    }

}

const renderSelected = (items, propName, updateUserProfile, admin, qualIndex, rowIndex) => {

    return items.map((item, index) => {
        return(
            <div key={index} className="selected-item-container">
                <div className="selected-item-name-container">
                    {propName === "position" ?
                        <span>{item.name} ({item.positionType})</span>
                    :
                        propName === "qualifications" || propName === "moreQualifications" ?
                            <span>{item.name}</span>
                        :
                            <span>{item}</span>
                    }
                </div>
                {(propName !== "moreQualifications" || (propName === "moreQualifications" && rowIndex === qualIndex)) &&
                    <div className="selected-item-icon-container" onClick={() => deselect(item, propName, items, updateUserProfile, admin, rowIndex)}>
                        <img src={require("../../assets/svgs/x-thick.svg")} alt="" />
                    </div>
                }                
            </div>
        );
    });

}

const deselect = (value, propName, selected, updateUserProfile, admin, rowIndex) => {

    let filteredValue = selected.filter(item => {
        return item !== value;
    });

    if (propName !== "moreQualifications") {
        if (admin) {
            return updateUserProfile(filteredValue);
        } else {
            return updateUserProfile([{ propName: propName, value: filteredValue }], true);
        }
    } else {
        return updateUserProfile(propName, filteredValue, rowIndex);
    }

}