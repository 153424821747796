import {
    SET_IDEAL_POSITION,
    RESET_IDEAL_POSITION,
    SET_CANCEL_DATA,
} from "../types/ideal-position";

const initialState = {
    data: {
        position: [],
        noYachtSizePreference: false,
        yachtSizeMin: "",
        yachtSizeMax: "",
        yachtType: [],
        jobType: [],
    },
    cancelData: null,
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_IDEAL_POSITION:

            let ops = {};

            action.data.forEach(item => {
                ops[item.propName] = item.value;
            });
        
            return {
                ...state,
                data: {
                    ...state.data,
                    ...ops,
                },
            };

        case RESET_IDEAL_POSITION:

            return {
                ...state,
                data: {
                    position: [],
                    noYachtSizePreference: false,
                    yachtSizeMin: "",
                    yachtSizeMax: "",
                    yachtType: [],
                    jobType: [],
                },
            }

        case SET_CANCEL_DATA:

            return {
                ...state,
                cancelData: action.data,
            };
        
        default:
            return state;

    }

};