export const getQualifications = () => {

    return fetch("/api/qualifications/")
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}