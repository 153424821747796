import React, { Component } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import Page from "../../../components/Page";
import moment from "moment";
import { getPositions } from "../../../api-requests/position";
import { getQualifications } from "../../../api-requests/qualification";
import AccordionJobs from "../../../components/AccordionJobs";
import { getJobsForGuest } from "../../../api-requests/job";
import { getMinMaxSize, getMinMaxAge, getMinMaxSalary } from "../../../helpers/job";
import Filter from "../../../components/Filter";
import { convertQueryStringToObject } from "../../../helpers/reset-password";
import NavigationBar from "../../../components/NavigationBar";

class FindJobs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: [],
            qualifications: [],
            positions: [],
            jobs: [],
            keyword: "",
            showFilter: false,
            selectedPositions: "",
            selectedJobTypes: "",
            selectedQualifications: [],
            selectedCoupleFriendly: [],
            selectedYachtTypes: [],
            selectedYachtSizes: [],
            selectedYachtAges: [],
            selectedSalaryRanges: [],
        };

    }

    componentDidMount() {

        const data = convertQueryStringToObject(this.props.location.search);

        if (data.keyword) {
            this.setState({ keyword: data.keyword });
        }

        return getJobsForGuest()
            .then(jobs => {
                return this.setState({ jobs: jobs });
            });

    }

    toggleFilter = (state) => {

        if (this.state.positions.length < 1) {
            getPositions()
                .then(result => {
                    if (result.status !== 201) return;
                    return this.setState({ positions: result.data });
                });
        } 
        
        if (this.state.qualifications.length < 1) {
            getQualifications()
                .then(result => {
                    if (result.status !== 201) return;
                    return this.setState({ qualifications: result.data });
                });
        }

        if (!state) this.setState({ keyword: "" });

        return this.setState({
            showFilter: !state,
            selectedPositions: "",
            selectedJobTypes: "",
            selectedQualifications: [],
            selectedCoupleFriendly: [],
            selectedYachtTypes: [],
            selectedYachtSizes: [],
            selectedYachtAges: [],
            selectedSalaryRanges: [],
        });
    }

    handleSuggestions = (state, jobs) => {
        return this.filterJobs(state, jobs);
    }

    handleFilterClick = (type, expandedFilter) => {

        if (type === expandedFilter) return this.setState({ expandedFilter: "" });

        return this.setState({ expandedFilter: type });

    }

    handleOptionClick = (propName, item, single) => {

        if (this.state[propName].includes(item)) return this.setState({ [propName]: this.state[propName].filter(i => i !== item) });

        if (!single) return this.setState({ [propName]: [...this.state[propName], item] });

        return this.setState({ [propName]: [item] });

    }

    filterJobs = (state, jobs) => {

        
        if (state.selectedPositions.length > 0) {
            jobs = jobs.filter(job => {
                return job.position.name.toLowerCase().includes(state.selectedPositions.toLowerCase());
            });
        }

        if (state.selectedJobTypes.length > 0) {
            jobs = jobs.filter(job => {
                return job.position.positionType.toLowerCase().includes(state.selectedJobTypes.toLowerCase())
            });
        }

        if (state.selectedYachtTypes.length > 0) jobs = jobs.filter(job => state.selectedYachtTypes.includes(job.yacht.yachtType));

        if (state.selectedQualifications.length > 0) {
            jobs = jobs.filter(job => {
                let matches = job.qualifications.filter(qualification => state.selectedQualifications.includes(qualification.name));
                return matches.length > 0;
            });
        } 

        if (state.selectedCoupleFriendly.length > 0) {
            jobs = jobs.filter(job => {
                let match = job.yacht.couples ? "Yes" : "No";
                return state.selectedCoupleFriendly.includes(match);
            });
        }

        if (state.selectedYachtSizes.length > 0) {
            jobs = jobs.filter(job => {
                let match = state.selectedYachtSizes.map(size => {
                    let minMaxSize = getMinMaxSize(size);                    
                    if (minMaxSize.max) return minMaxSize.min <= job.yacht.size && job.yacht.size <= minMaxSize.max;
                    return minMaxSize.min <= job.yacht.size;
                });
                return match.includes(true);
            });
        }

        if (state.selectedYachtAges.length > 0) {
            jobs = jobs.filter(job => {
                let match = state.selectedYachtAges.map(age => {
                    let minMaxAge = getMinMaxAge(age);
                    let yachtAge = job.yacht.age,
                        currentYear = moment(new Date()).year(),
                        yearDifference = currentYear - yachtAge;

                    if (minMaxAge.min && minMaxAge.max) return minMaxAge.min <= yearDifference && yearDifference <= minMaxAge.max;
                    if (minMaxAge.min) return minMaxAge.min <= yearDifference;

                    return yearDifference <= minMaxAge.max;
                });
                return match.includes(true);
            });
        }

        if (state.selectedSalaryRanges.length > 0) {
            jobs = jobs.filter(job => {
                if (job.salaryType === "DOE" && state.selectedSalaryRanges.includes("Salary DOE")) return true;

                let amount;
                let salaryAmount = job.salaryType === "Fixed" ? parseInt(job.salaryAmount, 10) : (parseInt(job.salaryRangeMin, 10) + parseInt(job.salaryRangeMax, 10)) / 2;

                if (!job.salaryCurrency || !salaryAmount) amount = 0;
                if (job.salaryCurrency === "usd") amount = salaryAmount*0.9;
                if (job.salaryCurrency === "gbp") amount = salaryAmount*1.1;
                if (job.salaryCurrency === "eur") amount = salaryAmount;

                let match = state.selectedSalaryRanges.map(salary => {
                    if (salary === "Salary DOE") return false;
                    let minMaxSalary = getMinMaxSalary(salary);     
                    if (minMaxSalary.max) return minMaxSalary.min <= amount && amount <= minMaxSalary.max;
                    return minMaxSalary.min <= amount;
                });
                return match.includes(true);

            });
        }

        return jobs;

    }

    searchKeyword = (e, propName) => {
        return this.setState({ [propName]: e });
    }

    render() {

        let suggestions = this.handleSuggestions(this.state, this.state.jobs);

        return(
            <Page match={this.props.match} hideNavbar={true}>
                <div className="page-intro-linear-container">
                    <NavigationBar { ...this.props } />
                    <div style={{ height: 30 }}></div>
                    <h4>Find Jobs</h4>
                    <p>We recruit crew of all levels, feel free to take a look at our current roles. If you are looking for a new position at the moment, please log in to update your profile or register with us. You can also reach out to us personally via email or phone.</p>
                    <div className="page-intro-buttons-container">
                        <Link to={"/sign-in"} style={{ backgroundColor: "#F2F2F3" }}>
                            <span style={{ color: "#333333" }}>Log In</span>
                        </Link>
                        <Link to={"/sign-up"} style={{ backgroundColor: "#A2A4AE" }}>
                            <span style={{ color: "#FFFFFF" }}>Register</span>
                        </Link>
                    </div>
                </div>
                <div className="find-jobs-container">
                    <div className="find-jobs-wrapper">
                        <div className="find-jobs-search-title-container">
                            <p>Search Jobs</p>
                        </div>
                        <div className="find-jobs-search-subtitle-container margin-top-1x">
                            <p>Search by job title or keyword</p>
                        </div>
                        <div className="jobs-filter-container margin-top-20px">
                            <div className="jobs-filter-button" onClick={() => this.toggleFilter(this.state.showFilter)} style={this.state.showFilter ? {backgroundColor: "white", border: "2px solid #1377FF"} : {backgroundColor: "#1377FF"}}>
                                <p className="jobs-filter-button-title" style={this.state.showFilter ? {color: "#1377FF"} : {color: "white"}}>{this.state.showFilter ? "See Less Filters" : "See More Filters"}</p>
                            </div>
                        </div>
                        <div className="jobs-filter-expanded-row-container margin-top-2x">
                            <Filter label={"Search Job Department"} type={"jobType"} handleFilterClick={this.handleFilterClick} propName={"selectedJobTypes"} input={true} keyword={this.state.selectedJobTypes} changeText={this.searchKeyword} /> 
                            <div className="jobs-filter-expanded-row-separator"></div>
                            <Filter label={"Search Position"} type={"position"} handleFilterClick={this.handleFilterClick} propName={"selectedPositions"} input={true} keyword={this.state.selectedPositions} changeText={this.searchKeyword} />
                        </div>
                        {this.state.showFilter &&
                            <div className="jobs-filter-expanded-container margin-top-2x">
                                <div className="jobs-filter-expanded-row-container margin-top-20px">
                                    <Filter label={"Select Yacht Size"} type={"yachtSize"} expandedFilter={this.state.expandedFilter} handleFilterClick={this.handleFilterClick} propName={"selectedYachtSizes"} handleOptionClick={this.handleOptionClick} selectedItems={this.state.selectedYachtSizes} options={["Up to 40m", "40m to 60m", "60m to 80m", "80m to 100m", "More Than 100m"]}  />
                                    <div className="jobs-filter-expanded-row-separator"></div>
                                    <Filter label={"Select Yacht Type"} type={"yachtType"} expandedFilter={this.state.expandedFilter} handleFilterClick={this.handleFilterClick} propName={"selectedYachtTypes"} handleOptionClick={this.handleOptionClick} selectedItems={this.state.selectedYachtTypes} options={["Motor Yacht", "Sailing Yacht"]} />
                                </div>
                                <div className="jobs-filter-expanded-row-container margin-top-20px">
                                    <Filter label={"Salary Range"} type={"salaryRange"} expandedFilter={this.state.expandedFilter} handleFilterClick={this.handleFilterClick} propName={"selectedSalaryRanges"} handleOptionClick={this.handleOptionClick} selectedItems={this.state.selectedSalaryRanges} options={["Salary DOE", "Up to €4000", "€4000 to €6000", "€6000 to €8000", "€8000 - €10 000", "More Than €10 000"]} />
                                    <div className="jobs-filter-expanded-row-separator"></div>
                                </div>
                            </div>
                        }
                        {this.state.jobs.length > 0 && (!this.state.selectedPositions && !this.state.selectedJobTypes) && !this.state.showFilter &&
                            <div className="margin-top-20px">
                                <AccordionJobs
                                    guest={true}
                                    jobs={this.state.jobs}
                                    expanded={this.state.expanded}
                                    handleRow={(result) => this.setState({ expanded: result })}
                                />
                            </div>
                        }
                        {((this.state.selectedPositions || this.state.selectedJobTypes) || this.state.showFilter) &&
                            <div className="margin-top-20px">
                                {suggestions.length > 0 ?
                                    <AccordionJobs
                                        guest={true}
                                        jobs={suggestions}
                                        expanded={this.state.expanded}
                                        handleRow={(result) => this.setState({ expanded: result })}
                                    />
                                :
                                    <p className="no-jobs-text">We have no jobs that match your preferences at the moment.</p>
                                }                        
                            </div>
                        }
                    </div>
                    <div className="web-page-footer-socials-container">
                        <a href={"https://twitter.com/J4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                            <img src={require("../../../assets/svgs/twitter-footer.svg")} alt="" />
                        </a>
                        <a href={"https://www.facebook.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                            <img src={require("../../../assets/svgs/facebook-footer.svg")} alt="" />
                        </a>
                        <a href={"https://www.instagram.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                            <img src={require("../../../assets/svgs/instagram-footer.svg")} alt="" />
                        </a>
                        <a href={"https://www.linkedin.com/company/5267269"} target={"_blank"} rel="noopener noreferrer">
                            <img src={require("../../../assets/svgs/linkedin-footer.svg")} alt="" />
                        </a>
                    </div>
                    <div className="web-page-footer-links-container" style={{ borderBottom: "1px solid rgb(225, 225, 225)" }}>
                        <p><Link to={"/terms-and-conditions"}>Terms</Link><Link to={"/privacy-policy"}>Privacy Policy</Link><Link to={"/contact-us"}>Get in Touch</Link><Link to={"/about-us"}>About Us</Link><Link to={"/mlc-2006"}>MLC 2006</Link></p>
                    </div>
                    <div style={{height: 10}}></div>
                    <div className="web-page-footer-links-container">
                        <p>Copyright ©{new Date().getFullYear()} J4Crew LTD</p>
                    </div>
                    <div style={{height: 30}}></div>
                </div>
            </Page>
        );

    }

}

export default FindJobs;