import React, { Component } from "react";
import "./index.css";
import { Link } from "react-router-dom";

/* Components */
import Page from "../../components/Page";

class NotFound extends Component {

    render() {

        return(
            <Page linear={true}>
                <div className="not-found-container">
                    <p className="not-found-title">Lost at sea? Page not found or no access.</p>
                    <p className="not-found-subtitle margin-top-10px">If you need assistance you can <Link to={"/contact-us"}>contact us</Link> or visit our <Link to={"/"}>home page</Link>.</p>
                </div>
            </Page>
        );

    }

}

export default NotFound;