import { SET_JOBS, REMOVE_JOB } from "../types/job";

export let setJobs = (data) => dispatch => {

    return dispatch({
        type: SET_JOBS,
        data,
    });
    
}

export let removeJob = (job) => dispatch => {

    return dispatch({
        type: REMOVE_JOB,
        job,
    });
    
}