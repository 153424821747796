import {
    SET_EXPERIENCE,
    RESET_EXPERIENCE,
} from "../types/experience";

const initialState = {
    data: {
        companyName: "",
        position: "",
        fromDate: "",
        toDate: "", 
        present: false,
        description: "",
    },
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_EXPERIENCE:

            let ops = {};

            action.data.forEach(item => {
                ops[item.propName] = item.value;
            });
        
            return {
                ...state,
                data: {
                    ...state.data,
                    ...ops,
                },
            };

        case RESET_EXPERIENCE:

            return {
                ...state,
                data: {
                    companyName: "",
                    position: "",
                    fromDate: "",
                    toDate: "", 
                    present: false,
                    description: "",
                },
            }
        
        default:
            return state;

    }

};