import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

/* Actions */
import { setAuth, setUserData } from "../../redux/actions/user";
import { toggleBurgerStatus, toggleBurgerMenu, setLogOut } from "../../redux/actions/misc";

class NavigationBar extends Component {

    handleBurgerMenu = (status) => {

        this.props.toggleBurgerStatus(status);

        return setTimeout(() => {
            return this.props.toggleBurgerMenu(true);
        }, 125);

    }

    render() {

        const { user } = this.props;
        const userReady = !user.isLoggingIn && user.isLoggedIn && user?.data?.profile?.profileType !== undefined && (user.data.profile.profileType !== "upload" || (user.data.profile.initialSetupCompleted && user.data.isVerified));
        const isAdmin = !user.isLoggingIn && user.isLoggedIn && user?.data?.userType === "Admin";

        return(
            <div className="navigation-bar-container" style={{ background: this.props.linear ? "linear-gradient(180deg, #192A56 0%, #3E5383 100%), #3E5383" : "transparent"  }}>
                {userReady &&
                    <div className="navigation-bar-left-side-items-container">
                        <div className="navigation-bar-left-side-item-container" onClick={() => this.handleBurgerMenu(true)}>
                            <img src={require("../../assets/svgs/burger.svg")} alt="" />
                        </div>
                    </div>
                }
                {isAdmin &&
                    <div className="navigation-bar-left-side-items-container">
                        <div className="navigation-bar-left-side-item-container" onClick={() => this.handleBurgerMenu(true)}>
                            <img src={require("../../assets/svgs/burger.svg")} alt="" />
                        </div>
                    </div>
                }
                {!user.isLoggingIn && (user.data || !user.isLoggedIn) &&
                    <div className="navigation-bar-logo-container" style={isAdmin ? {} : !userReady ? {justifyContent: "flex-start"} : {}}>
                        {!user.isLoggedIn && !this.props?.match?.path.includes("/sign-up") && !this.props?.match?.path.includes("/sign-in") && !this.props?.match?.path.includes("/reset-password") && !this.props?.match?.path.includes("/reference-verification") &&
                            <div className="navigation-bar-no-auth-burger" onClick={() => this.handleBurgerMenu(true)}>
                                <img src={require("../../assets/svgs/burger.svg")} alt="" />
                            </div>
                        }
                        <Link to={"/"}>
                            <img src={require("../../assets/svgs/j4crew-logo-new.svg")} alt="J4Crew" />
                        </Link>
                        {!user.isLoggedIn && !this.props?.match?.path.includes("/sign-up") && !this.props?.match?.path.includes("/sign-in") && !this.props?.match?.path.includes("/reset-password") && !this.props?.match?.path.includes("/reference-verification") &&
                            <div className="navigation-bar-home-pages-menu-container">
                                <div className="navigation-bar-home-pages-menu-item-container">
                                    <Link to={"/find-jobs"} style={this.props?.match?.path === "/find-jobs" ? { fontFamily: "Inter-Bold", fontWeight: "bold" } : {}}>Find Jobs</Link>
                                </div>
                                <div className="navigation-bar-home-pages-menu-item-container">
                                    <Link to={"/looking-for-crew"} style={this.props?.match?.path === "/looking-for-crew" ? { fontFamily: "Inter-Bold", fontWeight: "bold" } : {}}>Looking for Crew?</Link>
                                </div>
                                <div className="navigation-bar-home-pages-menu-item-container">
                                    <Link to={"/about-us"} style={this.props?.match?.path === "/about-us" ? { fontFamily: "Inter-Bold", fontWeight: "bold" } : {}}>About Us</Link>
                                </div>
                                <div className="navigation-bar-home-pages-menu-item-container">
                                    <Link to={"/cv-advice"} style={this.props?.match?.path === "/cv-advice" ? { fontFamily: "Inter-Bold", fontWeight: "bold" } : {}}>CV Advice</Link>
                                </div>
                                <div className="navigation-bar-home-pages-menu-item-container">
                                    <Link to={"/contact-us"} style={this.props?.match?.path === "/contact-us" ? { fontFamily: "Inter-Bold", fontWeight: "bold" } : {}}>Contact Us</Link>
                                </div>
                            </div>
                        }
                    </div>
                }
                {user?.data &&
                    <div className="navigation-bar-right-side-items-container">
                        {userReady &&
                            <Link to={"/settings"}>
                                <div className="navigation-bar-right-side-settings-container">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke={"#FFFFFF"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.4 15C19.1277 15.6171 19.2583 16.3378 19.73 16.82L19.79 16.88C20.1656 17.2551 20.3766 17.7642 20.3766 18.295C20.3766 18.8258 20.1656 19.3349 19.79 19.71C19.4149 20.0856 18.9058 20.2966 18.375 20.2966C17.8442 20.2966 17.3351 20.0856 16.96 19.71L16.9 19.65C16.4178 19.1783 15.6971 19.0477 15.08 19.32C14.4755 19.5791 14.0826 20.1724 14.08 20.83V21C14.08 22.1046 13.1846 23 12.08 23C10.9754 23 10.08 22.1046 10.08 21V20.91C10.0642 20.2327 9.63587 19.6339 9 19.4C8.38291 19.1277 7.66219 19.2583 7.18 19.73L7.12 19.79C6.74486 20.1656 6.23582 20.3766 5.705 20.3766C5.17418 20.3766 4.66514 20.1656 4.29 19.79C3.91445 19.4149 3.70343 18.9058 3.70343 18.375C3.70343 17.8442 3.91445 17.3351 4.29 16.96L4.35 16.9C4.82167 16.4178 4.95235 15.6971 4.68 15.08C4.42093 14.4755 3.82764 14.0826 3.17 14.08H3C1.89543 14.08 1 13.1846 1 12.08C1 10.9754 1.89543 10.08 3 10.08H3.09C3.76733 10.0642 4.36613 9.63587 4.6 9C4.87235 8.38291 4.74167 7.66219 4.27 7.18L4.21 7.12C3.83445 6.74486 3.62343 6.23582 3.62343 5.705C3.62343 5.17418 3.83445 4.66514 4.21 4.29C4.58514 3.91445 5.09418 3.70343 5.625 3.70343C6.15582 3.70343 6.66486 3.91445 7.04 4.29L7.1 4.35C7.58219 4.82167 8.30291 4.95235 8.92 4.68H9C9.60447 4.42093 9.99738 3.82764 10 3.17V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3V3.09C14.0026 3.74764 14.3955 4.34093 15 4.6C15.6171 4.87235 16.3378 4.74167 16.82 4.27L16.88 4.21C17.2551 3.83445 17.7642 3.62343 18.295 3.62343C18.8258 3.62343 19.3349 3.83445 19.71 4.21C20.0856 4.58514 20.2966 5.09418 20.2966 5.625C20.2966 6.15582 20.0856 6.66486 19.71 7.04L19.65 7.1C19.1783 7.58219 19.0477 8.30291 19.32 8.92V9C19.5791 9.60447 20.1724 9.99738 20.83 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H20.91C20.2524 14.0026 19.6591 14.3955 19.4 15Z" stroke={"#FFFFFF"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </Link>
                        }
                        <div className="navigation-bar-right-side-item-container" onClick={() => this.props.setLogOut(true)}>
                            <img src={require("../../assets/svgs/log-out.svg")} alt="" />
                        </div>
                    </div>
                }
                {!user.isLoggingIn && !user.isLoggedIn && !user.data &&
                    <div className="navigation-bar-right-side-items-container">
                        <Link to={"/sign-in"}>
                            <div className="navigation-bar-log-in-button">
                                <span className="navigation-bar-log-in-button-text">Log In</span>
                            </div>
                        </Link>
                        <Link to="/sign-up">
                            <div className="navigation-bar-sign-up-button">
                                <span className="navigation-bar-sign-up-button-text">Register</span>
                            </div>
                        </Link>
                    </div>
                }
            </div>
        );
        

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setUserData, setAuth, toggleBurgerStatus, toggleBurgerMenu, setLogOut }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);