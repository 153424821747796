import React from "react";
import "./index.css";

export default ({ propName, value }) => {

    return(
        <div className="jobs-accordion-expanded-text-row-container">
            <p className="jobs-accordion-expanded-text-row-text"><span className="jobs-accordion-expanded-text-row-text-prop">{propName}:</span> {value}</p>
        </div>
    );

}