import {
    EMAIL_JUST_VERIFIED,
    TOGGLE_BURGER_STATUS,
    TOGGLE_BURGER_MENU,
    ADJUST_PROFILE_IMAGE,
    SET_PROFILE_BUILDER_FINISH_LINE,
    SET_CV_PREVIEW,
    SET_DELETE_ACCOUNT,
    SET_PASSWORD_CHANGED,
    SET_DETAILS_CHANGED,
    SET_LOG_OUT,
    SET_ALERT,
    SET_DELETE_LOCAL_YACHT_ACCOUNT,
    VIEW_LISTING,
} from "../types/misc";

const initialState = {
    emailJustVerified: false,
    history: null,
    burgerStatus: false,
    showBurger: false,
    adjustProfileImage: false,
    profileBuilderFinishLine: false,
    showCVPreview: false,
    previewJob: null,
    showDeleteAccount: false,
    deleteAccountAsAdmin: false,
    passwordChanged: false,
    detailsChanged: false,
    logOut: false,
    alertMessage: "",
    alertStyle: "",
    showAlert: false,
    showDeleteLocalYacht: false,
    deletedYacht: "",
    viewListing: false,
};

export default (state = initialState, action) => {

    switch (action.type) {

        case EMAIL_JUST_VERIFIED:

            return {
                ...state,
                emailJustVerified: action.data,
                history: action.history,
            };

        case ADJUST_PROFILE_IMAGE:

            return {
                ...state,
                adjustProfileImage: action.data,
            };

        case SET_PROFILE_BUILDER_FINISH_LINE:

            return {
                ...state,
                profileBuilderFinishLine: action.data,
            };

        case TOGGLE_BURGER_STATUS:

            return {
                ...state,
                burgerStatus: action.data,
            };
        
        case TOGGLE_BURGER_MENU:

            return {
                ...state,
                showBurger: action.data,
            };

        case SET_CV_PREVIEW:

            return {
                ...state,
                showCVPreview: action.data,
                previewJob: action.job,
            };

        case VIEW_LISTING:

            return {
                ...state,
                viewListing: action.data,
            };

        case SET_DELETE_ACCOUNT:

            return {
                ...state,
                showDeleteAccount: action.status,
                deleteAccountAsAdmin: action.admin,
            };

        case SET_DELETE_LOCAL_YACHT_ACCOUNT:

            return {
                ...state,
                showDeleteLocalYacht: action.status,
                deletedYacht: action.id,
            };
        
        case SET_PASSWORD_CHANGED:

            return {
                ...state,
                passwordChanged: action.data,
            };

        case SET_DETAILS_CHANGED:

            return {
                ...state,
                detailsChanged: action.data,
            };

        case SET_LOG_OUT:

            return {
                ...state,
                logOut: action.data,
            };

        case SET_ALERT:

            return {
                ...state,
                alertMessage: action.message,
                alertStyle: action.style,
                showAlert: action.status,
            };
        
        default:
            return state;

    }

};