import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { setAlert } from "../../../../redux/actions/misc";
import { setCVProfile, setCVApplication } from "../../../../redux/actions/admin";

/* API requests */
import { getApplication } from "../../../../api-requests/application";

/* Components */
import OnlineCV from "../../../DashboardCandidate/components/CV/components/OnlineCV";
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import { updateCandidate } from "../../../../api-requests/candidate";

class Application extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            application: {},
        };

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        if (this.props.match.params.applicationId) {
            return getApplication(this.props.match.params.applicationId)
                .then(result => {
                    this.props.setCVProfile(result.user);
                    this.props.setCVApplication(result.application);
                    return this.setState({ application: result.application, user: result.user });
                });
        }

    }

    saveCandidateNotes = (_id, value) => {

        return updateCandidate(_id, [{ propName: "notes", value: value }])
            .then(_ => {
                this.props.setAlert("Candidate notes updated!", "success", true);
                return setTimeout(() => {
                    return this.props.setAlert("Candidate notes updated!", "success", false);
                }, 2700);
            });

    }

    render() {

        return(
            <div className="application-main-container">
                <div className="yacht-listings-name-container app-name-helper">
                    {this.state?.application?.job?.yacht &&
                        <p>{this.state?.application?.job?.yacht?.yachtType === "Motor Yacht" ? "M/Y" : "S/Y"} {this.state.application.job.yacht.name} - {this.state.application.job.position.name || ""}</p>
                    }
                </div>
                <div className="profile-master-container margin-top-20px">
                    <OnlineCV user={this.state.user} admin={true} application={true} />
                </div>
                <DashboardSectionBase title={"Candidate Notes"} description={"Any relevant information about this candidate."}>
                    <div className="application-candidate-notes-container margin-top-20px">
                        <textarea placeholder={"Type..."} value={this.state?.user?.profile?.notes || ""} onChange={(e) => this.setState({ user: { ...this.state.user, profile: { ...this.state.user.profile, notes: e.target.value } } })}></textarea>
                    </div>
                    <div className="application-candidate-notes-save-button margin-top-20px" onClick={() => this.saveCandidateNotes(this.state.user.profile._id, this.state.user.profile.notes)}>
                        <p>Save</p>
                    </div>
                </DashboardSectionBase>
            </div>
        );

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAlert, setCVProfile, setCVApplication }, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(Application);