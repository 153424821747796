import {
    SET_USER_AUTH,
    SET_USER_DATA,
    SET_USER_LOGGING,
    UPDATE_USER_DATA,
    UPDATE_USER_PROFILE,
    UPDATE_USER_DOCUMENTS,
    UPDATE_USER_PROFILE_LOOKING_FOR,
    UPDATE_USER_PROFILE_YACHT,
} from "../types/user";

/* Helpers */
import { save } from "../../helpers/candidate";
import { saveUser } from "../../helpers/user";
import { readDob } from "../../helpers/date";

const initialState = {
    isLoggingIn: true,
    isLoggedIn: false,
    isFetching: true,
    data: null,
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_USER_LOGGING:

            return {
                ...state,
                isLoggingIn: action.data,
            };

        case SET_USER_AUTH:

            return {
                ...state,
                isLoggedIn: action.data,
            };

        case SET_USER_DATA:
            
            if (action.data) {
                return {
                    ...state,
                    data: {
                        ...action.data,
                        profile: {
                            ...action.data.profile,
                            dob: readDob(action.data.profile.dob),
                        },
                    },
                    isFetching: false,
                };
            } else {
                return {
                    ...state,
                    data: action.data,
                    isFetching: false,
                }
            }

        case UPDATE_USER_DATA:

            let userOps = {};

            action.data.forEach(item => {
                userOps[item.propName] = item.value;
            });
        
            if (action.autoSave) saveUser(action.data, state.data);

            return {
                ...state,
                data: {
                    ...state.data,
                    ...userOps,
                },
            };


        case UPDATE_USER_PROFILE:

            let profileOps = {};

            action.data.forEach(item => {
                profileOps[item.propName] = item.value;
                if (action.autoSave) save(item.propName, item.value, state.data.profile);
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    profile: {
                        ...state.data.profile,
                        ...profileOps,
                    },
                }
            };

        case UPDATE_USER_DOCUMENTS:

            return {
                ...state,
                data: {
                    ...state.data,
                    profile: {
                        ...state.data.profile,
                        [action.propName]: state.data.profile[action.propName].map(item => {
                            if (item.id === action.index) {
                                return {
                                    ...item,
                                    url: action.data.url,
                                    loading: false,
                                };
                            } else {
                                return item;
                            }
                        }),
                    }
                }
            }

        case UPDATE_USER_PROFILE_LOOKING_FOR:

            let lookingForOps = {};

            action.data.forEach(item => {
                lookingForOps[item.propName] = item.value;
            });

            let value = {
                ...state.data.profile.lookingFor,
                ...lookingForOps,
            };

            if (action.autoSave) save("lookingFor", value, state.data.profile);
        
            return {
                ...state,
                data: {
                    ...state.data,
                    profile: {
                        ...state.data.profile,
                        lookingFor: value,  
                    },
                }
            };

        case UPDATE_USER_PROFILE_YACHT:

            let yachtOps = {};

            action.data.forEach(item => {
                yachtOps[item.propName] = item.value;
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    profile: {
                        ...state.data.profile,
                        yacht: {
                            ...state.data.profile.yacht,
                            ...yachtOps,
                        },  
                    },
                }
            };
        
        default:
            return state;

    }

};