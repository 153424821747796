import {
    SET_COMPLETED_USERS,
    SET_INCOMPLETE_USERS,
    SET_USERS_COUNT,
    REMOVE_CANDIDATE_FROM_THE_LIST,
    SET_CV_PROFILE,
    SET_CV_APPLICATION,
    SET_CREW,
    ADD_CREW,
    SET_YACHTS,
    UPDATE_LOCAL_YACHT,
    SET_LISTING,
    UPDATE_CV_PROFILE,
} from "../types/admin";

const initialState = {
    completedUsersCount: 0,
    isFetchingCompletedUsers: true,
    completedUsers: [],
    isFetchingIncompleteUsers: true,
    incompleteUsers: [],
    cvProfile: null,
    cvApplication: null,
    crew: [],
    isFetchingCrew: true,
    skipCrew: 50,
    yachts: [],
    isFetchingYachts: true,
    localYacht: {}, 
    listing: null,
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_USERS_COUNT:

            return {
                ...state,
                completedUsersCount: action.completedUsersCount,
            }

        case SET_COMPLETED_USERS:

            return {
                ...state,
                isFetchingCompletedUsers: false,
                completedUsers: action.data,
            }
        
        case SET_INCOMPLETE_USERS:

            return {
                ...state,
                isFetchingIncompleteUsers: false,
                incompleteUsers: action.data,
            }

        case REMOVE_CANDIDATE_FROM_THE_LIST:
            
            return {
                ...state,
                [action.propName]: state[action.propName].filter(item => item._id !== action.pid),
                completedUsersCount: action.propName === "completedUsers" ? state.completedUsersCount-1 : state.completedUsersCount,
            }

        case SET_CV_PROFILE:

            return {
                ...state,
                cvProfile: action.data,
            }

        case UPDATE_CV_PROFILE:

            let profileOps = {};

            action.data.forEach(item => {
                profileOps[item.propName] = item.value;            
            });

            return {
                ...state,
                cvProfile: {
                    ...state.cvProfile,
                    profile: {
                        ...state.cvProfile.profile,
                        ...profileOps,
                    },
                }
            };

        case SET_LISTING:

            return {
                ...state,
                listing: action.data,
            };

        case SET_CV_APPLICATION:

            return {
                ...state,
                cvApplication: action.data,
            }
        
        case SET_CREW:

            return {
                ...state,
                isFetchingCrew: false,
                crew: action.data,
                skipCrew: 50,
            }

        case ADD_CREW:

            return {
                ...state,
                crew: [...state.crew, ...action.data],
                skipCrew: state.skipCrew+50,
            }

        case SET_YACHTS:

            return {
                ...state,
                isFetchingYachts: false,
                yachts: action.data,
            }

        case UPDATE_LOCAL_YACHT:

            let localYachtOps = {};

            action.data.forEach(item => {
                localYachtOps[item.propName] = item.value;
            });

            return {
                ...state,
                localYacht: {
                    ...state.localYacht,
                    ...localYachtOps,
                },
            }
        
        default:
            return state;

    }

};