import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile } from "../../redux/actions/user";

/* Helpers */
import { handleAvailability } from "../../helpers/candidate";

/* API requests */
import { updateCandidate } from "../../api-requests/candidate";

class SelectAvailability extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };

    }

    updateAvailability = (id, isAvailable, hasNotice, noticePeriod) => {

        return this.setState({ show: false }, () => {

            const body = [
                { propName: "isAvailable", value: isAvailable },
                { propName: "hasNotice", value: hasNotice },
                { propName: "noticePeriod", value: noticePeriod },
            ];

            return updateCandidate(id, body)
                .then(result => {
                    return this.props.updateUserProfile(result.data);
                });

        });

    }

    render() {

        const { profile } = this.props.user.data;

        return(
            <div className="select-availability-dropdown-master-container">
                <div className={!this.state.show ? "select-availability-dropdown-container" : "select-availability-dropdown-container select-availability-dropdown-open-container"} onClick={() => this.setState({ show: !this.state.show })}>
                    <div className="select-availability-dropdown-icon-container">
                        <img src={require("../../assets/svgs/calendar.svg")} alt="" />
                    </div>
                    <div className="select-availability-dropdown-main-container">
                        <div className="select-availability-dropdown-main-item-title-container">
                            <p className="select-availability-dropdown-main-label">Your Availability</p>
                        </div>
                        <div className="select-availability-dropdown-main-item-value-container">
                            <p className="select-availability-dropdown-main-value" style={{color: profile.isAvailable ? "#333333" : "#F55D5D"}}>{handleAvailability(profile)}</p>
                        </div>
                    </div>
                    <div className="select-availability-dropdown-icon-container">
                        <img src={require("../../assets/svgs/chevron-down.svg")} alt="" />
                    </div>
                </div>
                {this.state.show &&
                    <div className="select-availability-dropdown-list-container">
                        <div className="select-availability-dropdown-list-item-container" style={{border: "none"}} onClick={() => this.updateAvailability(profile._id, true, false, 0)}>
                            <p>Immediate</p>
                        </div>
                        <div className="select-availability-dropdown-list-item-container" onClick={() => this.updateAvailability(profile._id, true, true, 7)}>
                            <p>Available in 1 week</p>
                        </div>
                        <div className="select-availability-dropdown-list-item-container" onClick={() => this.updateAvailability(profile._id, true, true, 14)}>
                            <p>Available in 2 weeks</p>
                        </div>
                        <div className="select-availability-dropdown-list-item-container" onClick={() => this.updateAvailability(profile._id, true, true, 28)}>
                            <p>Available in 4+ weeks</p>
                        </div>
                        <div className="select-availability-dropdown-list-item-container" onClick={() => this.updateAvailability(profile._id, false, false, 0)}>
                            <p>Unavailable</p>
                        </div>
                    </div>
                }
            </div>
        ); 

    }  

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAvailability);