import React from "react";
import "./index.css";

export default ({ value, propName, placeholder, onChangeText }) => {

    return(
        <div className="text-area-container">
            <textarea value={value} placeholder={placeholder} onChange={(e) => onChangeText([{ propName: propName, value: e.target.value }])} onBlur={(e) => onChangeText([{ propName: propName, value: e.target.value }], true)} />
            <div className="text-area-footer-container margin-top-10px">
                <p className="text-area-footer-title">We suggest around 600 characters</p>
                <p className="text-area-footer-value">{value ? value.length : 0}</p>
            </div>
        </div>
    );

}