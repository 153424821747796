import React from "react";
import "./index.css";

const handleButtonStyle = (type) => {

    switch (type) {

        case "primary":
            return "primary-button-container";
        case "tertiary":
            return "tertiary-button-container";
        default:
            return "";

    }

}

const handleButtonTextStyle = (type) => {

    switch (type) {

        case "primary":
            return "primary-button-text";
        case "tertiary":
            return "tertiary-button-text";
        default:
            return "";

    }

}

const handleDisabledButtonStyle = (type) => {

    switch (type) {

        case "primary":
            return "primary-button-container primary-disabled";
        case "tertiary":
            return "tertiary-button-container tertiary-disabled";
        default:
            return "";

    }

}

const handleDisabledButtonTextStyle = (type) => {

    switch (type) {

        case "primary":
            return "primary-button-text";
        case "tertiary":
            return "tertiary-text-disabled";
        default:
            return "";

    }

}

const Button = ({ title, type, onClick, loading }) => {

    if (!loading) {
        return(
            <div className={handleButtonStyle(type)} onClick={onClick}>
                <span className={handleButtonTextStyle(type)}>{title}</span>
            </div>
        );
    } else {
        return(
            <div className={handleDisabledButtonStyle(type)}>
                <span className={handleDisabledButtonTextStyle(type)}>{title}</span>
            </div>
        );
    }

}

export default Button;