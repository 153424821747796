import React from "react";
import "./index.css";
import DashboardSectionBase from "../../../components/DashboardSectionBase";

export default ({ title, name, updateState }) => {

    return(
        <DashboardSectionBase title={title} description={`Hi ${name.split(" ")[0]}! Please choose an option below, providing a full reference will take a few minutes.`} overflow={true}>
            <div className="reference-verification-buttons-container margin-top-20px">
                <div className="reference-verification-primary-button" onClick={() => updateState(["step", "full"], [1, true], true)}>
                    <p>Provide a reference</p>
                </div>
                <div style={{width: 20, height: 20}}></div>
                <div className="reference-verification-secondary-button" onClick={() => updateState(["step", "full"], [1, false], true)}>
                    <p>Leave a quick comment</p>
                </div>                
            </div>
        </DashboardSectionBase>
    );

}

