import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import WebPageHome from "../WebPageHome";
import Home from "../Home";

/* Helpers */
import { convertQueryStringToObject } from "../../helpers/reset-password";

/* Actions */
import { updateUserData } from "../../redux/actions/user";
import { setEmailJustVerified } from "../../redux/actions/misc";
import { toggleEmailVerifiedAlert } from "../../redux/actions/initial-setup";

/* API requests */
import { verifyEmail } from "../../api-requests/verify";
import Page from "../../components/Page";

class Init extends Component {

    componentDidMount() {

        const data = convertQueryStringToObject(this.props.location.search);

        if (data.verificationCode) {
            return verifyEmail(data.verificationCode)
                .then(result => {
                    if (result.status === 201) {
                        this.props.updateUserData([ { propName: "isVerified", value: true } ], false);
                        this.props.setEmailJustVerified(true, this.props.history);
                        this.props.history.push("/");
                        setTimeout(() => {
                            this.props.toggleEmailVerifiedAlert(true);
                        }, 500);
                        return setTimeout(() => {
                            return this.props.toggleEmailVerifiedAlert(false);
                        }, 5000);
                    }
                    return;
                });
        }

    }

    handleView = (props) => {

        if (props.user.isLoggingIn) return <Page {...props} linear={true}></Page>;
        if (props.user.isLoggedIn) return <Home {...props} />;

        return <WebPageHome {...props} />;

    }

    render() {

        return this.handleView(this.props);    

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserData, toggleEmailVerifiedAlert, setEmailJustVerified }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Init);