import React from "react";
import "./index.css";

const exceptions = ["position", "qualifications", "moreQualifications"]

export default ({ label, placeholder, icon, iconLeft, show, toggleShow, value, values, propName, options, onChangeText, select, single, qualIndex }) => {

    const suggestions = options.filter(option => {
        if (exceptions.includes(propName)) {
            let flatten = values.map(val => val.name);
            return (value || show) && !flatten.includes(option.name) && option.name.toLowerCase().includes(value.toLowerCase());
        } else {
            return value && !values.includes(option.value) && option.value.toLowerCase().includes(value.toLowerCase());
        }
    });

    return(
        <div className="text-input-with-dropdown-master-wrapper">
            <div className="text-input-with-dropdown-wrapper" style={{borderRadius: (suggestions.length > 0 || show) && "7px 7px 0 0"}}>
                {iconLeft &&
                    <div className="text-input-left-icon-container">
                        <img src={iconLeft} alt="" />
                    </div>
                }
                <div className="text-input-with-dropdown-main-container">
                    <div className="text-input-with-dropdown-label-container">
                        <p className="text-input-with-dropdown-label">{label}</p>
                    </div>
                    <div className="text-input-with-dropdown-container">
                        {single &&
                            <input value={value} onChange={(e) => onChangeText(e.target.value)} onBlur={(e) => handleOnBlur(select, propName, e.target.value, suggestions.length)} className="text-input-with-dropdown" type="text" placeholder={placeholder} />
                        }
                        {!single &&
                            <input value={value} onChange={(e) => onChangeText(e.target.value)} className="text-input-with-dropdown" type="text" placeholder={placeholder} />
                        }
                    </div>
                </div>
                {icon &&
                    <div className="text-input-right-icon-container" style={{cursor: propName === "position" ? "pointer" : "default"}} onClick={toggleShow}>
                        <img src={icon} alt="" />
                    </div>
                }
            </div>
            {value && suggestions.length > 0 &&
                <div className="text-input-with-dropdown-result-list-container">
                    {renderDropdown(suggestions, select, propName, values, onChangeText, single, qualIndex)}
                </div>
            }
            {show &&
                <div className="text-input-with-dropdown-result-list-container">
                    {renderDropdown(suggestions, select, propName, values, onChangeText, single, qualIndex)}
                </div>
            }
        </div>
    );

}

const renderDropdown = (suggestions, select, propName, values, onChangeText, single, qualIndex) => {

    return suggestions.map((item, index) => {
        return(
            <div key={index} className="text-input-with-dropdown-results-list-item-container" style={{border: "none"}} onClick={() => handleClick(select, onChangeText, propName, values, item, single, qualIndex)}>
                {propName === "position" ?
                    <p>{item.name} ({item.positionType})</p>
                :
                    propName === "qualifications" || propName === "moreQualifications" ?
                        <p>{item.name}</p>
                    :
                        <p>{item.value}</p>
                }
            </div>
        )
    });

}

const handleClick = (select, onChangeText, propName, values, item, single, qualIndex) => {

    let value;

    if (single) {
        if (propName === "position") {
            return select(item);
        } else {
            value = item.value;
            return select([ { propName: propName, value: value } ], true);
        }
    } else {
        if (propName === "position" || propName === "qualifications") {
            value = item;
            select(item, propName);
        } else if (propName === "moreQualifications") {
            value = item;
            select(item, propName, qualIndex);
        } else if (propName === "skills" || propName === "equipment") {
            value = item.value;
            select(item.value, propName);
        } else {
            value = [...values, item.value];
            select([ { propName: propName, value: value } ], true);
        }
        return onChangeText("");
    }

}

const handleOnBlur = (select, propName, value, len) => {

    if (propName === "position") return;

    if (len < 1) {
        return select([ { propName, value } ], true)
    }
    return;

}