import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import InitialSetupBase from "../../../../components/InitialSetupBase";
import SelectAvailability from "../../../../components/SelectAvailability";
import SelectLocation from "../../../../components/SelectLocation";
import DesiredMonthlySalary from "../../../../components/DesiredMonthlySalary";

/* Actions */
import { updateUserProfileLookingFor } from "../../../../redux/actions/user";

class BasicInformation extends Component {

    render() {

        const { profile } = this.props.user.data;

        return(
            <InitialSetupBase title={"Initial Set Up - Basic Information"} description={"Please provide some important information."}>
                <div className="margin-top-20px">
                    <SelectAvailability />
                </div>
                <div className="margin-top-20px">
                    <SelectLocation />
                </div>
                <div className="margin-top-20px">
                    <DesiredMonthlySalary profile={profile} updateUserProfileLookingFor={this.props.updateUserProfileLookingFor} />
                </div>
            </InitialSetupBase>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfileLookingFor }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformation);