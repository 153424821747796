import React from "react";
import "./index.css";

export default ({ title, upload, propName, loading, multiple }) => {

    if (loading) {
        return(
            <div className="upload-document-disabled-button">
                <p className="upload-document-disabled-button-p">{title}</p>
            </div>
        );
    } else {
        return(
            <label className="upload-document-button">
                <input hidden={true} type={"file"} name={"file"} onChange={(e) => upload(e.target.files, propName)} multiple={multiple} />
                <p className="upload-document-button-p">{title}</p>
            </label>
        );
    }   

}