import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AvatarEditor from "react-avatar-editor";
import { FileDrop } from "react-file-drop";
import ReactSlider from "react-slider";

/* Actions */
import { setProfilePictureEditor, setProfilePicture, setProfilePictureScale, setProfilePictureDegree } from "../../redux/actions/initial-setup";

class AdjustPhoto extends Component {

    componentDidMount() {

        if (this.props.user.data.profile.profileImage) {
            return this.props.setProfilePicture(this.props.user.data.profile.profileImage);
        };

    }

    handleImage = (file) => {

        if (file) {
            if (file.type !== "image/jpeg" && file.type !== "image/png") return alert(file.type);
            return setTimeout(() => {
                return this.props.setProfilePicture(file);
            }, 125);
        }

    }

    handleRotate = (degree) => {

        if (degree === 0) {
            return this.props.setProfilePictureDegree(270);
        }

        if (degree === 90) {
            return this.props.setProfilePictureDegree(0);
        }

        if (degree === 180) {
            return this.props.setProfilePictureDegree(90);
        }

        if (degree === 270) {
            return this.props.setProfilePictureDegree(180);
        }

    }

    handleScale = (value) => {

        if (value < 1) return this.props.setProfilePictureScale(1);
        if (value > 5) return this.props.setProfilePictureScale(5);

        return this.props.setProfilePictureScale(value);

    }

    setEditorRef = (editor) => {

        return this.props.setProfilePictureEditor(editor);

    }

    render() {

        return(
            <>
                {!this.props.initialSetup.image && 
                    <FileDrop onDrop={(files) => this.handleImage(files[0])}>
                        <label className="adjust-photo-input-field-container margin-top-20px">
                            <div className="adjust-photo-input-field-wrapper">
                                <img src={require("../../assets/svgs/upload-photo.svg")} alt="" />
                                <div className="adjust-photo-input-field">
                                    <p className="adjust-photo-input-field-text">Drag & Drop File or <label className="adjust-photo-input-field-text-browse"><input className="adjust-photo-input" hidden={true} type={"file"} name={"file"} onChange={(e) => this.props.setProfilePicture(e.target.files[0])} />browse</label> your files</p>
                                </div>
                            </div>
                        </label>
                    </FileDrop>
                }
                {this.props.initialSetup.image &&
                    <div className="adjust-photo-avatar-editor-container">
                        <div className="adjust-photo-avatar-editor-rotate-container">
                            <img onClick={() => this.handleRotate(this.props.initialSetup.degree)} className="adjust-photo-avatar-editor-rotate-icon" src={require("../../assets/svgs/rotate-left.svg")} alt="" />
                        </div>
                        <div className="adjust-photo-avatar-editor-wrapper">
                            <div>
                                <AvatarEditor
                                    ref={this.setEditorRef}
                                    image={this.props.initialSetup.image}
                                    border={0}
                                    width={200}
                                    height={200}
                                    scale={this.props.initialSetup.scale}
                                    rotate={this.props.initialSetup.degree}
                                />
                            </div>
                            <div className="adjust-photo-slider-container">
                                <div className="adjust-photo-slider-button" onClick={() => this.handleScale(this.props.initialSetup.scale+0.2)}>
                                    <img src={require("../../assets/svgs/plus.svg")} alt="" />
                                </div>
                                <div className="adjust-photo-slider-wrapper">
                                    <ReactSlider
                                        className="adjust-photo-slider"
                                        thumbClassName="adjust-photo-slider-thumb"
                                        thumbActiveClassName="adjust-photo-slider-thumb-active"
                                        trackClassName="adjust-photo-slider-track"
                                        invert={true}
                                        min={1}
                                        max={5}
                                        step={0.1}
                                        onChange={(value) => this.props.setProfilePictureScale(parseFloat(value))}
                                        orientation={"vertical"}
                                        value={this.props.initialSetup.scale}
                                        defaultValue={1}
                                    />
                                </div>
                                <div className="adjust-photo-slider-button" onClick={() => this.handleScale(this.props.initialSetup.scale-0.2)}>
                                    <img src={require("../../assets/svgs/minus.svg")} alt="" />
                                </div>
                            </div>
                        </div>
                        <label className="adjust-photo-replace-button-container margin-top-20px">
                            <input className="adjust-photo-input" hidden={true} type={"file"} name={"file"} onChange={(e) => this.props.setProfilePicture(e.target.files[0])} />
                            <div className="adjust-photo-replace-button-icon-container">
                                <img className="adjust-photo-replace-button-icon" src={require("../../assets/svgs/upload-cloud.svg")} alt="" />
                            </div>
                            <div className="adjust-photo-replace-button-title-container">
                                <p className="adjust-photo-replace-button-title">Replace</p>
                            </div>
                            <div className="adjust-photo-replace-button-icon-container">
                            </div>
                        </label>
                    </div>
                }
            </>
        )
    
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setProfilePictureEditor, setProfilePicture, setProfilePictureScale, setProfilePictureDegree }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustPhoto);