import React, { Component } from "react";
import "./index.css";
import PlacesAutocomplete from "react-places-autocomplete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* API requests */
import { getQualifications } from "../../../../api-requests/qualification";
import { getPositions } from "../../../../api-requests/position";
import { getYacht } from "../../../../api-requests/yacht";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import TextInputDropdown from "../../../../components/TextInputDropdown";
import RegularTextInput from "../../../../components/RegularTextInput";
import SelectedItems from "../../../../components/SelectedItems";
import Categories from "../../../../components/Categories";
import CheckBox from "../../../../components/CheckBox";

/* Helpers */
import { handleYachtSize, handleYachtType, handleYachtIcon, handleJobPostTitle, handleYachtAge, handleVisas, handleYachtBuildRegion, handleContractType, handleStartDate } from "../../../../helpers/job";
import { handleMultiple, handleMultipleQualifications } from "../../../../helpers/candidate";
import { handleDraft, getJob, listJob, getUniqueReference } from "../../../../api-requests/job";
import { convertQueryStringToObject } from "../../../../helpers/reset-password";

/* Actions */
import { setAlert } from "../../../../redux/actions/misc";

class AddListing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: "",
            jobId: "",
            yacht: {
                itinerary: [],
            },
            step: 1,
            completed: false,
            positions: [],
            positionKeyword: "",
            qualificationsKeyword: "",
            moreQualificationsKeyword: "",
            qualIndex: 0,
            showPositions: false,
            job: {
                eng1_cert: true,
                stcwBasic_cert: true,
                qualifications: [],
                moreQualifications: [[]],
            },
            allQualifications: [],
            qualificationsShow: false,
        };

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        getUniqueReference()
            .then(ref => {
                return this.setState({ job: { ...this.state.job, reference: ref } })
            });
        
        getQualifications()
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ allQualifications: result.data });
            });

        getPositions()
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ positions: result.data });
            });

        if (this.props.match.params.yachtId) {
            getYacht(this.props.match.params.yachtId)
                .then(result => {
                    return this.setState({ yacht: result });
                });
        }

        if (this.props.history.location.search) {
            let queryString = convertQueryStringToObject(this.props.history.location.search);
            if (queryString.job) {
                if (queryString.status) this.setState({ status: queryString.status });
                getJob(queryString.job)
                    .then(result => {
                        return this.setState({
                            jobId: result._id,
                            job: {
                                ...result,
                                eng1_cert: result.eng1,
                                stcwBasic_cert: result.stcwBasic,
                                stcwAdvanced_cert: result.stcwAdvanced,
                                b1b2_visa: result.b1b2,
                                c1d_visa: result.c1d,
                                schengen_visa: result.schengen,
                                greenCard_visa: result.greenCard,
                            },
                            positionKeyword: `${result.position.name} (${result.position.positionType})`,
                        })
                    });
            }
        }

    }

    renderSelected = (selected) => {

        return selected.map((select, index) => {
            return(
                <div key={index} className="yacht-details-selected-position-container">
                    <div className="yacht-details-selected-position-name-container">
                        <span>{select}</span>
                    </div>
                    <div className="yacht-details-selected-position-icon-container">
                        <img src={require("../../../../assets/svgs/x.svg")} alt="" />
                    </div>
                </div>
            );
        });

    }

    selectPosition = (item) => {

        return this.setState({ job: { ...this.state.job, position: item }, positionKeyword: `${item.name} (${item.positionType})`, showPositions: false });

    }

    selectQualification = (item, propName) => {

        return this.setState({ job: { ...this.state.job, [propName]: [...this.state.job[propName], item] } });

    }

    selectMoreQualification = (item, propName, index) => {

        let newQuals = [...this.state.job[propName][index], item];

        let updatedQuals = this.state.job[propName].map((row, rowIndex) => {
            if (rowIndex === index) return newQuals;
            return row;
        });

        return this.setState({ job: { ...this.state.job, [propName]: updatedQuals } });

    }

    deselectMoreQualification = (propName, value, rowIndex) => {

        let updatedQuals = this.state.job[propName].map((row, index) => {
            if (index === rowIndex) return value;
            return row;
        });

        return this.setState({ job: { ...this.state.job, [propName]: updatedQuals } });

    }

    deleteAlternative = (rowIndex, len) => {

        if (rowIndex === 0 && len === 1) {
            return this.setState({ qualIndex: 0 }, () => {
                return this.setState({ job: { ...this.state.job, moreQualifications: [[]] } })
            });
        } else {
            if (rowIndex === this.state.qualIndex && rowIndex !== 0) {
                return this.setState({ qualIndex: this.state.qualIndex-1 }, () => {
                    return this.setState({ job: { ...this.state.job, moreQualifications: this.state.job.moreQualifications.filter((_, index) => index !== rowIndex) } });
                });
            } else if (rowIndex === this.state.qualIndex && rowIndex === 0) {
                return this.setState({ qualIndex: this.state.qualIndex+1 }, () => {
                    return this.setState({ job: { ...this.state.job, moreQualifications: this.state.job.moreQualifications.filter((_, index) => index !== rowIndex) } });
                });
            } else {

                return this.setState({ qualIndex: this.state.qualIndex < rowIndex ? this.state.qualIndex : this.state.qualIndex-1 }, () => {
                    return this.setState({ job: { ...this.state.job, moreQualifications: this.state.job.moreQualifications.filter((_, index) => index !== rowIndex) } });
                });
            }
        }

    }

    handleProceed = (step, job) => {

        if (step === 1) {
            if (!job.consultant) return alert("Please select consultant for this job.");
            if (!job.reference) return alert("Please select reference for this job.");
            if (!job.position) return alert("Please select required position.");
            if (!job.contractType) return alert("Please select contract type.");
            if (!job.salaryCurrency) return alert("Please select salary currency.");
            if (!job.salaryType) return alert("Please select salary type.");
            if (job.salaryType === "Range") {
                if (!job.salaryAmount) return alert("Please select minimum salary.");
                if (!job.salaryRangeMax) return alert("Please select maximum salary.");
            }
            if (job.salaryType === "Fixed") {
                if (!job.salaryAmount) return alert("Please select salary.");
            }
            if (job.contractType === "Rotation") {
                if (!job.periodLength || !job.periodLengthSecondary) return alert("Rotation length is required.");
            }
            if (!job.gender) return alert("Please select gender.");
            if (!job.startDateType) return alert("Please select start date type.");
            if (job.startDateType === "Approximate Date") {
                if (!job.startDateApprx) return alert("Please select approximate start date.");
            }
            if (job.visible === undefined) return alert("Please select job visibility.");
            
            return this.setState({ step: 2 }, () => {
                document.getElementById("Dashboard").scrollTo(0, 0);
                return handleDraft(this.state.jobId, this.state.job, this.state.yacht, this.props.user.data, true)
                    .then(result => {
                        if (this.state.jobId) {
                            return;
                        } else {
                            return this.setState({ jobId: result.data._id });
                        }
                    });
            });
        }

        if (step === 2) {

            return this.setState({ step: 3 }, () => {
                document.getElementById("Dashboard").scrollTo(0, 0);
                return handleDraft(this.state.jobId, this.state.job, this.state.yacht, this.props.user.data, true)
                    .then(result => {
                        if (this.state.jobId) {
                            return;
                        } else {
                            return this.setState({ jobId: result.data._id });
                        }
                    });
            });
        }

        if (step === 3) {
            if (!job.description) return alert("Please provide a job description.");

            return this.setState({ step: 4, completed: true }, () => {
                document.getElementById("Dashboard").scrollTo(0, 0);
                return handleDraft(this.state.jobId, this.state.job, this.state.yacht, this.props.user.data, true)
                    .then(result => {
                        if (this.state.jobId) {
                            return;
                        } else {
                            return this.setState({ jobId: result.data._id });
                        }
                    });
            });
        }


    }

    handlePrevious = (step) => {

        return this.setState({ step: step-1 }, () => {
            return document.getElementById("Dashboard").scrollTo(0, 0);
        });

    }

    handleDotClick = (step, currentStep, completed) => {

        if (completed) return this.setState({ step: step });
        if (step < currentStep) {
            return this.setState({ step: step });
        }
        return;

    }

    confirmListing = (id, yacht, status) => {

        if (status !== "archived") {
            return listJob(id)
                .then(result => {
                    if (result.success) {

                        if (this.props.match.path.includes("/yachts/")) {
                            this.props.history.push(`/yachts/listings/${yacht._id}/active`);
                        } else {
                            this.props.history.push(`/listings`);
                        }

                        this.props.setAlert((!status || status === "draft") ? "Listing added successfully!" : status === "active" ? "Listing edited successfully!" : "Position successfully re-listed!", "success", true);

                        return setTimeout(() => {
                            return this.props.setAlert((!status || status === "draft") ? "Listing added successfully!" : status === "active" ? "Listing edited successfully!" : "Position successfully re-listed!", "success", false);
                        }, 2700);

                    }
                });
        } else {
            return handleDraft("", this.state.job, this.state.yacht, this.props.user.data, false)
                .then(_ => {

                    if (this.props.match.path.includes("/yachts/")) {
                        this.props.history.push(`/yachts/listings/${yacht._id}/active`);
                    } else {
                        this.props.history.push(`/listings`);
                    }

                    this.props.setAlert("Position successfully re-listed!", "success", true);

                    return setTimeout(() => {
                        return this.props.setAlert("Position successfully re-listed!", "success", false);
                    }, 2700);

                });
        }

    }

    render() {

        let visas = handleVisas(this.state.job);
        let contractType = handleContractType(this.state.job);
        let startDate = handleStartDate(this.state.job);

        return(
            <div className="add-listing-container">
                {this.state.yacht.name &&
                    <div className="add-listing-title-container">
                        <p>{!this.state.status ? "New Listing" : this.state.status === "active" ? "Edit Listing" : this.state.status === "draft" ? "Complete Listing" : "Re-List Position"} | {this.state.yacht.name}</p>
                    </div>
                }
                <div className="add-listing-wrapper">
                    <div className="add-listing-progress-bar-container margin-top-2x">
                        <div className="add-listing-progress-bar-dot-container">
                            <div className="add-listing-progress-bar-dot-outer" style={this.state.step > 1 ? {backgroundColor: "#1377FF", cursor: "pointer"} : {backgroundColor: "white"}} onClick={() => this.handleDotClick(1, this.state.step, this.state.completed)}>
                                <div className="add-listing-progress-bar-dot-inner">
                                    {this.state.step > 1 &&
                                        <img src={require("../../../../assets/svgs/tick.svg")} alt="" />
                                    }
                                </div>
                            </div>
                            <div className="add-listing-progress-bar-dot-title">
                                <p>Job Details</p>
                            </div>
                            <div className="add-listing-progress-bar-separator"></div>
                        </div>
                        <div className="add-listing-progress-bar-dot-container">
                            <div className="add-listing-progress-bar-dot-outer" style={this.state.step > 2 ? {backgroundColor: "#1377FF", cursor: "pointer"} : {backgroundColor: "white"}} onClick={() => this.handleDotClick(2, this.state.step, this.state.completed)}>
                                {this.state.step > 1 &&
                                    <div className="add-listing-progress-bar-dot-inner">
                                        {this.state.step > 2 &&
                                            <img src={require("../../../../assets/svgs/tick.svg")} alt="" />
                                        }
                                    </div>
                                }
                            </div>
                            <div className="add-listing-progress-bar-dot-title">
                                <p>Certification</p>
                            </div>
                            <div className="add-listing-progress-bar-separator"></div>
                        </div>
                        <div className="add-listing-progress-bar-dot-container">
                            <div className="add-listing-progress-bar-dot-outer" style={this.state.step > 3 ? {backgroundColor: "#1377FF", cursor: "pointer"} : {backgroundColor: "white"}} onClick={() => this.handleDotClick(3, this.state.step, this.state.completed)}>
                                {this.state.step > 2 &&
                                    <div className="add-listing-progress-bar-dot-inner">
                                        {this.state.step > 3 &&
                                            <img src={require("../../../../assets/svgs/tick.svg")} alt="" />
                                        }
                                    </div>
                                }
                            </div>
                            <div className="add-listing-progress-bar-dot-title">
                                <p>Description</p>
                            </div>
                            <div className="add-listing-progress-bar-separator"></div>
                        </div>
                        <div className="add-listing-progress-bar-dot-container">
                            <div className="add-listing-progress-bar-dot-outer" style={this.state.step > 4 ? {backgroundColor: "#1377FF", cursor: "pointer"} : {backgroundColor: "white"}} onClick={() => this.handleDotClick(4, this.state.step, this.state.completed)}>
                                {this.state.step > 3 &&
                                    <div className="add-listing-progress-bar-dot-inner">
                                        {this.state.step > 4 &&
                                            <img src={require("../../../../assets/svgs/tick.svg")} alt="" />
                                        }
                                    </div>
                                }
                            </div>
                            <div className="add-listing-progress-bar-dot-title">
                                <p>Review</p>
                            </div>
                        </div>
                    </div>

                    {this.state.step === 1 &&
                        <>
                            <div className="add-listing-review-yacht-details-container margin-top-2x">
                                <div className="add-listing-review-yacht-details-overlay"></div>
                                <div className="add-listing-section-title-container">
                                    <p>Review Yacht Details</p>
                                </div>
                                <div className="toggle-button-master-container margin-top-20px">
                                    <div className="toggle-button-title-container">
                                        <p>Yacht Status</p>
                                    </div>
                                    <div className="toggle-button-container" style={{border: "1px solid #666666"}}>
                                        <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.yachtStatus === "Private" ? "#666666" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtStatus: "Private" } })}>
                                            <p className="toggle-button-item-text" style={{color: this.state.yacht.yachtStatus === "Private" ? "white" : "#333333"}}>Private</p>
                                        </div>
                                        <div className="toggle-button-items-separator" style={{backgroundColor: "#666666"}}></div>
                                        <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.yachtStatus === "Private & Charter" ? "#666666" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtStatus: "Private & Charter" } })}>
                                            <p className="toggle-button-item-text" style={{color: this.state.yacht.yachtStatus === "Private & Charter" ? "white" : "#333333"}}>Private & Charter</p>
                                        </div>
                                        <div className="toggle-button-items-separator" style={{backgroundColor: "#666666"}}></div>
                                        <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.yachtStatus === "Charter" ? "#666666" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtStatus: "Charter" } })}>
                                            <p className="toggle-button-item-text" style={{color: this.state.yacht.yachtStatus === "Charter" ? "white" : "#333333"}}>Charter</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-top-20px">
                                    <PlacesAutocomplete
                                        value={this.state?.yacht?.homePort?.name || ""}
                                        onChange={(value) => value}
                                        onSelect={(value) => value}
                                    >
                                        {({ getInputProps }) => (
                                            <div className="more-yacht-details-location-dropdown-master-container">
                                                <div className="more-yacht-details-location-dropdown-container">
                                                    <div className="more-yacht-details-location-dropdown-icon-container">
                                                        <img src={require("../../../../assets/svgs/home.svg")} alt="" />
                                                    </div>
                                                    <div className="more-yacht-details-location-dropdown-main-container">
                                                        <div className="more-yacht-details-location-dropdown-main-item-title-long-container">
                                                            <p className="more-yacht-details-location-dropdown-main-label">Home Port</p>
                                                        </div>
                                                        <div className="more-yacht-details-location-dropdown-main-item-value-container">
                                                            <input {...getInputProps({placeholder: "Search..." })} className="more-yacht-details-location-value-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <div className="margin-top-20px">
                                    <PlacesAutocomplete
                                        value={this.state?.yacht?.currentLocation?.name || ""}
                                        onChange={(value) => value}
                                        onSelect={(value) => value}
                                    >
                                        {({ getInputProps }) => (
                                            <div className="more-yacht-details-location-dropdown-master-container">
                                                <div className="more-yacht-details-location-dropdown-container">
                                                    <div className="more-yacht-details-location-dropdown-icon-container">
                                                        <img src={require("../../../../assets/svgs/pin.svg")} alt="" />
                                                    </div>
                                                    <div className="more-yacht-details-location-dropdown-main-container">
                                                        <div className="more-yacht-details-location-dropdown-main-item-title-long-container">
                                                            <p className="more-yacht-details-location-dropdown-main-label">Yacht Location</p>
                                                        </div>
                                                        <div className="more-yacht-details-location-dropdown-main-item-value-container">
                                                            <input {...getInputProps({placeholder: "Search..." })} className="more-yacht-details-location-value-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <div className="yacht-details-dropdown-master-container margin-top-20px">
                                    <div className="yacht-details-dropdown-container">
                                        <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                                            <div className="yacht-details-dropdown-main-item-title-long-container">
                                                <p className="yacht-details-dropdown-main-label">Expected Itinerary (Can select multiple)</p>
                                            </div>
                                            <div className="yacht-details-dropdown-main-item-value-container">
                                                <input className="yacht-details-value-input" type="text" placeholder="Type..." />
                                            </div>
                                        </div>
                                        <div className="yacht-details-dropdown-icon-container">
                                            <img src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="yacht-details-selected-positions-container">
                                    {this.renderSelected(this.state.yacht.itinerary)}
                                </div>
                                <div className="toggle-button-master-container margin-top-20px">
                                    <div className="toggle-button-title-container">
                                        <p>Couple Friendly?</p>
                                    </div>
                                    <div className="toggle-button-container" style={{border: "1px solid #666666"}}>
                                        <div className="toggle-button-item-container" style={{backgroundColor: (!this.state.yacht.couples && this.state.yacht.couples !== undefined) ? "#666666" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, couples: false } })}>
                                            <p className="toggle-button-item-text" style={{color: (!this.state.yacht.couples && this.state.yacht.couples !== undefined) ? "white" : "#333333"}}>No Couples</p>
                                        </div>
                                        <div className="toggle-button-items-separator" style={{backgroundColor: "#666666"}}></div>
                                        <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.couples ? "#666666" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, couples: true } })}>
                                            <p className="toggle-button-item-text" style={{color: this.state.yacht.couples ? "white" : "#333333"}}>Couples Are Considered</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DashboardSectionBase title={"Assign Recruitment Consultant"} description={"Who is the designated consultant for this job?"}>
                                <div className="margin-top-10px">
                                    <CheckBox label={"Jacob"} status={this.state.job.consultant === "Jacob Hodgson"} propName={"consultant"} value={"Jacob Hodgson"} toggle={(value) => this.setState({ job: { ...this.state.job, consultant: value } })} />
                                </div>     
                                <div className="margin-top-10px">
                                    <CheckBox label={"Chay"} status={this.state.job.consultant === "Chay Hodgson"} propName={"consultant"} value={"Chay Hodgson"} toggle={(value) => this.setState({ job: { ...this.state.job, consultant: value } })} />
                                </div>
                                <div className="margin-top-20px">
                                    <RegularTextInput label={"Job Reference"} placeholder={"Type..."} value={this.state?.job?.reference || ""} propName={"reference"} onChangeText={(e) => this.setState({ job: { ...this.state.job, reference: e } })} />
                                </div>
                            </DashboardSectionBase>
                            <DashboardSectionBase title={"Job Details"}>
                                <div className="margin-top-20px">
                                    <TextInputDropdown show={this.state.showPositions} toggleShow={() => this.setState({ showPositions: !this.state.showPositions })} icon={require("../../../../assets/svgs/chevron-down.svg")} label={"Position"} placeholder="Search..." value={this.state.positionKeyword} values={[this.state.positionKeyword]} options={this.state.positions} onChangeText={(value) => this.setState({ positionKeyword: value })} propName={"position"} select={this.selectPosition} single={true} />
                                </div>
                                <div className="margin-top-20px">
                                    <div className="yacht-listing-toggle-section-label">
                                        <p>Contract Type</p>
                                    </div>
                                    <div className="yacht-listing-toggle-section-container">
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.contractType === "Full time" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, contractType: "Full time" } })}>
                                            <p style={{color: this.state.job.contractType === "Full time" ? "#FFFFFF" : "#333333"}}>Full time</p>
                                        </div>
                                        <div className="yacht-listing-toggle-buttons-separator"></div>
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.contractType === "Rotation" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, contractType: "Rotation" } })}>
                                            <p style={{color: this.state.job.contractType === "Rotation" ? "#FFFFFF" : "#333333"}}>Rotation</p>
                                        </div>
                                        <div className="yacht-listing-toggle-buttons-separator"></div>
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.contractType === "Temporary" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, contractType: "Temporary" } })}>
                                            <p style={{color: this.state.job.contractType === "Temporary" ? "#FFFFFF" : "#333333"}}>Temporary</p>
                                        </div>
                                        <div className="yacht-listing-toggle-buttons-separator"></div>
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.contractType === "Daywork" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, contractType: "Daywork" } })}>
                                            <p style={{color: this.state.job.contractType === "Daywork" ? "#FFFFFF" : "#333333"}}>Daywork</p>
                                        </div>
                                    </div>
                                </div>
                                {["Rotation", "Temporary", "Daywork"].includes(this.state.job.contractType) &&
                                    <div className="margin-top-20px">
                                        <div className="yacht-listing-contract-type-length-label">
                                            <p>{this.state.job.contractType} Length</p>
                                        </div>
                                        <div className="yacht-listing-contract-length-input-container">
                                            {this.state.job.contractType === "Rotation" ?
                                                <div className="yacht-listing-contract-length-input-wrapper">
                                                    <div className="yacht-listing-contract-length-input">
                                                        <input type={"text"} placeholder={"Type..."} value={this.state.job.periodLength} onChange={(e) => this.setState({ job: { ...this.state.job, periodLength: e.target.value } })} />
                                                    </div>
                                                    <div style={{width: 20}}></div>
                                                    <div className="yacht-listing-contract-length-input">
                                                        <input type={"text"} placeholder={"Type..."} value={this.state.job.periodLengthSecondary} onChange={(e) => this.setState({ job: { ...this.state.job, periodLengthSecondary: e.target.value } })} />
                                                    </div>
                                                </div>
                                            :
                                                <div className="yacht-listing-contract-length-input">
                                                    <input type={"text"} placeholder={"Type..."} value={this.state.job.periodLength} onChange={(e) => this.setState({ job: { ...this.state.job, periodLength: e.target.value } })} />
                                                </div>
                                            }
                                            <div className="yacht-listing-contract-period-type-toggle-container">
                                                {["Temporary", "Daywork"].includes(this.state.job.contractType) &&
                                                    <>
                                                        <div className="yacht-listing-contract-period-type-toggle" style={{backgroundColor: this.state.job.periodType === "Days" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, periodType: "Days" } })}>
                                                            <p style={{color: this.state.job.periodType === "Days" ? "#FFFFFF" : "#333333"}}>Days</p>
                                                        </div>
                                                        <div className="yacht-listing-contract-period-type-separator"></div>
                                                    </>
                                                }
                                                <div className="yacht-listing-contract-period-type-toggle" style={{backgroundColor: this.state.job.periodType === "Weeks" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, periodType: "Weeks" } })}>
                                                    <p style={{color: this.state.job.periodType === "Weeks" ? "#FFFFFF" : "#333333"}}>Weeks</p>
                                                </div>
                                                <div className="yacht-listing-contract-period-type-separator"></div>
                                                <div className="yacht-listing-contract-period-type-toggle" style={{backgroundColor: this.state.job.periodType === "Months" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, periodType: "Months" } })}>
                                                    <p style={{color: this.state.job.periodType === "Months" ? "#FFFFFF" : "#333333"}}>Months</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="margin-top-20px">
                                    <div className="yacht-listing-toggle-section-label">
                                        <p>Salary</p>
                                    </div>
                                    <div className="yacht-listing-double-toggle-container">
                                        <div className="yacht-listing-mini-toggle-container">
                                            <div className="yacht-listing-mini-toggle-button" style={{backgroundColor: this.state.job.salaryCurrency === "eur" ? "#1377FF" : "white"}} onClick={() => this.setState({ job: { ...this.state.job, salaryCurrency: "eur" } })}>
                                                <p style={{color: this.state.job.salaryCurrency === "eur" ? "white" : "#333333"}}>€</p>
                                            </div>
                                            <div className="yacht-listing-toggle-buttons-separator"></div>
                                            <div className="yacht-listing-mini-toggle-button" style={{backgroundColor: this.state.job.salaryCurrency === "usd" ? "#1377FF" : "white"}} onClick={() => this.setState({ job: { ...this.state.job, salaryCurrency: "usd" } })}>
                                                <p style={{color: this.state.job.salaryCurrency === "usd" ? "white" : "#333333"}}>$</p>
                                            </div>
                                            <div className="yacht-listing-toggle-buttons-separator"></div>
                                            <div className="yacht-listing-mini-toggle-button" style={{backgroundColor: this.state.job.salaryCurrency === "gbp" ? "#1377FF" : "white"}} onClick={() => this.setState({ job: { ...this.state.job, salaryCurrency: "gbp" } })}>
                                                <p style={{color: this.state.job.salaryCurrency === "gbp" ? "white" : "#333333"}}>£</p>
                                            </div>
                                        </div>
                                        <div style={{width: 20}}></div>
                                        <div className="yacht-listing-toggle-section-container">
                                            <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.salaryType === "Fixed" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, salaryType: "Fixed" } })}>
                                                <p style={{color: this.state.job.salaryType === "Fixed" ? "#FFFFFF" : "#333333"}}>Fixed</p>
                                            </div>
                                            <div className="yacht-listing-toggle-buttons-separator"></div>
                                            <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.salaryType === "Range" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, salaryType: "Range" } })}>
                                                <p style={{color: this.state.job.salaryType === "Range" ? "#FFFFFF" : "#333333"}}>Range</p>
                                            </div>
                                            <div className="yacht-listing-toggle-buttons-separator"></div>
                                            <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.salaryType === "DOE" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, salaryType: "DOE" } })}>
                                                <p style={{color: this.state.job.salaryType === "DOE" ? "#FFFFFF" : "#333333"}}>DOE</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.job.salaryType && this.state.job.salaryType !== "DOE" &&
                                    <div className="yacht-listing-salary-amount-master-container margin-top-20px">
                                        <div className="yacht-listing-salary-amount-container">
                                            <div className="yacht-listing-salary-amount-label-container">
                                                <p>{this.state.job.salaryCurrency === "eur" ? "€" : this.state.job.salaryCurrency === "usd" ? "$" : this.state.job.salaryCurrency === "gbp" ? "£" : ""}</p>
                                            </div>
                                            <div className="yacht-listing-salary-amount-input-container">
                                                <input type={"text"} placeholder={"5000"} value={this.state.job.salaryAmount} onChange={(e) => this.setState({ job: { ...this.state.job, salaryAmount: e.target.value } })} />
                                            </div>
                                        </div>
                                        {this.state.job.salaryType === "Range" &&
                                            <div className="yacht-listing-salary-amount-max-container">
                                                <div className="yacht-listing-salary-amount-to"></div>
                                                <div className="yacht-listing-salary-amount-container">
                                                    <div className="yacht-listing-salary-amount-label-container">
                                                        <p>{this.state.job.salaryCurrency === "eur" ? "€" : this.state.job.salaryCurrency === "usd" ? "$" : this.state.job.salaryCurrency === "gbp" ? "£" : ""}</p>
                                                    </div>
                                                    <div className="yacht-listing-salary-amount-input-container">
                                                        <input type={"text"} placeholder={"10000"} value={this.state.job.salaryRangeMax} onChange={(e) => this.setState({ job: { ...this.state.job, salaryRangeMax: e.target.value } })} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="margin-top-20px">
                                    <div className="yacht-listing-toggle-section-label">
                                        <p>Cabin Status</p>
                                    </div>
                                    <div className="yacht-listing-toggle-section-container">
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.cabinStatus === "Own Cabin" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, cabinStatus: "Own Cabin" } })}>
                                            <p style={{color: this.state.job.cabinStatus === "Own Cabin" ? "#FFFFFF" : "#333333"}}>Own Cabin</p>
                                        </div>
                                        <div className="yacht-listing-toggle-buttons-separator"></div>
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.cabinStatus === "Shared Cabin" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, cabinStatus: "Shared Cabin" } })}>
                                            <p style={{color: this.state.job.cabinStatus === "Shared Cabin" ? "#FFFFFF" : "#333333"}}>Shared Cabin</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-top-20px">
                                    <div className="yacht-listing-toggle-section-label">
                                        <p>Gender Required (Confidential and not revealed to candidates)</p>
                                    </div>
                                    <div className="yacht-listing-toggle-section-container">
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.gender === "Male" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, gender: "Male" } })}>
                                            <p style={{color: this.state.job.gender === "Male" ? "#FFFFFF" : "#333333"}}>Male</p>
                                        </div>
                                        <div className="yacht-listing-toggle-buttons-separator"></div>
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.gender === "Female" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, gender: "Female" } })}>
                                            <p style={{color: this.state.job.gender === "Female" ? "#FFFFFF" : "#333333"}}>Female</p>
                                        </div>
                                        <div className="yacht-listing-toggle-buttons-separator"></div>
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.gender === "Male or Female" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, gender: "Male or Female" } })}>
                                            <p style={{color: this.state.job.gender === "Male or Female" ? "#FFFFFF" : "#333333"}}>Male or Female</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-top-20px">
                                    <div className="yacht-listing-toggle-section-label">
                                        <p>Start Date</p>
                                    </div>
                                    <div className="margin-top-10px">
                                        <CheckBox label={"ASAP"} status={this.state.job.startDateType === "ASAP"} propName={"startDateType"} value={"ASAP"} toggle={(value) => this.setState({ job: { ...this.state.job, startDateType: value } })} />
                                    </div>
                                    <div className="margin-top-10px">
                                        <CheckBox label={"Unconfirmed"} status={this.state.job.startDateType === "Unconfirmed"} propName={"startDateType"} value={"Unconfirmed"} toggle={(value) => this.setState({ job: { ...this.state.job, startDateType: value} })} />
                                    </div>
                                    <div className="margin-top-10px">
                                        <CheckBox label={"Approximate Date"} status={this.state.job.startDateType === "Approximate Date"} propName={"startDateType"} value={"Approximate Date"} toggle={(value) => this.setState({ job: { ...this.state.job, startDateType: value} })} />
                                    </div>
                                    {this.state.job.startDateType === "Approximate Date" &&
                                        <div className="margin-top-20px">
                                            <RegularTextInput label={"Approximate Date"} placeholder={"DD/MM/YYYY"} value={this.state?.job?.startDateApprx || ""} propName={"startDateApprx"} onChangeText={(e) => this.setState({ job: { ...this.state.job, startDateApprx: e } })} />
                                        </div>
                                    }
                                </div>
                                <div className="margin-top-20px">
                                    <div className="yacht-listing-toggle-section-label">
                                        <p>Job Visibility</p>
                                    </div>
                                    <div className="yacht-listing-toggle-section-container">
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.visible ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, visible: true } })}>
                                            <p style={{color: this.state.job.visible ? "#FFFFFF" : "#333333"}}>Live</p>
                                        </div>
                                        <div className="yacht-listing-toggle-buttons-separator"></div>
                                        <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.visible === false ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, visible: false } })}>
                                            <p style={{color: this.state.job.visible === false ? "#FFFFFF" : "#333333"}}>Hidden</p>
                                        </div>
                                    </div>
                                </div>
                            </DashboardSectionBase>
                            <DashboardSectionBase title={"Job Notes"} description={"Any job specific notes related to this job."}>
                                <div className="margin-top-20px">
                                    <textarea className="yacht-listing-job-notes" placeholder={"Start Typing..."} value={this.state?.job?.notes || ""} onChange={(e) => this.setState({ job: { ...this.state.job, notes: e.target.value } })} />
                                </div>
                            </DashboardSectionBase>
                        </>
                    }

                    {this.state.step === 2 &&
                        <>
                            <DashboardSectionBase title={"Essential Certification"}>
                                <div className="margin-top-20px">
                                    <CheckBox label={"ENG 1"} status={this.state.job.eng1_cert} propName={"eng1_cert"} value={this.state.job.eng1_cert ? false : true} toggle={(value) => this.setState({ job: { ...this.state.job, eng1_cert: value } })} />
                                </div>
                                <div className="margin-top-20px">
                                    <CheckBox label={"STCW Basic Safety Training"} status={this.state.job.stcwBasic_cert} propName={"stcwBasic_cert"} value={this.state.job.stcwBasic_cert ? false : true} toggle={(value) => this.setState({ job: { ...this.state.job, stcwBasic_cert: value } })} />
                                </div>
                                <div className="margin-top-20px">
                                    <CheckBox label={"STCW Advanced Safety Training"} status={this.state.job.stcwAdvanced_cert} propName={"stcwAdvanced_cert"} value={this.state.job.stcwAdvanced_cert ? false : true} toggle={(value) => this.setState({ job: { ...this.state.job, stcwAdvanced_cert: value } })} />
                                </div>
                            </DashboardSectionBase>
                            <DashboardSectionBase title={"Qualifications Required"} description={"Search and add any relevant qualifications or choose from the dropdown categories."}>
                                <div className="margin-top-20px">
                                    <TextInputDropdown icon={require("../../../../assets/svgs/search.svg")} label={"Qualifications"} placeholder={"Type..."} value={this.state.qualificationsKeyword} values={this.state.job.qualifications} options={this.state.allQualifications} onChangeText={(e) => this.setState({ qualificationsKeyword: e })} propName={"qualifications"} select={this.selectQualification}  />
                                </div>
                                <SelectedItems items={this.state.job.qualifications} propName={"qualifications"} updateUserProfile={(e) => this.setState({ job: { ...this.state.job, qualifications: e } })} admin={true} />
                                <div className="margin-top-20px">
                                    <Categories propName={"qualifications"} categories={this.state.allQualifications} selected={this.state.job.qualifications} show={this.state.qualificationsShow} select={this.selectQualification} toggle={() => this.setState({ qualificationsShow: !this.state.qualificationsShow })} />
                                </div>
                            </DashboardSectionBase>
                            <DashboardSectionBase title={"Alternative Qualifications"} description={"Search and add any relevant qualifications or choose from the dropdown categories."}>
                                <div className="margin-top-20px">
                                    <TextInputDropdown icon={require("../../../../assets/svgs/search.svg")} label={"Alternative Qualifications"} placeholder={"Type..."} value={this.state.moreQualificationsKeyword} values={this.state.job.moreQualifications[this.state.qualIndex]} options={this.state.allQualifications} onChangeText={(e) => this.setState({ moreQualificationsKeyword: e })} propName={"moreQualifications"} qualIndex={this.state.qualIndex} select={this.selectMoreQualification}  />
                                </div>
                                <SelectedItems deleteAlternative={this.deleteAlternative} changeQualIndex={(e) => this.setState({ qualIndex: e })} qualIndex={this.state.qualIndex} items={this.state.job.moreQualifications} propName={"moreQualifications"} updateUserProfile={this.deselectMoreQualification} admin={true} />
                                <div className="margin-top-20px">
                                    <Categories addAlternative={() => this.setState({ job: { ...this.state.job, moreQualifications: [...this.state.job.moreQualifications, []] } })} propName={"moreQualifications"} categories={this.state.allQualifications} moreQuals={this.state.job.moreQualifications} selected={this.state.job.moreQualifications[this.state.qualIndex]} show={this.state.moreQualificationsShow} qualIndex={this.state.qualIndex} select={this.selectMoreQualification} toggle={() => this.setState({ moreQualificationsShow: !this.state.moreQualificationsShow })} />
                                </div>
                            </DashboardSectionBase>
                            <DashboardSectionBase title={"Visas Required"}>
                                <div className="margin-top-20px">
                                    <CheckBox label={"B1/B2"} status={this.state.job.b1b2_visa} propName={"b1b2_visa"} value={this.state.job.b1b2_visa ? false : true} toggle={(value) => this.setState({ job: { ...this.state.job, b1b2_visa: value } })} />
                                </div>
                                <div className="margin-top-20px">
                                    <CheckBox label={"C1/D"} status={this.state.job.c1d_visa} propName={"c1d_visa"} value={this.state.job.c1d_visa ? false : true} toggle={(value) => this.setState({ job: { ...this.state.job, c1d_visa: value } })} />
                                </div>
                                <div className="margin-top-20px">
                                    <CheckBox label={"Schengen"} status={this.state.job.schengen_visa} propName={"schengen_visa"} value={this.state.job.schengen_visa ? false : true} toggle={(value) => this.setState({ job: { ...this.state.job, schengen_visa: value } })} />
                                </div>
                                <div className="margin-top-20px">
                                    <CheckBox label={"Green Card"} status={this.state.job.greenCard_visa} propName={"greenCard_visa"} value={this.state.job.greenCard_visa ? false : true} toggle={(value) => this.setState({ job: { ...this.state.job, greenCard_visa: value } })} />
                                </div>
                            </DashboardSectionBase>
                            <DashboardSectionBase title={"Required Experience"}>
                                <div className="yacht-listing-toggle-section-container margin-top-20px">
                                    <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.experience === "None" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, experience: "None" } })}>
                                        <p style={{color: this.state.job.experience === "None" ? "#FFFFFF" : "#333333"}}>None</p>
                                    </div>
                                    <div className="yacht-listing-toggle-buttons-separator"></div>
                                    <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.experience === "6 Months" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, experience: "6 Months" } })}>
                                        <p style={{color: this.state.job.experience === "6 Months" ? "#FFFFFF" : "#333333"}}>6 Months</p>
                                    </div>
                                    <div className="yacht-listing-toggle-buttons-separator"></div>
                                    <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.experience === "1 Year" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, experience: "1 Year" } })}>
                                        <p style={{color: this.state.job.experience === "1 Year" ? "#FFFFFF" : "#333333"}}>1 Year</p>
                                    </div>
                                    <div className="yacht-listing-toggle-buttons-separator"></div>
                                    <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.experience === "2 Years" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, experience: "2 Years" } })}>
                                        <p style={{color: this.state.job.experience === "2 Years" ? "#FFFFFF" : "#333333"}}>2 Years</p>
                                    </div>
                                    <div className="yacht-listing-toggle-buttons-separator"></div>
                                    <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.experience === "5 Years" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, experience: "5 Years" } })}>
                                        <p style={{color: this.state.job.experience === "5 Years" ? "#FFFFFF" : "#333333"}}>5 Years</p>
                                    </div>
                                    <div className="yacht-listing-toggle-buttons-separator"></div>
                                    <div className="yacht-listing-toggle-button-container" style={{backgroundColor: this.state.job.experience === "10 Years" ? "#1377FF" : "#FFFFFF"}} onClick={() => this.setState({ job: { ...this.state.job, experience: "10 Years" } })}>
                                        <p style={{color: this.state.job.experience === "10 Years" ? "#FFFFFF" : "#333333"}}>10 Years</p>
                                    </div>
                                </div>
                            </DashboardSectionBase>
                        </>
                    }

                    {this.state.step === 3 &&
                        <DashboardSectionBase title={"Job Description"} description={"Please provide some detail about the roles and responsibilities for this position."}>
                            <div className="margin-top-20px">
                                <textarea className="yacht-listing-job-notes" placeholder={"Start Typing..."} value={this.state?.job?.description || ""} onChange={(e) => this.setState({ job: { ...this.state.job, description: e.target.value } })} />
                            </div>
                            <div className="yacht-listing-description-chars-counter margin-top-10px">
                                <p>We suggest around 600 characters.</p>
                                <p>{this.state.job.description ? this.state.job.description.length : "0"}</p>
                            </div>
                        </DashboardSectionBase>
                    }

                    {this.state.step === 4 &&
                        <div className="yacht-listing-review-container margin-top-2x">
                            <div className="yacht-listing-review-row-container">
                                <div className="yacht-listing-review-row-icon-container">
                                    <img src={handleYachtIcon(this.state.yacht.size, this.state.yacht.yachtType)} alt="" />
                                </div>
                                <div className="yacht-listing-review-row-type-container">
                                    <p>{handleYachtSize(this.state.yacht.size)} {handleYachtType(this.state.yacht.yachtType)}</p>
                                </div>
                                <div className="yacht-listing-review-row-position-container">
                                    <p>{handleJobPostTitle(this.state.job)}</p>
                                </div>
                            </div>
                            <div className="yacht-listing-review-section-title-container margin-top-20px">
                                <p>Yacht Details</p>
                            </div>
                            {this.state.yacht.yachtStatus &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-1x">
                                    <p>Charter Status: <span>{this.state.yacht.yachtStatus}</span></p>
                                </div>
                            }
                            {this.state.yacht.age &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Yacht Age: <span>{handleYachtAge(this.state.yacht.age)}</span></p>
                                </div>
                            }
                            {this.state.yacht.yachtBuilderCountry &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Build: <span>{handleYachtBuildRegion(this.state.yacht.yachtBuilderCountry)}</span></p>
                                </div>
                            }
                            {this.state.yacht.couples !== undefined &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Couples Considered: <span>{this.state.yacht.couples ? "Yes" : "No"}</span></p>
                                </div>
                            }
                            {this.state.yacht.itinerary.length > 0 &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Itinerary: <span>{handleMultiple(this.state.yacht.itinerary)}</span></p>
                                </div>
                            }
                            {visas &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Visas Required: <span>{visas}</span></p>
                                </div>
                            }
                            {this.state.yacht?.currentLocation?.country &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Current Location: <span>{this.state.yacht.currentLocation.country}</span></p>
                                </div>
                            }
                            {this.state.yacht?.homePort?.country &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Home Port: <span>{this.state.yacht.homePort.country}</span></p>
                                </div>
                            }
                            {this.state.yacht.smoking !== undefined &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Smoking Policy: <span>{this.state.yacht.smoking ? "Smoking Allowed" : "No Smoking"}</span></p>
                                </div>
                            }
                            {this.state.yacht.tattoo &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Tattoo Policy: <span>{this.state.yacht.tattoo}</span></p>
                                </div>
                            }
                            {this.state.yacht.dryBoat !== undefined &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Dry Boat: <span>{this.state.yacht.dryBoat ? "Yes" : "No"}</span></p>
                                </div>
                            }
                            <div className="yacht-listing-review-section-title-container margin-top-20px">
                                <p>Job Details</p>
                            </div>
                            {contractType &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Contract Type: <span>{contractType}</span></p>
                                </div>
                            }
                            {startDate &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Start Date: <span>{startDate}</span></p>
                                </div>
                            }
                            {this.state.job.cabinStatus &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Cabin Status: <span>{this.state.job.cabinStatus}</span></p>
                                </div>
                            }
                            {this.state.job.experience &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Required Experience: <span>{this.state.job.experience}</span></p>
                                </div>
                            }
                            {this.state.job.qualifications.length > 0 &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Qualifications: <span>{handleMultipleQualifications(this.state.job.qualifications.map(item => item.name), this.state.job.moreQualifications)}</span></p>
                                </div>
                            }
                            {this.state.job.description &&
                                <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                    <p>Job Description: <span>{this.state.job.description}</span></p>
                                </div>
                            }
                            <div className="yacht-listing-review-buttons-container margin-top-2x">
                                <div className="yacht-listing-edit-position-button" onClick={() => this.handlePrevious(2)}>
                                    <p>Edit</p>
                                </div>
                                <div className="yacht-listing-confirm-position-button" onClick={() => this.confirmListing(this.state.jobId, this.state.yacht, this.state.status)}>
                                    <p>{(!this.state.status || this.state.status === "draft") ? "List This Position" : this.state.status === "archived" ? "Re-List Position" : "Return to Listings"}</p>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                <div className="yacht-listing-buttons-container margin-top-20px" style={{paddingLeft: 30}}>
                    {this.state.step < 4 &&
                        <div className="yacht-listing-proceed-button" onClick={() => this.handleProceed(this.state.step, this.state.job)}>
                            <p>{"Save & Continue"}</p>
                            <img src={require("../../../../assets/svgs/continue.svg")} alt="" />
                        </div>
                    }
                    {[2, 3].includes(this.state.step) &&
                        <div className="yacht-listing-previous-step-button">
                            <p onClick={() => this.handlePrevious(this.state.step)}>Previous Step</p>
                        </div>
                    }
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddListing);