import "./index.css";

import React, { Component } from "react";
import { Link } from "react-router-dom";

/* Components */
import Page from "../../../components/Page";
import NavigationBar from "../../../components/NavigationBar";

class Home extends Component {

    constructor(props) {
        super(props);

        this.scrollDownRef = React.createRef();

        this.state = {
            keyword: "",
        };

    }

    componentDidMount() {

        return window.scrollTo(0, 0);

    }

    render() {

        return(
            <Page match={this.props.match} hideNavbar={true}>
                <div className="web-page-home-intro-container">
                    <div className="web-page-home-intro-wrapper">
                        <NavigationBar />
                        <div className="web-page-home-intro-welcome-container">
                            <h1 style={{ color: "#e4e4e7" }}>Yacht</h1>
                            <h1 style={{ color: "#e4e4e7" }}>Crew</h1>
                            <h1 style={{ color: "#a2a4af" }}>Agency</h1>
                            <h2 style={{ color: "#f2f2f3" }}>Est. 1999</h2>
                            <div className="web-page-home-intro-welcome-buttons-container">
                                <Link to={"/find-jobs"} style={{ backgroundColor: "#F2F2F3" }}>
                                    <span style={{ color: "#131313" }}>Find Jobs</span>
                                </Link>
                                <Link to={"/looking-for-crew"} style={{ backgroundColor: "#3E5383" }}>
                                    <span style={{ color: "#FFFFFF" }}>Looking for Crew?</span>
                                </Link>
                            </div>
                        </div>
                        <div className="web-page-home-intro-welcome-scroll-down-container" onClick={() => this.scrollDownRef.current.scrollIntoView({ behavior: "smooth", block: "start" })}>
                            <img src={require("../../../assets/svgs/scroll-down.svg")} alt="" />
                        </div>
                    </div>
                </div>
                <div className="web-page-home-section-container" ref={this.scrollDownRef}>
                    <div className="web-page-home-section-header-container">
                        <p>Bespoke, world-class recruitment</p>
                    </div>
                    <div className="web-page-home-section-description-container">
                        <p>Since starting in 1999, we have established ourselves as an industry leader and have built long term relationships with our clients and crew. We are committed to providing the highest standards and focus on providing quality over quantity. While specialising in engineering, we cover all departments onboard a yacht, finding the best crew available for each position. Having done the job ourselves, we understand how it works and have built a large network of clients and vetted crew.</p>
                    </div>
                </div>
                <div className="web-page-home-perks-container">
                    <div className="web-page-home-perks-row-container">
                        <div className="web-page-home-perks-cell-container">
                            <h4>High Calibre Crew</h4>
                            <p>Through our considerable network of contacts, we handpick the best available crew to meet your needs.</p>
                        </div>
                        <div className="web-page-home-perks-cell-container">
                            <h4>Experience</h4>
                            <p>Our recruiters are qualified marine professionals with yachting experience, understanding the ebbs and flows of life at sea.</p>
                        </div>
                    </div>
                    <div className="web-page-home-perks-row-container">
                        <div className="web-page-home-perks-cell-container">
                            <h4>MLC Registered</h4>
                            <p>We are an MLC registered crew agency based in the UK.</p>
                        </div>
                        <div className="web-page-home-perks-cell-container">
                            <h4>Unrivalled Technology</h4>
                            <p>Our proprietary technology allows us to find the best possible candidates in the shortest time frame.</p>
                        </div>
                    </div>
                </div>
                <div style={{ height: 90 }}></div>
                <img className="about-us-separator-image" src={require("../../../assets/images/palma-1.webp")} alt="" />
                <div className="web-page-home-about-us-container">
                    <div className="web-page-home-about-us-cell-container">
                        <h2>About Us</h2>
                        <p>Jacob and Chay Hodgson are experts in their field and understand what it takes to work on the most prestigious super yachts.</p>
                        <div style={{height: 50}}></div>
                        <div className="web-page-home-about-us-button-container">
                            <Link to={"/about-us"}>
                                <span>Find Out More</span>
                                <img src={require("../../../assets/svgs/find-out-more.svg")} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="web-page-home-about-us-cell-container">
                        <div className="web-page-home-about-us-cell-row-container">
                            <div className="web-page-home-about-us-cell-row-image-container">
                                <img src={require("../../../assets/images/chay.webp")} alt="" />
                            </div>
                            <div className="web-page-home-about-us-cell-row-info-container">
                                <h4>Chay Hodgson</h4>
                                <p className="about-us-team-member-position">Director and Recruitment Consultant</p>
                                <p>Chay is a qualified Engineer OOW with years of yachting experience. Joining J4Crew in 2017, he has helped many crew forge their career in the industry.</p>
                                <div className="web-page-home-about-us-cell-row-socials-container">
                                    <a href={"https://www.linkedin.com/in/chayhodgson/"} target={"_blank"} rel="noopener noreferrer">
                                        <img src={require("../../../assets/svgs/linkedin.svg")} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="web-page-home-about-us-cell-row-container">
                            <div className="web-page-home-about-us-cell-row-image-container">
                                <img src={require("../../../assets/images/jacob.webp")} alt="" />
                            </div>
                            <div className="web-page-home-about-us-cell-row-info-container">
                                <h4>Jacob Hodgson</h4>
                                <p className="about-us-team-member-position">Director and Recruitment Consultant</p>
                                <p>Jacob is a qualified Deck OOW with both commercial and yachting experience. He joined in 2018 and continues to diligently match clients and crew.</p>
                                <div className="web-page-home-about-us-cell-row-socials-container">
                                    <a href={"https://www.linkedin.com/in/jacob-hodgson-259b8b120/"} target={"_blank"} rel="noopener noreferrer">
                                        <img src={require("../../../assets/svgs/linkedin.svg")} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="about-us-separator-image" src={require("../../../assets/images/palma-2.webp")} alt="" />
                <div className="web-page-testimonials-container">
                    <div className="web-page-testimonials-header-container">
                        <p>Don’t just take our word for it...</p>
                    </div>
                    <div className="web-page-testimonials-description-container">
                        <p>Here is what our clients and crew think about us...</p>
                    </div>
                    <div className="web-page-testimonials-grid-container">
                        <div className="web-page-testimonials-grid-item-wrapper">
                            <div className="web-page-testimonials-grid-item-container">
                                <p>"We have used J4crew for over 10 years now and have always been 100% satisfied with the services provided."</p>
                                <h4>Rich Swansbury</h4>
                                <span>Technical Superintendant</span>
                            </div>
                        </div>
                        <div className="web-page-testimonials-grid-item-wrapper">
                            <div className="web-page-testimonials-grid-item-container">
                                <p>“These guys are awesome! Really helpful! Got my first job through these guys! And I will use them everytime I need one! One of the best for sure!”</p>
                                <h4>Alex Smith</h4>
                                <span>EOOW Engineer</span>
                            </div>
                        </div>
                        <div className="web-page-testimonials-grid-item-wrapper">
                            <div className="web-page-testimonials-grid-item-container">
                                <p>“I began using J4Crew more than 10 years ago and he has been my primary contact whenever I am seeking new roles on large yachts. Truly, best-in-class service!”</p>
                                <h4>Simon Glass</h4>
                                <span>USCG Chief Engineer Unlimited</span>
                            </div>
                        </div>
                        <div className="web-page-testimonials-grid-item-wrapper">
                            <div className="web-page-testimonials-grid-item-container">
                                <p>“J4Crew have placed me on several boats over the last 10 years. Great service and always quick to respond to phonecalls and emails. No ridiculously long forms full of impersonal and irrelevant questions. would recommend to anyone in the yachting industry.”</p>
                                <h4>Tom Pearson</h4>
                                <span>Class 2 Engineer</span>
                            </div>
                        </div>
                        <div className="web-page-testimonials-grid-item-wrapper">
                            <div className="web-page-testimonials-grid-item-container">
                                <p>“Since I joined Yachting in 2008, J4Crew have always play a primordial role, providing an outstanding and personal service, fulfilling my personal needs, and placing me in the correct yacht every time.”</p>
                                <h4>Ignacio Bordenave</h4>
                                <span>Y1 Engineer</span>
                            </div>
                        </div>
                        <div className="web-page-testimonials-grid-item-wrapper">
                            <div className="web-page-testimonials-grid-item-container">
                                <p>“I would not hesitate to recommend J4Crew as the best engineering recruitment agency. I have been helped through your professional and dedicated work to find my ideal engineer position. Great service best option for beginners, friends and old colleagues looking for new amazing job opportunities.”</p>
                                <h4>Juan Riauba</h4>
                                <span>Y1 Engineer</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height: 100}}></div>
                <div className="lets-get-started-container">
                    <h4>Let's Get Started!</h4>
                    <div className="lets-get-started-row-container">
                        <div className="lets-get-started-row-info-container">
                            <h3>Ready to get in touch?</h3>
                            <p>Whether you are a crew member looking for your dream yacht job or you are looking to recruit, our team is ready to help!</p>
                        </div>
                        <div className="lets-get-started-row-buttons-container">
                            <Link style={{ backgroundColor: "#747889" }} to={"/sign-up"}>
                                <span>Register</span>
                            </Link>
                            <Link style={{ backgroundColor: "#3E5383" }} to={"/looking-for-crew"}>
                                <span>Looking for Crew?</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div style={{height: 100}}></div>
                <div className="web-page-footer-socials-container">
                    <a href={"https://twitter.com/J4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/twitter-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.facebook.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/facebook-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.instagram.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/instagram-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.linkedin.com/company/5267269"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/linkedin-footer.svg")} alt="" />
                    </a>
                </div>
                <div className="web-page-footer-links-container" style={{ borderBottom: "1px solid rgb(225, 225, 225)" }}>
                    <p><Link to={"/terms-and-conditions"}>Terms</Link><Link to={"/privacy-policy"}>Privacy Policy</Link><Link to={"/contact-us"}>Get in Touch</Link><Link to={"/about-us"}>About Us</Link><Link to={"/mlc-2006"}>MLC 2006</Link></p>
                </div>
                <div style={{height: 10}}></div>
                <div className="web-page-footer-links-container">
                    <p>Copyright ©{new Date().getFullYear()} J4Crew LTD</p>
                </div>
                <div style={{height: 30}}></div>
            </Page>
        );

    }

}

export default Home;