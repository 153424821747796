import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserData, updateUserProfile } from "../../redux/actions/user";
import { setDeleteAccount, setPasswordChanged, setDetailsChanged } from "../../redux/actions/misc";

/* Components */
import CoupleRequestButtons from "./components/CoupleRequestButtons";
import TextInputWithIcon from "../../components/TextInputWithIcon";
import CheckBox from "../../components/CheckBox";

/* Helpers */
import { handlePartner } from "../../helpers/couple";

/* API requests */
import { checkPreviousPassword, changePassword, checkEmail } from "../../api-requests/user";
import { sendConnectionRequest, updateConnection } from "../../api-requests/couple";

class SettingsCandidate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            previousPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            firstName: "",
            lastName: "",
            email: "",
            previousEmail: "",
            partnerEmail: "",
        };

    }

    componentDidMount() {

        const { data } = this.props.user;

        return this.setState({ firstName: data.firstName, lastName: data.lastName, email: data.email, previousEmail: data.email })

    }

    handleBasicDetailsChange = ({ firstName, lastName, email, previousEmail }, id) => {

        if (firstName && lastName && email) {

            let body;

            if (previousEmail !== email) {
                body = [{ propName: "firstName", value: firstName }, { propName: "lastName", value: lastName }, { propName: "email", value: email }];
                return checkEmail(email)
                    .then(result => {
                        if (result.status) {
                            return this.setState({ previousEmail: email }, () => {
                                this.props.updateUserData(body, true);
                                this.props.updateUserProfile(body, true);
                                this.props.setDetailsChanged(true);

                                return setTimeout(() => {
                                    return this.props.setDetailsChanged(false);
                                }, 3333);
                            });
                        } else {
                            return alert("This email is already taken. Please try another one.");
                        }
                    });
            } else {
                body = [{ propName: "firstName", value: firstName }, { propName: "lastName", value: lastName }];
                
                this.props.updateUserData(body, true);
                this.props.updateUserProfile(body, true);
                this.props.setDetailsChanged(true);

                return setTimeout(() => {
                    return this.props.setDetailsChanged(false);
                }, 3333);
            }
        }
        return alert("All detail fields are required.");

    }

    handlePasswordChange = ({ previousPassword, newPassword, confirmNewPassword }, id) => {

        if (previousPassword && newPassword && confirmNewPassword) {
            return checkPreviousPassword(id, previousPassword)
                .then(result => {
                    if (result.status) {
                        if (newPassword === confirmNewPassword) {
                            if (newPassword.length >= 6) {
                                if (newPassword !== previousPassword) {
                                    return changePassword(id, newPassword)
                                        .then(result => {
                                            if (result.success) {
                                                return this.setState({ previousPassword: "", newPassword: "", confirmNewPassword: "" }, () => {
                                                    this.props.setPasswordChanged(true);
                                                    return setTimeout(() => {
                                                        return this.props.setPasswordChanged(false);
                                                    }, 3333);
                                                });
                                            }
                                            return;
                                        });
                                } else {
                                    return alert("New password cannot be the same as your previous password.");
                                }
                            } else {
                                return alert("New password must be at least 6 characters.");
                            }
                        } else {
                            return alert("New password does not match.");
                        }
                    } else {
                        return alert("Previous password is incorrect. Please try again.");
                    }
                });
        }
        return alert("All password fields are required.");

    }

    handleAddingPartner = (email, myEmail, id) => {

        if (email === myEmail) return alert("Nice try! :)");

        if (email) {
            return checkEmail(email)
                .then(result => {
                    if (!result.status) {
                        return sendConnectionRequest(id, result._id)
                            .then(result => {
                                if (result?.couple) {
                                    this.setState({ partnerEmail: "" });
                                    return this.props.updateUserProfile([{ propName: "couple", value: result.couple }]);
                                }
                                return alert("Something went wrong. Please try again.");
                            });
                    } else {
                        return alert("There is no user corresponding to this email.");
                    }
                });
        } else {
            return alert("Partner's email is required.");
        }

    }

    handleConnectionUpdate = (partner, status) => {

        const { profile } = this.props.user.data;

        return updateConnection(partner.coupleId, status)
            .then(result => {
                if (result.error) return;

                if (status === "cancelled" || status === "declined" || status === "removed") return this.props.updateUserProfile([ { propName: "couple", value: null } ]);

                return this.props.updateUserProfile([ { propName: "couple", value: { ...profile.couple, status: "linked" } } ]);
            });

    }

    handlePartnerText = (partner) => {

        if (!partner) return "To connect your account with your partner, please enter the email address of your partner below.";
        if (partner.status === "linked") return "Your accounts are connected. Apply for positions as a couple.";
        if (partner?.role === "receiver") return "You have requested to partner up with "+partner.name+". An email has been sent out.";
        if (partner?.role === "sender") return "Connecting your accounts will enable you to apply for positions as a couple.";

        return "";

    }

    render() {

        const { data } = this.props.user,
            partner = handlePartner(data.profile.couple, data._id);

        return(
            <div className="c-settings-container">
                <div className="c-settings-title-container">
                    <p>Account Settings</p>
                </div>
                <div className="c-settings-content-container">
                    <div className="c-settings-content-side-container margin-top-20px">
                        <div className="c-settings-section-title-container">
                            <p>Basic Details</p>
                        </div>
                        <div className="margin-top-20px">
                            <TextInputWithIcon value={this.state.firstName} onChangeText={(e) => this.setState({ firstName: e })} placeholder={"First Name"} hide={false} />
                        </div>
                        <div className="margin-top-20px">
                            <TextInputWithIcon value={this.state.lastName} onChangeText={(e) => this.setState({ lastName: e })} placeholder={"Last Name"} hide={false} />
                        </div>
                        <div className="margin-top-20px">
                            <TextInputWithIcon value={this.state.email} onChangeText={(e) => this.setState({ email: e })} placeholder={"Email"} hide={false} />
                        </div>
                        <div className="margin-top-20px">
                            <div className="change-password-button" onClick={() => this.handleBasicDetailsChange(this.state)}>
                                <p>Update Account Settings</p>
                            </div>
                        </div>
                        <div className="c-settings-section-title-container margin-top-2x">
                            <p>Couples Account Connection</p>
                        </div>
                        <div className="c-settings-section-subtitle-container margin-top-10px">
                            <p>{this.handlePartnerText(partner)}</p>
                        </div>
                        <div className="margin-top-20px">
                            <TextInputWithIcon value={partner ? partner.name+", "+partner.email : this.state.partnerEmail} onChangeText={(e) => this.setState({ partnerEmail: e })} placeholder={partner ? "Partner" : "Partner's Email Address"} hide={false} disabled={partner ? true : false} />
                        </div>
                        <div className="margin-top-20px" style={{display: "flex", flexDirection: "row"}}>
                            <CoupleRequestButtons partner={partner} handleConnectionUpdate={this.handleConnectionUpdate} handleAddingPartner={() => this.handleAddingPartner(this.state.partnerEmail, data.email, data._id)} />
                        </div>
                    </div>
                    <div className="c-settings-content-side-container margin-top-20px">
                        <div className="c-settings-section-title-container">
                            <p>Notifications</p>
                        </div>
                        <div className="c-settings-section-description-container margin-top-10px">
                            <p>Choose which notifications you wish to receive. Notifications will be sent to {data.email}</p>
                        </div>
                        <div className="c-settings-notifications-item-container margin-top-20px">
                            <CheckBox label={"Monthly Newsletter"} propName={"newsLetter"} value={data?.newsLetter ? false : true} status={data?.newsLetter} toggle={this.props.updateUserData} />
                        </div>
                        <div className="c-settings-delete-account-container margin-top-20px">
                            <p>Have questions?</p>
                            <p>We’re only an email away: <a href={"mailto:crew@j4crew.com"} className="c-settings-email">crew@j4crew.com</a></p>
                            <p>To Delete your account, please <span className="c-settings-delete-account" onClick={() => this.props.setDeleteAccount(true, false)}>click here</span></p>
                        </div>
                        <div className="c-settings-section-title-container margin-top-2x">
                            <p>Change Password</p>
                        </div>
                        <div className="margin-top-20px">
                            <TextInputWithIcon value={this.state.previousPassword} onChangeText={(e) => this.setState({ previousPassword: e })} placeholder={"Enter Previous Password"} hide={true} />
                        </div>
                        <div className="margin-top-20px">
                            <TextInputWithIcon value={this.state.newPassword} onChangeText={(e) => this.setState({ newPassword: e })} placeholder={"Enter New Password"} hide={true} />
                        </div>
                        <div className="margin-top-20px">
                            <TextInputWithIcon value={this.state.confirmNewPassword} onChangeText={(e) => this.setState({ confirmNewPassword: e })} placeholder={"Confirm New Password"} hide={true} />
                        </div>
                        <div className="margin-top-20px">
                            <div className="change-password-button" onClick={() => this.handlePasswordChange(this.state, data._id)}>
                                <p>Change Password</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserData, setDeleteAccount, setPasswordChanged, setDetailsChanged, updateUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCandidate);