import React, { Component } from "react";

class ContactUs extends Component {

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        return(
            <>
                <div className="contact-us-intro-container">
                    <p>Contact Us</p>
                </div>
                <div className="about-us-text">
                    <p>If you are a have any questions about our vacancies or roles, or generally would like to have an informal chat, please contact us via the details below.</p>
                </div>
                <div className="about-us-text">
                    <p>For general enquries from crew, please contact <a href="mailto:crew@j4crew.com" target="_blank" rel="noopener noreferrer">crew@j4crew.com</a> and we will get back to you ASAP.</p>
                </div>
                <div style={{height: 30}}></div>
                <div className="contact-us-team-members-container">
                    <div className="contact-us-team-members-item-container">
                        <div className="contact-us-team-members-item-image-container">
                            <img src={require("../../../../assets/images/chay.jpg")} alt="" />
                        </div>
                        <div className="contact-us-team-members-item-info-container">
                            <p>Chay Hodgson</p>
                            <p><a href="https://wa.me/447857138274" target={"_blank"} rel="noopener noreferrer">+44 (0) 785 713 8274</a></p>
                            <a href="mailto:chay@j4crew.com" target="_blank" rel="noopener noreferrer">chay@j4crew.com</a>
                        </div>
                    </div>
                    <div className="contact-us-team-members-item-container">
                        <div className="contact-us-team-members-item-image-container">
                            <img src={require("../../../../assets/images/jacob.jpg")} alt="" />
                        </div>
                        <div className="contact-us-team-members-item-info-container">
                            <p>Jacob Hodgson</p>
                            <p><a href="https://wa.me/447598382232" target={"_blank"} rel="noopener noreferrer">+44 (0) 759 838 2232</a></p>
                            <a href="mailto:jacob@j4crew.com" target="_blank" rel="noopener noreferrer">jacob@j4crew.com</a>
                        </div>
                    </div>
                </div>
            </>
        );

    }

}

export default ContactUs;