import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

/* Components */
import TextInputWithIcon from "../../../../components/TextInputWithIcon";
import Button from "../../../../components/Button";

/* Actions */
import { setUserData, setAuth } from "../../../../redux/actions/user";
import { setRegistrationLoadingState, setAccountType, setFirstName, setFirstNameError, setLastName, setLastNameError, setEmail, setEmailError, setPassword, setPasswordError, clearRegistrationFields } from "../../../../redux/actions/register";

/* API requests */
import { register } from "../../../../api-requests/register";

class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

    }

    handleRegistration = (userType, firstName, lastName, email, password) => {
        
        if (!firstName) this.props.setFirstNameError(true, "First name is a required field.");
        if (!lastName) this.props.setLastNameError(true, "Last name is a required field.");
        if (!email) this.props.setEmailError(true, "Email is a required field.");
        if (!password) this.props.setPasswordError(true, "Password is a required field.");
        if (password && password.length < 6) this.props.setPasswordError(true, "Password should be at least 6 characters.");

        if (firstName && lastName && email && password && password.length >= 6) {
            this.props.setRegistrationLoadingState(true);
            return register(firstName, lastName, email, password, userType)
                .then(result => {
                    if (result.error) {
                        if (result.error_code === "already-exists") this.props.setEmailError(true, result.error_message);
                        return this.props.setRegistrationLoadingState(false);
                    }
                    this.props.setAuth(true);
                    this.props.setUserData(result.user);                    
                    this.props.clearRegistrationFields();
                    if (userType === "Candidate") {
                        return this.props.history.push("/setup");
                    } else {
                        return this.props.history.push("/setup");
                    }
                });
            
        }        


    }

    handleKeyPress = (e, accountType, firstName, lastName, email, password) => {        

        if (e.key === "Enter") {
            return this.handleRegistration(accountType, firstName, lastName, email, password);
        }
        return;

    }

    render() {

        return(
            <div className="register-form-container" tabIndex={0} onKeyPress={(e) => this.handleKeyPress(e, this.props.register.accountType, this.props.register.firstName, this.props.register.lastName, this.props.register.email, this.props.register.password)}>
                <div className="register-form-header-container">
                    <span className="register-form-header-title">Crew Registration - Sign Up Free!</span>
                </div>
                <div className="margin-top-2x">
                    <TextInputWithIcon value={this.props.register.firstName} onChangeText={this.props.setFirstName} error={this.props.register.firstNameError} icon={require("../../../../assets/svgs/user.svg")} placeholder={"First Name"} hide={false} />
                </div>
                <div className="register-error-container">
                    {this.props.register.firstNameError && 
                        <p className="register-error-text">{this.props.register.firstNameErrorMessage}</p>
                    }
                </div>
                <div>
                    <TextInputWithIcon value={this.props.register.lastName} onChangeText={this.props.setLastName} error={this.props.register.lastNameError} icon={require("../../../../assets/svgs/user.svg")} placeholder={"Last Name"} hide={false} />
                </div>
                <div className="register-error-container">
                    {this.props.register.lastNameError &&
                        <p className="register-error-text">{this.props.register.lastNameErrorMessage}</p>
                    }
                </div>
                <div>
                    <TextInputWithIcon value={this.props.register.email} onChangeText={this.props.setEmail} error={this.props.register.emailError} icon={require("../../../../assets/svgs/mail.svg")} placeholder={"Your Email"} hide={false} />
                </div>
                <div className="register-error-container">
                    {this.props.register.emailError &&
                        <p className="register-error-text">{this.props.register.emailErrorMessage}</p>
                    }
                </div>
                <div>
                    <TextInputWithIcon value={this.props.register.password} onChangeText={this.props.setPassword} error={this.props.register.passwordError} icon={require("../../../../assets/svgs/password.svg")} placeholder={"Password"} hide={true} />
                </div>
                <div className="register-error-container">
                    {this.props.register.passwordError &&
                        <p className="register-error-text">{this.props.register.passwordErrorMessage}</p>
                    }
                </div>
                <div className="register-form-button-container">
                    <Button onClick={() => this.handleRegistration(this.props.register.accountType, this.props.register.firstName, this.props.register.lastName, this.props.register.email, this.props.register.password)} loading={this.props.register.loading} title={"Sign Up"} type="primary" />
                    <span className="register-form-button-container-sidenote">Have an Account? <Link className="register-form-button-container-sidenote-link" to={"/sign-in"}>Log In</Link></span>
                </div>
                <div className="register-form-bottom-separator"></div>
                <div className="register-form-terms-container">
                    <p className="register-form-terms-text">By creating an account, you accept the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> & <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and you must be over 18 years old. You also agree to receiving marketing messages from J4Crew from which you can opt out within your account settings.</p>
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        register: state.register,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setRegistrationLoadingState, setAccountType, setFirstName, setFirstNameError, setLastName, setLastNameError, setEmail, setEmailError, setPassword, setPasswordError, clearRegistrationFields, setUserData, setAuth }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);