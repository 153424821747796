
export const handlePartner = (couple, pid) => {

    if (!couple) return null;
    if (!couple._id) return null;
    if (!couple.receiver) return null;
    if (!couple.sender) return null;
    if (!couple.receiver._id) return null;
    if (!couple.sender._id) return null;
    if (couple.receiver._id !== pid) {
        return {
            coupleId: couple._id,
            _id: couple.receiver.profile._id,
            name: `${couple.receiver.firstName} ${couple.receiver.lastName}`,
            email: couple.receiver.email,
            role: "receiver",
            status: couple.status,
            desiredRoles: couple?.receiver?.profile?.lookingFor?.desiredRoles?.length > 0 ? couple.receiver.profile.lookingFor.desiredRoles : null,
            isVerified: couple?.receiver?.profile?.isVerified,
        };
    }
    if (couple.sender._id !== pid) {
        return {
            coupleId: couple._id,
            _id: couple.sender.profile._id,
            name: `${couple.sender.firstName} ${couple.sender.lastName}`,
            email: couple.sender.email,
            role: "sender",
            status: couple.status,
            desiredRoles: couple?.sender?.profile?.lookingFor?.desiredRoles?.length > 0 ? couple.sender.profile.lookingFor.desiredRoles : null,
            isVerified: couple?.sender?.profile?.isVerified,
        };
    }

    return null;

}