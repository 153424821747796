import {
    SET_APPLICATIONS,
    ADD_APPLICATION,
    REMOVE_APPLICATION,
} from "../types/application";

const initialState = {
    isFetching: true,
    applications: [],
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_APPLICATIONS:

            return {
                ...state,
                isFetching: false,
                applications: action.data,
            }
        
        case ADD_APPLICATION:

            return {
                ...state,
                applications: [...state.applications, action.data],
            }

        case REMOVE_APPLICATION:

            return {
                ...state,
                applications: state.applications.filter(application => application._id !== action.id),
            }
        
        default:
            return state;

    }

};