import React from "react";

/* API requests */
import { updateCandidate } from "../api-requests/candidate";

/* Components */
import TextRowReact from "../pages/DashboardCandidate/components/CV/components/TextRow";
import { TextRow } from "../components/CVPDF/components";


export const phoneOrWhatsapp = (admin, number) => {

    if (admin) return getNumbersOnly(number);

    return number;

}

export const handleAvailability = (profile) => {

    if (!profile.isAvailable) return "Unavailable";
    if (profile.isAvailable && !profile.hasNotice) return "Immediate";
    if (profile.isAvailable && profile.hasNotice && profile.noticePeriod === 7) return "Available in 1 week";
    if (profile.isAvailable && profile.hasNotice && profile.noticePeriod === 14) return "Available in 2 weeks";
    if (profile.isAvailable && profile.hasNotice && profile.noticePeriod === 28) return "Available in 4+ weeks";

}

export const handleMultiple = (items) => {

    let text = "";

    items.forEach((item, index) => {
        if (index < 1) {
            text = item;
        } else {
            text = text+", "+item;
        }
    }); 

    return text ? text : "None";

}

export const handleMultipleQualifications = (items, moreQuals) => {

    let text = "";

    items.forEach((item, index) => {
        if (index < 1) {
            text = item;
        } else {
            text = text+" & "+item;
        }
    });

    if (moreQuals?.length < 1 || moreQuals[0]?.length < 1) return text ? text : "None";

    moreQuals.forEach(arr => {
        let moreText = "";
        text = text+" or ";
        arr.forEach((item, index) => {
            if (index < 1) {
                moreText = item.name;
            } else {
                moreText = moreText+" & "+item.name;
            }
        });
        text = text+moreText;
    });

    return text;

}

export const constructObjective = (role, index, roles) => {

    let text;

    if (roles.length < 2) {
        text = "I am looking for a";
    } else {
        text = index < 1 ? "I am primarily looking for a" : "I would also consider a";
    }

    if (role?.jobType.length === 3) text = text+" "+role?.jobType[0].toLowerCase()+", "+role?.jobType[1].toLowerCase()+" or "+role?.jobType[2].toLowerCase();
    if (role?.jobType.length === 2) text = text+" "+role?.jobType[0].toLowerCase()+" or "+role?.jobType[1].toLowerCase();
    if (role?.jobType.length === 1) text = text+" "+role?.jobType[0].toLowerCase();

    if (text.includes("rotation")) text = text.replace("rotation", "rotational");
    
    if (role.position.length === 1) text = text+" "+role.position[0].name+" position";
    if (role.position.length === 2) text = text+" "+role.position[0].name+" or "+role.position[1].name+" position";

    if (role.position.length > 2) {
        role.position.forEach((position, index) => {
            if ((role.position.length-1) === index) {
                text = text+" "+position.name+" position";
            } else if ((role.position.length-2) === index) {
                text = text+" "+position.name+" or";
            } else {
                text = text+" "+position.name+",";
            }
        });
    }

    if (!role.noYachtSizePreference) {
        if (role.yachtSizeMin && role.yachtSizeMax) text = text+" on a "+role.yachtSizeMin+"m to "+role.yachtSizeMax+"m";
        if (role.yachtSizeMin && !role.yachtSizeMax) text = text+" on a "+role.yachtSizeMin+"m+";
        if (!role.yachtSizeMin && role.yachtSizeMax) text = text+" on an up to "+role.yachtSizeMax+"m";
        if (!role.yachtSizeMin && !role.yachtSizeMax) text = text+" on a";
    } else {
        text = text+" on a";
    }

    if (role.yachtType.length === 2) text = text+" Motor or Sailing Yacht";
    if (role.yachtType.length === 1) text = text+" "+role.yachtType[0];
    if (role.yachtType.length === 0) text = text+" yacht";

    return text+".";

}

export const constructPartnersObjective = (partner) => {

    return "I would like a couples position with my partner "+partner.name+" who is looking for a position as "+partner.desiredRoles[0].position[0].name+".";

}

export const handleCurrencySymbol = (lookingFor) => {

    if (!lookingFor) return "";
    if (!lookingFor.idealSalaryCurrency) return "";
    if (lookingFor.idealSalaryCurrency === "eur") return "€";
    if (lookingFor.idealSalaryCurrency === "usd") return "$";
    if (lookingFor.idealSalaryCurrency === "gbp") return "£";

}

export const constructPreferenceText = (preference) => {

    let sentence = "";

    if (preference.jobType.length !== 0) {
        if (preference.jobType.length === 1) sentence = preference.jobType[0]+" ";
        if (preference.jobType.length === 2) sentence = preference.jobType[0]+" or "+preference.jobType[1]+" ";
        if (preference.jobType.length === 3) sentence = preference.jobType[0]+", "+preference.jobType[1]+" or "+preference.jobType[2]+" ";
        if (preference.jobType.length === 4) sentence = "Any ";
    }

    if (preference.position.length > 1) {
        sentence = sentence+preference.position[0].name+" or "+(preference.position.length-1)+" other; ";
    } else {
        sentence = sentence+preference.position[0].name+"; ";
    }

    if (!preference.noYachtSizePreference) {
        if (preference.yachtSizeMin && preference.yachtSizeMax) sentence = sentence+preference.yachtSizeMin+"m to "+preference.yachtSizeMax+"m";
        if (preference.yachtSizeMin && !preference.yachtSizeMax) sentence = sentence+preference.yachtSizeMin+"m+";
        if (!preference.yachtSizeMin && preference.yachtSizeMax) sentence = sentence+"up to "+preference.yachtSizeMax+"m";
    }

    if (preference.yachtType.length === 2) sentence = sentence+" Motor or Sailing Yacht; ";
    if (preference.yachtType.length === 1) sentence = sentence+" "+preference.yachtType[0]+"; ";

    return sentence;

}

export const constructPreferenceTitle = (index) => {

    if (index === 1) return "1st Preference";
    if (index === 2) return "2nd Preference";
    if (index === 3) return "3rd Preference";

    return index+"th Preference";

}

export const save = (propName, value, profile) => {

    const body = [{ propName, value }];
    return updateCandidate(profile._id, body);

}

export const calculatePercent = (data) => {

    let cents = 0;

    if (data?.lookingFor?.desiredRoles[0]?.position[0]?.name) cents = cents+20;
    if (data.profileImage) cents = cents+10;
    if (data?.currentLocation?.country) cents = cents+8;
    if (data?.cv?.name) cents = cents+6;
    if (data.primaryPhone) cents = cents+5;
    if (data.dob) cents = cents+4;
    if (data.gender) cents = cents+4;
    if (data?.nationality?.length > 0) cents = cents+3;
    if (data?.languages?.length > 0) cents = cents+3;
    if (data.height) cents = cents+3;
    if (data.tattoo) cents = cents+3;
    if (data.smoking !== undefined) cents = cents+3;
    if (data.country) cents = cents+3;
    if (data.relationshipStatus) cents = cents+3;
    if (data.aboutMe) cents = cents+3;
    if (data.hobbies) cents = cents+3;
    if (data?.pastRoles?.length > 0) cents = cents+3;
    if (data?.education?.length > 0) cents = cents+3;
    if (data?.references?.length > 0) cents = cents+3;
    if (data.eng1.length > 0) cents = cents+3;
    if (data?.stcwBasic?.length > 0) cents = cents+3;
    if (data?.writtenReferences?.length > 0) cents = cents+1;
    
    return cents;

}

export const balancePersonalDetails = (profile) => {

    let details = [];

    if (profile?.nationality.length !== 0) details = [...details, <TextRowReact propName={"Nationality"} value={handleMultiple(profile.nationality)} />];
    if (profile?.gender) details = [...details, <TextRowReact propName={"Gender"} value={profile.gender} />];
    if (profile?.height) details = [...details, <TextRowReact propName={"Height"} value={profile.height+"cm"} />];
    if (profile?.b1b2?.status || profile?.c1d?.status || profile?.schengen?.status || profile?.greenCard?.status) details = [...details, <TextRowReact propName={"Visa"} value={profile} type={"visa"} />];
    if (profile?.relationshipStatus) details = [...details, <TextRowReact propName={"Relationship Status"} value={profile.relationshipStatus} />];
    if (profile?.tattoo) details = [...details, <TextRowReact propName={"Tattoos"} value={profile.tattoo} />];
    if (profile?.smoking !== undefined) details = [...details, <TextRowReact propName={"Smoking"} value={profile.smoking ? "Smoker" : "Non Smoker"} />];
    if (profile?.languages.length !== 0) details = [...details, <TextRowReact propName={"Languages"} value={handleMultiple(profile.languages)} />];
    if (profile?.country) details = [...details, <TextRowReact propName={"Residence"} value={profile.country} />];

    const half = Math.ceil(details.length / 2);    

    const firstHalf = details.slice(0, half)
    const secondHalf = details.length % 2 ? details.slice(-half+1) : details.slice(-half);

    return [firstHalf, secondHalf];

}

export const balancePersonalDetailsPDF = (profile) => {

    let details = [];

    if (profile?.nationality.length !== 0) details = [...details, <TextRow propName={"Nationality"} value={handleMultiple(profile.nationality)} />];
    if (profile?.gender) details = [...details, <TextRow propName={"Gender"} value={profile.gender} />];
    if (profile?.height) details = [...details, <TextRow propName={"Height"} value={profile.height+"cm"} />];
    if (profile?.b1b2?.status || profile?.c1d?.status || profile?.schengen?.status || profile?.greenCard?.status) details = [...details, <TextRow propName={"Visa"} value={profile} type={"visa"} />];
    if (profile?.relationshipStatus) details = [...details, <TextRow propName={"Relationship Status"} value={profile.relationshipStatus} />];
    if (profile?.tattoo) details = [...details, <TextRow propName={"Tattoos"} value={profile.tattoo} />];
    if (profile?.smoking !== undefined) details = [...details, <TextRow propName={"Smoking"} value={profile.smoking ? "Smoker" : "Non Smoker"} />];
    if (profile?.languages.length !== 0) details = [...details, <TextRow propName={"Languages"} value={handleMultiple(profile.languages)} />];
    if (profile?.country) details = [...details, <TextRow propName={"Residence"} value={profile.country} />];

    const half = Math.ceil(details.length / 2);    

    const firstHalf = details.slice(0, half)
    const secondHalf = details.length % 2 ? details.slice(-half+1) : details.slice(-half);

    return [firstHalf, secondHalf];

}

const getNumbersOnly = (number) => {

    let nrs = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let splittedNr = number.split("");

    let cleanNr = splittedNr.filter(nr => nrs.includes(nr)).join("");

    return "https://wa.me/"+cleanNr;

}