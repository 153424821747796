import React, { Component } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

/* Actions */
import { setAlert } from "../../../../redux/actions/misc";

/* Helpers */
import { handleStartDate, handleJobSalary, handleContractType } from "../../../../helpers/job";

/* API requests */
import { getAllListings, searchListings, deleteJob, archiveJob, handleDraft } from "../../../../api-requests/job";

class Listings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: "",
            showDropdown: false,
            selected: "",
            active: [],
            archived: [],
            drafts: [],
            isFetching: true,
            searchResults: [],
            isFetchingSearchResults: false,
        };
    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        return getAllListings()
            .then(listings => {
                return this.setState({ drafts: listings.drafts, archived: listings.archived, active: listings.active, isFetching: false });
            });

    }

    renderDrafts = (posts) => {

        if (this.state.selected) posts = posts.filter(post => post.consultant === this.state.selected);

        if (posts.length > 0) {
            return posts.map((post, index) => {
                return(
                    <div key={index} className="yacht-listings-list-item-container">
                        <Link className="yacht-listings-list-item-wrapper" to={`/listings/add/${post.yacht._id}?job=${post._id}&status=draft`}>
                            <div className="yacht-listings-list-item-position-container">
                                <p>{post?.yacht?.yachtType === "Sailing Yacht" ? "S/Y " : post?.yacht?.yachtType === "Motor Yacht" ? "M/Y " : ""}{post.yacht.name} - {post?.position?.name ? post.position.name : "N/A"} - Job Ref: {post.reference}</p>
                            </div>
                            <div className="yacht-listings-list-item-start-date-container">
                                <p className="yacht-listings-list-item-text">{handleStartDate(post)}</p>
                            </div>
                            <div className="yacht-listings-list-item-salary-container">
                                <p className="yacht-listings-list-item-text">{handleJobSalary(post)}</p>
                            </div>
                            <div className="yacht-listings-list-item-contract-container">
                                <p className="yacht-listings-list-item-text">{handleContractType(post)}</p>
                            </div>
                        </Link>
                        <div className="yacht-listings-list-item-buttons-container">
                            <Link className="yacht-listings-list-item-complete-button-container" to={`/listings/add/${post.yacht._id}?job=${post._id}&status=draft`}>
                                <p>Complete Listing</p>
                            </Link>
                            <div className="yacht-listings-list-item-trash-button-container">
                                <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                                <div className="yacht-listings-list-item-trash-confirmation-button" onClick={() => this.handleDelete(post._id)}>
                                    <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                                    <p>Delete Forever</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else if (!this.state.isFetching) {
            return(
                <p className="no-jobs-listed-atm">There are currently no drafted jobs.</p>
            );
        } else {
            return "";
        }

    }

    handleDelete = (_id) => {

        return deleteJob(_id)
            .then(result => {
                if (result.success) {
                    return getAllListings()
                        .then(listings => {
                            return this.setState({ drafts: listings.drafts, archived: listings.archived, active: listings.active }, () => {
                                this.props.setAlert("Listing deleted successfully!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Listing deleted successfully!", "success", false);
                                }, 2700);
                            });
                        });
                }
            });

    }

    renderArchive = (posts) => {

        if (this.state.selected) posts = posts.filter(post => post.consultant === this.state.selected);

        if (posts.length > 0) {
            return posts.map((post, index) => {
                return(
                    <div key={index} className="yacht-listings-list-item-container">
                        <div className="yacht-listings-list-item-wrapper" onClick={() => this.handleRelistPosition(post)}>
                            <div className="yacht-listings-list-item-position-container">
                                <p>{post?.yacht?.yachtType === "Sailing Yacht" ? "S/Y " : post?.yacht?.yachtType === "Motor Yacht" ? "M/Y " : ""}{post.yacht.name} - {post?.position?.name ? post.position.name : "N/A"} - Job Ref: {post.reference}</p>
                            </div>
                            <div className="yacht-listings-list-item-start-date-container">
                                <p className="yacht-listings-list-item-text">{handleStartDate(post)}</p>
                            </div>
                            <div className="yacht-listings-list-item-salary-container">
                                <p className="yacht-listings-list-item-text">{handleJobSalary(post)}</p>
                            </div>
                            <div className="yacht-listings-list-item-contract-container">
                                <p className="yacht-listings-list-item-text">{handleContractType(post)}</p>
                            </div>
                        </div>
                        <div className="yacht-listings-list-item-buttons-container">
                            <div className="yacht-listings-list-item-complete-button-container" onClick={() => this.handleRelistPosition(post)}>
                                <p>Re-List Position</p>
                            </div>
                            <div className="yacht-listings-list-item-trash-button-container">
                                <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                                <div className="yacht-listings-list-item-trash-confirmation-button" onClick={() => this.handleDelete(post._id)}>
                                    <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                                    <p>Delete Forever</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else if (!this.state.isFetching) {
            return(
                <p className="no-jobs-listed-atm">There are no archived jobs yet.</p>
            );
        } else {
            return "";
        }

    }

    handleArchive = (_id) => {

        return archiveJob(_id)
            .then(result => {
                if (result.success) {
                    return getAllListings()
                        .then(listings => {
                            return this.setState({ drafts: listings.drafts, archived: listings.archived, active: listings.active }, () => {
                                this.props.setAlert("Listing archived successfully!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Listing archived successfully!", "success", false);
                                }, 2700);
                            });
                        });
                }
            });

    }

    handleRelistPosition = (post) => {

        return handleDraft("", post, post.yacht, post.postedBy)
            .then(result => {
                return this.props.history.push(`/listings/add/${result.data.yacht}?job=${result.data._id}&status=archived`);
            });

    }

    sortByApplications = (a, b) => {

        if (a.newApplications && b.newApplications) {
            
            if (a.newApplications.length > b.newApplications.length) return -1;
            if (a.newApplications.length < b.newApplications.length) return 1;
    
        }   
    
        return 0;
    
    }

    renderPlaceholders = () => {

        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
            return(
                <div className="yacht-listings-list-item-placeholder" key={index}>
                </div>
            );
        });

    }

    renderActive = (posts) => {

        if (this.state.selected) posts = posts.filter(post => post.consultant === this.state.selected);

        if ((posts.length > 0) || this.state.searchResults.length > 0) {

            posts = this.state.searchResults.length < 1 ? posts : this.state.searchResults;

            let sortedByNewApplications = posts.sort(this.sortByApplications);

            return sortedByNewApplications.map((post, index) => {
                return(
                    <div key={index} className="yacht-listings-list-item-container">
                        <Link className="yacht-listings-list-item-wrapper" to={`/listings/applications/${post.yacht._id}/job/${post._id}`}>
                            <div className="yacht-listings-list-item-position-container">
                                <div className="yacht-listings-list-item-position-title-container">
                                    <p>{post?.yacht?.yachtType === "Sailing Yacht" ? "S/Y " : post?.yacht?.yachtType === "Motor Yacht" ? "M/Y " : ""}{post.yacht.name} - {post?.position?.name ? post.position.name : "N/A"} - Job Ref: {post.reference}</p>
                                </div>
                                <div className="yacht-listings-list-item-position-applications-counter-container">
                                    <div className="yacht-listings-list-item-position-applications-counter-item-container">
                                        <img src={require("../../../../assets/svgs/total-applications.svg")} alt="" />
                                        <span style={{color: "#1377FF"}}>{post.applications.length}</span>
                                    </div>
                                    <div className="yacht-listings-list-item-position-applications-counter-item-container" style={{paddingLeft: 10}}>
                                        <img src={post.newApplications.length < 1 ? require("../../../../assets/svgs/no-new-applications.svg") : require("../../../../assets/svgs/new-applications.svg")} alt="" />
                                        <span style={{color: post.newApplications.length < 1 ? "#8A8A8A" : "#FB8266"}}>{post.newApplications.length}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="yacht-listings-list-item-start-date-container">
                                <p className="yacht-listings-list-item-text">{handleStartDate(post)}</p>
                            </div>
                            <div className="yacht-listings-list-item-salary-container">
                                <p className="yacht-listings-list-item-text">{handleJobSalary(post)}</p>
                            </div>
                            <div className="yacht-listings-list-item-contract-container">
                                <p className="yacht-listings-list-item-text">{handleContractType(post)}</p>
                            </div>
                        </Link>
                        <div className="yacht-listings-list-item-buttons-container">
                            <Link className="yacht-listings-list-item-complete-button-container" to={`/listings/applications/${post.yacht._id}/job/${post._id}`}>
                                <p>See Applications</p>
                            </Link>
                            <div className="yacht-listings-list-item-more-button-container">
                                <img src={require("../../../../assets/svgs/more.svg")} alt="" />
                                <div className="yacht-listing-list-item-more-buttons-container">
                                    <Link className="yacht-listings-list-item-more-edit-button" to={`/listings/add/${post.yacht._id}?job=${post._id}&status=active`}>
                                        <img src={require("../../../../assets/svgs/edit-dark.svg")} alt="" />
                                        <p>Edit Details</p>
                                    </Link>
                                    <div className="yacht-listings-list-item-more-edit-button" onClick={() => this.handleArchive(post._id)}>
                                        <img src={require("../../../../assets/svgs/end-listing.svg")} alt="" />
                                        <p>End & Archive Listing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else if (!this.state.isFetching) {
            return(
                <p className="no-jobs-listed-atm">There are currently no active jobs.</p>
            );
        } else {
            return "";
        }

    }

    getCount = (name, status) => {

        let posts = this.state[status ? status : "active"].filter(post => post.consultant === name);

        return posts.length;

    }

    handleSearch = (keyword, status) => {

        if (!status) status = "active";

        return this.setState({ keyword }, () => {
            if (keyword.length >= 2) {
                return this.setState({ isFetchingSearchResults: true }, () => {
                    return searchListings(keyword, status)
                        .then(result => {
                            return this.setState({ searchResults: result, isFetchingSearchResults: false });
                        });
                });
            }
            return this.setState({ searchResults: [], isFetchingSearchResults: false });
        });

    }

    render() {

        return(
            <div className="listings-master-container">
                <div className="listings-header-container">
                    <div className="listings-header-items-container">
                        <p className="listings-header-items-title">Listings</p>
                    </div>
                    <div className="listings-header-search-container">
                        <div className="listings-header-search-input-container">
                            <input type={"text"} placeholder={"Start typing..."} value={this.state.keyword} onChange={(e) => this.handleSearch(e.target.value, this.props.match.params.status)} />
                        </div>
                        <div className="listings-header-search-icon-container">
                            <img src={require("../../../../assets/svgs/search.svg")} alt="" />
                        </div>
                    </div>
                </div>
                <div className="listings-sort-input-container margin-top-20px">
                    <div className="listings-sort-input-wrapper" style={this.state.showDropdown ? {borderRadius: "4px 4px 0 0"} : {}} onClick={() => this.setState({ showDropdown: !this.state.showDropdown })}>
                        <div className="listings-sort-input-content-container">
                            <div className="listings-sort-input-title-container">
                                <p>Sort By</p>
                            </div>
                            <div className="listings-sort-input-data-container">
                                <p>{this.state.selected ? this.state.selected : "All Listings"}</p>
                            </div>
                        </div>
                        <div className="listings-sort-input-icon-container">
                            <img style={this.state.showDropdown ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                        </div>
                    </div>
                    {this.state.showDropdown &&
                        <div className="listings-sort-dropdown-list-container">
                            <div className="listings-sort-dropdown-list-item-container" onClick={() => this.setState({ showDropdown: false, selected: "Jacob Hodgson" })}>
                                <p>Jacob ({this.getCount("Jacob Hodgson", this.props.match.params.status)})</p>
                            </div>
                            <div className="listings-sort-dropdown-list-item-container" onClick={() => this.setState({ showDropdown: false, selected: "Chay Hodgson" })}>
                                <p>Chay ({this.getCount("Chay Hodgson", this.props.match.params.status)})</p>
                            </div>
                            <div className="listings-sort-dropdown-list-item-container" onClick={() => this.setState({ showDropdown: false, selected: "" })}>
                                <p>All Listings ({this.state[this.props.match.params.status ? this.props.match.params.status : "active"].length})</p>
                            </div>
                        </div>
                    }
                </div>
                <div className="yacht-listings-header-container">
                    <div className="yacht-listings-header-items-container">
                        <Link to={`/listings`}>
                            <div className="yacht-listings-header-item-container">
                                <p style={(this.props.match.params.status === "active" || !this.props.match.params.status) ?  {color: "#1377FF"} : {}}>Active</p>
                                {(this.props.match.params.status === "active" || !this.props.match.params.status) &&
                                    <div className="yacht-listings-header-item-active"></div>
                                }
                            </div>
                        </Link>
                        <Link to={`/listings/archived`}>
                            <div className="yacht-listings-header-item-container">
                                <p style={this.props.match.params.status === "archived" ?  {color: "#1377FF"} : {}}>Archived</p>
                                {this.props.match.params.status === "archived" &&
                                    <div className="yacht-listings-header-item-active"></div>
                                }
                            </div>
                        </Link>
                        <Link to={`/listings/drafts`}>
                            <div className="yacht-listings-header-item-container">
                                {this.state.drafts.length > 0 &&
                                    <div className="sign-ups-header-users-counter">
                                        <span>{this.state.drafts.length}</span>
                                    </div> 
                                }
                                <p style={this.props.match.params.status === "drafts" ?  {color: "#1377FF"} : {}}>Drafts</p>
                                {this.props.match.params.status === "drafts" &&
                                    <div className="yacht-listings-header-item-active"></div>
                                }
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="yacht-listing-list-contianer margin-top-20px">
                    {this.state[this.props.match.params.status ? this.props.match.params.status : "active"].length > 0 &&
                        <div className="yacht-listing-list-header-container">
                            <div className="yacht-listing-list-header-position-container">
                                <p>Position</p>
                            </div>
                            <div className="yacht-listing-list-header-start-date-container">
                                <p>Start Date</p>
                            </div>
                            <div className="yacht-listing-list-header-salary-container">
                                <p>Salary</p>
                            </div>
                            <div className="yacht-listing-list-header-contract-type-container">
                                <p>Contract Type</p>
                            </div>
                            <div className="yacht-listing-list-header-actions-container">
                                <p>Actions</p>
                            </div>
                        </div>
                    }
                    {this.state.isFetching &&
                        this.renderPlaceholders()
                    }
                    {this.props.match.params.status === "drafts" && !this.state.isFetching &&
                        this.renderDrafts(this.state.drafts)
                    }
                    {this.props.match.params.status === "archived" && !this.state.isFetching &&
                        this.renderArchive(this.state.archived)
                    }
                    {(this.props.match.params.status === "active" || !this.props.match.params.status) && !this.state.isFetching &&
                        this.renderActive(this.state.active)
                    }
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listings);