/* Get user currently active session */
export const getUserSession = () => {

    return fetch("/api/auth/session")
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

/* Log user in and create new session. */
export const loginUser = (email, password) => {

    const body = {
        email,
        password,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/auth/login", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

/* Log user out and destory current session. */
export const logoutUser = () => {

    return fetch("/api/auth/logout")
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

/* Request password request */
export const requestPasswordReset = (email) => {

    const body = {
        email,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/auth/send-reset-password-code", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

/* Request password request */
export const resetPassword = (passwordResetCode, password) => {

    const body = {
        passwordResetCode,
        password,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/auth/reset-password", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

/* Update user props */
export const updateUser = (id, data) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id,
            data,
        }),
    };

    return fetch("/api/users/update/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

/* Delete user with userType === Candidate */
export const deleteUserCandidate = (user) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user,
        }),
    };

    return fetch("/api/users/delete-candidate-account/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

/* Check previous password */
export const checkPreviousPassword = (id, password) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id,
            password,
        }),
    };

    return fetch("/api/auth/check-previous-password", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

/* Check email */
export const checkEmail = (email) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email,
        }),
    };

    return fetch("/api/auth/check-email", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

/* Change password */
export const changePassword = (id, password) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id,
            password,
        }),
    };

    return fetch("/api/auth/change-password", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

/* Get user data as an Admin */
export const getUserData = (id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id,
        }),
    };

    return fetch("/api/users/data/", request)
        .then(res => res.json())
        .then(result => {
            return result.user;
        })
        .catch(err => {
            return err;
        });

}