import React from "react";
import "./index.css";
import moment from "moment";
import { Link } from "react-router-dom";

/* Components */
import TextRow from "./components/TextRow";

/* Helpers */
import { handleYachtType, handleYachtSize, handleJobSalary, handleContractType, handleStartDate, compareArchived, compareExpired } from "../../helpers/job";

/* API requests */
import { createApplication, getApplications, updateApplication } from "../../api-requests/application";
import { getJobs } from "../../api-requests/job";

export default ({ admin, guest, setCVPreview, withdraw, profile, expanded, handleRow, jobs, updateUserProfile, removeJob, type, setAlert, setApplications, setJobs }) => {

    if (type === "applications") jobs = jobs.map(item => { return { ...item.job, application: item } } );

    return jobs.map((job, index) => {

        let contractType = handleContractType(job);
        let startDate = handleStartDate(job);

        let archived = compareArchived(job.archived);
        let expired = compareExpired(job.dateArchived, job.archived);

        if (type === "applications" && expired) return <div key={index}></div>;

        return(
            <div className="jobs-accordion-wrapper" key={index} style={{ marginBottom: index+1 < jobs.length ? 20 : 0, borderColor: expanded.includes(job._id) && !guest ? "#1377FF" : "#E5E5E5" }} onClick={() => handleClick(job._id, expanded, handleRow)}>
                <div className="jobs-accordion-master-content-wrapper">
                    <div className="jobs-accordion-content-wrapper">
                        <div className="jobs-accordion-content-wrapper-header-container">
                            <h2>{job.position.name}</h2>
                            {type === "applications" &&
                                <b style={{ color: archived ? "#EB5757" : "#2F77FF"}}>{archived ? "Position Closed" : "Position Open"}</b>
                            }
                        </div>
                        <p>{handleJobSalary(job, true)}</p>
                        <p>{handleYachtSize(job.yacht.size)} {handleYachtType(job.yacht.yachtType)}</p>
                        <strong style={{ color: contractType === "Full Time" ? "#56CCF2" : contractType.includes("Temporary") ? "#34AE60" : "#BB6CD9" }}>{contractType}</strong>
                        <p><span>Qualifications:</span> {job.qualifications}</p>
                        <p><span>Job Description:</span> {job.description}</p>                        
                        {expanded.includes(job._id) && !guest ?
                                <div></div>
                            :
                                <p style={{ marginTop: 15 }}><span>{job.dateCreated}<span style={{ padding: "0 10px"}}>•</span></span>More..</p>
                        }
                    </div>
                </div>
                {expanded.includes(job._id) && !guest &&
                    <div className="jobs-accordion-expanded-container">
                        <div className="jobs-accordion-expanded-wrapper">
                            <div className="jobs-accordion-expanded-wrapper-section" style={{ paddingRight: 20, paddingBottom: 20 }}>
                                <div className="jobs-accordion-expanded-title-container">
                                    <p className="jobs-accordion-expanded-title">Yacht Details</p>
                                </div>
                                <div className="margin-top-20px">
                                    {job?.yacht?.yachtStatus &&
                                        <TextRow propName={"Charter Status"} value={job.yacht.yachtStatus} />
                                    }
                                    {job?.yacht?.age &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Yacht Age"} value={job.yacht.age} />
                                        </div>
                                    }
                                    {job?.yacht?.yachtBuilderCountry &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Build"} value={job.yacht.yachtBuilderCountry} />
                                        </div>
                                    }
                                    {job.yacht.couples !== undefined &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Couples Considered"} value={job.yacht.couples ? "Yes" : "No"} />
                                        </div>
                                    }
                                    {job.yacht.itinerary.length > 0 &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Itinerary"} value={job.yacht.itinerary} />
                                        </div>
                                    }
                                    {job.visas &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Visas Required"} value={job.visas} />
                                        </div>
                                    }
                                    {job?.yacht?.currentLocation?.country &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Current Location"} value={job.yacht.currentLocation.country} />
                                        </div>
                                    }
                                    {job?.yacht?.homePort?.country &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Home Port"} value={job.yacht.homePort.country} />
                                        </div>
                                    }
                                    {job.yacht.smoking !== undefined &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Smoking Policy"} value={job.yacht.smoking} />
                                        </div>
                                    }
                                    {job?.yacht?.tattoo &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Tattoo Policy"} value={job.yacht.tattoo} />
                                        </div>
                                    }
                                    {job.yacht.dryBoat !== undefined &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Dry Boat?"} value={job.yacht.dryBoat} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="jobs-accordion-expanded-wrapper-section">
                                <div className="jobs-accordion-expanded-title-container">
                                    <p className="jobs-accordion-expanded-title">Job Details</p>
                                </div>
                                <div className="margin-top-20px">
                                    {job?.consultant && job?.reference &&
                                        <TextRow propName={"Posted By"} value={`J4Crew (${job.consultant}) - Job Ref: ${job.reference}`} />
                                    }
                                    {contractType &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Contract Type"} value={contractType} />
                                        </div>
                                    }
                                    {startDate &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Start Date"} value={startDate} />
                                        </div>
                                    }
                                    {job?.cabinStatus &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Cabin Status"} value={job.cabinStatus} />
                                        </div>
                                    }
                                    {job?.experience &&
                                        <div className="margin-top-10px">
                                            <TextRow propName={"Required Experience"} value={job.experience} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>                        
                        {/* !admin && type !== "applications" &&
                            <div className="jobs-accordion-expanded-match-container margin-top-25px">
                                <div className="jobs-accordion-expanded-match-title-container">
                                    <p className="jobs-accordion-expanded-match-title">Job Match</p>
                                    <p className="jobs-accordion-expanded-match-percent">{job.percent}%</p>
                                </div>
                                <div className="jobs-accordion-expanded-percent-bar-container margin-top-10px">
                                    <div className="jobs-accordion-expanded-percent" style={{width: `${job.percent}%`}}></div>
                                </div>
                            </div>
                         */}
                        {!admin && type !== "applications" ?
                            <div className="jobs-accordion-expanded-buttons-container margin-top-2x">
                                <div className="jobs-accordion-expanded-button-secondary" onClick={() => handleNotInterested(profile, updateUserProfile, job, removeJob, setAlert)}>
                                    <p className="jobs-accordion-expanded-button-secondary-title">Not Interested</p>
                                </div>
                                <div className="jobs-accordion-expanded-button-primary" onClick={() => handleApply(setCVPreview,profile, updateUserProfile, job, removeJob, setAlert, setApplications, setJobs)}>
                                    <p className="jobs-accordion-expanded-button-primary-title">Apply</p>
                                </div>
                            </div>
                        :
                            !archived && !admin ?
                                <div className="jobs-accordion-expanded-buttons-container margin-top-2x">
                                    <div className="jobs-accordion-expanded-button-secondary" onClick={() => handleWithdraw(job.application._id, withdraw, setAlert)}>
                                        <p className="jobs-accordion-expanded-button-secondary-title">Withdraw Application</p>
                                    </div>
                                </div>
                            :
                                <div></div>
                        }
                    </div>
                }
                {expanded.includes(job._id) && guest &&
                    <div className="jobs-accordion-expanded-container blurry-bg-jobs" style={{height: 937}}>
                        {guest &&
                            <div className="jobs-accordion-guest-overlay">
                                <p><Link to={"/sign-in"}>Log In</Link> or <Link to={"/sign-up"}>Sign Up</Link> to see more details</p>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    })

}

const handleClick = (jobId, expanded, handleRow) => {

    if (!expanded.includes(jobId)) return handleRow([...expanded, jobId]);

    return handleRow(expanded.filter(item => item !== jobId));

}

const handleNotInterested = (profile, updateUserProfile, job, removeJob, setAlert) => {

    updateUserProfile([{ propName: "notInterestedJobs", value: [...profile.notInterestedJobs, job._id] }], true);

    removeJob(job);

    setAlert("This job wasn’t for you. We have removed it from your view", "success", true);
                
    return setTimeout(() => {
        return setAlert("This job wasn’t for you. We have removed it from your view", "success", false);
    }, 2700);

}

const handleApply = (setCVPreview, profile, updateUserProfile, job, removeJob, setAlert, setApplications, setJobs) => {

    let month = moment(new Date()).month();
        month = month+1;

    if (!profile.cvPreviewNotice || profile.cvPreviewNotice !== month) setCVPreview(true, job);

    updateUserProfile([{ propName: "cvPreviewNotice", value: month }, { propName: "notInterestedJobs", value: [...profile.notInterestedJobs, job._id] }], true);
    removeJob(job);

    return createApplication(job, profile)
        .then(_ => {
            return getApplications()
                .then(applications => {
                    return getJobs()
                        .then(jobs => {
                            setApplications(applications);
                            setJobs(jobs);

                            setAlert("We received your application", "success", true);
                
                            return setTimeout(() => {
                                return setAlert("We received your application", "success", false);
                            }, 2700);
                        });
                });

        });

}

const handleWithdraw = (id, withdraw, setAlert) => {

    withdraw(id);

    updateApplication(id, [{ propName: "status", value: "withdrawn" }]);

    setAlert("This job wasn’t for you. We have removed it from your view", "success", true);
                
    return setTimeout(() => {
        return setAlert("This job wasn’t for you. We have removed it from your view", "success", false);
    }, 2700);

}