import React, { Component } from "react";
import "./index.css";
import "../YachtDetails/index.css";
import "../BasicInformation/index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";

/* Components */
import InitialSetupBase from "../../../../components/InitialSetupBase";

/* Actions */
import { updateUserProfileYacht } from "../../../../redux/actions/user";

class MoreYachtDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            keyword: "",
            itineraries: ["Dual Season", "Single Season", "Mediterranean", "USA", "Caribbean", "Asia", "South Africa", "Australia / NZ", "Pacific Islands", "Middle East", "Worldwide"],
            suggestions: [],
            address_home_port: "",
            address_yacht: "",
        };

    }

    componentDidMount() {

        if (this.props.user.data.profile.yacht.currentLocation) {
            this.setState({ address_yacht: this.props.user.data.profile.yacht.currentLocation.name });
        }

        if (this.props.user.data.profile.yacht.homePort) {
            this.setState({ address_home_port: this.props.user.data.profile.yacht.homePort.name });
        }

    }

    handleSuggestions = (value, selected) => {

        return this.setState({ keyword: value, show: false }, () => {
            if (value.length !== 0) {

                const suggestions = this.state.itineraries.filter(itinerary => {
                    return !selected.includes(itinerary) && (itinerary.includes(value) || itinerary.toLowerCase().includes(value));
                }); 

                return this.setState({ suggestions: suggestions });
            } else {
                return this.setState({ suggestions: [] });
            }
        });

    }

    renderDropdown = (list, selected) => {

        list = list.filter(item => !selected.includes(item));

        return list.map((item, index) => {
            return(
                <div key={index} className="yacht-details-dropdown-list-item-container" style={{border: "none"}} onClick={() => this.selectItinerary(item, selected)}>
                    <p>{item}</p>
                </div>
            );
        });

    }

    selectItinerary = (itinerary, selected) => {

        return this.setState({ show: false, keyword: "", suggestions: [] }, () => {
            return this.props.updateUserProfileYacht([
                { propName: "itinerary", value: [...selected, itinerary] },
            ]);
        });

    }

    deselectItinerary = (itinerary) => {

        const result = this.props.user.data.profile.yacht.itinerary.filter(it => it !== itinerary);
        
        return this.props.updateUserProfileYacht([
            { propName: "itinerary", value: result },
        ]);

    }

    renderSelected = (selected) => {

        return selected.map((select, index) => {
            return(
                <div key={index} className="yacht-details-selected-position-container">
                    <div className="yacht-details-selected-position-name-container">
                        <span>{select}</span>
                    </div>
                    <div className="yacht-details-selected-position-icon-container" onClick={() => this.deselectItinerary(select)}>
                        <img src={require("../../../../assets/svgs/x.svg")} alt="" />
                    </div>
                </div>
            );
        });

    }

    handleCurrentLocationChange = (value) => {
        return this.setState({ address_yacht: value });
    };

    handleHomePortChange = (value) => {
        return this.setState({ address_home_port: value });
    };

    handleCurrentLocationSelect = (address) => {

        return geocodeByAddress(address)
            .then(results => {
                return getLatLng(results[0])
                    .then(data => {

                        const splitAddress = results[0].formatted_address.split(", ");
                        
                        const country = splitAddress[splitAddress.length-1],
                            name = results[0].formatted_address;

                        this.props.updateUserProfileYacht([{ propName: "currentLocation", value: {
                            country: country,
                            name: name,
                            lat: data.lat,
                            lng: data.lng,
                        } }]);

                        return this.setState({ address_yacht: name });
                    });
            })
            .catch(error => {
                return console.log(error);
            });

    };

    handleHomePortSelect = (address) => {

        return geocodeByAddress(address)
            .then(results => {
                return getLatLng(results[0])
                    .then(data => {

                        const splitAddress = results[0].formatted_address.split(", ");
                        
                        const country = splitAddress[splitAddress.length-1],
                            name = results[0].formatted_address;

                        this.props.updateUserProfileYacht([{ propName: "homePort", value: {
                            country: country,
                            name: name,
                            lat: data.lat,
                            lng: data.lng,
                        } }]);

                        return this.setState({ address_home_port: name });
                    });
            })
            .catch(error => {
                return console.log(error);
            });

    };

    render() {

        return(
            <InitialSetupBase title={"Initial Set Up - More Yacht Details"} description={"Please let us know some further details about the vessel."}>
                <div className="margin-top-20px">
                    <PlacesAutocomplete
                        value={this.state.address_home_port}
                        onChange={(value) => this.handleHomePortChange(value)}
                        onSelect={(value) => this.handleHomePortSelect(value)}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <div className="more-yacht-details-location-dropdown-master-container">
                                <div className={suggestions.length < 1 ? "more-yacht-details-location-dropdown-container" : "more-yacht-details-location-dropdown-container more-yacht-details-location-dropdown-open-container"}>
                                    <div className="more-yacht-details-location-dropdown-icon-container">
                                        <img src={require("../../../../assets/svgs/pin.svg")} alt="" />
                                    </div>
                                    <div className="more-yacht-details-location-dropdown-main-container">
                                        <div className="more-yacht-details-location-dropdown-main-item-title-long-container">
                                            <p className="more-yacht-details-location-dropdown-main-label">Home Port (Exact location is not revealed to candidates)</p>
                                        </div>
                                        <div className="more-yacht-details-location-dropdown-main-item-value-container">
                                            <input {...getInputProps({placeholder: "Search..." })} className="more-yacht-details-location-value-input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="more-yacht-details-location-autocomplete-dropdown-container" style={{display: suggestions.length < 1 ? "none" : "flex"}}>
                                    {suggestions.map((suggestion, index) => {
                                        return (
                                            <div className="more-yacht-details-location-autocomplete-dropdown-item-container" {...getSuggestionItemProps(suggestion)} style={index < 1 ? {border: "none"} : {}}>
                                                <p>{suggestion.description}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>
                <div className="margin-top-20px">
                    <PlacesAutocomplete
                        value={this.state.address_yacht}
                        onChange={(value) => this.handleCurrentLocationChange(value)}
                        onSelect={(value) => this.handleCurrentLocationSelect(value)}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <div className="more-yacht-details-location-dropdown-master-container">
                                <div className={suggestions.length < 1 ? "more-yacht-details-location-dropdown-container" : "more-yacht-details-location-dropdown-container more-yacht-details-location-dropdown-open-container"}>
                                    <div className="more-yacht-details-location-dropdown-icon-container">
                                        <img src={require("../../../../assets/svgs/pin.svg")} alt="" />
                                    </div>
                                    <div className="more-yacht-details-location-dropdown-main-container">
                                        <div className="more-yacht-details-location-dropdown-main-item-title-long-container">
                                            <p className="more-yacht-details-location-dropdown-main-label">Yacht Location (Exact location is not revealed to candidates)</p>
                                        </div>
                                        <div className="more-yacht-details-location-dropdown-main-item-value-container">
                                            <input {...getInputProps({placeholder: "Search..." })} className="more-yacht-details-location-value-input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="more-yacht-details-location-autocomplete-dropdown-container" style={{display: suggestions.length < 1 ? "none" : "flex"}}>
                                    {suggestions.map((suggestion, index) => {
                                        return (
                                            <div className="more-yacht-details-location-autocomplete-dropdown-item-container" {...getSuggestionItemProps(suggestion)} style={index < 1 ? {border: "none"} : {}}>
                                                <p>{suggestion.description}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>
                <div className="yacht-details-dropdown-master-container margin-top-20px">
                    <div className={this.state.show || this.state.suggestions.length !== 0 ? "yacht-details-dropdown-container yacht-details-dropdown-open-container" : "yacht-details-dropdown-container"}>
                        <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                            <div className="yacht-details-dropdown-main-item-title-long-container">
                                <p className="yacht-details-dropdown-main-label">Expected Itinerary (Can select multiple)</p>
                            </div>
                            <div className="yacht-details-dropdown-main-item-value-container">
                                <input className="yacht-details-value-input" onChange={(e) => this.handleSuggestions(e.target.value, this.props.user.data.profile.yacht.itinerary)} value={this.state.keyword || ""} type="text" placeholder="Type..." />
                            </div>
                        </div>
                        <div className="yacht-details-dropdown-icon-container" onClick={() => this.setState({ show: !this.state.show, suggestions: [], keyword: "" })}>
                            <img src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                        </div>
                    </div>
                    {this.state.suggestions.length !== 0 &&
                        <div className="yacht-details-dropdown-list-container">
                            {this.renderDropdown(this.state.suggestions, this.props.user.data.profile.yacht.itinerary)}
                        </div>
                    }
                    {this.state.show &&
                        <div className="yacht-details-dropdown-list-container">
                            {this.renderDropdown(this.state.itineraries, this.props.user.data.profile.yacht.itinerary)}
                        </div>
                    }
                </div>
                <div className="yacht-details-selected-positions-container">
                    {this.renderSelected(this.props.user.data.profile.yacht.itinerary)}
                </div>
                <div className="toggle-button-master-container margin-top-20px">
                    <div className="toggle-button-title-container">
                        <p>Couple Friendly?</p>
                    </div>
                    <div className="toggle-button-container">
                        <div className="toggle-button-item-container" style={{backgroundColor: (!this.props.user.data.profile.yacht.couples && this.props.user.data.profile.yacht.couples !== undefined) ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "couples", value: false }])}>
                            <p className="toggle-button-item-text" style={{color: (!this.props.user.data.profile.yacht.couples && this.props.user.data.profile.yacht.couples !== undefined) ? "white" : "#333333"}}>No Couples</p>
                        </div>
                        <div className="toggle-button-items-separator"></div>
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.couples ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "couples", value: true }])}>
                            <p className="toggle-button-item-text" style={{color: this.props.user.data.profile.yacht.couples ? "white" : "#333333"}}>Couples Are Considered</p>
                        </div>
                    </div>
                </div>
                <div className="toggle-button-master-container margin-top-20px">
                    <div className="toggle-button-title-container">
                        <p>Tattoo Policy</p>
                    </div>
                    <div className="toggle-button-container">
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.tattoo === "No Tattoos" ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "tattoo", value: "No Tattoos" }])}>
                            <p className="toggle-button-item-text" style={{color: this.props.user.data.profile.yacht.tattoo === "No Tattoos" ? "white" : "#333333"}}>No Tattoos</p>
                        </div>
                        <div className="toggle-button-items-separator"></div>
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.tattoo === "No Visible Tattoos" ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "tattoo", value: "No Visible Tattoos" }])}>
                            <p className="toggle-button-item-text" style={{color: this.props.user.data.profile.yacht.tattoo === "No Visible Tattoos" ? "white" : "#333333"}}>No Visible Tattoos</p>
                        </div>
                        <div className="toggle-button-items-separator"></div>
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.tattoo === "Visible Tattoos Allowed" ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "tattoo", value: "Visible Tattoos Allowed" }])}>
                            <p className="toggle-button-item-text-long" style={{color: this.props.user.data.profile.yacht.tattoo === "Visible Tattoos Allowed" ? "white" : "#333333"}}>Visible Tattoos Allowed</p>
                        </div>
                    </div>
                </div>
                <div className="toggle-button-master-container margin-top-20px">
                    <div className="toggle-button-title-container">
                        <p>Smoking Policy</p>
                    </div>
                    <div className="toggle-button-container">
                        <div className="toggle-button-item-container" style={{backgroundColor: (!this.props.user.data.profile.yacht.smoking && this.props.user.data.profile.yacht.smoking !== undefined) ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "smoking", value: false }])}>
                            <p className="toggle-button-item-text" style={{color: (!this.props.user.data.profile.yacht.smoking && this.props.user.data.profile.yacht.smoking !== undefined) ? "white" : "#333333"}}>No Smoking Allowed</p>
                        </div>
                        <div className="toggle-button-items-separator"></div>
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.smoking ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "smoking", value: true }])}>
                            <p className="toggle-button-item-text" style={{color: this.props.user.data.profile.yacht.smoking ? "white" : "#333333"}}>Smoking Allowed</p>
                        </div>
                    </div>
                </div>
                <div className="toggle-button-master-container margin-top-20px">
                    <div className="toggle-button-title-container">
                        <p>Dry Boat?</p>
                    </div>
                    <div className="toggle-button-container">
                        <div className="toggle-button-item-container" style={{backgroundColor: this.props.user.data.profile.yacht.dryBoat ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "dryBoat", value: true }])}>
                            <p className="toggle-button-item-text" style={{color: this.props.user.data.profile.yacht.dryBoat ? "white" : "#333333"}}>Yes</p>
                        </div>
                        <div className="toggle-button-items-separator"></div>
                        <div className="toggle-button-item-container" style={{backgroundColor: (!this.props.user.data.profile.yacht.dryBoat && this.props.user.data.profile.yacht.dryBoat !== undefined) ? "#1377FF" : "white"}} onClick={() => this.props.updateUserProfileYacht([{ propName: "dryBoat", value: false }])}>
                            <p className="toggle-button-item-text" style={{color: (!this.props.user.data.profile.yacht.dryBoat && this.props.user.data.profile.yacht.dryBoat !== undefined) ? "white" : "#333333"}}>No</p>
                        </div>
                    </div>
                </div>
            </InitialSetupBase>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfileYacht }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreYachtDetails);