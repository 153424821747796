import {
    SET_REFERENCE,
    RESET_REFERENCE,
} from "../types/reference";

export let setReference = (data) => dispatch => {

    return dispatch({
        type: SET_REFERENCE,
        data,
    });
    
}

export let resetReference = () => dispatch => {

    return dispatch({
        type: RESET_REFERENCE,
    });
    
}