import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import AccordionJobs from "../../../../components/AccordionJobs";
import Filter from "../../../../components/Filter";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";
import { setApplications, addApplication, removeApplication } from "../../../../redux/actions/application";
import { setJobs, removeJob } from "../../../../redux/actions/job";
import { setCVPreview, setAlert } from "../../../../redux/actions/misc";

/* API requests */
import { getPositions } from "../../../../api-requests/position";
import { getQualifications } from "../../../../api-requests/qualification";

class Jobs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expandedFilter: "",
            positions: [],
            qualifications: [],
            expanded: [],
            keyword: "",
            /* Filters */
            engineers: true,
            decks: true,
            galleys: true,
            interiors: true,
            selectedPositions: "",
            selectedJobTypes: "",
            selectedQualifications: "",
            selectedCoupleFriendly: [],
            selectedYachtTypes: [],
            selectedYachtSizes: [],
            selectedYachtAges: [],
            selectedSalaryRanges: [],
        };

    }

    toggleFilter = (state) => {

        if (this.state.positions.length < 1) {
            getPositions()
                .then(result => {
                    if (result.status !== 201) return;
                    return this.setState({ positions: result.data });
                });
        } 
        
        if (this.state.qualifications.length < 1) {
            getQualifications()
                .then(result => {
                    if (result.status !== 201) return;
                    return this.setState({ qualifications: result.data });
                });
        }

        if (!state) this.setState({ keyword: "" });

        return this.setState({
            selectedPositions: "",
            selectedJobTypes: "",
            selectedQualifications: "",
            selectedCoupleFriendly: [],
            selectedYachtTypes: [],
            selectedYachtSizes: [],
            selectedYachtAges: [],
            selectedSalaryRanges: [],
        });

    }

    handleSuggestions = (state, jobs) => {

        return this.filterJobs(state, jobs);

    }

    handleFilterClick = (type, expandedFilter, input) => {

        if (!input) {
            if (type === expandedFilter) return this.setState({ expandedFilter: "" });

            return this.setState({ expandedFilter: type });
        }

        return;

    }

    handleOptionClick = (propName, item, single) => {

        if (this.state[propName].includes(item)) return this.setState({ [propName]: this.state[propName].filter(i => i !== item) });

        if (!single) return this.setState({ [propName]: [...this.state[propName], item] });

        return this.setState({ [propName]: [item] });

    }

    filterJobs = (state, jobs) => {

        if (!state.engineers) jobs = jobs.filter(job => job.position.positionType !== "Engineer");
        if (!state.decks) jobs = jobs.filter(job => job.position.positionType !== "Deck");
        if (!state.galleys) jobs = jobs.filter(job => job.position.positionType !== "Galley");
        if (!state.interiors) jobs = jobs.filter(job => job.position.positionType !== "Interior");

        if (state.selectedPositions.length > 0) {
            jobs = jobs.filter(job => {
                return job.position.name.toLowerCase().includes(state.selectedPositions.toLowerCase());
            });
        }

        if (state.selectedQualifications.length > 0) {
            jobs = jobs.filter(job => {
                return job.qualifications.toLowerCase().includes(state.selectedQualifications.toLowerCase())
            });
        }

        return jobs

    }

    searchKeyword = (e, propName) => {

        return this.setState({ [propName]: e });

    }

    render() {

        let jobs = this.handleSuggestions(this.state, this.props.jobs);

        return(
            <DashboardSectionBase title={this.props.title}>
                <div className="jobs-description-container margin-top-20px">
                    {this.props.description}
                </div>
                {this.props.filter &&
                    <div className="jobs-filters-container">
                        <div className="jobs-filters-section-container" style={{ paddingRight: 20 }}>                        
                            <div className="margin-top-20px">
                                <Filter label={"Search Position"} type={"position"} handleFilterClick={this.handleFilterClick} propName={"selectedPositions"} input={true} keyword={this.state.selectedPositions} changeText={this.searchKeyword} />
                            </div>
                        </div>
                        <div className="jobs-filters-section-container">
                            <div className="margin-top-20px">
                                <Filter label={"Search Qualification"} type={"qualifications"} handleFilterClick={this.handleFilterClick} propName={"selectedQualifications"} input={true} keyword={this.state.selectedQualifications} changeText={this.searchKeyword} />
                            </div>
                        </div>
                    </div>
                }
                {jobs.length > 0 &&
                    <div className="margin-top-20px">
                        <AccordionJobs admin={this.props.admin} setApplications={this.props.setApplications} setJobs={this.props.setJobs} setAlert={this.props.setAlert} setCVPreview={this.props.setCVPreview} apply={this.props.addApplication} withdraw={this.props.removeApplication} type={this.props.type} profile={this.props.user.data.profile} jobs={jobs} expanded={this.state.expanded} handleRow={(result) => this.setState({ expanded: result })} updateUserProfile={this.props.updateUserProfile} removeJob={this.props.removeJob} />
                    </div>
                }
            </DashboardSectionBase>
        );

    }  

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setApplications, setJobs, setCVPreview, updateUserProfile, removeJob, addApplication, removeApplication, setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);