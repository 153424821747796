import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fastLoadVessels, getAllVessels } from "../../../../api-requests/vessel";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import TextArea from "../../../../components/TextArea";
import { addLocalYacht, getYacht, editLocalYacht } from "../../../../api-requests/yacht";

import { setAlert } from "../../../../redux/actions/misc";

class CreateYacht extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: "",
            yacht: {
                itinerary: [],
            },
            show: false,
            showItineraries: false,
            vessels: [],
            suggestions: [],
            suggestionsItineraries: [],
            itineraries: ["Dual Season", "Single Season", "Mediterranean", "USA", "Caribbean", "Asia", "South Africa", "Australia / NZ", "Pacific Islands", "Middle East", "Worldwide"],
            address_home_port: "",
            address_yacht: "",
        };

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        if (this.props.match.params.yachtId) {
            getYacht(this.props.match.params.yachtId)
                .then(result => {
                    return this.setState({ yacht: result, address_yacht: result?.currentLocation?.name || "", address_home_port: result?.homePort?.name || "" });
                });
        }

        return fastLoadVessels()
            .then(result => {
                return result
            })
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ vessels: result.data }, () => {
                    return getAllVessels()
                        .then(result => {
                            if (result.status !== 201) return;
                            return this.setState({ vessels: [...this.state.vessels, ...result.data] });
                        });
                });
            });

    }

    handleSuggestions = (value, selected) => {

        return this.setState({ show: false, yacht: { ...this.state.yacht, name: value } }, () => {
            if (value.length !== 0) {
                const selectedIDs = selected.map(select => select._id);
                const suggestions = this.state.vessels.filter(vessel => {
                    return !selectedIDs.includes(vessel._id) && (vessel.name.includes(value) || vessel.name.toLowerCase().includes(value));
                }); 

                return this.setState({ suggestions: suggestions });
            } else {
                return this.setState({ suggestions: [] });
            }
        });

    }

    renderDropdown = (list, selected) => {

        const selectedIDs = selected.map(select => select._id);
        list = list.filter(item => !selectedIDs.includes(item._id));

        return list.map((item, index) => {
            return(
                <div key={index} className="yacht-details-dropdown-list-item-container" style={{border: "none"}} onClick={() => this.selectVessel(item)}>
                    <p>{item.name} {item.age && item.size ? `(${item.age}, ${item.size}m)`: `(${item.age})`}</p>
                </div>
            );
        });

    }

    selectVessel = (vessel) => {

        return this.setState({ show: false, suggestions: [], yacht: { ...this.state.yacht, ...vessel } });

    }

    handleCurrentLocationChange = (value) => {
        return this.setState({ address_yacht: value });
    };

    handleHomePortChange = (value) => {
        return this.setState({ address_home_port: value });
    };

    handleCurrentLocationSelect = (address) => {

        return geocodeByAddress(address)
            .then(results => {
                return getLatLng(results[0])
                    .then(data => {

                        const splitAddress = results[0].formatted_address.split(", ");
                        
                        const country = splitAddress[splitAddress.length-1],
                            name = results[0].formatted_address;

                        return this.setState({
                            address_yacht: name,
                            yacht: {
                                ...this.state.yacht,
                                currentLocation: {
                                    country: country,
                                    name: name,
                                    lat: data.lat,
                                    lng: data.lng,
                                },
                            },
                        });

                    });
            })
            .catch(error => {
                return console.log(error);
            });

    };

    handleHomePortSelect = (address) => {

        return geocodeByAddress(address)
            .then(results => {
                return getLatLng(results[0])
                    .then(data => {

                        const splitAddress = results[0].formatted_address.split(", ");
                        
                        const country = splitAddress[splitAddress.length-1],
                            name = results[0].formatted_address;

                        return this.setState({
                            address_home_port: name,
                            yacht: {
                                ...this.state.yacht,
                                homePort: {
                                    country: country,
                                    name: name,
                                    lat: data.lat,
                                    lng: data.lng,
                                },
                            },
                        });

                    });
            })
            .catch(error => {
                return console.log(error);
            });

    };

    selectItinerary = (itinerary, selected) => {

        return this.setState({ showItineraries: false, keyword: "", suggestionsItineraries: [], yacht: { ...this.state.yacht, itinerary: [...selected, itinerary] } });

    }

    renderItineraries = (list, selected) => {

        list = list.filter(item => !selected.includes(item));

        return list.map((item, index) => {
            return(
                <div key={index} className="yacht-details-dropdown-list-item-container" style={{border: "none"}} onClick={() => this.selectItinerary(item, selected)}>
                    <p>{item}</p>
                </div>
            );
        });

    }

    deselectItinerary = (itinerary) => {

        const result = this.state.yacht.itinerary.filter(it => it !== itinerary);
        
        return this.setState({ yacht: { ...this.state.yacht, itinerary: result } });

    }

    renderSelected = (selected) => {

        return selected.map((select, index) => {
            return(
                <div key={index} className="yacht-details-selected-position-container">
                    <div className="yacht-details-selected-position-name-container">
                        <span>{select}</span>
                    </div>
                    <div className="yacht-details-selected-position-icon-container" onClick={() => this.deselectItinerary(select)}>
                        <img src={require("../../../../assets/svgs/x.svg")} alt="" />
                    </div>
                </div>
            );
        });

    }

    handleItinerarySuggestions = (value, selected) => {

        return this.setState({ keyword: value, show: false }, () => {
            if (value.length !== 0) {

                const suggestions = this.state.itineraries.filter(itinerary => {
                    return !selected.includes(itinerary) && (itinerary.includes(value) || itinerary.toLowerCase().includes(value));
                }); 

                return this.setState({ suggestionsItineraries: suggestions });
            } else {
                return this.setState({ suggestionsItineraries: [] });
            }
        });

    }

    handleNotes = (notes) => {

        return this.setState({ yacht: { ...this.state.yacht, notes: notes[0].value } });

    }

    handleSave = (yacht) => {

        if (yacht.name && yacht.size && yacht.yachtType) {
            return addLocalYacht(yacht)
                .then(_ => {
                    this.props.history.push("/yachts");

                    this.props.setAlert("New local yacht created!", "success", true);

                    return setTimeout(() => {
                        return this.props.setAlert("New local yacht created!", "success", false);
                    }, 2700);
                    
                });
        } else {
            return alert("Yacht name, type and size are required fields.");
        }

    }

    handleEdit = (yacht) => {

        if (yacht.name && yacht.size && yacht.yachtType) {
            return editLocalYacht(yacht)
                .then(_ => {

                    this.props.setAlert("Yacht details updated!", "success", true);

                    return setTimeout(() => {
                        return this.props.setAlert("Yacht details updated!", "success", false);
                    }, 2700);
                    
                });
        } else {
            return alert("Yacht name, type and size are required fields.");
        }

    }

    render() {

        return(
            <div className="create-yacht-container">
                <div className="create-yacht-title-container">
                    <p className="create-yacht-title">{this.props.match.params.yachtId ? "Edit Yacht Details" : "New Local Yacht"}</p>
                </div>
                <div className="create-yacht-wrapper margin-top-10px">
                    <div className="yacht-details-dropdown-master-container">
                        <div className={this.state.show || this.state.suggestions.length !== 0 ? "yacht-details-dropdown-container yacht-details-dropdown-open-container" : "yacht-details-dropdown-container"}>
                            <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                                <div className="yacht-details-dropdown-main-item-title-long-container">
                                    <p className="yacht-details-dropdown-main-label">Yacht Name (Confidential and not revealed to candidates)</p>
                                </div>
                                <div className="yacht-details-dropdown-main-item-value-container">
                                    <input className="yacht-details-value-input" onChange={(e) => this.handleSuggestions(e.target.value, [this.state.yacht])} value={this.state?.yacht?.name || ""} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                                </div>
                            </div>
                            <div className="yacht-details-dropdown-icon-container" onClick={() => this.setState({ show: !this.state.show, suggestions: [] })}>
                                <img src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                            </div>
                        </div>
                        {this.state.suggestions.length !== 0 &&
                            <div className="yacht-details-dropdown-list-container">
                                {this.renderDropdown(this.state.suggestions, [this.state.yacht])}
                            </div>
                        }
                        {this.state.show &&
                            <div className="yacht-details-dropdown-list-container">
                                {this.renderDropdown(this.state.vessels, [this.state.yacht])}
                            </div>
                        }
                    </div>
                    <div className="toggle-button-master-container margin-top-20px">
                        <div className="toggle-button-title-container">
                            <p>Yacht Type</p>
                        </div>
                        <div className="toggle-button-container">
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state?.yacht?.yachtType === "Motor Yacht" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtType: "Motor Yacht" } })}>
                                <p className="toggle-button-item-text" style={{color: this.state?.yacht?.yachtType === "Motor Yacht" ? "white" : "#333333"}}>Motor Yacht</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state?.yacht?.yachtType === "Sailing Yacht" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtType: "Sailing Yacht" } })}>
                                <p className="toggle-button-item-text" style={{color: this.state?.yacht?.yachtType === "Sailing Yacht" ? "white" : "#333333"}}>Sailing Yacht</p>
                            </div>
                        </div>
                    </div>
                    <div className="yacht-details-dropdown-master-container margin-top-20px">
                        <div className="yacht-details-dropdown-container">
                            <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                                <div className="yacht-details-dropdown-main-item-title-container">
                                    <p className="yacht-details-dropdown-main-label">Yacht Length</p>
                                </div>
                                <div className="yacht-details-dropdown-main-item-value-container">
                                    <input className="yacht-details-value-input" value={this.state?.yacht?.size || ""} onChange={(e) => this.setState({ yacht: { ...this.state.yacht, size: e.target.value } })} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                                    <div className="yacht-details-value-unit-container">
                                        <span>metres</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="yacht-details-dropdown-master-container margin-top-20px">
                        <div className="yacht-details-dropdown-container">
                            <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                                <div className="yacht-details-dropdown-main-item-title-container">
                                    <p className="yacht-details-dropdown-main-label">Yacht Build Year</p>
                                </div>
                                <div className="yacht-details-dropdown-main-item-value-container">
                                    <input className="yacht-details-value-input" value={this.state?.yacht?.age || ""} onChange={(e) => this.setState({ yacht: { ...this.state.yacht, age: e.target.value } })} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                                    <div className="yacht-details-value-unit-container">
                                        <span>year</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="yacht-details-dropdown-master-container margin-top-20px">
                        <div className="yacht-details-dropdown-container">
                            <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                                <div className="yacht-details-dropdown-main-item-title-container">
                                    <p className="yacht-details-dropdown-main-label">Yacht Build</p>
                                </div>
                                <div className="yacht-details-dropdown-main-item-value-container">
                                    <input className="yacht-details-value-input" value={this.state?.yacht?.yachtBuilderName || ""} onChange={(e) => this.setState({ yacht: { ...this.state.yacht, yachtBuilderName: e.target.value } })} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="yacht-details-dropdown-master-container margin-top-20px">
                        <div className="yacht-details-dropdown-container">
                            <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                                <div className="yacht-details-dropdown-main-item-title-container">
                                    <p className="yacht-details-dropdown-main-label">Yacht Build Country</p>
                                </div>
                                <div className="yacht-details-dropdown-main-item-value-container">
                                    <input className="yacht-details-value-input" value={this.state?.yacht?.yachtBuilderCountry || ""} onChange={(e) => this.setState({ yacht: { ...this.state.yacht, yachtBuilderCountry: e.target.value } })} disabled={this.state.vessels.length < 1 ? true : false} type="text" placeholder="Type..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="toggle-button-master-container margin-top-20px">
                        <div className="toggle-button-title-container">
                            <p>Yacht Status</p>
                        </div>
                        <div className="toggle-button-container">
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.yachtStatus === "Private" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtStatus: "Private" } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.yachtStatus === "Private" ? "white" : "#333333"}}>Private</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.yachtStatus === "Private & Charter" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtStatus: "Private & Charter" } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.yachtStatus === "Private & Charter" ? "white" : "#333333"}}>Private & Charter</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.yachtStatus === "Charter" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, yachtStatus: "Charter" } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.yachtStatus === "Charter" ? "white" : "#333333"}}>Charter</p>
                            </div>
                        </div>
                    </div>
                    <div className="margin-top-20px">
                        <PlacesAutocomplete
                            value={this.state.address_home_port}
                            onChange={(value) => this.handleHomePortChange(value)}
                            onSelect={(value) => this.handleHomePortSelect(value)}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                <div className="more-yacht-details-location-dropdown-master-container">
                                    <div className={suggestions.length < 1 ? "more-yacht-details-location-dropdown-container" : "more-yacht-details-location-dropdown-container more-yacht-details-location-dropdown-open-container"}>
                                        <div className="more-yacht-details-location-dropdown-icon-container">
                                            <img src={require("../../../../assets/svgs/home.svg")} alt="" />
                                        </div>
                                        <div className="more-yacht-details-location-dropdown-main-container">
                                            <div className="more-yacht-details-location-dropdown-main-item-title-long-container">
                                                <p className="more-yacht-details-location-dropdown-main-label">Home Port</p>
                                            </div>
                                            <div className="more-yacht-details-location-dropdown-main-item-value-container">
                                                <input {...getInputProps({placeholder: "Search..." })} className="more-yacht-details-location-value-input" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more-yacht-details-location-autocomplete-dropdown-container" style={{display: suggestions.length < 1 ? "none" : "flex"}}>
                                        {suggestions.map((suggestion, index) => {
                                            return (
                                                <div key={index} className="more-yacht-details-location-autocomplete-dropdown-item-container" {...getSuggestionItemProps(suggestion)} style={index < 1 ? {border: "none"} : {}}>
                                                    <p>{suggestion.description}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div className="margin-top-20px">
                        <PlacesAutocomplete
                            value={this.state.address_yacht}
                            onChange={(value) => this.handleCurrentLocationChange(value)}
                            onSelect={(value) => this.handleCurrentLocationSelect(value)}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                <div className="more-yacht-details-location-dropdown-master-container">
                                    <div className={suggestions.length < 1 ? "more-yacht-details-location-dropdown-container" : "more-yacht-details-location-dropdown-container more-yacht-details-location-dropdown-open-container"}>
                                        <div className="more-yacht-details-location-dropdown-icon-container">
                                            <img src={require("../../../../assets/svgs/pin.svg")} alt="" />
                                        </div>
                                        <div className="more-yacht-details-location-dropdown-main-container">
                                            <div className="more-yacht-details-location-dropdown-main-item-title-long-container">
                                                <p className="more-yacht-details-location-dropdown-main-label">Yacht Location</p>
                                            </div>
                                            <div className="more-yacht-details-location-dropdown-main-item-value-container">
                                                <input {...getInputProps({placeholder: "Search..." })} className="more-yacht-details-location-value-input" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more-yacht-details-location-autocomplete-dropdown-container" style={{display: suggestions.length < 1 ? "none" : "flex"}}>
                                        {suggestions.map((suggestion, index) => {
                                            return (
                                                <div key={index} className="more-yacht-details-location-autocomplete-dropdown-item-container" {...getSuggestionItemProps(suggestion)} style={index < 1 ? {border: "none"} : {}}>
                                                    <p>{suggestion.description}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div className="yacht-details-dropdown-master-container margin-top-20px">
                        <div className={this.state.showItineraries || this.state.suggestionsItineraries.length !== 0 ? "yacht-details-dropdown-container yacht-details-dropdown-open-container" : "yacht-details-dropdown-container"}>
                            <div className="yacht-details-dropdown-main-container" style={{paddingLeft: 20}}>
                                <div className="yacht-details-dropdown-main-item-title-long-container">
                                    <p className="yacht-details-dropdown-main-label">Expected Itinerary (Can select multiple)</p>
                                </div>
                                <div className="yacht-details-dropdown-main-item-value-container">
                                    <input className="yacht-details-value-input" onChange={(e) => this.handleItinerarySuggestions(e.target.value, this.state.yacht.itinerary)} value={this.state.keyword || ""} type="text" placeholder="Type..." />
                                </div>
                            </div>
                            <div className="yacht-details-dropdown-icon-container" onClick={() => this.setState({ showItineraries: !this.state.showItineraries, suggestions: [], keyword: "" })}>
                                <img src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                            </div>
                        </div>
                        {this.state.suggestionsItineraries.length !== 0 &&
                            <div className="yacht-details-dropdown-list-container">
                                {this.renderItineraries(this.state.suggestionsItineraries, this.state.yacht.itinerary)}
                            </div>
                        }
                        {this.state.showItineraries &&
                            <div className="yacht-details-dropdown-list-container">
                                {this.renderItineraries(this.state.itineraries, this.state.yacht.itinerary)}
                            </div>
                        }
                    </div>
                    <div className="yacht-details-selected-positions-container">
                        {this.renderSelected(this.state.yacht.itinerary)}
                    </div>
                    <div className="toggle-button-master-container margin-top-20px">
                        <div className="toggle-button-title-container">
                            <p>Couple Friendly?</p>
                        </div>
                        <div className="toggle-button-container">
                            <div className="toggle-button-item-container" style={{backgroundColor: (!this.state.yacht.couples && this.state.yacht.couples !== undefined) ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, couples: false } })}>
                                <p className="toggle-button-item-text" style={{color: (!this.state.yacht.couples && this.state.yacht.couples !== undefined) ? "white" : "#333333"}}>No Couples</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.couples ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, couples: true } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.couples ? "white" : "#333333"}}>Couples Are Considered</p>
                            </div>
                        </div>
                    </div>
                    <div className="toggle-button-master-container margin-top-20px">
                        <div className="toggle-button-title-container">
                            <p>Tattoo Policy</p>
                        </div>
                        <div className="toggle-button-container">
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.tattoo === "No Tattoos" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, tattoo: "No Tattoos" } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.tattoo === "No Tattoos" ? "white" : "#333333"}}>No Tattoos</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.tattoo === "No Visible Tattoos" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, tattoo: "No Visible Tattoos" } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.tattoo === "No Visible Tattoos" ? "white" : "#333333"}}>No Visible Tattoos</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.tattoo === "Visible Tattoos Allowed" ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, tattoo: "Visible Tattoos Allowed" } })}>
                                <p className="toggle-button-item-text-long" style={{color: this.state.yacht.tattoo === "Visible Tattoos Allowed" ? "white" : "#333333"}}>Visible Tattoos Allowed</p>
                            </div>
                        </div>
                    </div>
                    <div className="toggle-button-master-container margin-top-20px">
                        <div className="toggle-button-title-container">
                            <p>Smoking Policy</p>
                        </div>
                        <div className="toggle-button-container">
                            <div className="toggle-button-item-container" style={{backgroundColor: (!this.state.yacht.smoking && this.state.yacht.smoking !== undefined) ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, smoking: false } })}>
                                <p className="toggle-button-item-text" style={{color: (!this.state.yacht.smoking && this.state.yacht.smoking !== undefined) ? "white" : "#333333"}}>No Smoking Allowed</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.smoking ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, smoking: true } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.smoking ? "white" : "#333333"}}>Smoking Allowed</p>
                            </div>
                        </div>
                    </div>
                    <div className="toggle-button-master-container margin-top-20px">
                        <div className="toggle-button-title-container">
                            <p>Dry Boat?</p>
                        </div>
                        <div className="toggle-button-container">
                            <div className="toggle-button-item-container" style={{backgroundColor: this.state.yacht.dryBoat ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, dryBoat: true } })}>
                                <p className="toggle-button-item-text" style={{color: this.state.yacht.dryBoat ? "white" : "#333333"}}>Yes</p>
                            </div>
                            <div className="toggle-button-items-separator"></div>
                            <div className="toggle-button-item-container" style={{backgroundColor: (!this.state.yacht.dryBoat && this.state.yacht.dryBoat !== undefined) ? "#1377FF" : "white"}} onClick={() => this.setState({ yacht: { ...this.state.yacht, dryBoat: false } })}>
                                <p className="toggle-button-item-text" style={{color: (!this.state.yacht.dryBoat && this.state.yacht.dryBoat !== undefined) ? "white" : "#333333"}}>No</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-yacht-wrapper margin-top-20px">
                    <div className="create-yacht-notes-title-container">
                        <p>Yacht Notes</p>
                    </div>
                    <div className="create-yacht-notes-description-container margin-top-10px">
                        <p>Any notes related to the yacht and type of candidates desired.</p>
                    </div>
                    <div className="margin-top-20px">
                        <TextArea value={this.state.yacht.notes || ""} propName={"notes"} placeholder={"Start typing..."} onChangeText={this.handleNotes} />
                    </div>
                </div>
                <div className="create-yacht-save-button-container margin-top-20px">
                    <div className="create-yacht-save-button" onClick={this.props.match.params.yachtId ? () => this.handleEdit(this.state.yacht) : () => this.handleSave(this.state.yacht)}>
                        <p>{this.props.match.params.yachtId ? "Save Changes" : "Save & Complete"}</p>
                        <img src={require("../../../../assets/svgs/tick-white.svg")} alt="" />
                    </div>
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateYacht);