export const register = (firstName, lastName, email, password, userType) => {

    const body = {
        firstName,
        lastName,
        email,
        password,
        userType,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/auth/register/", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}