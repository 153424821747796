import {
    SET_EDUCATION,
    RESET_EDUCATION,
} from "../types/education";

const initialState = {
    data: {
        name: "",
        fromDate: "",
        toDate: "",
        present: false,
        description: "",
    },
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_EDUCATION:

            let ops = {};

            action.data.forEach(item => {
                ops[item.propName] = item.value;
            });
        
            return {
                ...state,
                data: {
                    ...state.data,
                    ...ops,
                },
            };

        case RESET_EDUCATION:

            return {
                ...state,
                data: {
                    name: "",
                    fromDate: "",
                    toDate: "",
                    present: false,
                    description: "",
                },
            }
        
        default:
            return state;

    }

};