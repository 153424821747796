
import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";
import { setExperience, resetExperience } from "../../../../redux/actions/experience";
import { setEducation, resetEducation } from "../../../../redux/actions/education";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import RegularTextInput from "../../../../components/RegularTextInput";
import SaveButton from "../../../../components/SaveButton";
import SaveCancel from "../../../../components/SaveCancel";
import Accordion from "../../../../components/Accordion";
import TextArea from "../../../../components/TextArea";
import CheckBox from "../../../../components/CheckBox";
import Button from "../../../../components/Button";

/* Constants */
import { candidateExperienceProps } from "../../../../constants";

class Experience extends Component {

    constructor(props) {
        super(props);
        this.expFormRef = React.createRef();
        this.eduFormRef = React.createRef();

        this.state = {
            showExp: false,
            expandedExp: [],
            showEdu: false,
            expandedEdu: [],
            cancelDataExp: null,
            cancelDataEdu: null,
        };

    }

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    saveExp = (companyName, position, fromDate, toDate, present, description) => {

        if (companyName && position && description) {
            if (present) {
                if (!fromDate) return alert("Start date is required.");
            } else {
                if (!fromDate || !toDate) return alert("Time period is required.");
            }

            const role = { companyName, position, fromDate, toDate, present, description };

            const value = [...this.props.user.data.profile.pastRoles, role];

            this.props.updateUserProfile([{ propName: "pastRoles", value: value }], true);
            
            let dashboard = document.getElementById("Dashboard");
            let y = document.getElementById("Experience").offsetTop - 80;
            dashboard.scroll(0, y);

            this.props.resetExperience();
            
            return this.setState({ showExp: false });

        } else {
            return alert("Company name, position and description are required.");
        }

    }

    saveEdu = (name, fromDate, toDate, present, description) => {

        if (name && description) {
            if (present) {
                if (!fromDate) return alert("Start date is required.");
            } else {
                if (!fromDate || !toDate) return alert("Time period is required.");
            }

            const edu = { name, fromDate, toDate, present, description };

            const value = [...this.props.user.data.profile.education, edu];

            this.props.updateUserProfile([{ propName: "education", value: value }], true);

            let dashboard = document.getElementById("Dashboard");
            let y = document.getElementById("Education").offsetTop - 80;
            dashboard.scroll(0, y);

            this.props.resetEducation();

            return this.setState({ showEdu: false });

        } else {
            return alert("School name and description are required.");
        }

    }

    cancelExp = (propName, data) => {

        if (propName === "pastRoles") {

            if (!data) return this.setState({ showExp: false }, () => { return this.props.resetExperience() });
            
            return this.setState({ showExp: false }, () => {
                
                const value = [...this.props.user.data.profile.pastRoles, data];
                
                this.props.updateUserProfile([{ propName: "pastRoles", value: value }], false);

                let dashboard = document.getElementById("Dashboard");
                let y = document.getElementById("Experience").offsetTop - 80;
                dashboard.scroll(0, y);

                return this.setState({ cancelDataExp: null }, () => {
                    return this.props.resetExperience();
                });

            });

        }

        if (propName === "education") {

            if (!data) return this.setState({ showEdu: false }, () => { return this.props.resetEducation() });

            return this.setState({ showEdu: false }, () => {

                const value = [...this.props.user.data.profile.education, data];

                this.props.updateUserProfile([{ propName: "education", value: value }], false);

                let dashboard = document.getElementById("Dashboard");
                let y = document.getElementById("Education").offsetTop - 80;
                dashboard.scroll(0, y);

                return this.setState({ cancelDataEdu: null }, () => {
                    return this.props.resetEducation();
                });

            });
        }


    }

    handleExp = (index, propName) => {

        if (propName === "pastRoles") {
            if (!this.state.expandedExp.includes(index)) {
                return this.setState({ expandedExp: [...this.state.expandedExp, index] });
            } else {
                return this.setState({ expandedExp: this.state.expandedExp.filter(item => item !== index) });
            }
        }   

        if (propName === "education") {
            if (!this.state.expandedEdu.includes(index)) {
                return this.setState({ expandedEdu: [...this.state.expandedEdu, index] });
            } else {
                return this.setState({ expandedEdu: this.state.expandedEdu.filter(item => item !== index) });
            }
        }

    }

    handleEdit = (item, index, propName) => {

        this.props.updateUserProfile([ { propName: propName, value: this.props.user.data.profile[propName].filter((_, idx) => idx !== index) } ]);

        if (propName === "pastRoles") {
            return this.setState({ showExp: true, cancelDataExp: item }, () => {
                this.props.setExperience([
                    { propName: "companyName", value: item.companyName },
                    { propName: "position", value: item.position },
                    { propName: "fromDate", value: item.fromDate },
                    { propName: "toDate", value: item.toDate },
                    { propName: "present", value: item.present },
                    { propName: "description", value: item.description },
                ]);
                return setTimeout(() => {
                    return this.expFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 125);
            });
        }

        if (propName === "education") {
            return this.setState({ showEdu: true, cancelDataEdu: item }, () => {   
                this.props.setEducation([
                    { propName: "name", value: item.name },
                    { propName: "fromDate", value: item.fromDate },
                    { propName: "toDate", value: item.toDate },
                    { propName: "present", value: item.present },
                    { propName: "description", value: item.description },
                ]); 
                return setTimeout(() => {
                    return this.eduFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 125);
            });
        }

    }

    handleRemove = (index, propName) => {

        const value = this.props.user.data.profile[propName].filter((_, idx) => idx !== index);

        return this.props.updateUserProfile([ { propName: propName, value: value }], true);

    }

    handleAddingNewExperience = (showExp) => {
        this.setState({ showExp: !showExp }, () => {
            if (!showExp) this.expFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }

    handleAddingNewEducation = (showEdu) => {
        this.setState({ showEdu: !showEdu }, () => {
            if (!showEdu) this.eduFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }

    render() {

        const { profile } = this.props.user.data;
        const { experience, education } = this.props;

        return(
            <>
                <DashboardSectionBase title={"Work Experience"} description={"Add and edit your work experience."}>
                    <div className="margin-top-20px" id="Experience">
                        <Button type={"tertiary"} title={this.state.showExp ? "Hide Adding Experience" : "Add Experience"} onClick={() => this.handleAddingNewExperience(this.state.showExp)} />
                    </div>
                    {profile?.pastRoles?.length !== 0 &&
                        <div className="margin-top-2x">
                            <Accordion propName={"pastRoles"} items={profile.pastRoles} expanded={this.state.expandedExp} onExpand={this.handleExp} edit={this.handleEdit} remove={this.handleRemove} />
                        </div>
                    }
                    <div ref={this.expFormRef}></div>
                    {this.state.showExp &&
                        <>
                            <div className="margin-top-2x">
                                <RegularTextInput label={"Yacht, Ship or Company name"} placeholder={"Type..."} value={experience.data.companyName} propName={"companyName"} onChangeText={this.props.setExperience} />
                            </div>
                            <div className="margin-top-20px">
                                <RegularTextInput label={"Job Title"} placeholder={"Type..."} value={experience.data.position} propName={"position"} onChangeText={this.props.setExperience} />
                            </div>
                            <div className="margin-top-20px">
                                <p className="experience-title">Time Period</p>
                            </div>
                            <div className="margin-top-1x">
                                <CheckBox label={"I am currently working here"} status={experience.data.present} value={!experience.data.present} propName={"present"} toggle={this.props.setExperience} />
                            </div>
                            <div className="margin-top-20px">
                                <RegularTextInput label={"Start Date"} placeholder={"MM/YYYY"} value={experience.data.fromDate} propName={"fromDate"} onChangeText={this.props.setExperience} />
                            </div>
                            {!experience.data.present &&
                                <div className="margin-top-20px">
                                    <RegularTextInput label={"End Date"} placeholder={"MM/YYYY"} value={experience.data.toDate} propName={"toDate"} onChangeText={this.props.setExperience} />
                                </div>
                            }
                            <div className="margin-top-20px">
                                <p className="experience-title">Description</p>
                                <p className="experience-subtitle">Describe your position, roles and responsibilities as well as any accomplishments.</p>
                            </div>
                            <div className="margin-top-10px">
                                <TextArea value={experience.data.description} placeholder={"Type..."} propName={"description"} onChangeText={this.props.setExperience} />                    
                            </div>
                            <div className="margin-top-20px">
                                <SaveCancel onSave={() => this.saveExp(experience.data.companyName, experience.data.position, experience.data.fromDate, experience.data.toDate, experience.data.present, experience.data.description)} onCancel={() => this.cancelExp("pastRoles", this.state.cancelDataExp)} />
                            </div>
                        </>
                    }
                </DashboardSectionBase>
                <DashboardSectionBase title={"Education"} description={"Add and edit your education."}>
                    <div className="margin-top-20px" id="Education">
                        <Button type={"tertiary"} title={this.state.showEdu ? "Hide Adding Education" : "Add Education"} onClick={() => this.handleAddingNewEducation(this.state.showEdu)} />
                    </div>
                    {profile?.education?.length !== 0 &&
                        <div className="margin-top-2x">
                            <Accordion propName={"education"} items={profile.education} expanded={this.state.expandedEdu} onExpand={this.handleExp} edit={this.handleEdit} remove={this.handleRemove} />
                        </div>
                    }
                    <div ref={this.eduFormRef}></div>
                    {this.state.showEdu &&
                        <>
                            <div className="margin-top-2x">
                                <RegularTextInput label={"College or University or High School"} placeholder={"Type..."} value={education.data.name} propName={"name"} onChangeText={this.props.setEducation} />
                            </div>
                            <div className="margin-top-20px">
                                <p className="experience-title">Time Period</p>
                            </div>
                            <div className="margin-top-1x">
                                <CheckBox label={"I am currently studying here"} status={education.data.present} value={!education.data.present} propName={"present"} toggle={this.props.setEducation} />
                            </div>
                            <div className="margin-top-20px">
                                <RegularTextInput label={"Start Date"} placeholder={"YYYY"} value={education.data.fromDate} propName={"fromDate"} onChangeText={this.props.setEducation} />
                            </div>
                            {!education.data.present &&
                                <div className="margin-top-20px">
                                    <RegularTextInput label={"End Date"} placeholder={"YYYY"} value={education.data.toDate} propName={"toDate"} onChangeText={this.props.setEducation} />
                                </div>
                            }
                            <div className="margin-top-20px">
                                <p className="experience-title">Description</p>
                                <p className="experience-subtitle">Describe your field of study and content of course.</p>
                            </div>
                            <div className="margin-top-10px">
                                <TextArea value={education.data.description} placeholder={"Type..."} propName={"description"} onChangeText={this.props.setEducation} />                    
                            </div>
                            <div className="margin-top-20px">
                                <SaveCancel onSave={() => this.saveEdu(education.data.name, education.data.fromDate, education.data.toDate, education.data.present, education.data.description)} onCancel={() => this.cancelExp("education", this.state.cancelDataEdu)} />
                            </div>
                        </>
                    }
                </DashboardSectionBase>
                <SaveButton propNames={candidateExperienceProps} profile={profile} updateUserProfile={this.props.updateUserProfile} previous={"/profile/qualifications"} next="/profile/references" history={this.props.history} />
            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        idealPosition: state.idealPosition,
        experience: state.experience,
        education: state.education,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile, setExperience, resetExperience, setEducation, resetEducation }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Experience);