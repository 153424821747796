import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "./index.css";
import { Link } from "react-router-dom";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

/* API requests */
import { updateCandidate } from "../../api-requests/candidate";

export default ({ user, active, status, isFetching, removeCandidateFromTheList, setAlert, type }) => {

    if (!isFetching) {
        return(
            <div className="sign-ups-user-row-container margin-top-20px">
                <Link className="sign-ups-user-row-wrapper" to={type === "crew" ? `/crew/profile/${user._id}` : `/sign-ups/profile/${user._id}`}>
                    <div className="sign-ups-user-row-profile-image-container">
                        <img src={user.image || require("../../assets/svgs/avatar-placeholder.svg")} onError={(e) => e.target.src = require("../../assets/svgs/avatar-placeholder.svg")} alt="" />
                    </div>
                    <div className="sign-ups-user-row-name-container admin-col-1">
                        <p className="sign-ups-user-row-name">{user.firstName} {user.lastName}</p>
                        {user.position &&
                            <p className="sign-ups-user-row-position">{user.position}</p>
                        }
                    </div>
                    <div className="sign-ups-user-row-data-container admin-col-2">
                        {type === "crew" ?
                            <span>{user.lastActive ? user.lastActive+" ago" : "N/A"}</span>
                        :
                            <span>{user.createdOn} ago</span>
                        }
                    </div>
                    {active &&
                        <div className="sign-ups-user-row-data-container admin-col-3">
                            <span>{type === "crew" ? user.createdOn : user.requestedDate} ago</span>
                        </div>
                    }
                    <div className="sign-ups-user-row-data-container admin-col-4">
                        <CircularProgressbarWithChildren
                            className="progress-bar"
                            value={user.percent}
                            maxValue={100}
                            strokeWidth={4}
                            styles={buildStyles({
                                pathColor: "#1377FF",
                            })}
                        >
                            <div className="progress-bar-inside-container">
                                <span>{user.percent}%</span>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </Link>
                {status &&
                    <div className="sign-ups-user-row-data-container admin-col-5" style={active ? {} : {width: 144}}>
                        <div className="sign-ups-user-row-buttons-container">
                            {active &&
                                <>
                                    <div className="sign-ups-user-row-reject-button" onClick={() => handleReject(removeCandidateFromTheList, setAlert, active, user)}>
                                        <p>Reject</p>
                                    </div>
                                    <div style={{width: 10, height: 10}}></div>
                                </>
                            }
                            <div className="sign-ups-user-row-verify-button" onClick={() => handleVerify(removeCandidateFromTheList, setAlert, active, user)}>
                                <p>Verify</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    } else {
        return(
            <div className="sign-ups-user-row-container margin-top-20px">
                <div className="sign-ups-user-row-wrapper">
                    <div className="sign-ups-user-row-profile-image-container">
                        <div className="sign-ups-user-row-profile-image-container-placeholder"></div>
                    </div>
                    <div className="sign-ups-user-row-name-container admin-col-1">
                        <div className="sign-ups-user-row-name-placeholder"></div>
                        <div className="sign-ups-user-row-position-placeholder"></div>
                    </div>
                    <div className="sign-ups-user-row-data-container admin-col-2">
                        <div className="sign-ups-user-row-data-placeholder"></div>
                    </div>
                    {active &&
                        <div className="sign-ups-user-row-data-container admin-col-3">
                            <div className="sign-ups-user-row-data-placeholder"></div>
                        </div>
                    }
                    <div className="sign-ups-user-row-data-container admin-col-4">
                        <CircularProgressbarWithChildren
                            className="progress-bar"
                            value={0}
                            maxValue={100}
                            strokeWidth={4}
                            styles={buildStyles({
                                pathColor: "#1377FF",
                            })}
                        >
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
                {status &&
                    <div className="sign-ups-user-row-data-container admin-col-5" style={active ? {} : {width: 144}}>
                        <div className="sign-ups-user-row-buttons-container">
                            <div className="sign-ups-user-row-data-placeholder"></div>
                            <div style={{width: 10, height: 10}}></div>
                            <div className="sign-ups-user-row-data-placeholder"></div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

const handleReject = (removeCandidateFromTheList, setAlert, active, user) => {

    let propName = active ? "completedUsers" : "incompleteUsers";
    let message = `${user.firstName} ${user.lastName} rejected!`;
    let body = [
        { propName: "requestedVerification", value: false },
        { propName: "requestedVerificationDate", value: null },
        { propName: "isRejected", value: true },
    ];

    updateCandidate(user._id, body);

    removeCandidateFromTheList(propName, user._id);

    setAlert(message, "success", true);

    setTimeout(() => {
        return setAlert(message, "success", false);
    }, 2700);

    return setTimeout(() => {
        return setAlert("", "success", false);
    }, 3000);

}

const handleVerify = (removeCandidateFromTheList, setAlert, active, user) => {

    let propName = active ? "completedUsers" : "incompleteUsers";
    let message = `${user.firstName} ${user.lastName} verified!`;
    let body = [
        { propName: "isVerified", value: true },
    ];

    updateCandidate(user._id, body);

    removeCandidateFromTheList(propName, user._id);

    setAlert(message, "success", true);

    setTimeout(() => {
        return setAlert(message, "success", false);
    }, 2700);

    return setTimeout(() => {
        return setAlert("", "success", false);
    }, 3000);

}