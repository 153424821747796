import React, { Component } from "react";

class TermsAndConditions extends Component {

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        return(
            <div className="terms-container">
                <div className="terms-title-container">
                    <p>Terms & Conditions</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>These terms and conditions apply to any user of our website and services. By registering to use this site and our services you are deemed to have accepted our terms and conditions. If you do not agree with any aspect of our terms and conditions, do not use this website or associated services.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>General Terms</p>
                </div>
                <div className="terms-text-container">
                    <p>1. The website and services available are provided in order to (i) assist candidates in a job search by allowing them to create a profile and apply for positions. It is also used to (ii) assistemployers to find candidates in order to fill a vacancy within their organisation or onboard their pleasure craft. (iii) Users can also use the website in order to have a deeper understanding of the job market and to discover information which could help progress their career. Users shall use our services for these purposes only and for no other personal or commercial purpose. Users may not otherwise copy, display or distribute any material from this site.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>2. All intellectual property rights in the website and application including any tools available as part of the service belong to J4Crew. The design and specification of the website and application is subject to change at any time and user content may be removed at our discretion.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>3. All registered users of the website and application are subject to a verification process and J4Crew may terminate the user’s right to access, and also remove any user content for any reason, at any time.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>4. We endeavour to ensure the constant availability of this website and services but cannot guarantee that our website and services will be uninterrupted. We accept no responsibility or liability to users for any delays or interruptions to our services and website.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>5. Although J4Crew is a managed database, we do not guarantee the accuracy, completeness or suitability of any of the content or output. The output may contain technical, grammatical or typographical errors. The use of content is therefore entirely at the user’s own risk.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>6. J4Crew does not guarantee that the content of this website will be free of viruses or secure.We therefore accept no liability with respect to viruses or security.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>7. Users of the website and application are strictly prohibited from introducing any malware, viruses, harmful code, content or material that could harm the website and IT systems.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>8. Users must not use our services to transmit or upload offensive, abusive, defamatory, indecent, or obscene content. We also prohibit any racist, sexist or otherwise illegal content. J4Crew reserves the right to immediately remove accounts associated with this type of activity.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>9. J4Crew may link to third party websites for informational purposes. We take no responsibility for the content associated with any third party website.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>10. Users agree that all content uploaded or published when registering onto the website and maintaining their profile is true, accurate and complete. It is the responsibility of the user to ensure that their account information is continually updated.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>11. All data collected by J4Crew is processed in accordance with our privacy policy.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>12. These terms and services are subject to change and we endeavour to keep users informed of any changes via email.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>13. J4Crew is not liable for any economic loss, loss of earnings, profits or contracts, loss of data or any other losses associated with using our services.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>14. Any notices to J4Crew from users should be sent via email to crew@j4crew.com and wewill endeavour to respond promptly.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Additional Terms for Candidates</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1. All candidates agree to abide by these additional terms and the general terms above. This forms a contract between the J4Crew and the candidate referred to as the “candidate agreement”. </p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>2. All Candidates must be over 18 years old to register.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>3. The candidate acknowledges and agrees that the candidate is solely responsible for the presentation, accuracy of any information published on our website. Information and documents must be kept updated including availability to ensure accuracy. The candidate acknowledges that the candidate may be contacted by employers directly via email, post, telephone or other means. </p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>4. J4Crew does not guarantee that you will be contacted by an employer and secure a job as a result of using our services. </p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>5. Candidates incur no costs from J4Crew at any point and do not store credit or debit card details.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>6. Candidate should be aware that no cost should be borne to the candidate for providing employment, other than the cost of obtaining a national statutory medical certificate, the national seafarer’s book and a passport or other personal travel documents. The costs of visas however, should be borne by the employer. </p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>7. J4Crew tries to ensure that there is no discrimination against age, disabilities, gender or race.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>8. J4Crew operates a verification system to maintain the integrity of our services. The approval process of candidates is based upon the completeness of the profile, accuracy of information, quality of documents provided, quality of written English, verification of identity, references provided and suitability of the profile picture provided. We do not discriminate against anyone registered or using our services.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>9. You agree that if you delete your account or if your account is terminated by J4Crew, all of the information associated with your account will be deleted, including all documents, content and information. You will also be removed as a candidate from all active job listings.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>10. J4Crew may terminate your account for any reason without notice. </p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>11. If you apply for a position, you agree to share your personal information, CV, and any other relevant information with the employer associated with that position.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>12. You must have permission from referees before publishing their information. You agree that referees will be contacted by us in order to confirm this permission to be listed as a reference with us.</p>
                </div>
            </div>
        );

    }   

}

export default TermsAndConditions;