import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import XLSX from "xlsx";
import "./index.css";

/* API requests */
import { getVerifiedReferences } from "../../../../api-requests/reference";

/* Components */
import { VerificationDocument } from "../../../ReferenceVerification/ReferenceVerificationPDF";

class References extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };

    }

    componentDidMount() {

        getVerifiedReferences()
            .then(result => {
                return this.setState({ data: result });
            });

    }

    generate = (document, reference) => {

        let blobPdf = pdf(document);
        blobPdf.updateContainer(document);
        
        return blobPdf.toBlob()
            .then(result => {
                return saveAs(result, `${reference.referenceName} Reference.pdf`);
            });
    }

    exportXlsx = () => {

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        return fetch("/api/candidates/all-references")
            .then(res => res.json())
            .then(json => {
                const ws = XLSX.utils.json_to_sheet(json);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });

                return saveAs(data, `References${fileExtension}`);
            })
            .catch(err => console.log(err));

    }

    render() {

        return(
            <div className="admin-references-container">
                <div className="admin-references-header-container">
                    <div className="admin-references-header-items-container">
                        <p className="admin-references-header-items-title">References</p>
                    </div>
                    <div className="admin-references-header-export-container" onClick={() => this.exportXlsx(this.state.data)}>
                        <p className="admin-references-header-export-title">Export spreadsheet</p>
                    </div>
                </div>
                <div className="admin-references-list-container">
                    {this.state.data.map((date, index) => {
                        return(
                            <div key={index}>
                                <div className="admin-reference-list-header-container">
                                    <p>{date.title}</p>
                                </div>
                                {date.items.map((reference, index) => {

                                    let verifiedPDF = <VerificationDocument candidate={reference.candidate} reference={reference} />;

                                    return(
                                        <div key={index} className="admin-references-list-item-container" style={date.items.length !== index+1 ? {borderBottom: "1px solid rgb(235, 235, 235)"} : {}}>
                                            <p>Candidate: <span><a href={"/crew/profile/"+reference.candidate._id} target="_blank" rel="noopener noreferrer">{reference.candidate.firstName} {reference.candidate.lastName}</a></span></p>
                                            <p>Document: <span onClick={() => this.generate(verifiedPDF, reference)}>{reference.referenceName} Reference.pdf</span></p>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}                    
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({  }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(References);