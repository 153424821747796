export const updateCandidate = (id, data) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id,
            data,
        }),
    };

    return fetch("/api/candidates/update/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const uploadCV = (id, file, name, extension, size) => {

    let formData = new FormData();

    formData.append("_id", id);
    formData.append("file", file);
    formData.append("name", name);
    formData.append("extension", extension);
    formData.append("size", size);

    const request = {
        body: formData,
        method: "POST",
    }

    return fetch("/api/candidates/upload-cv", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log("Error:", err);
            return err;
        });

}

export const uploadUpdatedCV = (id, name, extension, size, file) => {

    let formData = new FormData();

    formData.append("_id", id);
    formData.append("name", name);
    formData.append("extension", extension);
    formData.append("size", size);
    formData.append("file", file);

    const request = {
        body: formData,
        method: "POST",
    }

    return fetch("/api/candidates/upload-updated-cv", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log("Error:", err);
            return err;
        });

}

export const uploadDocument = (id, name, extension, size, propName, file) => {

    let formData = new FormData();

    formData.append("_id", id);
    formData.append("name", name);
    formData.append("extension", extension);
    formData.append("size", size);
    formData.append("propName", propName);
    formData.append("file", file);

    const request = {
        body: formData,
        method: "POST",
    }

    return fetch("/api/candidates/upload-document", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log("Error:", err);
            return err;
        });

}

export const uploadAvatar = (id, file) => {

    let formData = new FormData();

    formData.append("_id", id);
    formData.append("file", file);

    const request = {
        body: formData,
        method: "POST",
    }

    return fetch("/api/candidates/upload-avatar", request)
        .then(res => res.json())
        .then(result => {
            console.log(result);
            return result;
        })
        .catch(err => {
            console.log("Error:", err);
            return err;
        });

}

export const getCrewStats = () => {

    return fetch("/api/candidates/stats")
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const verifyReference = (id, email) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id,
            email,
        }),
    };

    return fetch("/api/candidates/verify-reference/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}