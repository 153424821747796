import React from "react";
import "./index.css";

/* Helpers */
import { formatUploadedDate } from "../../helpers/date";

export default ({ uploaded, propName, document, handleDocumentRemove, index }) => {

    return(
        <>
            <div className="documents-item-container">
                {!document.loading &&
                    <div className="documents-item-icon-container">
                        <img src={require("../../assets/svgs/document.svg")} alt="" />
                    </div>
                }
                {document.loading &&
                    <div className="documents-item-icon-loading-container">
                        <img className="documents-item-icon-loading" src={require("../../assets/animations/blue-spinner.svg")} alt="" />
                    </div>
                }
                <div className="documents-item-text-container">
                    <p className="documents-item-name">{document.name}</p>
                    <p className="documents-item-metadata">{document.extension} / {document.size}</p>
                </div>
                {!document.loading &&
                    <div className="documents-item-button-container">
                        <img onClick={() => handleDocumentRemove(propName, index)} src={require("../../assets/svgs/trash.svg")} alt="" />
                    </div>
                }
            </div>
            {uploaded &&
                <div className="margin-top-10px">
                    <p className="documents-item-uploaded">Uploaded {formatUploadedDate(uploaded)}</p>
                </div>
            }
        </>
    );

}   