export const getApplications = () => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
    };

    return fetch("/api/applications/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const createApplication = (job, candidate) => {

    const body = {
        job: job._id,
        candidate: candidate._id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/create/", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const updateApplication = (id, data) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id,
            data,
        }),
    };

    return fetch("/api/applications/update/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getApplicationsForJob = (job) => {

    const body = {
        job,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/job", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const rejectApplication = (_id) => {

    const body = {
        _id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/reject", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const restoreApplication = (_id) => {

    const body = {
        _id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/restore", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const forwardApplication = (_id) => {

    const body = {
        _id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/forward", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const hireApplication = (_id) => {

    const body = {
        _id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/hire", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const getApplication = (_id) => {

    const body = {
        _id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/find", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const handlePutForward =  (_id) => {

    const body = {
        _id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/applications/put-forward", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}