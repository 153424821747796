import React, { Component } from "react";
import "./index.css";
import Page from "../../../components/Page";
import moment from "moment";
import { Link } from "react-router-dom";
import NavigationBar from "../../../components/NavigationBar";


class CVAdvice extends Component {

    componentDidMount() {

        return window.scrollTo(0, 0);

    }

    render() {

        return(
            <Page match={this.props.match} hideNavbar={true}>
                <div className="page-intro-linear-container">
                    <NavigationBar { ...this.props } />
                    <div style={{ height: 30 }}></div>
                    <h4>CV Advice</h4>
                </div>
                <div style={{height: 75}}></div>
                <div className="about-us-team-header-container">
                    <p>Writing a Yacht CV</p>
                </div>
                <div className="about-us-text">
                    <p style={{ textAlign: "center" }}>It’s important to remember while writing your CV that it is a reflection of who you are as a person. If it is messy with typo’s and broken links, it could be an indication that you lack attention to detail, and come across as disorganised. I’m sure you will agree that this isn’t a great first impression… Here are some good tips that if you follow will definitely be helpful when searching for a new position.</p>
                </div>
                <div style={{height: 100}}></div>
                <img className="about-us-separator-image" src={require("../../../assets/images/cvadvice.webp")} alt="" />
                <div style={{height: 50}}></div>
                <div className="cv-advice-list-header-container">
                    <h4>Fonts</h4>
                </div>
                <ul className="cv-advice-list-container">
                    <li>Use common fonts such as Arial, Times New Roman, Calibri.</li>
                    <li>Size 11 or 12 is perfect, not too big or too small.</li>
                </ul>
                <div className="cv-advice-list-header-container">
                    <h4>Formatting</h4>
                </div>
                <ul className="cv-advice-list-container">
                    <li>Create a new document on Word, keep it simple. You can also use Google Docs.</li>
                    <li>Use a narrow margin to conserve space.</li>
                    <li>Divide your CV clearly into different headings, subheadings, text with bullet points as necessary.</li>
                    <li>Take care with page transitions so that they look tidy.</li>
                    <li>Most recent work history must come first. </li>
                    <li>Name the document to identify you, such as ‘John Doe CV’. </li>
                    <li>Export your CV as a PDF ideally, or Word. Never use Pages or lesser known services as they may not be compatible.</li>
                </ul>
                <div className="cv-advice-list-header-container">
                    <h4>Content</h4>
                </div>
                <ul className="cv-advice-list-container">
                    <li><strong>Profile Section:</strong> Summarise yourself and your career in a paragraph. You are a person, not just data so it’s important to show some of your personality.</li>
                    <li><strong>Hobbies/ Interests:</strong> Often overlooked, but since half the battle is fitting in with the rest of the crew it helps if you include some of your interests. Keep this to a few sentences</li>
                    <li><strong>Be humble.</strong> It’s important to be honest with what your skills are and not try to exaggerate your abilities as you will quickly be found out.</li>
                </ul>
                <div className="cv-advice-list-header-container">
                    <h4>Photo</h4>
                </div>
                <ul className="cv-advice-list-container">
                    <p>Those from commercial or land based positions may not understand that yachting CV’s include a photo, and there are some specific requirements for this.</p>                    
                    <div style={{height: 20}}></div>
                    <li>Commercial uniform with your epaulettes is not advised.</li>
                    <li>Dress code is smart/ casual but more emphasis on smart.</li>
                    <li>Happy expression is important, but don’t overdo it. Try to look rested and energetic.</li>
                    <li>Background must be either a white, or taken in an outdoor setting is OK. Avoid photos on boats, cropped photo of you on a night out (it happens), dark settings.</li>
                    <li>No selfies.</li>
                </ul>
                <div className="cv-advice-list-header-container">
                    <h4>References</h4>
                </div>
                <ul className="cv-advice-list-container">
                    <p>Make sure that you have <strong>all</strong> the contact details of your referees clearly stated. If you would rather leave them off your CV make sure that you have them ready in a separate document. This also needs to be well formatted and clear who is who and what is the relation.</p>
                </ul>
                <div className="cv-advice-list-header-container">
                    <h4>We can help</h4>
                </div>
                <ul className="cv-advice-list-container">
                    <p>If you need some help with your CV, we can help advise you personally. We also have a ‘J4Crew CV’ that you can download once you complete your online profile with us, which is industry standard.</p>
                </ul>
                <div style={{height: 75}}></div>
                <div className="web-page-footer-socials-container">
                    <a href={"https://twitter.com/J4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/twitter-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.facebook.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/facebook-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.instagram.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/instagram-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.linkedin.com/company/5267269"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/linkedin-footer.svg")} alt="" />
                    </a>
                </div>
                <div className="web-page-footer-links-container" style={{ borderBottom: "1px solid rgb(225, 225, 225)" }}>
                    <p><Link to={"/terms-and-conditions"}>Terms</Link><Link to={"/privacy-policy"}>Privacy Policy</Link><Link to={"/contact-us"}>Get in Touch</Link><Link to={"/about-us"}>About Us</Link><Link to={"/mlc-2006"}>MLC 2006</Link></p>
                </div>
                <div style={{height: 10}}></div>
                <div className="web-page-footer-links-container">
                    <p>Copyright ©{new Date().getFullYear()} J4Crew LTD</p>
                </div>
                <div style={{height: 30}}></div>
            </Page>
        );

    }

}

export default CVAdvice;