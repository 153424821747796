import {
    SET_PROFILE_PICTURE_EDITOR,
    SET_PROFILE_PICTURE,
    SET_PROFILE_PICTURE_SCALE,
    SET_PROFILE_PICTURE_DEGREE,
    TOGGLE_CV_UPLOAD_SUCCESS_POP_UP,
    TOGGLE_INITIAL_SETUP_COMPLETED,
    TOGGLE_EMAIL_VERIFIED_ALERT,
    TOGGLE_RESEND_EMAIL,
} from "../types/initial-setup";

const initialState = {
    editor: null,
    image: null,
    scale: 1,
    degree: 0,
    currency: "eur",
    salary: "",
    cvSuccess: false,
    verifyEmail: false,
    verified: false,
    resend: false,
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_PROFILE_PICTURE_EDITOR:
        
            return {
                ...state,
                editor: action.data,
            };

        case SET_PROFILE_PICTURE:
        
            return {
                ...state,
                image: action.data,
                scale: 1,
                degree: 0,
            };

        case SET_PROFILE_PICTURE_SCALE:
    
            return {
                ...state,
                scale: action.data,
            };

        case SET_PROFILE_PICTURE_DEGREE:

            return {
                ...state,
                degree: action.data,
            };

        case TOGGLE_CV_UPLOAD_SUCCESS_POP_UP:

            return {
                ...state,
                cvSuccess: !state.cvSuccess,
            };

        case TOGGLE_INITIAL_SETUP_COMPLETED:

            return {
                ...state,
                verifyEmail: action.data,
            };

        case TOGGLE_EMAIL_VERIFIED_ALERT:

            return {
                ...state,
                verified: action.data,
            };

        case TOGGLE_RESEND_EMAIL:

            return {
                ...state,
                resend: action.data,
            };
        
        default:
            return state;

    }

};