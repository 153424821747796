import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

/* Components */
import TextRow from "../TextRow";

/* Helpers */
import { handleAvailability, constructObjective, phoneOrWhatsapp, balancePersonalDetails } from "../../../../../../helpers/candidate";
import { handleTimePeriod, latestFirst, readDob } from "../../../../../../helpers/date";
import { handlePartner } from "../../../../../../helpers/couple";

export default ({ user, admin, application, verifyReference }) => {

    if (user) {

        let references = admin ? user.profile.references : user.profile.references.filter(item => !item.archived);

        const partner = user?.profile?.couple ? handlePartner(user.profile.couple, user._id) : null;
        const personalDetails = balancePersonalDetails(user.profile);

        return(
            <div className="cv-container">
                <div className="cv-meta-container">
                    <div className="cv-meta-content-contianer">
                        <div className="cv-name-container">
                            <p>{user.profile.firstName} {user.profile.lastName}</p>
                            {user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.name &&
                                <span>{user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.name.toUpperCase()}</span>
                            }
                        </div>
                        <div className="cv-meta-basic-information-container">
                            {/* {user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.name &&
                                <TextRow propName={"Desired Position"} value={user.profile.lookingFor.desiredRoles[0].position[0].name} />
                            } */}
                            {user?.profile?.currentLocation &&
                                <TextRow propName={"Location"} value={user.profile.currentLocation.name} />
                            }
                            <TextRow propName={"Availability"} value={handleAvailability(user.profile)} />
                            <TextRow propName={"Email"} value={user.profile.email} type={"email"} />
                            {user?.profile?.primaryPhone &&
                                <TextRow propName={"Mobile Phone"} admin={admin} type={"mobile"} phoneNumber={user.profile.primaryPhone} value={phoneOrWhatsapp(admin, user.profile.primaryPhone)} whatsapp={user.profile.primaryPhoneWhatsApp} />
                            }
                            {user?.profile?.secondaryPhone &&
                                <TextRow propName={"Other Phone"} admin={admin} type={"mobile"} phoneNumber={user.profile.secondaryPhone} value={phoneOrWhatsapp(admin, user.profile.secondaryPhone)} whatsapp={user.profile.secondaryPhoneWhatsApp} />
                            }
                            {user?.profile?.dob &&
                                <TextRow propName={"Date of Birth"} value={admin ? readDob(user.profile.dob) : user.profile.dob} />
                            }
                        </div>
                    </div>
                    {user?.profile?.profileImage &&
                        <div className="cv-profile-image-container">
                            <img src={user.profile.profileImage} alt="" />
                        </div>
                    }
                </div>
                <div className="cv-section-separator">
                    <p>Personal Details</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-list-container">
                        {personalDetails[0].map((detail, index) => {
                            return(
                                <div key={index}>
                                    {detail}
                                </div>
                            );
                        })}
                    </div>
                    <div className="cv-section-list-container">
                        {personalDetails[1].map((detail, index) => {
                            return(
                                <div key={index}>
                                    {detail}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {user?.profile?.aboutMe &&
                    <>
                        <div className="cv-section-separator">
                            <p>Profile</p>
                        </div>
                        <div className="cv-section-container">
                            <p className="cv-section-text">{user.profile.aboutMe}</p>
                        </div>
                    </>
                }
                {user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.name &&
                    <>
                        <div className="cv-section-separator">
                            <p>Objective</p>
                        </div>
                        {partner?.desiredRoles?.length > 0 &&
                            <div className="cv-section-text-container">
                                {admin && !application &&
                                    <p className="cv-section-text">{"I would like a couples position with my partner "}<Link to={partner.isVerified ? "/crew/profile/"+partner._id : "/sign-ups/profile/"+partner._id} style={{color: "#1377FF", textDecoration: "underline", cursor: "pointer"}}>{partner.name}</Link>{" who is looking for a position as "+partner.desiredRoles[0].position[0].name+"."}</p>
                                }
                                {!admin && !application &&
                                    <p className="cv-section-text">{"I would like a couples position with my partner "+partner.name+" who is looking for a position as "+partner.desiredRoles[0].position[0].name+"."}</p>
                                }
                                {application &&
                                    <p className="cv-section-text">{"I would like a couples position with my partner "}<a href={partner.isVerified ? "/crew/profile/"+partner._id : "/sign-ups/profile/"+partner._id} target={"_blank"} rel={"noopener noreferrer"} style={{color: "#1377FF", textDecoration: "underline", cursor: "pointer"}}>{partner.name}</a>{" who is looking for a position as "+partner.desiredRoles[0].position[0].name+"."}</p>
                                }
                            </div>
                        }
                        <div className={partner?.desiredRoles?.length > 0 ? "cv-section-text-container" : "cv-section-text-container"}>
                            {user.profile.lookingFor.desiredRoles.map((role, index) => {
                                return <p key={index} className="cv-section-text">{constructObjective(role, index, user.profile.lookingFor.desiredRoles)}</p>;
                            })}
                        </div>
                    </>
                }
                {(user?.profile?.qualifications.length !== 0 || user?.profile?.eng1Info?.status || user?.profile?.stcwBasicInfo?.status || user?.profile?.eng1Info?.stcwAdvancedInfo)  &&
                    <>
                        <div className="cv-section-separator">
                            <p>License & Qualifications</p>
                        </div>
                        <div className="cv-section-container">
                            <div className="cv-section-list-container">
                                {user?.profile?.eng1Info?.status &&
                                    <p className="cv-section-list-item"><span style={{paddingRight: 20}}>•</span>ENG 1 {user?.profile?.eng1Info?.expiryDate && <span className="cv-section-list-item-date">(exp. {user.profile.eng1Info.expiryDate})</span>}</p>
                                }
                                {user.profile.qualifications.slice(0, ((parseInt(user.profile.qualifications.length / 2))+1)).map((quality, index) => {
                                    return <p className="cv-section-list-item" key={index}><span style={{paddingRight: 20}}>•</span>{quality.name}</p>;
                                })}
                            </div>
                            <div className="cv-section-list-container">
                                {user?.profile?.stcwBasicInfo?.status &&
                                    <p className="cv-section-list-item"><span style={{paddingRight: 20}}>•</span>Basic STCW Safety Training {user?.profile?.stcwBasicInfo?.expiryDate && <span className="cv-section-list-item-date">(exp. {user.profile.stcwBasicInfo.expiryDate})</span>}</p>
                                }
                                {user?.profile?.stcwAdvancedInfo?.status &&
                                    <p className="cv-section-list-item"><span style={{paddingRight: 20}}>•</span>Advanced STCW Safety Training {user?.profile?.stcwAdvancedInfo?.expiryDate && <span className="cv-section-list-item-date">(exp. {user.profile.stcwAdvancedInfo.expiryDate})</span>}</p>
                                }
                                {user.profile.qualifications.slice((parseInt(user.profile.qualifications.length / 2))+1).map((quality, index) => {
                                    return <p className="cv-section-list-item" key={index}><span style={{paddingRight: 20}}>•</span>{quality.name}</p>;
                                })}
                            </div>
                        </div>
                    </>
                }
                {user?.profile?.pastRoles.length !== 0 &&
                    <>
                        <div className="cv-section-separator">
                            <p>Experience</p>
                        </div>
                        <div className="cv-section-list-container">
                            {user.profile.pastRoles.sort(latestFirst).map((role, index) => {
                                return(
                                    <div className="cv-section-list-item-container" key={index}>
                                        {/* <div className="cv-section-date-container">
                                            <p>{handleTimePeriod(role.fromDate, role.toDate, role.present)}</p>
                                        </div> */}
                                        <div className="cv-section-experience-container"> 
                                            <div className="cv-section-experience-title-container">
                                                <p>{handleTimePeriod(role.fromDate, role.toDate, role.present)} <span>|</span> {role.position} <span>|</span> {role.companyName}</p>
                                            </div>
                                            <div className="cv-section-experience-description-container">
                                                <p>{role.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            
                            
                        </div>
                    </>
                }
                {user?.profile?.education.length !== 0 &&
                    <>
                        <div className="cv-section-separator">
                            <p>Education</p>
                        </div>
                        <div className="cv-section-list-container">
                            {user.profile.education.sort(latestFirst).map((edu, index) => {
                                return(
                                    <div className="cv-section-list-item-container" key={index}>
                                        {/* <div className="cv-section-date-container">
                                            <p>{handleTimePeriod(edu.fromDate, edu.toDate, edu.present, true)}</p>
                                        </div> */}
                                        <div className="cv-section-experience-container"> 
                                            <div className="cv-section-experience-title-container">
                                                <p>{handleTimePeriod(edu.fromDate, edu.toDate, edu.present, true)} <span>|</span> {edu.name}</p>
                                            </div>
                                            <div className="cv-section-experience-description-container">
                                                <p>{edu.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            
                            
                        </div>
                    </>
                }
                {user?.profile?.skills.length !== 0 &&
                    <>
                        <div className="cv-section-separator">
                            <p>Skills</p>
                        </div>
                        <div className="cv-section-container">
                            <div className="cv-section-list-container">
                                {user.profile.skills.slice(0, ((parseInt(user.profile.skills.length / 2))+1)).map((skill, index) => {
                                    return <p className="cv-section-list-item" key={index}><span style={{paddingRight: 20}}>•</span>{skill}</p>;
                                })}
                            </div>
                            <div className="cv-section-list-container">
                                {user.profile.skills.slice((parseInt(user.profile.skills.length / 2))+1).map((skill, index) => {
                                    return <p className="cv-section-list-item" key={index}><span style={{paddingRight: 20}}>•</span>{skill}</p>;
                                })}
                            </div>
                        </div>
                    </>
                }
                {user?.profile?.equipment.length !== 0 &&
                    <>
                        <div className="cv-section-separator">
                            <p>Equipment Knowledge</p>
                        </div>
                        <div className="cv-section-container">
                            <div className="cv-section-list-container">
                                {user.profile.equipment.slice(0, ((parseInt(user.profile.equipment.length / 2))+1)).map((item, index) => {
                                    return <p className="cv-section-list-item" key={index}><span style={{paddingRight: 20}}>•</span>{item}</p>;
                                })}
                            </div>
                            <div className="cv-section-list-container">
                                {user.profile.equipment.slice((parseInt(user.profile.equipment.length / 2))+1).map((item, index) => {
                                    return <p className="cv-section-list-item" key={index}><span style={{paddingRight: 20}}>•</span>{item}</p>;
                                })}
                            </div>
                        </div>
                    </>
                }
                {user?.profile?.hobbies &&
                    <>
                        <div className="cv-section-separator">
                            <p>Interests & Hobbies</p>
                        </div>
                        <div className="cv-section-container">
                            <p className="cv-section-text">{user.profile.hobbies}</p>
                        </div>
                    </>
                }
                {references.length !== 0 &&
                    <>
                        <div className="cv-section-separator" style={{marginBottom: 10}}>
                            <p>References</p>
                        </div>
                        <div className="cv-section-container">
                            <div className="cv-section-list-container">
                                {references.map((reference, index) => {
                                    return(
                                        <div className="cv-section-reference-item-container" key={index}>
                                            <div className="cv-section-reference-item-title-container">
                                                <p>{reference.companyName}</p>
                                                {admin && reference.completed &&
                                                    <img src={require("../../../../../../assets/svgs/badge.svg")} alt="" />
                                                }
                                                {admin && reference.emailSent && !reference.completed &&
                                                    <p style={{marginLeft: 10, color: "#9C9C9C"}}>Email Sent</p>
                                                }
                                                {admin && reference.archived &&
                                                    <p style={{marginLeft: 10, color: "tomato"}}>Archived</p>
                                                }
                                                {admin && !reference.completed && !reference.emailSent &&
                                                    <span style={{marginLeft: 10, cursor: "pointer"}} onClick={() => verifyReference(user.profile, reference)}>Verify Reference</span>
                                                }
                                            </div>
                                            <div className="cv-section-reference-item-content-container">
                                                {reference?.position &&
                                                    <TextRow propName={"Referee Position"} value={reference.position} />
                                                }
                                                {reference?.referenceName &&
                                                    <TextRow propName={"Referee Name"} value={reference.referenceName} />
                                                }
                                                {reference?.email &&
                                                    <TextRow propName={"Email"} type={"email"} value={reference.email} />
                                                }
                                                {reference?.contactNumber &&
                                                    <TextRow propName={"Contact Number"} value={reference.contactNumber} />
                                                }
                                                {admin && reference.completed &&
                                                    <TextRow propName={"Document"} type={"file"} value={reference} candidate={user.profile} />
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    } else {
        return(
            <div className="cv-container">
                <div className="cv-meta-container">
                    <div className="cv-meta-content-contianer">
                        <div className="cv-name-container">                            
                        </div>
                        <div className="cv-meta-basic-information-container">
                            <TextRow />
                            <TextRow />
                            <TextRow />
                            <TextRow />
                            <TextRow />
                            <TextRow />
                        </div>
                    </div>
                    <div className="cv-profile-image-container">
                        <div className="cv-profile-image-placeholder"></div>
                    </div>
                </div>
                <div className="cv-section-separator">
                    <p>Personal Details</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-list-container">
                        <TextRow />
                        <TextRow />
                        <TextRow />
                    </div>
                    <div className="cv-section-list-container">
                        <TextRow />                                                
                        <TextRow />                                                                   
                    </div>
                </div>
                <div className="cv-section-separator">
                    <p>Profile</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-long-row-placeholder"></div>
                </div>
                <div className="cv-section-separator">
                    <p>Objective</p>
                </div>
                <div className="cv-section-text-container">
                    <div className="cv-section-long-row-placeholder"></div>
                    <div className="cv-section-long-row-placeholder"></div>
                    <div className="cv-section-short-row-placeholder"></div>
                </div>
                <div className="cv-section-text-container">
                </div>
                <div className="cv-section-separator">
                    <p>Qualifications</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-list-container">
                        <TextRow />
                        <TextRow />
                        <TextRow />
                    </div>
                    <div className="cv-section-list-container">
                        <TextRow />
                        <TextRow />
                        <TextRow />
                    </div>
                </div>
                <div className="cv-section-separator">
                    <p>Experience</p>
                </div>
                <div className="cv-section-list-container">
                    <div className="cv-section-long-row-placeholder"></div>
                    <div className="cv-section-long-row-placeholder"></div>
                    <div className="cv-section-short-row-placeholder"></div>
                </div>
                <div className="cv-section-separator">
                    <p>Education</p>
                </div>
                <div className="cv-section-list-container">
                    <div className="cv-section-long-row-placeholder"></div>
                    <div className="cv-section-short-row-placeholder"></div>
                </div>
                <div className="cv-section-separator">
                    <p>Skills</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-list-container">
                        <TextRow />
                        <TextRow />
                        <TextRow />
                    </div>
                    <div className="cv-section-list-container">
                        <TextRow />
                        <TextRow />
                    </div>
                </div>
                <div className="cv-section-separator">
                    <p>Equipment Knowledge</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-list-container">
                        <TextRow />
                        <TextRow />
                    </div>
                    <div className="cv-section-list-container">
                        <TextRow />
                    </div>
                </div>
                <div className="cv-section-separator">
                    <p>Interests & Hobbies</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-long-row-placeholder"></div>
                    <div className="cv-section-short-row-placeholder"></div>
                </div>
                <div className="cv-section-separator">
                    <p>References</p>
                </div>
                <div className="cv-section-container">
                    <div className="cv-section-list-container">
                        <div className="cv-section-long-row-placeholder"></div>
                        <div className="cv-section-short-row-placeholder"></div>
                    </div>
                </div>
            </div>
        );
    }

}