import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Jobs from "../../../DashboardCandidate/components/Jobs";

import { getJobs } from "../../../../api-requests/job";
import { setJobs } from "../../../../redux/actions/job";

class AdminJobs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isFetching: true,
        };
    }

    componentDidMount() {

        return getJobs()
            .then(jobs => {
                return this.setState({ data: jobs, isFetching: false });
            });

    }

    render() {
        
        return <Jobs admin={true} isFetching={this.state.isFetching} jobs={this.state.data} filter={true} type={"all"} title={"Jobs"} />

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
        job: state.job,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setJobs }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminJobs);