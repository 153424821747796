export const fastLoadVessels = () => {

    return fetch("/api/vessels/fast-load")
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getAllVessels = () => {

    return fetch("/api/vessels/all")
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}