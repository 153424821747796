import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

/* Fonts */
import "./assets/fonts/OpenSans-Regular.ttf";
import "./assets/fonts/OpenSans-Bold.ttf";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);