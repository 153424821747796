import React from "react";
import { Page, Text, View, Document, Image, Link } from "@react-pdf/renderer";

/* Styles */
import { styles } from "./styles";

/* Helpers */
import { readDob } from "../../../helpers/date";

export const VerificationDocument = ({ candidate, reference }) => {
    
    return(
        <Document>
            <Page size={"A4"} style={styles.page}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>Reference for {candidate.firstName} {candidate.lastName}, {reference.companyName}</Text>
                </View>
                <View style={styles.dateOfCompletionContainer}>
                    <Text style={styles.dateOfCompletion}>{readDob(reference.dateOfCompletion)}</Text>
                </View>
                <View style={styles.refereeDetailsTitleContainer}>
                    <Text style={styles.refereeDetailsTitle}>Referee Details</Text>
                </View>
                <View style={styles.refereeDetailsRowContainer}>
                    <Text style={styles.refereeDetailsRowText}>Name: {reference.referenceName}</Text>
                </View>
                <View style={styles.refereeDetailsRowContainer}>
                    <Text style={styles.refereeDetailsRowText}>Position: {reference.position}</Text>
                </View>
                <View style={styles.refereeDetailsRowContainer}>
                    <Text style={styles.refereeDetailsRowText}>Email: <Link style={styles.refereeDetailsRowTextLink} href={`mailto:${reference.email}`}>{reference.email}</Link></Text>
                </View>
                {reference.contactNumber && reference.contactNumber !== "-" ?
                    <View style={styles.refereeDetailsRowContainer}>
                        <Text style={styles.refereeDetailsRowText}>Phone: {reference.contactNumber}</Text>
                    </View>
                :
                    <View></View>
                }
                <View style={styles.sectionTitleContainer}>
                    <Text style={styles.sectionTitle}>Overall Performance</Text>
                </View>
                <View style={styles.sectionSubTitleContianer}>
                    <Text style={styles.sectionSubTitle}>Rating: {reference.performance}</Text>
                </View>
                {reference.performanceExtra ?
                    <View style={styles.sectionTextContainer}>
                        <Text style={styles.sectionText}>{reference.performanceExtra}</Text>
                    </View>
                :
                    <View></View>
                }
                {reference.interactionWithGuests && reference.interactionWithCrew ?
                    <View style={styles.wrapper}>
                        <View style={styles.sectionTitleContainer}>
                            <Text style={styles.sectionTitle}>Interpersonal Skills</Text>
                        </View>
                        <View style={styles.sectionSubTitleContianer}>
                            <Text style={styles.sectionSubTitle}>Interaction with guests: {reference.interactionWithGuests}</Text>
                        </View>
                        <View style={styles.sectionSubTitleContianer}>
                            <Text style={styles.sectionSubTitle}>Interaction with crew: {reference.interactionWithCrew}</Text>
                        </View>
                        {reference.interpersonalSkillsExtra ?
                            <View style={styles.sectionTextContainer}>
                                <Text style={styles.sectionText}>{reference.interpersonalSkillsExtra}</Text>
                            </View>
                        :
                            <View></View>
                        }
                    </View>
                :
                    <View></View>
                }
                {reference.presentation ?
                    <View style={styles.wrapper}>
                        <View style={styles.sectionTitleContainer}>
                            <Text style={styles.sectionTitle}>Presentation</Text>
                        </View>
                        <View style={styles.sectionSubTitleContianer}>
                            <Text style={styles.sectionSubTitle}>Rating: {reference.presentation}</Text>
                        </View>
                        {reference.presentationExtra ?
                            <View style={styles.sectionTextContainer}>
                                <Text style={styles.sectionText}>{reference.presentationExtra}</Text>
                            </View>
                        :
                            <View></View>
                        }
                    </View>
                :
                    <View></View>
                }
                {reference.sobriety ?
                    <View style={styles.wrapper}>
                        <View style={styles.sectionTitleContainer}>
                            <Text style={styles.sectionTitle}>Sobriety</Text>
                        </View>
                        <View style={styles.sectionSubTitleContianer}>
                            <Text style={styles.sectionSubTitle}>Rating: {reference.sobriety}</Text>
                        </View>
                        {reference.sobrietyExtra ?
                            <View style={styles.sectionTextContainer}>
                                <Text style={styles.sectionText}>{reference.sobrietyExtra}</Text>
                            </View>
                        :
                            <View></View>
                        }
                    </View>
                :
                    <View></View>
                }
                {reference.punctuality ?
                    <View style={styles.wrapper}>
                        <View style={styles.sectionTitleContainer}>
                            <Text style={styles.sectionTitle}>Punctuality</Text>
                        </View>
                        <View style={styles.sectionSubTitleContianer}>
                            <Text style={styles.sectionSubTitle}>Rating: {reference.punctuality}</Text>
                        </View>
                        {reference.punctualityExtra ?
                            <View style={styles.sectionTextContainer}>
                                <Text style={styles.sectionText}>{reference.punctualityExtra}</Text>
                            </View>
                        :
                            <View></View>
                        }
                    </View>
                :
                    <View></View>
                }
                {reference.employability ?
                    <View style={styles.wrapper}>
                        <View style={styles.sectionTitleContainer}>
                            <Text style={styles.sectionTitle}>Employability</Text>
                        </View>
                        <View style={styles.sectionSubTitleContianer}>
                            <Text style={styles.sectionSubTitle}>Re-hire or recommend: {reference.employability}</Text>
                        </View>
                        {reference.employabilityExtra ?
                            <View style={styles.sectionTextContainer}>
                                <Text style={styles.sectionText}>{reference.employabilityExtra}</Text>
                            </View>
                        :
                            <View></View>
                        }
                    </View>
                :
                    <View></View>
                }
                {reference.reasonForLeaving ?
                    <View style={styles.wrapper}>
                        <View style={styles.sectionTitleContainer}>
                            <Text style={styles.sectionTitle}>Reason for leaving</Text>
                        </View>
                        <View style={styles.sectionSubTitleContianer}>
                            <Text style={styles.sectionSubTitle}>Reason: {reference.reasonForLeaving}</Text>
                        </View>
                        {reference.reasonForLeavingExtra ?
                            <View style={styles.sectionTextContainer}>
                                <Text style={styles.sectionText}>{reference.reasonForLeavingExtra}</Text>
                            </View>
                        :
                            <View></View>
                        }
                    </View>
                :
                    <View></View>
                }
                <View fixed style={styles.poweredContainer}>
                    <Image style={styles.poweredImage} source={require("../../../assets/pngs/powered.png")} />
                </View>
            </Page>
        </Document>
    );

}