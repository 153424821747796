import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import TextInputDropdown from "../../../../components/TextInputDropdown";
import SelectedItems from "../../../../components/SelectedItems";
import Categories from "../../../../components/Categories";
import CheckBox from "../../../../components/CheckBox";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";

/* API requests */
import { getQualifications } from "../../../../api-requests/qualification";
import InitialSetupBase from "../../../../components/InitialSetupBase";

class Qualifications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            keyword: "",
            allQualifications: [],
        };

    }

    componentDidMount() {

        return getQualifications()
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ allQualifications: result.data });
            });

    }

    select = (item, propName) => {

        const value = [...this.props.user.data.profile[propName], item]

        return this.props.updateUserProfile([{ propName: propName, value }], true);

    }

    render() {

        const { profile } = this.props.user.data;

        return(
            <InitialSetupBase title={"Initial Set Up - Qualifications"} description={""}>
                <div className="qualifications-subtitle-contianer margin-top-20px">
                    <p className="qualifications-subtitle">Essential Certificates</p>
                </div>
                <div className="margin-top-20px">
                    <CheckBox label={"ENG 1"} status={profile?.eng1Info?.status} value={profile?.eng1Info} toggle={this.props.updateUserProfile} profile={profile} propName={"eng1Info"} />
                </div>
                <div className="margin-top-20px">
                    <CheckBox label={"STCW Basic Safety Training"} status={profile?.stcwBasicInfo?.status} value={profile?.stcwBasicInfo} toggle={this.props.updateUserProfile} profile={profile} propName={"stcwBasicInfo"}  />
                </div>
                <div className="margin-top-20px">
                    <CheckBox label={"STCW Advanced Safety Training (If Applicable)"} status={profile?.stcwAdvancedInfo?.status} value={profile?.stcwAdvancedInfo} toggle={this.props.updateUserProfile} profile={profile} propName={"stcwAdvancedInfo"} />
                </div>
                <div className="qualifications-subtitle-contianer margin-top-2x">
                    <p className="qualifications-subtitle">Qualifications</p>
                </div>
                <div className="qualifications-description-contianer margin-top-20px">
                    <p className="qualifications-description">Search and add any relevant qualifications or choose from the dropdown categories.</p>
                </div>
                <div className="margin-top-20px">
                    <TextInputDropdown icon={require("../../../../assets/svgs/search.svg")} label={"Qualifications"} placeholder={"Type..."} value={this.state.qualifications_keyword} values={profile.qualifications} options={this.state.allQualifications} onChangeText={(e) => this.setState({ qualifications_keyword: e })} propName={"qualifications"} select={this.select}  />
                </div>
                <SelectedItems items={profile.qualifications} propName={"qualifications"} updateUserProfile={this.props.updateUserProfile} />
                <div className="margin-top-20px">
                    <Categories propName={"qualifications"} categories={this.state.allQualifications} selected={profile.qualifications} show={this.state.qualifications_show} select={this.select} toggle={() => this.setState({ qualifications_show: !this.state.qualifications_show })} />
                </div>
            </InitialSetupBase>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Qualifications);