import { SET_EMAIL, SET_PASSWORD, SET_LOGIN_ERROR, CLEAR_LOGINS } from "../types/login";

export let setLoginEmail = (data) => dispatch => {

    dispatch({
        type: SET_LOGIN_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_EMAIL,
        data,
    });
    
}

export let setLoginPassword = (data) => dispatch => {

    dispatch({
        type: SET_LOGIN_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_PASSWORD,
        data,
    });
    
}

export let setLoginError = (status, message) => dispatch => {

    return dispatch({
        type: SET_LOGIN_ERROR,
        status,
        message,
    });
    
}

export let clearLogins = () => dispatch => {

    return dispatch({
        type: CLEAR_LOGINS,
    });
    
}