import {
    SET_USER_AUTH,
    SET_USER_DATA,
    SET_USER_LOGGING,
    UPDATE_USER_DATA,
    UPDATE_USER_PROFILE,
    UPDATE_USER_DOCUMENTS,
    UPDATE_USER_PROFILE_LOOKING_FOR,
    UPDATE_USER_PROFILE_YACHT,
} from "../types/user";

export const setLogging = (data) => dispatch => {

    return dispatch({
        type: SET_USER_LOGGING,
        data,
    });

}

export const setAuth = (data) => dispatch => {

    return dispatch({
        type: SET_USER_AUTH,
        data,
    });

}

export const setUserData = (data) => dispatch => {

    return dispatch({
        type: SET_USER_DATA,
        data,
    });
    
}

export const updateUserData = (data, autoSave) => dispatch => {

    return dispatch({
        type: UPDATE_USER_DATA,
        data,
        autoSave,
    });
    
}

export const updateUserProfile = (data, autoSave) => dispatch => {

    return dispatch({
        type: UPDATE_USER_PROFILE,
        data,
        autoSave,
    });
    
}

export const updateUserDocuments = (propName, index, data) => dispatch => {

    return dispatch({
        type: UPDATE_USER_DOCUMENTS,
        propName,
        index,
        data,
    });
    
}

export const updateUserProfileLookingFor = (data, autoSave) => dispatch => {

    return dispatch({
        type: UPDATE_USER_PROFILE_LOOKING_FOR,
        data,
        autoSave,
    });
    
}

export const updateUserProfileYacht = (data) => dispatch => {

    return dispatch({
        type: UPDATE_USER_PROFILE_YACHT,
        data,
    });
    
}