import saveAs from "file-saver";

export const downloadCV = (file, user) => {

    const request = {
        responseType: "blob",
    };

    let url = file.url;

    if (url.charAt(0) !== "/") url = "/"+url;

    return fetch(`/media${url}`, request)
        .then(res => res.blob())
        .then(blob => {
            return saveAs(blob, `${handleFileName(user, file)}`);
        })
        .catch(err => {
            console.log(err);
        });

}

export const downloadFile = (file) => {

    let url = file.url;

    if (url.charAt(0) !== "/") url = "/"+url;

    const request = {
        responseType: "arraybuffer",
    };

    return fetch(`/media${url}`, request)
        .then(res => res.arrayBuffer())
        .then(buffer => {
            return buffer;
        })
        .catch(err => {
            console.log(err);
        });

}

const handleFileName = (user, file) => {

    let firstName = user.firstName,
        lastName = user.lastName;

    return user.profile.cv.uploadDate ? `${firstName} ${lastName}${file.extension}` : `${firstName} ${lastName}${file.extension}`;

}