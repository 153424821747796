/* Send connection request */
export const sendConnectionRequest = (sender, receiver) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            sender,
            receiver,
        }),
    };

    return fetch("/api/couples/create", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

/* Update connection */
export const updateConnection = (_id, status) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
            status,
        }),
    };

    return fetch("/api/couples/update", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}