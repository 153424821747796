import moment from "moment";

/* Helpers */
import { handleMultiple } from "./candidate";

/* Constants */
const europe = ["france", "germany", "monaco", "netherlands", "norway", "united kingdom"];

/* Add hours */
Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

export const handleYachtIcon = (size, type) => {

    if (type === "Sailing Yacht") return require("../assets/svgs/sailing-yacht.svg");
    if (size >= 100) return require("../assets/svgs/motor-yacht-big.svg");

    return require("../assets/svgs/motor-yacht.svg");
        
}

export const handleYachtSize = (size) => {

    size = Math.round(size / 5) * 5;

    if (size >= 100) return "100m+";

    return `${size}m`;

}

export const handleYachtType = (type) => {

    if (type === "Motor Yacht") return "M/Y";

    return "S/Y";

}

export const handleJobPostTitle = (job) => {

    let position = job?.position?.name,
        salary = "",
        contractType = handleContractType(job);

    if (job?.salaryType === "Fixed") salary = `${handleCurrency(job.salaryCurrency)}${job.salaryAmount} per month`;
    if (job?.salaryType === "Range") salary = `${handleCurrency(job.salaryCurrency)}${job.salaryAmount} to ${handleCurrency(job.salaryCurrency)}${job.salaryRangeMax} per month`;
    if (job?.salaryType === "DOE") salary = "Salary DOE";

    if (!position) position = "N/A";

    return `${position} - ${salary} - ${contractType ? contractType : "N/A"}`;

}

export const handleJobSalary = (job, extra) => {

    let salary = "";

    if (job?.salaryType === "Fixed") salary = `${handleCurrency(job.salaryCurrency)}${job.salaryAmount} per month`;
    if (job?.salaryType === "Range") salary = `${handleCurrency(job.salaryCurrency)}${job.salaryAmount} to ${handleCurrency(job.salaryCurrency)}${job.salaryRangeMax} per month`;
    if (job?.salaryType === "DOE") salary = extra ? "Salary DOE" : "DOE";

    return salary;

}

export const handleYachtAge = (age) => {

    if (!age) return "N/A";

    let currentYear = moment(new Date()).year(),
        yearsOld = currentYear - age;

    if (yearsOld < 1) return "New Build";
    if (yearsOld < 5) return "Less Than 5 Years Old";
    if (yearsOld <= 10) return "5 to 10 Years Old";
    
    return "Over 10 Years Old";

}

export const handleYachtBuildRegion = (country) => {

    if (!country) return "N/A";

    country = country.toLowerCase();

    if (europe.includes(country)) return "Northern European";
    if (country === "italy") return "Italian";
    if (country === "turkey") return "Turkey";
    if (country === "united states") return "USA";

    return "Rest of World";

}

export const handleVisas = (job) => {

    let visas = [];

    if (job.b1b2) visas = [...visas, "B1/B2"];
    if (job.c1d) visas = [...visas, "C1/D"];
    if (job.schengen) visas = [...visas, "Schengen"];
    if (job.greenCard) visas = [...visas, "Green Card"];

    if (job.b1b2_visa && !visas.includes("B1/B2")) visas = [...visas, "B1/B2"];
    if (job.c1d_visa && !visas.includes("C1/D")) visas = [...visas, "C1/D"];
    if (job.schengen_visa && !visas.includes("Schengen")) visas = [...visas, "Schengen"];
    if (job.greenCard_visa && !visas.includes("Green Card")) visas = [...visas, "Green Card"];

    return visas.length < 1 ? null : handleMultiple(visas);

}

export const handleContractType = (job) => {

    if (!job.contractType) return null;
    if (job.contractType === "Full time") return "Full Time";
    if (job.contractType === "Rotation") return `${job.contractType} ${job.periodLength}:${job.periodLengthSecondary} ${job.periodType}`;
    
    let periodLength = parseInt(job.periodLength, 10);
    let periodType = job.periodType;

    if (periodLength === 1 && periodType) periodType = periodType.substring(0, periodType.length-1);

    return `${job.contractType} ${periodLength} ${periodType}`;

}

export const handleStartDate = (job) => {

    if (!job.startDateType) return null;
    if (job.startDateType === "Approximate Date") return job.startDateApprx;
    
    return job.startDateType;

}

/* Filter new jobs */
export const fitNewJobs = (jobs, profile) => {

    let lookingForPositions = [];
    let jobTypes = [];
    let yachtTypes = [];

    let newJobs = [];

    if (profile?.lookingFor?.desiredRoles?.length > 0) {
        profile.lookingFor.desiredRoles.forEach(role => {
            if (role?.position?.length > 0) {
                role.position.forEach(position => {
                    lookingForPositions = [...lookingForPositions, position.name];
                });
            }
            if (role?.jobType?.length > 0) {
                role.jobType.forEach(type => {
                    jobTypes = [...jobTypes, type];
                });
            }
            if (role?.yachtType?.length > 0) {
                role.yachtType.forEach(type => {
                    yachtTypes = [...yachtTypes, type];
                });
            }
        });

    
        jobs.forEach(job => {

            if (lookingForPositions.includes(job.position.name) && jobTypes.includes(job.contractType)
                && yachtTypes.includes(job.yacht.yachtType)) {
                newJobs = [...newJobs, { ...job, percent: determineJobFit(job, profile) }]
            }
        });
    }

    return newJobs;

}

/* Filter new jobs */
export const fitJobs = (jobs, profile) => {

    return jobs.map(job => {
        return { ...job, percent: determineJobFit(job, profile) };
    });

}

export const sortByPercentage = (a, b) => {

    if (a.percent > b.percent) return -1;
    if (a.percent < b.percent) return 1;

    return 0;

}

export const getMinMaxSize = (size) => {

    if (size === "Up to 40m") return { min: 0, max: 40 };
    if (size === "40m to 60m") return { min: 40, max: 60 };
    if (size === "60m to 80m") return { min: 60, max: 80 };
    if (size === "80m to 100m") return { min: 80, max: 100 };

    return { min: 100 };

}

export const getMinMaxAge = (age) => {

    if (age === "New Build") return { max: 1 };
    if (age === "Less Than 5 Years Old") return { min: 1, max: 5 };
    if (age === "5 to 10 Years Old") return { min: 5, max: 10 };

    return { min: 10 };

}

export const getMinMaxSalary = (amount) => {

    if (amount === "Up to €4000") return { min: 0, max: 4000 };
    if (amount === "€4000 to €6000") return { min: 4000, max: 6000 };
    if (amount === "€6000 to €8000") return { min: 6000, max: 8000 };
    if (amount === "€8000 to €10 000") return { min: 8000, max: 10000 };

    return { min: 10000 };

}

const handleCurrency = (currency) => {

    if (currency === "eur") return "€";
    if (currency === "usd") return "$";
    if (currency === "gbp") return "£";

}

export const getJobMatch = (job, profile) => {

    return determineJobFit(job, profile);

}

export const timeSinceLastActive = (d) => {

    var date = new Date(d);
  
    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = Math.floor(seconds / 31536000);
  
    if (interval > 1) {
      return interval + "y";
    } else if (interval === 1) {
      return interval + "y";
    }
  
    interval = Math.floor(seconds / 2592000);
  
    if (interval > 1) {
      return interval + "mo";
    } else if (interval === 1) {
      return interval + "mo";
    }
  
    interval = Math.floor(seconds / 86400);
  
    if (interval > 1) {
      return interval + "d";
    } else if (interval === 1) {
      return interval + "d";
    }
  
    interval = Math.floor(seconds / 3600);
  
    if (interval > 1) {
      return interval + "h";
    } else if (interval === 1) {
      return interval + "h";
    }
  
    interval = Math.floor(seconds / 60);
  
    if (interval > 1) {
      return interval + "m";
    } else if (interval === 1) {
      return interval + "m";
    }
  
    if (Math.floor(seconds) === 0) {
      return "Just Now";
    }
  
    return Math.floor(seconds) + "s";
  
}

const determineJobFit = (job, profile) => {

    let position = 0,
        salary = 0,
        contractType = 0;

    if (profile?.lookingFor?.desiredRoles?.length > 0) {

        position = matchPosition(job, profile);
        contractType = matchContractType(profile?.lookingFor?.desiredRoles, job.contractType);      

    }

    salary = matchSalary(job, profile);

    let total = position+salary+contractType;

    return total;

}

const matchPosition = (job, profile) => {

    /* Declare need variables */
    let requiredPosition = job.position.name,
        requiredPositionType = job.position.positionType,
        lookingForPositions = [],
        lookingForPositionTypes = [];

    /* Get all desired positions */
    if (profile?.lookingFor?.desiredRoles?.length > 0) {
        profile.lookingFor.desiredRoles.forEach(role => {
            if (role?.position?.length > 0) {
                role.position.forEach(position => {
                    lookingForPositions = [...lookingForPositions, position.name];
                    lookingForPositionTypes = [...lookingForPositionTypes, position.positionType];
                });
            }
        });
    }

    /* Return percent respective to result */
    if (lookingForPositions.includes(requiredPosition)) return 50;
    if (lookingForPositionTypes.includes(requiredPositionType)) return 20;

    /* Return 0 percent in case of no matches */
    return 0;

}

const matchSalary = (job, profile) => {

    if (job.salaryType === "DOE") return 30;

    let salaryAmount = job.salaryType === "Range" ? ((parseInt(job.salaryRangeMin, 10) + parseInt(job.salaryRangeMax, 10)) / 2) : parseInt(job.salaryAmount, 10);

    let desiredSalary = handleSalary(profile?.lookingFor?.idealSalaryCurrency, profile?.lookingFor?.idealSalary),
        salary = handleSalary(job.salaryCurrency, salaryAmount),
        salaryDifference = Math.abs((salary - desiredSalary));
    
    if (0 <= salaryDifference && salaryDifference <= 200) return 30;
    if (201 <= salaryDifference && salaryDifference <= 500) return 25;
    if (501 <= salaryDifference && salaryDifference <= 1000) return 20;
    if (1001 <= salaryDifference && salaryDifference <= 2000) return 10;

    return 0;

}

const handleSalary = (currency, amount) => {

    if (!currency || !amount) return 0;

    if (currency === "usd") return amount*0.9;
    if (currency === "gbp") return amount*1.1;

    return amount;

}

const matchContractType = (roles, contractType) => {

    let types = [];

    roles.forEach(role => {
        if (role.jobType) {
            role.jobType.forEach(type => {
                if (!types.includes(type)) {
                    types = [...types, type];
                }
            });
        }
    });

    if (types.includes(contractType)) return 20;

    return 0;

}

export const compareArchived = (archived) => {

    return archived;

}

export const compareExpired = (d, a) => {

    if (!d && a) return true;

    if (d) {
        let now = (new Date()).toISOString();
        let expirationDate = (new Date(d).addHours(336)).toISOString();

        return now > expirationDate;
    }

    return false;

}