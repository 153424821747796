import React from "react";
import "./index.css";

/* Constants */
import { checkBoxesWithStatus } from "../../constants";

export default ({ label, placeholder, value, propName, unit, onChangeText, profile }) => {

    return(
        <div className="regular-text-input-wrapper">
            <div className="regular-text-input-label-container">
                <p className="regular-text-input-label">{label}</p>
            </div>
            <div className="regular-text-input-container">
                <input value={value} onChange={(e) => handleOnChangeText(propName, e.target.value, onChangeText, profile)} onBlur={(e) => handleOnChangeText(propName, e.target.value, onChangeText, profile, true)} className="regular-text-input" type="text" placeholder={placeholder} />
                {unit &&
                    <div className="regular-text-input-unit-container">
                        <p className="regular-text-input-unit">{unit}</p>
                    </div>
                }
            </div>
        </div>
    );

}

const handleOnChangeText = (propName, value, onChangeText, profile, autoSave) => {

    if (checkBoxesWithStatus.includes(propName)) {
        value = { ...profile[propName], expiryDate: value };
    }

    if (propName === "reference" || propName === "startDateApprx") {
        return onChangeText(value);
    } else {
        return onChangeText([{ propName: propName, value: value }], autoSave);
    }

}