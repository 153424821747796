export const getJob = (_id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
        }),
    };

    return fetch("/api/jobs/find", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getUniqueReference = () => {
    return fetch("/api/jobs/unique-reference")
        .then(res => res.json())
        .then(result => {
            return result.ref;
        })
        .catch(err => {
            return err;
        });
}

export const getYachtListings = (_id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
        }),
    };

    return fetch("/api/jobs/yacht-listings/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getJobs = () => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    };

    return fetch("/api/jobs/all", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getJobsForGuest = () => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    };

    return fetch("/api/jobs/for-guest", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const listJob = (_id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
        }),
    };

    return fetch("/api/jobs/list", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const handleDraft = (_id, job, yacht, user, draft) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id: _id,
            postedBy: user._id,
            yacht: yacht._id,
            position: job.position._id,
            contractType: job.contractType,
            periodLength: job.periodLength,
            periodLengthSecondary: job.periodLengthSecondary,
            periodType: job.periodType,
            salaryType: job.salaryType,
            salaryCurrency: job.salaryCurrency,
            salaryAmount: job.salaryAmount,
            salaryRangeMax: job.salaryRangeMax,
            cabinStatus: job.cabinStatus,
            gender: job.gender,
            startDateType: job.startDateType,
            startDateApprx: job.startDateApprx,
            visible: job.visible,
            eng1: job.eng1_cert,
            stcwBasic: job.stcwBasic_cert,
            stcwAdvanced: job.stcwAdvanced_cert,
            qualifications: job.qualifications.map(item => item._id),
            moreQualifications: job.moreQualifications.map(item => item.map(i => i._id)),
            b1b2: job.b1b2_visa,
            c1d: job.c1d_visa,
            schengen: job.schengen_visa,
            greenCard: job.greenCard_visa,
            experience: job.experience,
            description: job.description,
            consultant: job.consultant,
            reference: job.reference,
            notes: job.notes,
            draft: draft,
        }),
    };

    console.log(job.moreQualifications.map(item => item.map(i => i._id)));

    return fetch("/api/jobs/handle-draft/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const deleteJob = (_id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
        }),
    };

    return fetch("/api/jobs/delete", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const archiveJob = (_id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
        }),
    };

    return fetch("/api/jobs/archive", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getAllListings = () => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    };

    return fetch("/api/jobs/only-admin", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const searchListings = (keyword, status) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            keyword,
            status,
        }),
    };

    return fetch("/api/jobs/search", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const matchUserToJob = (filters, job) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            filters,
            job,
        }),
    };

    return fetch("/api/jobs/match", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getJobStats = () => {

    return fetch("/api/jobs/stats")
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const sendJobAlerts = (_id, list, selected) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
            list,
            selected,
        }),
    };

    return fetch("/api/jobs/alerts", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getEmailList = (list) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            list,
        }),
    };

    return fetch("/api/jobs/email-list", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const sendLookingForCrew = (position, name, email, message) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            position,
            name,
            email,
            message,
        }),
    };

    return fetch("/api/jobs/looking-for-crew", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}