import React from "react";
import "./index.css";

/* API requests */
import { resendVerificationEmail } from "../../api-requests/verify";

export default ({ email, userID, show, resend, noexit }) => {

    return(
        <div className="verify-email-modal-container">
            {!noexit &&
                <img className="verify-email-modal-exit-button" onClick={() => show(false)} src={require("../../assets/svgs/x.svg")} alt="" />
            }
            <div className="verify-email-modal-title-container">
                <p>Confirm Your Email Address</p>
            </div>
            <div className="verify-email-modal-description-container margin-top-20px">
                <p>Click the confirmation link we sent to your email address <span className="verify-email-modal-description-email">{email}</span> to confirm your account.</p>
            </div>
            <div className="margin-top-2x">
                <img src={require("../../assets/svgs/email.svg")} alt="" />
            </div>
            <div className="verify-email-modal-separator margin-top-2x"></div>
            <div className="verify-email-modal-footer-container margin-top-20px">
                <p>Didn’t get the email? Check your spam or promotional emails.<br/><span className="verify-email-modal-footer-link" onClick={() => handleResendEmail(userID, resend)}>Email link again</span></p>
            </div>
        </div>
    );

}

const handleResendEmail = (userID, resend) => {

    return resendVerificationEmail(userID)
        .then(result => {
            if (result.status === 201) {
                resend(true);
                return setTimeout(() => {
                    return resend(false);
                }, 5000);
            }
        });

}