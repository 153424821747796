import React from "react";
import "./index.css";

/* Helpers */
import { constructPreferenceTitle, constructPreferenceText } from "../../helpers/candidate";

export default ({ jobs, updateUserProfileLookingFor, setIdealPosition, show, setCancelData }) => {

    return renderAddedPositions(jobs, updateUserProfileLookingFor, setIdealPosition, show, setCancelData);

}

const renderAddedPositions = (jobs, updateUserProfileLookingFor, setIdealPosition, show, setCancelData) => {

    return jobs.map((item, index) => {
        return(
            <div key={index} className="job-preference-container margin-top-20px">
                <div className="job-preference-wrapper">
                    <div className="job-preference-title-container">
                        <p>{constructPreferenceTitle(index+1)}</p>
                    </div>
                    <div className="job-preference-text-container">
                        <p>{constructPreferenceText(item)}</p>
                    </div>
                </div>
                <div className="job-preference-actions-container">
                    <div className="job-preference-actions-button-container" onClick={() => edit(updateUserProfileLookingFor, setIdealPosition, item, jobs, index, show, setCancelData)}>
                        <img src={require("../../assets/svgs/edit.svg")} alt="" />
                    </div>
                    <div className="job-preference-actions-button-container" onClick={() => del(updateUserProfileLookingFor, jobs, index)}>
                        <img src={require("../../assets/svgs/trash.svg")} alt="" />
                    </div>
                </div>
            </div>
        );  
    });

}

const edit = (updateUserProfileLookingFor, setIdealPosition, item, jobs, index, show, setCancelData) => {

    setCancelData(item);

    updateUserProfileLookingFor([{ propName: "desiredRoles", value: jobs.filter((_, idx) => idx !== index) }]);

    setIdealPosition([{ propName: "position", value: item.position }, { propName: "noYachtSizePreference", value: item.noYachtSizePreference }, { propName: "yachtSizeMin", value: item.yachtSizeMin }, { propName: "yachtSizeMax", value: item.yachtSizeMax }, { propName: "yachtType", value: item.yachtType }, { propName: "jobType", value: item.jobType }]);

    return show ? show() : "";

}

const del = (updateUserProfileLookingFor, jobs, index) => {

    return updateUserProfileLookingFor([{ propName: "desiredRoles", value: jobs.filter((_, idx) => idx !== index) }], true);

}