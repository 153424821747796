export const SET_REGISTRATION_LOADING_STATE = "SET_REGISTRATION_LOADING_STATE";
export const SET_REGISTRATION_ACCOUNT_TYPE = "SET_REGISTRATION_ACCOUNT_TYPE";
export const SET_REGISTRATION_FIRST_NAME = "SET_REGISTRATION_FIRST_NAME";
export const SET_REGISTRATION_LAST_NAME = "SET_REGISTRATION_LAST_NAME";
export const SET_REGISTRATION_EMAIL = "SET_REGISTRATION_EMAIL";
export const SET_REGISTRATION_PASSWORD = "SET_REGISTRATION_PASSWORD";
export const SET_REGISTRATION_FIRST_NAME_ERROR = "SET_REGISTRATION_FIRST_NAME_ERROR";
export const SET_REGISTRATION_LAST_NAME_ERROR = "SET_REGISTRATION_LAST_NAME_ERROR";
export const SET_REGISTRATION_EMAIL_ERROR = "SET_REGISTRATION_EMAIL_ERROR";
export const SET_REGISTRATION_PASSWORD_ERROR = "SET_REGISTRATION_PASSWORD_ERROR";
export const CLEAR_REGISTRATION_FIELDS = "CLEAR_REGISTRATION_FIELDS";