import { SET_REQUESTED_EMAIL, SET_NEW_PASSWORD, PASSWORD_RESET_REQUEST_SUCCESS, PASSWORD_RESET_ERROR } from "../types/reset-password";

const initialState = {
    email: "",
    newPassword: "",
    success: false,
    error: false,
    errorMessage: "",
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_REQUESTED_EMAIL:
        
            return {
                ...state,
                email: action.data,
            };

        case SET_NEW_PASSWORD:
    
            return {
                ...state,
                newPassword: action.data,
            };

        case PASSWORD_RESET_REQUEST_SUCCESS:
    
            return {
                ...state,
                success: action.data,
            };

        case PASSWORD_RESET_ERROR:

            return {
                ...state,
                error: action.status,
                errorMessage: action.message,
            };
        
        default:
            return state;

    }

};