import React, { Component } from "react";
import "./index.css";
import { getCrewStats } from "../../../../api-requests/candidate";
import { getJobStats } from "../../../../api-requests/job";

class Stats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            crew: {},
            jobs: {},
        };

    }

    componentDidMount() {

        return getCrewStats()
            .then(crew => {
                return getJobStats()
                    .then(jobs => {
                        return this.setState({ crew, jobs });
                    });
            });

    }

    render() {

        return(
            <div className="stats-master-container">
                <div className="stats-list-wrapper">
                    <div className="stats-title-container">
                        <p>Crew</p>
                    </div>
                    <div className="stats-content-container margin-top-10px">
                        <p>Total Crew: <span>{this.state.crew.total}</span></p>
                        <p>New Crew This Month: <span>{this.state.crew.thisMonth}</span></p>
                        <p>New Crew This Week: <span>{this.state.crew.thisWeek}</span></p>
                        <p>Incomplete Crew: <span>{this.state.crew.incomplete}</span></p>
                        <p>Total Engineers: <span>{this.state.crew.engineers}</span></p>
                        <p>Total Deck: <span>{this.state.crew.decks}</span></p>
                        <p>Total Stew: <span>{this.state.crew.stews}</span></p>
                        <p>Active Users This Month: <span>{this.state.crew.activeUsers}</span></p>
                    </div>
                </div>
                <div className="stats-list-wrapper">
                    <div className="stats-title-container">
                        <p>Jobs</p>
                    </div>
                    <div className="stats-content-container margin-top-10px">
                        <p>Total Jobs: <span>{this.state.jobs.total}</span></p>
                        <p>Total Jobs This Year: <span>{this.state.jobs.thisYear}</span></p>
                        <p>New Jobs This Month: <span>{this.state.jobs.thisMonth}</span></p>
                        <p>New Jobs This Week: <span>{this.state.jobs.thisWeek}</span></p>
                        <p>New Jobs Today: <span>{this.state.jobs.today}</span></p>
                    </div>
                </div>
            </div>
        );

    }

}

export default Stats;