import React, { Component } from "react";

class ComplaintsProcedure extends Component {

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        return(
            <div className="terms-container">
                <div className="terms-title-container">
                    <p>Complaints Procedure</p>
                </div>
                <div className="terms-text-container margin-top-2x">
                    <p>A complaint is classified as any written or spoken expression of dissatisfaction with the service we provide. J4crew takes complaints seriously. We aim to deal with complaints openly, promptly and honestly. We try to resolve complaints amicably as soon as possible andlearn from complaints to improve our services. If you would like to register a complaint, please send an e-mail to: <a href="mailto:crew@j4crew.com" target="_blank" rel="noopener noreferrer">crew@j4crew.com</a></p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>Please include the following information in your complaint:</p>
                    <p>Your name</p>
                    <p>Your address</p>
                    <p>Your telephone number</p>
                    <p>A short description of the events that have led to your complaint, including the names of the people involved.</p>
                    <p>NOTE: All complaints will be treated in confidence.</p>
                </div>
                <div className="terms-text-container margin-top-2x">
                    <p>J4crew will send you an e-mail to acknowledge your complaint and ask you to confirm or explain the details set out. Your complaint will be logged in our ‘Complaints Procedure Record’. At this stage we will do our utmost to resolve your complaint. You will receive a written reply within 5 working days of your initial e-mail, the time required to investigate your complaint. In certain instances we may have to extend this to 10 working days, in whichcase you will be informed by e-mail.</p>
                </div>
                <div className="terms-text-container margin-top-2x">
                    <p>Our reply will include the findings from our investigation and suggestions for resolving the matter. If a complaint is unresolved, J4Crew will advise the complainant and the competent authority:</p>
                </div>
                <div className="terms-text-container margin-top-2x">
                    <p>UK – Maritime and Coastguard Agency (MCA)</p>
                    <p>MLC 2006 Co-ordination Group,</p>
                    <p>Bay 2/20 Spring Place</p>
                    <p>105 Commercial Road</p>
                    <p>Southampton SO15 1EG</p>
                    <p>Email: <a href="mailto:mlc@mcga.gov.uk" target="_blank" rel="noopener noreferrer">mlc@mcga.gov.uk</a></p>
                </div>
                <div className="terms-text-container margin-top-2x">
                    <p>Please note the above are only able to deal with complaints that are related to UK ships and ports. Complaints regarding non UK ships or ports should be addressed to the appropriate national authorities for the ship/port concerned.</p>
                </div>
            </div>
        );

    }   

}

export default ComplaintsProcedure;