import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import InitialSetupBase from "../../../../components/InitialSetupBase";
import AddIdealPosition from "../../../../components/AddIdealPosition";
import JobPreferences from "../../../../components/JobPreferences";
import Button from "../../../../components/Button";

/* Actions */
import { updateUserProfileLookingFor } from "../../../../redux/actions/user";
import { setIdealPosition, setCancelData } from "../../../../redux/actions/ideal-position";

class IdealPosition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
        };
    }

    render() {
        return(
            <InitialSetupBase title={"Initial Set Up - Ideal Position"} description={"Please let us know what you are looking for."}>
                {this.props.user.data?.profile?.lookingFor?.desiredRoles?.length > 0 &&
                    <div className="margin-top-20px">
                        <Button title={this.state.show ? "Hide Adding Position" : "Add Another Position"} type={"tertiary"} onClick={() => this.setState({ show: !this.state.show })} />
                    </div>
                }
                {(this.state.show || this.props.user.data?.profile?.lookingFor?.desiredRoles?.length < 1) && <AddIdealPosition initialSetup={true} initialSetupHide={() => this.setState({ show: false })} />}
                <JobPreferences jobs={this.props.user.data.profile.lookingFor.desiredRoles} updateUserProfileLookingFor={this.props.updateUserProfileLookingFor} setIdealPosition={this.props.setIdealPosition} setCancelData={this.props.setCancelData} />
            </InitialSetupBase>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfileLookingFor, setIdealPosition, setCancelData }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdealPosition);