import React from "react";
import "./index.css";

/* Helpers */
import { handleCurrencySymbol } from "../../helpers/candidate";

export default ({ profile, updateUserProfileLookingFor }) => {

    return(
        <>
            <div className="desired-salary-title-container">
                <p className="desired-salary-title">Desired Monthly Salary</p>
            </div>
            <div className="desired-salary-action-container  margin-top-10px">
                <div className="desired-salary-currency-container">
                    <div className="desired-salary-currency-button" onClick={() => updateUserProfileLookingFor([{ propName: "idealSalaryCurrency", value: "eur" }], true)} style={{backgroundColor: profile.lookingFor?.idealSalaryCurrency === "eur" ? "#1377FF" : "white"}}>
                        <p style={{color: profile.lookingFor?.idealSalaryCurrency === "eur" ? "white" : "#333333"}}>€</p>
                    </div>
                    <div className="desired-salary-currency-button-separator"></div>
                    <div className="desired-salary-currency-button" onClick={() => updateUserProfileLookingFor([{ propName: "idealSalaryCurrency", value: "usd" }], true)} style={{backgroundColor: profile.lookingFor?.idealSalaryCurrency === "usd" ? "#1377FF" : "white"}}>
                        <p style={{color: profile.lookingFor?.idealSalaryCurrency === "usd" ? "white" : "#333333"}}>$</p>
                    </div>
                    <div className="desired-salary-currency-button-separator"></div>
                    <div className="desired-salary-currency-button" onClick={() => updateUserProfileLookingFor([{ propName: "idealSalaryCurrency", value: "gbp" }], true)} style={{backgroundColor: profile.lookingFor?.idealSalaryCurrency === "gbp" ? "#1377FF" : "white"}}>
                        <p style={{color: profile.lookingFor?.idealSalaryCurrency === "gbp" ? "white" : "#333333"}}>£</p>
                    </div>
                </div>
                <div className="desired-salary-input-main-container">
                    <div className="desired-salary-input-selected-currency-container">
                        <p>{handleCurrencySymbol(profile.lookingFor)}</p>
                    </div>
                    <div className="desired-salary-input-container">
                        <input className="desired-salary-input" value={profile.lookingFor?.idealSalary || ""} onChange={(e) => updateUserProfileLookingFor([{ propName: "idealSalary", value: e.target.value }])} onBlur={(e) => updateUserProfileLookingFor([{ propName: "idealSalary", value: e.target.value }], true)} type="text" placeholder={"Enter number"} />
                    </div>
                </div>
            </div>
        </>
    );

}