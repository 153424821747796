import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";

/* Styles */
import { styles } from "./styles";

/* Helpers */
import { handleAvailability, constructObjective, constructPartnersObjective, balancePersonalDetailsPDF } from "../../helpers/candidate";
import { latestFirst, handleTimePeriod, readDob } from "../../helpers/date";
import { handlePartner } from "../../helpers/couple";

/* Components */
import { TextRow, SectionSeparator } from "./components";

export const MyDocument = ({ user, admin }) => {

    const partner = user?.profile?.couple ? handlePartner(user.profile.couple, user._id) : null;
    const personalDetails = balancePersonalDetailsPDF(user.profile);
    
    return(
        <Document>
            <Page size={"A4"} style={styles.page}>
                <View style={styles.metaContainer}>
                    <View style={styles.metaContentContainer}>
                        <View style={styles.metaBasicInformationContainer}>
                            <Text style={styles.fullName}>{user.profile.firstName} {user.profile.lastName}</Text>
                            {user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.name ?
                                <Text style={styles.desiredPosition}>{user.profile.lookingFor.desiredRoles[0].position[0].name.toUpperCase()}</Text>
                            :
                                <View></View>
                            }
                            {user?.profile?.currentLocation ?
                                <TextRow propName={"Location"} value={user.profile.currentLocation.name} />
                            :
                                <View></View>
                            }
                            <TextRow propName={"Availability"} value={handleAvailability(user.profile)} />
                            <TextRow propName={"Email"} value={user.profile.email} type={"email"} />
                            {user?.profile?.primaryPhone ?
                                <TextRow propName={"Mobile Phone"} value={user.profile.primaryPhone} whatsapp={user.profile.primaryPhoneWhatsApp} />
                            :
                                <View></View>
                            }
                            {user?.profile?.secondaryPhone ?
                                <TextRow propName={"Other Phone"} value={user.profile.secondaryPhone} whatsapp={user.profile.secondaryPhoneWhatsApp} />
                            :
                                <View></View>
                            }
                            {user?.profile?.dob ?
                                <TextRow propName={"Date of Birth"} value={admin ? readDob(user.profile.dob) : user.profile.dob} />
                            :
                                <View></View>
                            }
                        </View>
                    </View>
                    {user?.profile?.profileImage ?
                        <View style={styles.profileImageContainer}>
                            <Image style={styles.profileImage} source={user.profile.profileImage} />
                        </View>
                    :
                        <View></View>
                    }
                </View>
                <SectionSeparator title={"Personal Details"} />
                <View style={styles.sectionContainer}>
                    <View style={styles.sectionListContainer}>
                        {personalDetails[0].map((detail, index) => {
                            return(
                                <View key={index}>
                                    {detail}
                                </View>
                            );
                        })}
                    </View>
                    <View style={styles.sectionListContainer}>
                        {personalDetails[1].map((detail, index) => {
                            return(
                                <View key={index}>
                                    {detail}
                                </View>
                            );
                        })}
                    </View>
                </View>
                {user?.profile?.aboutMe ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"Profile"} />
                        <View style={styles.sectionContainer}>
                            <Text style={styles.paragraph}>{user.profile.aboutMe}</Text>
                        </View>
                    </View>
                :
                    <View></View>
                }
                {user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.name ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"Objective"} />
                        <View style={[styles.sectionContainer, { flexDirection: "column" }]}>
                            {partner?.desiredRoles?.length > 0 ?
                                <View style={{display: "flex", flexDirection: "column", paddingBottom: 10}}>
                                    <Text style={styles.paragraph}>{constructPartnersObjective(partner)}</Text>
                                </View>
                            :
                                <View></View>
                            }
                            {user.profile.lookingFor.desiredRoles.map((role, index) => {
                                if (index !== (user.profile.lookingFor.desiredRoles.length-1)) {
                                    return(
                                        <View key={index} style={{display: "flex", flexDirection: "column", paddingBottom: 10}}>
                                            <Text style={styles.paragraph}>{constructObjective(role, index, user.profile.lookingFor.desiredRoles)}</Text>
                                        </View>
                                    );
                                } else {
                                    return(
                                        <Text key={index} style={styles.paragraph}>{constructObjective(role, index, user.profile.lookingFor.desiredRoles)}</Text>
                                    );   
                                }
                            })}
                        </View>
                    </View>
                :
                    <View></View>
                }
                {(user?.profile?.qualifications.length !== 0 || user?.profile?.eng1Info?.status || user?.profile?.stcwBasicInfo?.status || user?.profile?.eng1Info?.stcwAdvancedInfo) ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"License & Qualifications"} />
                        <View style={styles.sectionContainer}>
                            <View style={styles.sectionListContainer}>
                                {user?.profile?.eng1Info?.status ?
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center", height: 17}}>
                                        <Text style={styles.textRowMultipleText}>•      ENG 1</Text>
                                        {user.profile?.eng1Info?.expiryDate ?
                                            <Text style={styles.textRowMultipleDate}>(exp. {user.profile.eng1Info.expiryDate})</Text>
                                        :
                                            <View></View>
                                        }
                                    </View>
                                :
                                    <View></View>
                                }
                                {user.profile.qualifications.slice(0, ((parseInt(user.profile.qualifications.length / 2))+1)).map((quality, index) => {
                                    return <Text style={styles.listItemText} key={index}>•      {quality.name}</Text>;
                                })}
                            </View>
                            <View style={styles.sectionListContainer}>
                                {user?.profile?.stcwBasicInfo?.status ?
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center", height: 17}}>
                                        <Text style={styles.textRowMultipleText}>•      Basic STCW Safety Training</Text>
                                        {user.profile?.stcwBasicInfo?.expiryDate ?
                                            <Text style={styles.textRowMultipleDate}>(exp. {user.profile.stcwBasicInfo.expiryDate})</Text>
                                        :
                                            <View></View>
                                        }
                                    </View>
                                :
                                    <View></View>
                                }
                                {user?.profile?.stcwAdvancedInfo?.status ?
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center", height: 17}}>
                                        <Text style={styles.textRowMultipleText}>•      Advanced STCW Safety Training</Text>
                                        {user.profile?.stcwAdvancedInfo?.expiryDate ?
                                            <Text style={styles.textRowMultipleDate}>(exp. {user.profile.stcwAdvancedInfo.expiryDate})</Text>
                                        :
                                            <View></View>
                                        }   
                                    </View>
                                :
                                    <View></View>
                                }
                                {user.profile.qualifications.slice((parseInt(user.profile.qualifications.length / 2))+1).map((quality, index) => {
                                    return <Text style={styles.listItemText} key={index}>•      {quality.name}</Text>;
                                })}
                            </View>
                        </View>
                    </View>
                :
                    <View></View>
                }
                {user?.profile?.pastRoles.length !== 0 ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"Experience"} />
                        <View style={styles.sectionContainer}>
                            <View style={styles.sectionListContainer}>
                                {user.profile.pastRoles.sort(latestFirst).map((role, index) => {
                                    return(
                                        <View style={styles.listItemContainer} key={index}>
                                            <View style={styles.sectionItemContentContainer}> 
                                                <View style={styles.sectionItemContentTitleContainer}>
                                                    <Text style={styles.sectionItemContentTitle}>{handleTimePeriod(role.fromDate, role.toDate, role.present)} | {role.position} | {role.companyName}</Text>
                                                </View>
                                                <View style={styles.sectionItemContentDescriptionContainer}>
                                                    <Text style={styles.sectionItemContentDescription}>{role.description}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    );
                                })}
                            </View>
                        </View>
                    </View>
                :
                    <View></View>
                }
                {user?.profile?.education.length !== 0 ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"Education"} />
                        <View style={styles.sectionContainer}>
                            <View style={styles.sectionListContainer}>
                                {user.profile.education.sort(latestFirst).map((edu, index) => {
                                    return(
                                        <View style={styles.listItemContainer} key={index}>
                                            <View style={styles.sectionItemContentContainer}> 
                                                <View style={styles.sectionItemContentTitleContainer}>
                                                    <Text style={styles.sectionItemContentTitle}>{handleTimePeriod(edu.fromDate, edu.toDate, edu.present, true)} | {edu.name}</Text>
                                                </View>
                                                <View style={styles.sectionItemContentDescriptionContainer}>
                                                    <Text style={styles.sectionItemContentDescription}>{edu.description}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    );
                                })}
                            </View>
                        </View>
                    </View>
                :
                    <View></View>
                }
                {user?.profile?.skills.length !== 0 ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"Skills"} />
                        <View style={styles.sectionContainer}>
                            <View style={styles.sectionListContainer}>
                                {user.profile.skills.slice(0, ((parseInt(user.profile.skills.length / 2))+1)).map((skill, index) => {
                                    return <Text style={styles.listItemText} key={index}>•      {skill}</Text>;
                                })}
                            </View>
                            <View style={styles.sectionListContainer}>
                                {user.profile.skills.slice((parseInt(user.profile.skills.length / 2))+1).map((skill, index) => {
                                    return <Text style={styles.listItemText} key={index}>•      {skill}</Text>;
                                })}
                            </View>
                        </View>
                    </View>
                :
                    <View></View>
                }
                {user?.profile?.equipment.length !== 0 ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"Equipment Knowledge"} />
                        <View style={styles.sectionContainer}>
                            <View style={styles.sectionListContainer}>
                                {user.profile.equipment.slice(0, ((parseInt(user.profile.equipment.length / 2))+1)).map((item, index) => {
                                    return <Text style={styles.listItemText} key={index}>•      {item}</Text>;
                                })}
                            </View>
                            <View style={styles.sectionListContainer}>
                                {user.profile.equipment.slice((parseInt(user.profile.equipment.length / 2))+1).map((item, index) => {
                                    return <Text style={styles.listItemText} key={index}>•      {item}</Text>;
                                })}
                            </View>
                        </View>
                    </View>
                :
                    <View></View>
                }
                {user?.profile?.hobbies ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"Interests & Hobbies"} />
                        <View style={styles.sectionContainer}>
                            <Text style={styles.paragraph}>{user.profile.hobbies}</Text>
                        </View>
                    </View>
                :
                    <View></View>
                }
                {user?.profile?.references.length !== 0 ?
                    <View style={styles.sectionMasterContainer}>
                        <SectionSeparator title={"References"} />
                        <View style={styles.sectionContainer}>
                            <View style={styles.sectionListContainer}>
                                {user.profile.references.map((reference, index) => {
                                    return(
                                        <View style={styles.referenceItemContainer} key={index}>
                                            <View style={styles.referenceItemTitleContainer}>
                                                <Text style={styles.referenceItemTitle}>{reference.companyName}</Text>
                                            </View>
                                            <View style={{display: "flex", flexDirection: "column"}}>
                                                {reference?.position ?
                                                    <TextRow propName={"Referee Position"} value={reference.position} />
                                                :
                                                    <View></View>
                                                }
                                                {reference?.referenceName ?
                                                    <TextRow propName={"Referee Name"} value={reference.referenceName} />
                                                :
                                                    <View></View>
                                                }
                                                {reference?.email ?
                                                    <TextRow propName={"Email"} type={"email"} value={reference.email} />
                                                :
                                                    <View></View>
                                                }
                                                {reference?.contactNumber ?
                                                    <TextRow propName={"Contact Number"} value={reference.contactNumber} />
                                                :
                                                    <View></View>
                                                }
                                            </View>
                                        </View>
                                    );
                                })}
                            </View>
                        </View>
                    </View>
                :
                    <View></View>
                }
            </Page>
        </Document>
    );

}