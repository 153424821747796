import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./index.css";
import { Link } from "react-router-dom";

/* API requests */
import { getYacht } from "../../../../api-requests/yacht";
import { getYachtListings, deleteJob, archiveJob, handleDraft } from "../../../../api-requests/job";

/* Helpers */
import { handleContractType, handleStartDate, handleJobSalary } from "../../../../helpers/job";

/* Actions */
import { setAlert } from "../../../../redux/actions/misc";

class YachtListings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            yacht: null,
            keyword: "",
            drafts: [],
            archived: [],
            active: [],
            isFetching: true,
        };

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        if (this.props.match.params.yachtId) {

            return getYachtListings(this.props.match.params.yachtId)
                .then(listings => {
                    return getYacht(this.props.match.params.yachtId)
                        .then(yacht => {
                            return this.setState({ yacht: yacht, drafts: listings.drafts, archived: listings.archived, active: listings.active, isFetching: false });
                        });
                });
        }

    }

    handleDescription = (status, isFetching) => {

        if (isFetching) return "";
        if (status === "drafts") {
            if (this.state.drafts.length > 0) return "Here are draft listings, you can edit and complete an unfinished listing below.";
            return "There are no draft listings yet.";
        }
        if (status === "archived") {
            if (this.state.archived.length > 0) return "Here are archived positions, you can re-list a position with one click.";
            return "There are no archived listings yet.";
        }

        if (this.state.active.length > 0) return "Here are active listings, you can create new listings by clicking the button below.";
        return "There are no active listings yet. Add a new listing below.";

    }

    handleDelete = (_id) => {

        return deleteJob(_id)
            .then(result => {
                if (result.success) {
                    getYachtListings(this.props.match.params.yachtId)
                        .then(result => {
                            return this.setState({ drafts: result.drafts, archived: result.archived, active: result.active }, () => {
                                this.props.setAlert("Listing deleted successfully!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Listing deleted successfully!", "success", false);
                                }, 2700);
                            });
                        });
                }
            });

    }

    handleArchive = (_id) => {

        return archiveJob(_id)
            .then(result => {
                if (result.success) {
                    getYachtListings(this.props.match.params.yachtId)
                        .then(result => {
                            return this.setState({ drafts: result.drafts, archived: result.archived, active: result.active }, () => {
                                this.props.setAlert("Listing archived successfully!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Listing archived successfully!", "success", false);
                                }, 2700);
                            });
                        });
                }
            });

    }

    renderDrafts = (posts) => {

        return posts.map((post, index) => {
            return(
                <div key={index} className="yacht-listings-list-item-container">
                    <Link className="yacht-listings-list-item-wrapper" to={`/yachts/listings/${post.yacht._id}/add?job=${post._id}&status=draft`}>
                        <div className="yacht-listings-list-item-position-container">
                            <p>{post?.position?.name ? post.position.name : "N/A"}</p>
                        </div>
                        <div className="yacht-listings-list-item-start-date-container">
                            <p className="yacht-listings-list-item-text">{handleStartDate(post)}</p>
                        </div>
                        <div className="yacht-listings-list-item-salary-container">
                            <p className="yacht-listings-list-item-text">{handleJobSalary(post)}</p>
                        </div>
                        <div className="yacht-listings-list-item-contract-container">
                            <p className="yacht-listings-list-item-text">{handleContractType(post)}</p>
                        </div>
                    </Link>
                    <div className="yacht-listings-list-item-buttons-container">
                        <Link className="yacht-listings-list-item-complete-button-container" to={`/yachts/listings/${post.yacht._id}/add?job=${post._id}&status=draft`}>
                            <p>Complete Listing</p>
                        </Link>
                        <div className="yacht-listings-list-item-trash-button-container">
                            <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                            <div className="yacht-listings-list-item-trash-confirmation-button" onClick={() => this.handleDelete(post._id)}>
                                <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                                <p>Delete Forever</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

    }

    handleRelistPosition = (post) => {

        return handleDraft("", post, post.yacht, post.postedBy)
            .then(result => {
                return this.props.history.push(`/yachts/listings/${result.data.yacht}/add?job=${result.data._id}&status=archived`);
            });

    }

    renderArchive = (posts) => {

        return posts.map((post, index) => {
            return(
                <div key={index} className="yacht-listings-list-item-container">
                    <div className="yacht-listings-list-item-wrapper" onClick={() => this.handleRelistPosition(post)}>
                        <div className="yacht-listings-list-item-position-container">
                            <p>{post?.position?.name ? post.position.name : "N/A"}</p>
                        </div>
                        <div className="yacht-listings-list-item-start-date-container">
                            <p className="yacht-listings-list-item-text">{handleStartDate(post)}</p>
                        </div>
                        <div className="yacht-listings-list-item-salary-container">
                            <p className="yacht-listings-list-item-text">{handleJobSalary(post)}</p>
                        </div>
                        <div className="yacht-listings-list-item-contract-container">
                            <p className="yacht-listings-list-item-text">{handleContractType(post)}</p>
                        </div>
                    </div>
                    <div className="yacht-listings-list-item-buttons-container">
                        <div className="yacht-listings-list-item-complete-button-container" onClick={() => this.handleRelistPosition(post)}>
                            <p>Re-List Position</p>
                        </div>
                        <div className="yacht-listings-list-item-trash-button-container">
                            <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                            <div className="yacht-listings-list-item-trash-confirmation-button" onClick={() => this.handleDelete(post._id)}>
                                <img src={require("../../../../assets/svgs/trash.svg")} alt="" />
                                <p>Delete Forever</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

    }

    sortByApplications = (a, b) => {

        if (a.newApplications && b.newApplications) {
            
            if (a.newApplications.length > b.newApplications.length) return -1;
            if (a.newApplications.length < b.newApplications.length) return 1;
    
        }   
    
        return 0;
    
    }

    renderActive = (posts) => {

        let sortedByNewApplications = posts.sort(this.sortByApplications);

        return sortedByNewApplications.map((post, index) => {
            return(
                <div key={index} className="yacht-listings-list-item-container">
                    <Link className="yacht-listings-list-item-wrapper" to={`/yachts/listings/${post.yacht._id}/applications/${post._id}`}>
                        <div className="yacht-listings-list-item-position-container">
                            <div className="yacht-listings-list-item-position-title-container">
                                <p>{post?.position?.name ? post.position.name : "N/A"}</p>
                            </div>
                            <div className="yacht-listings-list-item-position-applications-counter-container">
                                <div className="yacht-listings-list-item-position-applications-counter-item-container">
                                    <img src={require("../../../../assets/svgs/total-applications.svg")} alt="" />
                                    <span style={{color: "#1377FF"}}>{post.applications.length}</span>
                                </div>
                                <div className="yacht-listings-list-item-position-applications-counter-item-container" style={{paddingLeft: 10}}>
                                    <img src={post.newApplications.length < 1 ? require("../../../../assets/svgs/no-new-applications.svg") : require("../../../../assets/svgs/new-applications.svg")} alt="" />
                                    <span style={{color: post.newApplications.length < 1 ? "#8A8A8A" : "#FB8266"}}>{post.newApplications.length}</span>
                                </div>
                            </div>
                        </div>
                        <div className="yacht-listings-list-item-start-date-container">
                            <p className="yacht-listings-list-item-text">{handleStartDate(post)}</p>
                        </div>
                        <div className="yacht-listings-list-item-salary-container">
                            <p className="yacht-listings-list-item-text">{handleJobSalary(post)}</p>
                        </div>
                        <div className="yacht-listings-list-item-contract-container">
                            <p className="yacht-listings-list-item-text">{handleContractType(post)}</p>
                        </div>
                    </Link>
                    <div className="yacht-listings-list-item-buttons-container">
                        <Link className="yacht-listings-list-item-complete-button-container" to={`/yachts/listings/${post.yacht._id}/applications/${post._id}`}>
                            <p>See Applications</p>
                        </Link>
                        <div className="yacht-listings-list-item-more-button-container">
                            <img src={require("../../../../assets/svgs/more.svg")} alt="" />
                            <div className="yacht-listing-list-item-more-buttons-container">
                                <Link className="yacht-listings-list-item-more-edit-button" to={`/yachts/listings/${post.yacht._id}/add?job=${post._id}&status=active`}>
                                    <img src={require("../../../../assets/svgs/edit-dark.svg")} alt="" />
                                    <p>Edit Details</p>
                                </Link>
                                <div className="yacht-listings-list-item-more-edit-button" onClick={() => this.handleArchive(post._id)}>
                                    <img src={require("../../../../assets/svgs/end-listing.svg")} alt="" />
                                    <p>End & Archive Listing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

    }

    renderPlaceholders = () => {

        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
            return(
                <div className="yacht-listings-list-item-placeholder" key={index}>
                </div>
            );
        });

    }

    handleSeeApplications = (post) => {

        return this.props.history.push();

    }

    render() {

        return(
            <div className="yacht-listings-container">
                <div className="yacht-listings-name-container">
                    {this.state.yacht &&
                        <p>{this.state?.yacht?.yachtType === "Motor Yacht" ? "M/Y" : "S/Y"} {this.state?.yacht?.name}</p>
                    }
                </div>
                <div className="yacht-listings-header-container">
                    <div className="yacht-listings-header-items-container">
                        <Link to={`/yachts/listings/${this.props.match.params.yachtId}/active`}>
                            <div className="yacht-listings-header-item-container">
                                <p style={(this.props.match.params.status === "active" || !this.props.match.params.status) ?  {color: "#1377FF"} : {}}>Active</p>
                                {(this.props.match.params.status === "active" || !this.props.match.params.status) &&
                                    <div className="yacht-listings-header-item-active"></div>
                                }
                            </div>
                        </Link>
                        <Link to={`/yachts/listings/${this.props.match.params.yachtId}/archived`}>
                            <div className="yacht-listings-header-item-container">
                                <p style={this.props.match.params.status === "archived" ?  {color: "#1377FF"} : {}}>Archived</p>
                                {this.props.match.params.status === "archived" &&
                                    <div className="yacht-listings-header-item-active"></div>
                                }
                            </div>
                        </Link>
                        <Link to={`/yachts/listings/${this.props.match.params.yachtId}/drafts`}>
                            <div className="yacht-listings-header-item-container">
                                {this.state.drafts.length > 0 &&
                                    <div className="sign-ups-header-users-counter">
                                        <span>{this.state.drafts.length}</span>
                                    </div> 
                                }
                                <p style={this.props.match.params.status === "drafts" ?  {color: "#1377FF"} : {}}>Drafts</p>
                                {this.props.match.params.status === "drafts" &&
                                    <div className="yacht-listings-header-item-active"></div>
                                }
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="yacht-listings-description-container">
                    <p className="yacht-listings-description">{this.handleDescription(this.props.match.params.status, this.state.isFetching)}</p>
                </div>
                {(this.props.match.params.status === "active" || !this.props.match.params.status) &&
                    <div className="yacht-listings-button-container">
                        <Link className="yacht-listings-button" to={`/yachts/listings/${this.props.match.params.yachtId}/add`}>
                            <p>Add New Listing</p>
                        </Link>
                    </div>
                }
                <div className="yacht-listing-list-contianer">
                    {this.state[this.props.match.params.status ? this.props.match.params.status : "active"].length > 0 &&
                        <div className="yacht-listing-list-header-container">
                            <div className="yacht-listing-list-header-position-container">
                                <p>Position</p>
                            </div>
                            <div className="yacht-listing-list-header-start-date-container">
                                <p>Start Date</p>
                            </div>
                            <div className="yacht-listing-list-header-salary-container">
                                <p>Salary</p>
                            </div>
                            <div className="yacht-listing-list-header-contract-type-container">
                                <p>Contract Type</p>
                            </div>
                            <div className="yacht-listing-list-header-actions-container">
                                <p>Actions</p>
                            </div>
                        </div>
                    }
                    {this.state.isFetching &&
                        this.renderPlaceholders()
                    }
                    {this.props.match.params.status === "drafts" && !this.state.isFetching &&
                        this.renderDrafts(this.state.drafts)
                    }
                    {this.props.match.params.status === "archived" && !this.state.isFetching &&
                        this.renderArchive(this.state.archived)
                    }
                    {(this.props.match.params.status === "active" || !this.props.match.params.status) && !this.state.isFetching &&
                        this.renderActive(this.state.active)
                    }
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(YachtListings);