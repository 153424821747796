import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

/* Actions */
import { updateUserProfile } from "../../redux/actions/user";
import { toggleCVPopup, toggleResendEmail, toggleVerifyEmailPopup } from "../../redux/actions/initial-setup";

/* Components */
import Page from "../../components/Page";
import SelectProfileType from "./components/SelectProfileType";
import UploadPhoto from "./components/UploadPhoto";
import ProceedButton from "./components/ProceedButton";
import BasicInformation from "./components/BasicInformation";
import IdealPosition from "./components/IdealPosition";
import Qualifications from "./components/Qualifications";
import Overlay from "../../components/Overlay";
import YachtDetails from "./components/YachtDetails";
import MoreYachtDetails from "./components/MoreYachtDetails";
import ContactDetails from "./components/ContactDetails";
import VerifyEmail from "../../components/VerifyEmail";

import { ProgressBar } from "./components/ProgressBar";

class InitialSetup extends Component {
    
    goToPreviousStep = (step) => {

        this.props.updateUserProfile([ { propName: "initialSetupStep", value: step } ]);

        return window.scrollTo(0, 0);

    }

    skipProfileImage = (step) => {

        return this.props.updateUserProfile([ { propName: "initialSetupStep", value: step } ], true);

    }

    render() {

        let { data, isFetching } = this.props.user;

        if (data && data.profile && data.profile.profileType !== undefined && data.profile.profileType !== "upload") return <Redirect to={"/"} />;
        if (data && data.isVerified && data.profile.initialSetupCompleted) return <Redirect to={"/"} />;

        return(
            <Page history={this.props.history} linear={true}>
                {!isFetching && data && data.userType === "Candidate" && data.profile.initialSetupStep === undefined && 
                    <SelectProfileType {...this.props} />
                }
                {!isFetching && data && data.userType === "Candidate" && data.profile.initialSetupStep !== undefined &&
                    <div className="initial-setup-container">
                        <ProgressBar type={"Candidate"} profile={data.profile} updateStep={this.props.updateUserProfile} />
                        {!isFetching && data && data.profile.initialSetupStep === 1 &&
                            <UploadPhoto />
                        }
                        {!isFetching && data && data.profile.initialSetupStep === 2 &&
                            <BasicInformation />
                        }
                        {!isFetching && data && data.profile.initialSetupStep === 3 &&
                            <IdealPosition />
                        }
                        {!isFetching && data && data.profile.initialSetupStep === 4 &&
                            <Qualifications />
                        }
                        <div className="initial-setup-buttons-container margin-top-2x">
                            <ProceedButton history={this.props.history} />
                            {!isFetching && data && data.profile.initialSetupStep === 1 &&
                                <div className="initial-setup-previous-step-button" style={this.props.initialSetup.image ? {} : { marginLeft: 0 }} onClick={() => this.skipProfileImage(2)}>
                                    <p className="initial-setup-previous-step-button-title">Skip photo upload</p>
                                </div>
                            }
                            {!isFetching && data && data.profile.initialSetupStep > 1 &&
                                <div className="initial-setup-previous-step-button" onClick={() => this.goToPreviousStep(this.props.user.data.profile.initialSetupStep-1)}>
                                    <p className="initial-setup-previous-step-button-title">Previous Step</p>
                                </div>
                            }
                        </div>
                    </div>
                }
                {!isFetching && data && data.userType === "Employer" &&
                    <div className="initial-setup-container">
                        <ProgressBar type={"Employer"} profile={data.profile} updateStep={this.props.updateUserProfile} />
                        {!isFetching && data && (data.profile.initialSetupStep === 1 || data.profile.initialSetupStep === undefined) &&
                            <YachtDetails />
                        }
                        {!isFetching && data && (data.profile.initialSetupStep === 2) &&
                            <MoreYachtDetails />
                        }
                        {!isFetching && data && (data.profile.initialSetupStep === 3) &&
                            <ContactDetails />
                        }
                        <div className="initial-setup-buttons-container margin-top-2x">
                            <ProceedButton history={this.props.history} />
                            {!isFetching && data && data.profile.initialSetupStep > 1 &&
                                <div className="initial-setup-previous-step-button" onClick={() => this.goToPreviousStep(this.props.user.data.profile.initialSetupStep-1)}>
                                    <p className="initial-setup-previous-step-button-title">Previous Step</p>
                                </div>
                            }
                        </div>
                    </div>
                }
                {this.props.initialSetup.cvSuccess &&
                    <Overlay>
                        <div className="cv-success-modal-container">
                            <img className="cv-success-modal-exit-button" onClick={() => this.props.toggleCVPopup()} src={require("../../assets/svgs/x.svg")} alt="" />
                            <div className="cv-success-modal-title-container">
                                <p>We prefilled your editable online profile</p>
                            </div>
                            <div className="cv-success-modal-content-container">
                                <div className="cv-success-modal-icon-container">
                                    <img src={require("../../assets/svgs/board.svg")} alt="" />
                                </div>
                                <div className="cv-success-modal-list-container">
                                    <p>• Check for mistakes we have made</p>
                                    <p>• Add details and elements that are missing</p>
                                    <p>• Make sure it looks great for employers!</p>
                                </div>
                            </div>
                            <div className="cv-success-modal-button-container" onClick={() => this.props.toggleCVPopup()}>
                                <p>Review Your Profile</p>
                            </div>
                        </div>
                    </Overlay>
                }
                {this.props.initialSetup.verifyEmail &&
                    <Overlay>
                        <VerifyEmail email={data.email} userID={data._id} show={this.props.toggleVerifyEmailPopup} resend={this.props.toggleResendEmail} />
                    </Overlay>
                }
            </Page>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile, toggleCVPopup, toggleResendEmail, toggleVerifyEmailPopup }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialSetup);