import React from "react";
import "./index.css";

export default ({ children }) => {

    return(
        <div className="initial-setup-overlay-container">
            {children}
        </div>
    );

}