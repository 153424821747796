import React from "react";
import "./index.css";

export default ({ children, title, description, icon, steps, step, overflow }) => {

    return(
        <div className="dashboard-section-base-container margin-top-2x" style={overflow ? { overflow: "hidden" } : {}}>
            {steps &&
                <div className="dashboard-section-steps-container">
                    {renderSteps(steps, step)}
                </div>
            }
            <div className="dashboard-section-base-title-contianer">
                <p className="dashboard-section-base-title">{title}</p>
                {icon &&
                    <img src={icon} alt="" />
                }
            </div>
            {description &&
                <div className="dashboard-section-base-description-contianer margin-top-20px">
                    <p className="dashboard-section-base-description">{description}</p>
                </div>
            }
            {children}
        </div>
    );

}

const renderSteps = (steps, step) => {

    return steps.map((current, index) => {
        return(
            <div className="dashboard-section-items-container" key={index}>
                <div className="dashboard-section-steps-item-container" style={current <= step ? {backgroundColor: "#1377FF"} : {backgroundColor: "#DCDCDC"}}>
                </div>
                {index < steps.length-1 &&
                    <div className="dashboard-section-steps-items-separator">
                    </div>
                }
            </div>
        );
    });

}