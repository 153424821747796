import React, { Component } from "react";
import { Link } from "react-router-dom";

class MLC2006 extends Component {

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        return(
            <div className="terms-container">
                <div className="terms-title-container">
                    <p>MLC 2006</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>MLC Article 1.4</p>
                </div>
                <div className="terms-text-container">
                    <p>J4Crew is compliant with the provisions of the Maritime Labour Convention with respect to crew recruiting and placement as detailed in Article 1.4 of the convention and as required under United Kingdom Legislation.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>This requires:</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.1 It will not maintain a black list to prevent individual seafarers from gaining employment.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.2 There is not a charge made against the seafarer for providing them with employment or to register for employment on the Website.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.3 We will ensure that seafarers recruited or placed are qualified for the job concerned and undertake reference checks to provide assurance.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.3 We will ensure that seafarers recruited or placed are qualified for the job concerned and undertake reference checks to provide assurance.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.5 That the clients seafarers’ employment agreements (SEA’s) are in accordance with flag state laws and regulations, as is any Collective Bargaining Agreement (CBA) that forms part of the employment agreement.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.6 Inform seafarers of their rights and duties under their employment agreements prior to engagement.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.7 Examine and respond to any complaint concerning their activities and advise the competent authority (Flag State) of any unresolved complaint.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.8 Make sure, as far as reasonably practicable, that the owner/management company/Captain has the means to protect seafarers from being stranded in a foreign port.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.9 We have insurance to compensate seafarers for monetary loss from the failure of the recruitment and placement service, or from breech of the seafarer employment agreement by the owner or his servants/agents to meet its obligations required of them in law. Please see Insurance Certificate attached.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.10 Protect the confidentiality of seafarers’ personal data.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.11 Maintain up-to-date lists of the vessels for which we provide seafarers and ensure that there is a means by which the services can be contacted in an emergency at all hours.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.12 We will not recruit any crew under the age of 16 years, or below the minimum age specified by vessel’s Flag (whichever is higher).</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.13 We will not recruit any crew under the age of 18 that shall be employed or engaged or work as a ship’s cook.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>1.1.14 We pride ourselves on being a fair and just company. Seafarers are not subject to exploitation by J4Crew or their personnel with regard to the offer of engagement on particular ships or by particular companies.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>Please note that the risk of working on ships which fly the flag of a country which has not ratified the MLC 2006 is that the seafarer employment agreements may not be MLC 2006 compliant.</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>If you have a complaint, please get in touch with us via our <Link to={"/complaints-procedure"}>complaints procedure</Link>.</p>
                </div>
            </div>
        );

    }   

}

export default MLC2006;