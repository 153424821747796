import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import "./index.css";

/* Components */
import Page from "../../components/Page";
import Login from "./components/Login";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";

class SignIn extends Component {

    handleView = (props) => {

        if (props.match.path.includes("/sign-in")) return <Login {...this.props} />;
        if (props.match.path.includes("/sign-up")) return <Register {...this.props} />;
        if (props.match.path.includes("/reset-password")) return <ResetPassword queryString={props.location.search} />;

    }

    render() {

        return(
            <Page {...this.props} linear={true}>
                <div className="auth-page-main-container">
                    <div className="auth-page-main-wrapper">
                        <div className="auth-page-form-container">
                            {this.handleView(this.props)}
                        </div>
                        <div className="auth-page-image-container">
                            <div className="auth-page-image-wrapper">
                                <img src={require("../../assets/svgs/yacht.svg")} alt="" />
                            </div>
                            <div className="auth-page-image-title-container">
                                <span className="auth-page-image-title">Find Great Jobs</span>
                            </div>
                            {this.props.match.path === "/sign-up" && 
                                <div className="auth-page-image-subtitle-container margin-top-2x">
                                    <span className="auth-page-image-subtitle">Try us today!</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="web-page-footer-container">
                    <p>© {moment().year()} J4Crew - <Link to={"/terms-and-conditions"}>Terms & Conditions</Link> - <Link to={"/privacy-policy"}>Privacy Policy</Link> - <Link to={"/contact-us"}>Get in Touch</Link> - <Link to={"/about-us"}>About Us</Link> - <Link to={"/mlc-2006"}>MLC 2006</Link></p>
                </div>
            </Page>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(SignIn);