import React from "react";
import "./index.css";

/* Components */
import UploadDocumentButton from "../UploadDocumentButton";
import Document from "../Document";


export default ({ title, profile, handleDocumentUpload, handleDocumentRemove, propName }) => {

    return(
        <>
            <div className="margin-top-40px">
                <p className="documents-section-title">{title}</p>
            </div>
            <div className="documents-wrapper">
                {propName !== "cv" && profile?.[propName]?.length > 0 &&
                    profile?.[propName].map((document, index) => {
                        return(
                            <div className="margin-top-20px margin-right-20px" key={index}>
                                <Document propName={propName} document={document} handleDocumentRemove={handleDocumentRemove} index={index} />
                            </div>
                        );
                    }).reverse()
                }
                {propName === "cv" && profile?.cv &&
                    <div className="margin-top-20px margin-right-20px">
                        <Document propName={propName} document={profile.cv} uploaded={profile.cv.uploadDate} handleDocumentRemove={handleDocumentRemove} />
                    </div>
                }
            </div>
            <div className="margin-top-20px">
                {propName !== "cv" &&
                    <UploadDocumentButton propName={propName} title={profile?.[propName]?.length > 0 ? "Add Another" : "Upload Document"} upload={handleDocumentUpload} multiple={true} />
                }
                {propName === "cv" &&
                    <UploadDocumentButton propName={propName} title={profile?.cv ? "Replace" : "Upload Document"} upload={handleDocumentUpload} multiple={false} />
                }
            </div>
        </>
    );

}