export const getReference = (id) => {

    const body = {
        id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/references/", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const updateReference = (id, data) => {

    const body = {
        id,
        data,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/references/update", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}

export const getVerifiedReferences = () => {

    return fetch("/api/candidates/verified-references")
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}