import React, { Component } from "react";
import "./index.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Page from "../../../components/Page";
import TextInputWithIcon from "../../../components/TextInputWithIcon";
import { getPositions } from "../../../api-requests/position";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAlert } from "../../../redux/actions/misc";
import { sendLookingForCrew } from "../../../api-requests/job";
import TextInputDropdown from "../../../components/TextInputDropdown";
import NavigationBar from "../../../components/NavigationBar";

class LookingForCrew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: "",
            positions: [],
            show: false,
            position: null,
            name: "",
            email: "",
            message: "",
        };

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        return getPositions()
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ positions: result.data });
            });

    }

    setName = (value) => {

        return this.setState({ name: value });

    }

    setEmail = (value) => {

        return this.setState({ email: value });

    }

    sendEmail = (position, name, email, message) => {

        if (position && name && email && message) {
            return sendLookingForCrew(position, name, email, message)
                .then(_ => {
                    return this.setState({ show: false, position: null, name: "", email: "", message: "", }, () => {
                        this.props.setAlert("Email sent successfully! We will get back to you shortly.", "success", true);
                        return setTimeout(() => {
                            return this.props.setAlert("Email sent successfully! We will get back to you shortly.", "success", false);
                        }, 2700);
                    });
                });
        } else {
            return alert("All fields are required");
        }

    }

    selectPosition = (item) => {

        return this.setState({ position: item, keyword: `${item.name} (${item.positionType})`, show: false });

    }

    render() {

        return(
            <Page match={this.props.match} hideNavbar={true}>
                <div className="page-intro-linear-container">
                    <NavigationBar { ...this.props } />
                    <div style={{ height: 30 }}></div>
                    <h4>Looking for Crew?</h4>
                    <p>We place crew of all levels and disciplines onboard super yachts, including temporary, rotational and permanent contracts.</p>
                </div>
                <div style={{ height: 75 }}></div>
                <div className="web-page-home-section-container">
                    <div className="web-page-home-section-description-container">
                        <p>For bespoke yacht recruitment or to find out more about fees, please either send us an email at <a href="mailto:crew@j4crew.com" target="_blank" rel="noopener noreferrer">crew@j4crew.com</a> or alternatively call us on <a href="https://wa.me/447857138274" target={"_blank"} rel="noopener noreferrer">+44 7857 138 274</a>. You can also fill in the form below:</p>
                    </div>
                </div>
                <div className="looking-for-crew-form-container">
                    <TextInputDropdown show={this.state.show} toggleShow={() => this.setState({ show: !this.state.show })} icon={require("../../../assets/svgs/chevron-down.svg")} iconLeft={require("../../../assets/svgs/jobs.svg")} label={"Position"} placeholder="Search..." value={this.state.keyword} values={[this.state.keyword]} options={this.state.positions} onChangeText={(value) => this.setState({ keyword: value })} propName={"position"} select={this.selectPosition} single={true} />
                    <div className="margin-top-20px">
                        <TextInputWithIcon value={this.state.name} onChangeText={this.setName} icon={require("../../../assets/svgs/user.svg")} placeholder={"Your Name"} hide={false} />
                    </div>
                    <div className="margin-top-20px">
                        <TextInputWithIcon value={this.state.email} onChangeText={this.setEmail} icon={require("../../../assets/svgs/mail.svg")} placeholder={"Your Email"} hide={false} />
                    </div>
                    <div className="looking-for-crew-form-title-container margin-top-10px">
                        <p>Description</p>
                    </div>
                    <div className="looking-for-crew-form-message-container margin-top-10px">
                        <textarea value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} placeholder={"Type..."}></textarea>
                    </div>
                    <div className="margin-top-20px">
                        <div className="looking-for-crew-send-message-button" onClick={() => this.sendEmail(this.state.position, this.state.name, this.state.email, this.state.message)}>
                            <p>Send Message</p>
                            <img src={require("../../../assets/svgs/send-message.svg")} alt="" />
                        </div>
                    </div>
                </div>
                <div style={{height: 100}}></div>
                <img className="about-us-separator-image" src={require("../../../assets/images/palma-3.webp")} alt="" />
                <div className="web-page-home-quals-container">
                    <div className="web-page-home-perks-column-container">
                        <div className="web-page-home-perks-cell-container">
                            <h2>Hand Picked Crew</h2>
                            <p>Our process is simple, let us know what you are looking for, and we will provide you with the most suitable hand-picked crew for you to choose from and interview.</p>
                        </div>
                    </div>
                    <div className="web-page-home-perks-column-container">
                        <div className="web-page-home-perks-cell-container">
                            <h3>Strong Network of Crew</h3>
                            <p>We have a strong network of qualified and vetted crew members to suit any role required onboard. We don’t outsource our recruitment or rely on 3rd party platforms.</p>
                        </div>
                        <div style={{height: 50}}></div>
                        <div className="web-page-home-perks-cell-container">
                            <h3>Unrivalled Technology</h3>
                            <p>Our proprietary technology allows us to manage our relationships with thousands of crew and find the best available in the shortest time frame, so that you don’t miss out on those top candidates.</p>
                        </div>
                        <div style={{height: 50}}></div>
                        <div className="web-page-home-perks-cell-container">
                            <h3>Discreet, Quality Service</h3>
                            <p>We ensure that client information is given only on a need-to-know basis following the level of confidentiality required. Some positions are not publicly listed due to their sensitive nature.</p>
                        </div>
                        <div style={{height: 50}}></div>
                        <div className="web-page-home-perks-cell-container">
                            <h3>MLC Registered</h3>
                            <p>We are an MLC registered agency based in the UK, rest assured that we do not blacklist, discriminate against nor charge crew for access to our services.</p>
                        </div>
                    </div>
                </div>
                <div style={{height: 100}}></div>
                <div className="web-page-footer-socials-container">
                    <a href={"https://twitter.com/J4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/twitter-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.facebook.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/facebook-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.instagram.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/instagram-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.linkedin.com/company/5267269"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/linkedin-footer.svg")} alt="" />
                    </a>
                </div>
                <div className="web-page-footer-links-container" style={{ borderBottom: "1px solid rgb(225, 225, 225)" }}>
                    <p><Link to={"/terms-and-conditions"}>Terms</Link><Link to={"/privacy-policy"}>Privacy Policy</Link><Link to={"/contact-us"}>Get in Touch</Link><Link to={"/about-us"}>About Us</Link><Link to={"/mlc-2006"}>MLC 2006</Link></p>
                </div>
                <div style={{height: 10}}></div>
                <div className="web-page-footer-links-container">
                    <p>Copyright ©{new Date().getFullYear()} J4Crew LTD</p>
                </div>
                <div style={{height: 30}}></div>
            </Page>
        );

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAlert }, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(LookingForCrew);