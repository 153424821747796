import React, { Component } from "react";
import "./index.css";

/* Components */
import SelectAvailability from "../SelectAvailability";
import SelectLocation from "../SelectLocation";
import SidebarDownloads from "../SidebarDownloads";

import { SidebarMenuButton, SidebarMenuSubButton } from "../SidebarMenuButtons";

class SidebarCandidate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            downloads: false,
        };

    }

    render() {

        return(
            <div className="sidebar-candidate-container">
                <SelectAvailability />
                <div className="margin-top-20px">
                    <SelectLocation />
                </div>
                <div className="sidebar-candidate-menu-container margin-top-20px">
                    <SidebarMenuButton active={this.props.match.path === "/" || this.props.match.path.includes("/jobs")} title={"Jobs"} path={"/"} />
                    <div className="margin-top-1x">
                        <SidebarMenuButton active={this.props.match.path.includes("/profile")} title={"Profile"} expandable={true} path={"/profile"} />
                        {this.props.match.path.includes("/profile") &&
                            <>
                                <SidebarMenuSubButton active={this.props.match.path.includes("/profile") && !this.props.match.path.includes("/cv")} title={"Edit your Profile"} path={"/profile"} />
                                <SidebarMenuSubButton active={this.props.match.path === "/profile/cv"} title={"See Your J4Crew CV"} path={"/profile/cv"} />
                            </>
                        }   
                    </div>
                    <SidebarDownloads show={this.state.downloads} toggle={() => this.setState({ downloads: !this.state.downloads })} user={this.props.user.data} originalCV={this.props.user.data.profile.cv} />
                </div>
            </div>
        );

    }

}

export default SidebarCandidate;