import React from "react";
import "../../index.css";
/* import { handleLinkingMessage } from "../../data/helpers"; */

export default ({ partner, handleAddingPartner, handleConnectionUpdate }) => {

    if (!partner) {
        return(
            <div className="change-password-button" onClick={handleAddingPartner}>
                <p>Add Partner</p>
            </div>
        );
    } else if (partner.status === "linked") {
        return(
            <div className="cancel-request-button" onClick={() => handleConnectionUpdate(partner, "removed")}>
                <p>Remove Partner</p>
            </div>
        );
    } else if (partner.role === "sender") {
        return(
            <>
                <div className="reject-request-button" onClick={() => handleConnectionUpdate(partner, "declined")}>
                    <p>Reject Partner</p>
                </div>
                <div style={{width: 40}}></div>
                <div className="accept-request-button" onClick={() => handleConnectionUpdate(partner, "linked")}>
                    <p>Accept Partner</p>
                </div>
            </>
        );
    } else {
        return(
            <div className="cancel-request-button" onClick={() => handleConnectionUpdate(partner, "cancelled")}>
                <p>Cancel Request</p>
            </div>
        );
    }

}