import {
    SET_EXPERIENCE,
    RESET_EXPERIENCE,
} from "../types/experience";

export let setExperience = (data) => dispatch => {

    return dispatch({
        type: SET_EXPERIENCE,
        data,
    });
    
}

export let resetExperience = () => dispatch => {

    return dispatch({
        type: RESET_EXPERIENCE,
    });
    
}