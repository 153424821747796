import React, { Component } from "react";
import "./index.css";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";

/* Components */
import OnlineCV from "./components/OnlineCV";
import { MyDocument } from "../../../../components/CVPDF";

/* Helpers */
import { calculatePercent } from "../../../../helpers/candidate";

export default class CV extends Component {

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    generate = (document, user) => {

        let firstName = user.firstName,
            lastName = user.lastName;
    
        let blobPdf = pdf(document);
        blobPdf.updateContainer(document);
        
        return blobPdf.toBlob()
            .then(result => {
                return saveAs(result, `${firstName} ${lastName} Generated CV.pdf`);
            });
    }

    render() {

        const OnlineCVPDF = <MyDocument user={this.props.user} />;

        return(
            <div className="cv-master-container">
                <div className="cv-title-container">
                    <p className="cv-title">Your J4Crew CV</p>
                </div>
                <div className="cv-description-container">
                    <p className="cv-description">Here is your J4Crew CV, please make sure that your information is complete and correct. You can edit your profile if you need to change or add anything. We often send tailored job suggestions based on the information that you give us. Feel free to download it also and use as you wish.</p>
                </div>
                <div className="cv-description-container">
                    <p className="cv-description">Your profile completion is <span>{calculatePercent(this.props.user.profile)}%</span></p>
                </div>
                <div className="cv-description-container">
                    <p className="cv-description"><span className="cv-download-pdf" onClick={() => this.generate(OnlineCVPDF, this.props.user)}>Download this CV as a PDF</span></p>
                </div>
                <div className="margin-top-2x">
                    <div className="cv-edit-button" onClick={() => this.props.history.push("/profile")}>
                        <p className="cv-edit-button-text">Edit your profile</p>
                    </div>
                </div>
                <div className="margin-top-2x">
                    <OnlineCV user={this.props.user} />
                </div>
            </div>
        );

    }

}