import React from "react";
import "./index.css";

/* Helpers */
import { handleTimePeriod, latestFirst } from "../../helpers/date";

export default ({ propName, items, expanded, onExpand, edit, remove, archive }) => {

    return renderList(propName, items, expanded, onExpand, edit, remove, archive);

}

const renderList = (propName, items, expanded, onExpand, edit, remove, archive) => {

    let sorted = items.sort(latestFirst);

    return sorted.map((item, index) => {

        if (item.archived) return <div key={index}></div>;

        return(
            <div className="accordion-wrapper" key={index} style={{ marginBottom: index+1 < sorted.length ? 20 : 0, borderColor: expanded.includes(index) ? "#1377FF" : "#E5E5E5" }}>
                <div className="accordion-content-container">
                    <div className="accordion-content-wrapper">
                        <div className="accordion-content-expander">
                            <div className="accordion-keys-container">
                                {propName !== "references" && <span>Dates:</span>}
                                {propName === "education" && <span>College, University or High School:</span>}
                                {propName === "references" && <span>Referee Name:</span>}
                                {propName !== "education" && <span>Yacht, Ship or Company:</span>}
                                {propName !== "education" && <span>Rank</span>}
                                {propName === "references" && <span>Email:</span>}
                                {propName === "references" && <span>Phone number:</span>}
                            </div>
                            <div className="accordion-values-container">
                                {propName !== "references" && <span>{handleTimePeriod(item.fromDate, item.toDate, item.present, propName === "education")}</span>}
                                {propName === "education" && <span>{item.name}</span>}
                                {propName === "references" && <span>{item.referenceName}</span>}
                                {propName !== "education" && <span>{item.companyName}</span>}
                                {propName !== "education" && <span>{item.position}</span>}
                                {propName === "references" && <span>{item.email}</span>}
                                {propName === "references" && <span>{item.contactNumber}</span>}
                            </div>
                        </div>
                    </div>
                    {(propName === "pastRoles" || propName === "education") &&
                        <div className="accordion-expanded-container" onClick={() => onExpand(index, propName)}>
                            <p>{!expanded.includes(index) && item.description.length > 200 ? item.description.substring(0, 199)+"..." : item.description}</p>
                            {!expanded.includes(index) && item.description.length > 200 && <span>See more...</span>}
                        </div>
                    }
                </div>
                <div className="accordion-buttons-container">
                    {!item.completed &&
                        <div className="accordion-buttons-item-container">
                            <img onClick={() => edit(item, index, propName)} src={require("../../assets/svgs/edit.svg")} alt="" />
                        </div>
                    }
                    <div className="accordion-buttons-item-container">
                        <img onClick={!item.completed ? () => remove(index, propName) : () => archive(index)} src={require("../../assets/svgs/trash.svg")} alt="" />
                    </div>
                </div>
            </div>
        );
    });

}