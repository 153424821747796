import { SET_EMAIL, SET_PASSWORD, SET_LOGIN_ERROR, CLEAR_LOGINS } from "../types/login";

const initialState = {
    email: "",
    password: "",
    error: false,
    errorMessage: "",
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_EMAIL:
        
            return {
                ...state,
                email: action.data,
            };

        case SET_PASSWORD:
            
            return {
                ...state,
                password: action.data,
            };

        case SET_LOGIN_ERROR:
    
            return {
                ...state,
                error: action.status,
                errorMessage: action.message,
            };

        case CLEAR_LOGINS:

            return {
                email: "",
                password: "",
                error: false,
                errorMessage: "",
            };
        
        default:
            return state;

    }

};