import { SET_REQUESTED_EMAIL, SET_NEW_PASSWORD, PASSWORD_RESET_REQUEST_SUCCESS, PASSWORD_RESET_ERROR } from "../types/reset-password";

export let setRequestedEmail = (data) => dispatch => {

    dispatch({
        type: PASSWORD_RESET_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_REQUESTED_EMAIL,
        data,
    });
    
}

export let setNewPassword = (data) => dispatch => {

    dispatch({
        type: PASSWORD_RESET_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_NEW_PASSWORD,
        data,
    });
    
}

export let setRequestPasswordSuccess = (data) => dispatch => {

    return dispatch({
        type: PASSWORD_RESET_REQUEST_SUCCESS,
        data,
    });
    
}

export let setRequestPasswordError = (status, message) => dispatch => {

    return dispatch({
        type: PASSWORD_RESET_ERROR,
        status,
        message,
    });
    
}