
import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile, updateUserProfileLookingFor } from "../../../../redux/actions/user";
import { setIdealPosition, resetIdealPosition, setCancelData } from "../../../../redux/actions/ideal-position";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import SaveButton from "../../../../components/SaveButton";
import JobPreferences from "../../../../components/JobPreferences";
import AddIdealPosition from "../../../../components/AddIdealPosition";
import DesiredMonthlySalary from "../../../../components/DesiredMonthlySalary";

/* Constants */
import { candidateIdealJobProps } from "../../../../constants";
import Button from "../../../../components/Button";

class IdealJob extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };

    }

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        const { profile } = this.props.user.data;

        return(
            <>
                <DashboardSectionBase title={"Ideal Position"} description={"Please let us know what you are looking for."}>
                    <div className="margin-top-20px">
                        <Button title={this.state.show ? "Hide Adding Position" : "Add Ideal Position"} type={"tertiary"} onClick={() => this.setState({ show: !this.state.show })} />
                    </div>
                    {this.state.show &&
                        <AddIdealPosition hide={() => this.setState({ show: false })} />
                    }
                    <JobPreferences jobs={profile.lookingFor?.desiredRoles || []} updateUserProfileLookingFor={this.props.updateUserProfileLookingFor} setIdealPosition={this.props.setIdealPosition} show={() => this.setState({ show: true })} setCancelData={this.props.setCancelData} />
                    <div className="margin-top-2x">
                        <DesiredMonthlySalary profile={profile} updateUserProfileLookingFor={this.props.updateUserProfileLookingFor} />
                    </div>
                    {!profile.couple &&
                        <div className="margin-top-2x">
                            <p className="ideal-job-footer-text">Applying as a couple? Go to <span className="ideal-job-footer-text-link" onClick={() => this.props.history.push("/settings")}>settings</span> to connect your accounts</p>
                        </div>
                    }
                </DashboardSectionBase>
                <SaveButton propNames={candidateIdealJobProps} profile={profile} ideal={this.props.idealPosition.data} updateUserProfile={this.props.updateUserProfile} resetIdealPosition={this.props.resetIdealPosition} previous={"/profile"} next="/profile/qualifications" history={this.props.history} />
            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        idealPosition: state.idealPosition,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile, updateUserProfileLookingFor, setIdealPosition, resetIdealPosition, setCancelData }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdealJob);