import React from "react";
import "./index.css";

/* Constants */
import { checkBoxesWithStatus } from "../../constants";

export default ({ status, icon, propName, value, label, toggle, profile }) => {

    return(
        <div className="checkbox-wrapper">
            <div className="checkbox-container">
                <div className="checkbox-button-container" style={{backgroundColor: status ? "#1377FF" : "white"}} onClick={() => handleToggle(propName, value, profile, toggle)}>
                    {status &&
                        <img src={require("../../assets/svgs/checked.svg")} alt="" />
                    }
                </div>
                <div className="checkbox-label-container">
                    <p className="checkbox-label">{label}</p>
                </div>
                {icon &&
                    <div className="checkbox-icon-container">
                        <img src={icon} alt="" />
                    </div>
                }
            </div>
        </div>
    );

}

const handleToggle = (propName, value, profile, toggle) => {

    if (checkBoxesWithStatus.includes(propName)) {
        value = { ...profile[propName], status: profile?.[propName]?.status ? false : true };
    }

    if (propName === "consultant" || propName === "startDateType" || propName === "b1b2_visa"
        || propName === "c1d_visa" || propName === "schengen_visa" || propName === "greenCard_visa"
        || propName === "eng1_cert" || propName === "stcwBasic_cert" || propName === "stcwAdvanced_cert" || propName === "jobFilters") {
            return toggle(value);
    } else {
        return toggle([ { propName, value } ], true);
    }

}