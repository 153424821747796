import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
    family: "OpenSans", fonts: [
        { src: require("../../../assets/fonts/OpenSans-Regular.ttf") },
        { src: require("../../../assets/fonts/OpenSans-Bold.ttf"), fontWeight: "bold" },
    ],
});

export const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 40,
        paddingBottom: 80,
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    title: {
        fontFamily: "OpenSans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 18,
        color: "#000000",
    },
    dateOfCompletionContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    dateOfCompletion: {
        fontFamily: "OpenSans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "#000000",
    },
    refereeDetailsTitleContainer: {
        marginTop: 25,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    refereeDetailsTitle: {
        fontFamily: "OpenSans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        color: "#000000",
    },
    refereeDetailsRowContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    refereeDetailsRowText: {
        fontFamily: "OpenSans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "#000000",
    },
    refereeDetailsRowTextLink: {
        color: "#1377FF",
    },
    sectionTitleContainer: {
        marginTop: 25,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    sectionTitle: {
        fontFamily: "OpenSans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        color: "#333333",
    },
    sectionSubTitleContianer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    sectionSubTitle: {
        fontFamily: "OpenSans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "#333333",
    },
    sectionTextContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    sectionText: {
        fontFamily: "OpenSans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "#333333",
    },
    poweredContainer: {
        position: "absolute",
        bottom: 40,
        right: 40,
    },
    poweredImage: {
        height: 12,
        objectFit: "contain",
    },
});