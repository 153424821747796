import React from "react";
import DashboardSectionBase from "../../../components/DashboardSectionBase";
import TextBox from "../TextBox";
import Footer from "../Footer";
import Title from "../Title";
import CheckBox from "../CheckBox";

export default ({ title, steps, step, updateState, reasonForLeaving, extra, state }) => {

    return(
        <>
            <Title title={title} />
            <DashboardSectionBase title={"Reason for leaving"} steps={steps} step={step} overflow={true}>
                <div className="margin-top-20px">
                    <CheckBox options={["Temporary contract", "Yacht sold", "Career break", "Career Progression", "Personal Issue", "Dismissed", "Other"]} updateState={updateState} selected={reasonForLeaving} propName={"reasonForLeaving"} />
                </div>
                <div className="margin-top-20px">
                    <TextBox title={"Any further details you would like to add (Optional)"} updateState={updateState} propName={"reasonForLeavingExtra"} value={extra} />
                </div>
            </DashboardSectionBase>
            <div className="margin-top-20px">
                <Footer title={"Save & Complete"} icon={require("../../../assets/svgs/tick-white.svg")} back={true} updateState={updateState} completed={true} step={step} state={state} propNames={["reasonForLeaving"]} />
            </div>
        </>
    );

}