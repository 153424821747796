
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import TextInputDropdown from "../../../../components/TextInputDropdown";
import RegularTextInput from "../../../../components/RegularTextInput";
import SelectedItems from "../../../../components/SelectedItems";
import Categories from "../../../../components/Categories";
import SaveButton from "../../../../components/SaveButton";
import CheckBox from "../../../../components/CheckBox";

/* API requests */
import { getQualifications } from "../../../../api-requests/qualification";

/* Constants */
import { skills, equipment, candidateQualificationProps } from "../../../../constants";

class Qualifications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            qualifications_show: false,
            qualifications_keyword: "",
            skills_keyword: "",
            skills_show: false,
            equipment_keyword: "",
            equipment_show: false,
            allQualifications: [],
            allSkills: skills,
            allEquipment: equipment,
        };

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        return getQualifications()
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ allQualifications: result.data });
            });

    }

    select = (item, propName) => {

        const value = [...this.props.user.data.profile[propName], item]

        return this.props.updateUserProfile([{ propName: propName, value }], true);

    }

    render() {

        const { profile } = this.props.user.data;

        return(
            <>
                <DashboardSectionBase title={"Essential Certificates"}>
                    <div className="margin-top-20px">
                        <CheckBox label={"ENG 1"} status={profile?.eng1Info?.status} value={profile?.eng1Info} toggle={this.props.updateUserProfile} profile={profile} propName={"eng1Info"} />
                        {profile?.eng1Info?.status &&
                            <div className="margin-top-1x">
                                <RegularTextInput label={"Expiry Date"} placeholder="DD/MM/YYYY" value={profile?.eng1Info?.expiryDate || ""} onChangeText={this.props.updateUserProfile} profile={profile} propName={"eng1Info"} />
                            </div>
                        }
                    </div>
                    <div className="margin-top-20px">
                        <CheckBox label={"STCW Basic Safety Training"} status={profile?.stcwBasicInfo?.status} value={profile?.stcwBasicInfo} toggle={this.props.updateUserProfile} profile={profile} propName={"stcwBasicInfo"}  />
                        {profile?.stcwBasicInfo?.status &&
                            <div className="margin-top-1x">
                                <RegularTextInput label={"Expiry Date"} placeholder="DD/MM/YYYY" value={profile?.stcwBasicInfo?.expiryDate || ""} onChangeText={this.props.updateUserProfile} profile={profile} propName={"stcwBasicInfo"} />
                            </div>
                        }
                    </div>
                    <div className="margin-top-20px">
                        <CheckBox label={"STCW Advanced Safety Training (If Applicable)"} status={profile?.stcwAdvancedInfo?.status} value={profile?.stcwAdvancedInfo} toggle={this.props.updateUserProfile} profile={profile} propName={"stcwAdvancedInfo"} />
                        {profile?.stcwAdvancedInfo?.status &&
                            <div className="margin-top-1x">
                                <RegularTextInput label={"Expiry Date"} placeholder="DD/MM/YYYY" value={profile?.stcwAdvancedInfo?.expiryDate || ""} onChangeText={this.props.updateUserProfile} profile={profile} propName={"stcwAdvancedInfo"} />
                            </div>
                        }
                    </div>
                </DashboardSectionBase>
                <DashboardSectionBase title={"Qualifications"} description={"Search and add any relevant qualifications or choose from the dropdown categories."}>
                    <div className="margin-top-20px">
                        <TextInputDropdown icon={require("../../../../assets/svgs/search.svg")} label={"Qualifications"} placeholder={"Type..."} value={this.state.qualifications_keyword} values={profile.qualifications} options={this.state.allQualifications} onChangeText={(e) => this.setState({ qualifications_keyword: e })} propName={"qualifications"} select={this.select}  />
                    </div>
                    <SelectedItems items={profile.qualifications} propName={"qualifications"} updateUserProfile={this.props.updateUserProfile} />
                    <div className="margin-top-20px">
                        <Categories propName={"qualifications"} categories={this.state.allQualifications} selected={profile.qualifications} show={this.state.qualifications_show} select={this.select} toggle={() => this.setState({ qualifications_show: !this.state.qualifications_show })} />
                    </div>
                </DashboardSectionBase>
                <DashboardSectionBase title={"Skills"} description={"Search and add any relevant skills or choose from the dropdown categories."}>
                    <div className="margin-top-20px">
                        <TextInputDropdown icon={require("../../../../assets/svgs/search.svg")} label={"Skills"} placeholder={"Type..."} value={this.state.skills_keyword} values={profile.skills} options={this.state.allSkills} onChangeText={(e) => this.setState({ skills_keyword: e })} propName={"skills"} select={this.select}  />
                    </div>
                    <SelectedItems items={profile.skills} propName={"skills"} updateUserProfile={this.props.updateUserProfile} />
                    <div className="margin-top-20px">
                        <Categories propName="skills" categories={this.state.allSkills} selected={profile.skills} show={this.state.skills_show} select={this.select} toggle={() => this.setState({ skills_show: !this.state.skills_show })} />
                    </div>
                </DashboardSectionBase>
                <DashboardSectionBase title={"Equipment Knowledge"} description={"Search and add any relevant knowledge of equipment. This section is mainly for engineers & ETO’s."}>
                    <div className="margin-top-20px">
                        <TextInputDropdown icon={require("../../../../assets/svgs/search.svg")} label={"Equipment Knowledge"} placeholder={"Type..."} value={this.state.equipment_keyword} values={profile.equipment} options={this.state.allEquipment} onChangeText={(e) => this.setState({ equipment_keyword: e })} propName={"equipment"} select={this.select}  />
                    </div>
                    <SelectedItems items={profile.equipment} propName={"equipment"} updateUserProfile={this.props.updateUserProfile} />
                    <div className="margin-top-20px">
                        <Categories propName="equipment" categories={this.state.allEquipment} selected={profile.equipment} show={this.state.equipment_show} select={this.select} toggle={() => this.setState({ equipment_show: !this.state.equipment_show })} />
                    </div>
                </DashboardSectionBase>
                <SaveButton propNames={candidateQualificationProps} profile={profile} updateUserProfile={this.props.updateUserProfile} previous={"/profile/ideal-job"} next="/profile/experience" history={this.props.history} />
            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        idealPosition: state.idealPosition,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Qualifications);