import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./index.css";

/* Actions */
import { setCrew } from "../../../../redux/actions/admin";

/* Components */
import UsersListHeader from "../../../../components/UsersListHeader";
import UsersList from "../../../../components/UsersList";

/* API requests */
import { getCrew, searchCrew } from "../../../../api-requests/admin";

class Crew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: "",
            searchResults: [],
            isFetchingSearchResults: false,
        };

        this.searchTimeOut = null;

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        return getCrew()
            .then(data => {
                return this.props.setCrew(data);
            });

    }

    handleSearch = (keyword) => {

        return this.setState({ keyword }, () => {
            if (keyword.length >= 5) {
                return this.setState({ isFetchingSearchResults: true }, () => {

                    if (this.searchTimeOut) {  
                        clearTimeout(this.searchTimeOut);
                    }

                    this.searchTimeOut = setTimeout(() => {
                        return searchCrew(keyword)
                            .then(result => {
                                return this.setState({ searchResults: result.users, isFetchingSearchResults: false });
                            });
                    }, 1000);

                });
            }
            return this.setState({ searchResults: [], isFetchingSearchResults: false });
        });

    }

    handleUsersList = (crew, searchResults, keyword) => {

        if (searchResults.length > 0 && keyword.length >= 5) return searchResults;
        return crew;

    }

    render() {

        return(
            <div className="crew-container">
                <div className="crew-header-container">
                    <div className="crew-header-items-container">
                        <p className="crew-header-items-title">Crew Accounts</p>
                    </div>
                    <div className="crew-header-search-container">
                        {this.state.isFetchingSearchResults && 
                            <div className="sign-ups-header-search-loading-container">
                                <img src={require("../../../../assets/animations/blue-spinner.svg")} alt="" />
                            </div>
                        }
                        <div className="crew-header-search-input-container">
                            <input type={"text"} placeholder={"Start typing..."} value={this.state.keyword} onChange={(e) => this.handleSearch(e.target.value)} />
                        </div>
                        <div className="crew-header-search-icon-container">
                            <img src={require("../../../../assets/svgs/search.svg")} alt="" />
                        </div>
                    </div>
                </div>
                <UsersListHeader
                    status={false}
                    active={true}
                    col1={"Basic Details"}
                    col2={"Last Active"}
                    col3={"Signed Up"}
                    col4={"Profile"}
                    col5={"Status"}
                />
                {this.state.keyword.length > 4 && !this.state.isFetchingSearchResults && this.state.searchResults.length < 1 &&
                    <p className="no-results-found-description">No crew members were found matching your search request.</p>
                }
                {this.state.keyword.length > 4 && !this.state.isFetchingSearchResults && this.state.searchResults.length > 0 &&
                    <UsersList
                        type={"crew"}
                        status={false}
                        active={true}
                        removeCandidateFromTheList={this.props.removeCandidateFromTheList}
                        isFetching={this.props.admin.isFetchingCrew}
                        users={this.handleUsersList(this.props.admin.crew, this.state.searchResults, this.state.keyword)}
                        isFetchingSearchResults={this.state.isFetchingSearchResults}
                    />
                }
                {this.state.keyword.length < 5 &&
                    <>
                        <UsersList
                            type={"crew"}
                            status={false}
                            active={true}
                            removeCandidateFromTheList={this.props.removeCandidateFromTheList}
                            isFetching={this.props.admin.isFetchingCrew}
                            users={this.handleUsersList(this.props.admin.crew, this.state.searchResults, this.state.keyword)}
                            isFetchingSearchResults={this.state.isFetchingSearchResults}
                        />
                        {/* <div className="load-more-sign-ups-container">
                            <p>Load More Sign Ups</p>
                        </div> */}
                    </>
                }
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setCrew }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Crew);