import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

/* Actions */
import { setAuth, setUserData } from "../../../../redux/actions/user";
import { setLoginEmail, setLoginPassword, setLoginError, clearLogins } from "../../../../redux/actions/login";

/* Components */
import TextInputWithIcon from "../../../../components/TextInputWithIcon";
import Button from "../../../../components/Button";

/* API requests */
import { loginUser } from "../../../../api-requests/user";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

    }

    handleLogin = (email, password) => {

        return this.setState({ loading: true }, () => {
            if (email && password) {
                return loginUser(email, password)
                    .then(result => {                        
                        if (result.user) {
                            this.props.clearLogins();
                            this.props.setUserData(result.user);
                            this.props.setAuth(true);
                            return this.props.history.push("/");
                        } else {
                            return this.setState({ loading: false }, () => {
                                return this.props.setLoginError(true, "Email or password is invalid. Please try again.");
                            });
                        }
                    });
            }
            return this.setState({ loading: false }, () => {
                return this.props.setLoginError(true, "Both fields are required. Please try again.");
            });
        });

    }

    handleKeyPress = (e, email, password) => {

        if (e.key === "Enter") {
            return this.handleLogin(email, password);
        }
        return;

    }

    render() {

        return(
            <div className="login-form-container" tabIndex={0} onKeyPress={(e) => this.handleKeyPress(e, this.props.login.email, this.props.login.password)}>
                <div className="login-form-header-container">
                    <span className="login-form-header-title">Log In</span>
                    <span className="login-form-header-sidenote">Don't Have an Account? <Link to={"/sign-up"} className="login-form-header-sidenote-link">Sign Up</Link></span>
                </div>
                <div className="margin-top-2x">
                    <TextInputWithIcon value={this.props.login.email} onChangeText={this.props.setLoginEmail} icon={require("../../../../assets/svgs/mail.svg")} placeholder={"Your Email"} hide={false} error={this.props.login.error} />
                </div>
                <div className="margin-top-2x">
                    <TextInputWithIcon value={this.props.login.password} onChangeText={this.props.setLoginPassword} icon={require("../../../../assets/svgs/password.svg")} placeholder={"Password"} hide={true} error={this.props.login.error} />
                </div>
                {this.props.login.error && this.props.login.errorMessage &&
                    <div className="login-form-error-container">
                        <p className="login-form-error-text">{this.props.login.errorMessage}</p>
                    </div>
                }
                <div className={!this.props.login.error ? "margin-top-10px" : ""}>
                    <Link className="forgot-password-link" to={"/reset-password"}>Forgot Password</Link>
                </div>
                <div className="margin-top-2x">
                    <Button onClick={() => this.handleLogin(this.props.login.email, this.props.login.password)} loading={this.state.loading} title={"Log In"} type={"primary"} />
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        login: state.login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAuth, setUserData, setLoginEmail, setLoginPassword, setLoginError, clearLogins }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);