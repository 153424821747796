/* List of propnames in 'Details' tab */
export const candidateDetailsProps = [
    "dob",
    "gender",
    "height",
    "languages",
    "nationality",
    "country",
    "smoking",
    "tattoo",
    "relationshipStatus",
    "b1b2",
    "c1d",
    "schengen",
    "greenCard",
    "primaryPhone",
    "primaryPhoneWhatsApp",
    "secondaryPhone",
    "secondaryPhoneWhatsApp",
    "aboutMe",
    "hobbies",
];

/* List of propnames in 'Ideal Job' tab */
export const candidateIdealJobProps = [
    "lookingFor",
];

/* List of propnames in 'Qualifications' tab */
export const candidateQualificationProps = [
    "eng1Info",
    "stcwBasicInfo",
    "stcwAdvancedInfo",
    "qualifications",
    "skills",
    "equipment",
];

/* List of propnames in 'Experience' tab */
export const candidateExperienceProps = [
    "pastRoles",
    "education",
];

/* List of propnames in 'References' tab */
export const candidateReferencesProps = [
    "references",
];

export const checkBoxesWithStatus = [
    "b1b2",
    "c1d",
    "schengen",
    "greenCard",
    "eng1Info",
    "stcwBasicInfo",
    "stcwAdvancedInfo",
];

/* Menu for candidate's profile header (~/components/CandidateHeader) */
export const candidateProfileHeaderLinks = [
    {
        name: "Details",
        path: "/profile",
    },
    {
        name: "Ideal Job",
        path: "/profile/ideal-job",
    },
    {
        name: "Qualifications",
        path: "/profile/qualifications",
    },
    {
        name: "Experience",
        path: "/profile/experience",
    },
    {
        name: "References",
        path: "/profile/references",
    },
    {
        name: "Documents",
        path: "/profile/documents",
    },
];

/* Menu for candidate's jobs header (~/components/CandidateHeader) */
export const candidateJobsHeaderLinks = [
    {
        name: "Jobs",
        path: "/",
    },
    {
        name: "Active Applications",
        path: "/jobs/applications",
    },
];

/* Array of spoken languages */
export const spokenLanguages = [
    {
        "code": "en",
        "value": "English",
        "label": "English",
        "nativeName": "English"
    },
    {
        "code": "fr",
        "value": "French",
        "label": "French",
        "nativeName": "français, langue française"
    },
    {
        "code": "es",
        "value": "Spanish",
        "label": "Spanish",
        "nativeName": "español, castellano"
    },
    {
        "code": "ru",
        "value": "Russian",
        "label": "Russian",
        "nativeName": "русский язык"
    },
    {
        "code": "pt",
        "value": "Portuguese",
        "label": "Portuguese",
        "nativeName": "Português"
    },
    {
        "code": "zh",
        "value": "Chinese",
        "label": "Chinese",
        "nativeName": "中文 (Zhōngwén), 汉语, 漢語"
    },

    {
        "code": "ab",
        "value": "Abkhaz",
        "label": "Abkhaz",
        "nativeName": "аҧсуа"
    },
    {
        "code": "aa",
        "value": "Afar",
        "label": "Afar",
        "nativeName": "Afaraf"
    },
    {
        "code": "af",
        "value": "Afrikaans",
        "label": "Afrikaans",
        "nativeName": "Afrikaans"
    },
    {
        "code": "ak",
        "value": "Akan",
        "label": "Akan",
        "nativeName": "Akan"
    },
    {
        "code": "sq",
        "value": "Albanian",
        "label": "Albanian",
        "nativeName": "Shqip"
    },
    {
        "code": "am",
        "value": "Amharic",
        "label": "Amharic",
        "nativeName": "አማርኛ"
    },
    {
        "code": "ar",
        "value": "Arabic",
        "label": "Arabic",
        "nativeName": "العربية"
    },
    {
        "code": "an",
        "value": "Aragonese",
        "label": "Aragonese",
        "nativeName": "Aragonés"
    },
    {
        "code": "hy",
        "value": "Armenian",
        "label": "Armenian",
        "nativeName": "Հայերեն"
    },
    {
        "code": "as",
        "value": "Assamese",
        "label": "Assamese",
        "nativeName": "অসমীয়া"
    },
    {
        "code": "av",
        "value": "Avaric",
        "label": "Avaric",
        "nativeName": "авар мацӀ, магӀарул мацӀ"
    },
    {
        "code": "ae",
        "value": "Avestan",
        "label": "Avestan",
        "nativeName": "avesta"
    },
    {
        "code": "ay",
        "value": "Aymara",
        "label": "Aymara",
        "nativeName": "aymar aru"
    },
    {
        "code": "az",
        "value": "Azerbaijani",
        "label": "Azerbaijani",
        "nativeName": "azərbaycan dili"
    },
    {
        "code": "bm",
        "value": "Bambara",
        "label": "Bambara",
        "nativeName": "bamanankan"
    },
    {
        "code": "ba",
        "value": "Bashkir",
        "label": "Bashkir",
        "nativeName": "башҡорт теле"
    },
    {
        "code": "eu",
        "value": "Basque",
        "label": "Basque",
        "nativeName": "euskara, euskera"
    },
    {
        "code": "be",
        "value": "Belarusian",
        "label": "Belarusian",
        "nativeName": "Беларуская"
    },
    {
        "code": "bn",
        "value": "Bengali",
        "label": "Bengali",
        "nativeName": "বাংলা"
    },
    {
        "code": "bh",
        "value": "Bihari",
        "label": "Bihari",
        "nativeName": "भोजपुरी"
    },
    {
        "code": "bi",
        "value": "Bislama",
        "label": "Bislama",
        "nativeName": "Bislama"
    },
    {
        "code": "bs",
        "value": "Bosnian",
        "label": "Bosnian",
        "nativeName": "bosanski jezik"
    },
    {
        "code": "br",
        "value": "Breton",
        "label": "Breton",
        "nativeName": "brezhoneg"
    },
    {
        "code": "bg",
        "value": "Bulgarian",
        "label": "Bulgarian",
        "nativeName": "български език"
    },
    {
        "code": "my",
        "value": "Burmese",
        "label": "Burmese",
        "nativeName": "ဗမာစာ"
    },
    {
        "code": "ca",
        "value": "Catalan; Valencian",
        "label": "Catalan; Valencian",
        "nativeName": "Català"
    },
    {
        "code": "ch",
        "value": "Chamorro",
        "label": "Chamorro",
        "nativeName": "Chamoru"
    },
    {
        "code": "ce",
        "value": "Chechen",
        "label": "Chechen",
        "nativeName": "нохчийн мотт"
    },
    {
        "code": "ny",
        "value": "Chichewa; Chewa; Nyanja",
        "label": "Chichewa; Chewa; Nyanja",
        "nativeName": "chiCheŵa, chinyanja"
    },
    {
        "code": "cv",
        "value": "Chuvash",
        "label": "Chuvash",
        "nativeName": "чӑваш чӗлхи"
    },
    {
        "code": "kw",
        "value": "Cornish",
        "label": "Cornish",
        "nativeName": "Kernewek"
    },
    {
        "code": "co",
        "value": "Corsican",
        "label": "Corsican",
        "nativeName": "corsu, lingua corsa"
    },
    {
        "code": "cr",
        "value": "Cree",
        "label": "Cree",
        "nativeName": "ᓀᐦᐃᔭᐍᐏᐣ"
    },
    {
        "code": "hr",
        "value": "Croatian",
        "label": "Croatian",
        "nativeName": "hrvatski"
    },
    {
        "code": "cs",
        "value": "Czech",
        "label": "Czech",
        "nativeName": "česky, čeština"
    },
    {
        "code": "da",
        "value": "Danish",
        "label": "Danish",
        "nativeName": "dansk"
    },
    {
        "code": "dv",
        "value": "Divehi; Dhivehi; Maldivian;",
        "label": "Divehi; Dhivehi; Maldivian;",
        "nativeName": "ދިވެހި"
    },
    {
        "code": "nl",
        "value": "Dutch",
        "label": "Dutch",
        "nativeName": "Nederlands, Vlaams"
    },
    {
        "code": "eo",
        "value": "Esperanto",
        "label": "Esperanto",
        "nativeName": "Esperanto"
    },
    {
        "code": "et",
        "value": "Estonian",
        "label": "Estonian",
        "nativeName": "eesti, eesti keel"
    },
    {
        "code": "ee",
        "value": "Ewe",
        "label": "Ewe",
        "nativeName": "Eʋegbe"
    },
    {
        "code": "fo",
        "value": "Faroese",
        "label": "Faroese",
        "nativeName": "føroyskt"
    },
    {
        "code": "fj",
        "value": "Fijian",
        "label": "Fijian",
        "nativeName": "vosa Vakaviti"
    },
    {
        "code": "fi",
        "value": "Finnish",
        "label": "Finnish",
        "nativeName": "suomi, suomen kieli"
    },

    {
        "code": "ff",
        "value": "Fula; Fulah; Pulaar; Pular",
        "label": "Fula; Fulah; Pulaar; Pular",
        "nativeName": "Fulfulde, Pulaar, Pular"
    },
    {
        "code": "gl",
        "value": "Galician",
        "label": "Galician",
        "nativeName": "Galego"
    },
    {
        "code": "ka",
        "value": "Georgian",
        "label": "Georgian",
        "nativeName": "ქართული"
    },
    {
        "code": "de",
        "value": "German",
        "label": "German",
        "nativeName": "Deutsch"
    },
    {
        "code": "el",
        "value": "Greek, Modern",
        "label": "Greek, Modern",
        "nativeName": "Ελληνικά"
    },
    {
        "code": "gn",
        "value": "Guaraní",
        "label": "Guaraní",
        "nativeName": "Avañeẽ"
    },
    {
        "code": "gu",
        "value": "Gujarati",
        "label": "Gujarati",
        "nativeName": "ગુજરાતી"
    },
    {
        "code": "ht",
        "value": "Haitian; Haitian Creole",
        "label": "Haitian; Haitian Creole",
        "nativeName": "Kreyòl ayisyen"
    },
    {
        "code": "ha",
        "value": "Hausa",
        "label": "Hausa",
        "nativeName": "Hausa, هَوُسَ"
    },
    {
        "code": "he",
        "value": "Hebrew (modern)",
        "label": "Hebrew (modern)",
        "nativeName": "עברית"
    },
    {
        "code": "hz",
        "value": "Herero",
        "label": "Herero",
        "nativeName": "Otjiherero"
    },
    {
        "code": "hi",
        "value": "Hindi",
        "label": "Hindi",
        "nativeName": "हिन्दी, हिंदी"
    },
    {
        "code": "ho",
        "value": "Hiri Motu",
        "label": "Hiri Motu",
        "nativeName": "Hiri Motu"
    },
    {
        "code": "hu",
        "value": "Hungarian",
        "label": "Hungarian",
        "nativeName": "Magyar"
    },
    {
        "code": "ia",
        "value": "Interlingua",
        "label": "Interlingua",
        "nativeName": "Interlingua"
    },
    {
        "code": "id",
        "value": "Indonesian",
        "label": "Indonesian",
        "nativeName": "Bahasa Indonesia"
    },
    {
        "code": "ie",
        "value": "Interlingue",
        "label": "Interlingue",
        "nativeName": "Originally called Occidental; then Interlingue after WWII"
    },
    {
        "code": "ga",
        "value": "Irish",
        "label": "Irish",
        "nativeName": "Gaeilge"
    },
    {
        "code": "ig",
        "value": "Igbo",
        "label": "Igbo",
        "nativeName": "Asụsụ Igbo"
    },
    {
        "code": "ik",
        "value": "Inupiaq",
        "label": "Inupiaq",
        "nativeName": "Iñupiaq, Iñupiatun"
    },
    {
        "code": "io",
        "value": "Ido",
        "label": "Ido",
        "nativeName": "Ido"
    },
    {
        "code": "is",
        "value": "Icelandic",
        "label": "Icelandic",
        "nativeName": "Íslenska"
    },
    {
        "code": "it",
        "value": "Italian",
        "label": "Italian",
        "nativeName": "Italiano"
    },
    {
        "code": "iu",
        "value": "Inuktitut",
        "label": "Inuktitut",
        "nativeName": "ᐃᓄᒃᑎᑐᑦ"
    },
    {
        "code": "ja",
        "value": "Japanese",
        "label": "Japanese",
        "nativeName": "日本語 (にほんご／にっぽんご)"
    },
    {
        "code": "jv",
        "value": "Javanese",
        "label": "Javanese",
        "nativeName": "basa Jawa"
    },
    {
        "code": "kl",
        "value": "Kalaallisut, Greenlandic",
        "label": "Kalaallisut, Greenlandic",
        "nativeName": "kalaallisut, kalaallit oqaasii"
    },
    {
        "code": "kn",
        "value": "Kannada",
        "label": "Kannada",
        "nativeName": "ಕನ್ನಡ"
    },
    {
        "code": "kr",
        "value": "Kanuri",
        "label": "Kanuri",
        "nativeName": "Kanuri"
    },
    {
        "code": "ks",
        "value": "Kashmiri",
        "label": "Kashmiri",
        "nativeName": "कश्मीरी, كشميري‎"
    },
    {
        "code": "kk",
        "value": "Kazakh",
        "label": "Kazakh",
        "nativeName": "Қазақ тілі"
    },
    {
        "code": "km",
        "value": "Khmer",
        "label": "Khmer",
        "nativeName": "ភាសាខ្មែរ"
    },
    {
        "code": "ki",
        "value": "Kikuyu, Gikuyu",
        "label": "Kikuyu, Gikuyu",
        "nativeName": "Gĩkũyũ"
    },
    {
        "code": "rw",
        "value": "Kinyarwanda",
        "label": "Kinyarwanda",
        "nativeName": "Ikinyarwanda"
    },
    {
        "code": "ky",
        "value": "Kirghiz, Kyrgyz",
        "label": "Kirghiz, Kyrgyz",
        "nativeName": "кыргыз тили"
    },
    {
        "code": "kv",
        "value": "Komi",
        "label": "Komi",
        "nativeName": "коми кыв"
    },
    {
        "code": "kg",
        "value": "Kongo",
        "label": "Kongo",
        "nativeName": "KiKongo"
    },
    {
        "code": "ko",
        "value": "Korean",
        "label": "Korean",
        "nativeName": "한국어 (韓國語), 조선말 (朝鮮語)"
    },
    {
        "code": "ku",
        "value": "Kurdish",
        "label": "Kurdish",
        "nativeName": "Kurdî, كوردی‎"
    },
    {
        "code": "kj",
        "value": "Kwanyama, Kuanyama",
        "label": "Kwanyama, Kuanyama",
        "nativeName": "Kuanyama"
    },
    {
        "code": "la",
        "value": "Latin",
        "label": "Latin",
        "nativeName": "latine, lingua latina"
    },
    {
        "code": "lb",
        "value": "Luxembourgish, Letzeburgesch",
        "label": "Luxembourgish, Letzeburgesch",
        "nativeName": "Lëtzebuergesch"
    },
    {
        "code": "lg",
        "value": "Luganda",
        "label": "Luganda",
        "nativeName": "Luganda"
    },
    {
        "code": "li",
        "value": "Limburgish, Limburgan, Limburger",
        "label": "Limburgish, Limburgan, Limburger",
        "nativeName": "Limburgs"
    },
    {
        "code": "ln",
        "value": "Lingala",
        "label": "Lingala",
        "nativeName": "Lingála"
    },
    {
        "code": "lo",
        "value": "Lao",
        "label": "Lao",
        "nativeName": "ພາສາລາວ"
    },
    {
        "code": "lt",
        "value": "Lithuanian",
        "label": "Lithuanian",
        "nativeName": "lietuvių kalba"
    },
    {
        "code": "lu",
        "value": "Luba-Katanga",
        "label": "Luba-Katanga",
        "nativeName": ""
    },
    {
        "code": "lv",
        "value": "Latvian",
        "label": "Latvian",
        "nativeName": "latviešu valoda"
    },
    {
        "code": "gv",
        "value": "Manx",
        "label": "Manx",
        "nativeName": "Gaelg, Gailck"
    },
    {
        "code": "mk",
        "value": "Macedonian",
        "label": "Macedonian",
        "nativeName": "македонски јазик"
    },
    {
        "code": "mg",
        "value": "Malagasy",
        "label": "Malagasy",
        "nativeName": "Malagasy fiteny"
    },
    {
        "code": "ms",
        "value": "Malay",
        "label": "Malay",
        "nativeName": "bahasa Melayu, بهاس ملايو‎"
    },
    {
        "code": "ml",
        "value": "Malayalam",
        "label": "Malayalam",
        "nativeName": "മലയാളം"
    },
    {
        "code": "mt",
        "value": "Maltese",
        "label": "Maltese",
        "nativeName": "Malti"
    },
    {
        "code": "mi",
        "value": "Māori",
        "label": "Māori",
        "nativeName": "te reo Māori"
    },
    {
        "code": "mr",
        "value": "Marathi (Marāṭhī)",
        "label": "Marathi (Marāṭhī)",
        "nativeName": "मराठी"
    },
    {
        "code": "mh",
        "value": "Marshallese",
        "label": "Marshallese",
        "nativeName": "Kajin M̧ajeļ"
    },
    {
        "code": "mn",
        "value": "Mongolian",
        "label": "Mongolian",
        "nativeName": "монгол"
    },
    {
        "code": "na",
        "value": "Nauru",
        "label": "Nauru",
        "nativeName": "Ekakairũ Naoero"
    },
    {
        "code": "nv",
        "value": "Navajo, Navaho",
        "label": "Navajo, Navaho",
        "nativeName": "Diné bizaad, Dinékʼehǰí"
    },
    {
        "code": "nb",
        "value": "Norwegian Bokmål",
        "label": "Norwegian Bokmål",
        "nativeName": "Norsk bokmål"
    },
    {
        "code": "nd",
        "value": "North Ndebele",
        "label": "North Ndebele",
        "nativeName": "isiNdebele"
    },
    {
        "code": "ne",
        "value": "Nepali",
        "label": "Nepali",
        "nativeName": "नेपाली"
    },
    {
        "code": "ng",
        "value": "Ndonga",
        "label": "Ndonga",
        "nativeName": "Owambo"
    },
    {
        "code": "nn",
        "value": "Norwegian Nynorsk",
        "label": "Norwegian Nynorsk",
        "nativeName": "Norsk nynorsk"
    },
    {
        "code": "no",
        "value": "Norwegian",
        "label": "Norwegian",
        "nativeName": "Norsk"
    },
    {
        "code": "ii",
        "value": "Nuosu",
        "label": "Nuosu",
        "nativeName": "ꆈꌠ꒿ Nuosuhxop"
    },
    {
        "code": "nr",
        "value": "South Ndebele",
        "label": "South Ndebele",
        "nativeName": "isiNdebele"
    },
    {
        "code": "oc",
        "value": "Occitan",
        "label": "Occitan",
        "nativeName": "Occitan"
    },
    {
        "code": "oj",
        "value": "Ojibwe, Ojibwa",
        "label": "Ojibwe, Ojibwa",
        "nativeName": "ᐊᓂᔑᓈᐯᒧᐎᓐ"
    },
    {
        "code": "cu",
        "value": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
        "label": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
        "nativeName": "ѩзыкъ словѣньскъ"
    },
    {
        "code": "om",
        "value": "Oromo",
        "label": "Oromo",
        "nativeName": "Afaan Oromoo"
    },
    {
        "code": "or",
        "value": "Oriya",
        "label": "Oriya",
        "nativeName": "ଓଡ଼ିଆ"
    },
    {
        "code": "os",
        "value": "Ossetian, Ossetic",
        "label": "Ossetian, Ossetic",
        "nativeName": "ирон æвзаг"
    },
    {
        "code": "pa",
        "value": "Panjabi, Punjabi",
        "label": "Panjabi, Punjabi",
        "nativeName": "ਪੰਜਾਬੀ, پنجابی‎"
    },
    {
        "code": "pi",
        "value": "Pāli",
        "label": "Pāli",
        "nativeName": "पाऴि"
    },
    {
        "code": "fa",
        "value": "Persian",
        "label": "Persian",
        "nativeName": "فارسی"
    },
    {
        "code": "pl",
        "value": "Polish",
        "label": "Polish",
        "nativeName": "polski"
    },
    {
        "code": "ps",
        "value": "Pashto, Pushto",
        "label": "Pashto, Pushto",
        "nativeName": "پښتو"
    },

    {
        "code": "qu",
        "value": "Quechua",
        "label": "Quechua",
        "nativeName": "Runa Simi, Kichwa"
    },
    {
        "code": "rm",
        "value": "Romansh",
        "label": "Romansh",
        "nativeName": "rumantsch grischun"
    },
    {
        "code": "rn",
        "value": "Kirundi",
        "label": "Kirundi",
        "nativeName": "kiRundi"
    },
    {
        "code": "ro",
        "value": "Romanian, Moldavian, Moldovan",
        "label": "Romanian, Moldavian, Moldovan",
        "nativeName": "română"
    },
    {
        "code": "sa",
        "value": "Sanskrit (Saṁskṛta)",
        "label": "Sanskrit (Saṁskṛta)",
        "nativeName": "संस्कृतम्"
    },
    {
        "code": "sc",
        "value": "Sardinian",
        "label": "Sardinian",
        "nativeName": "sardu"
    },
    {
        "code": "sd",
        "value": "Sindhi",
        "label": "Sindhi",
        "nativeName": "सिन्धी, سنڌي، سندھی‎"
    },
    {
        "code": "se",
        "value": "Northern Sami",
        "label": "Northern Sami",
        "nativeName": "Davvisámegiella"
    },
    {
        "code": "sm",
        "value": "Samoan",
        "label": "Samoan",
        "nativeName": "gagana faa Samoa"
    },
    {
        "code": "sg",
        "value": "Sango",
        "label": "Sango",
        "nativeName": "yângâ tî sängö"
    },
    {
        "code": "sr",
        "value": "Serbian",
        "label": "Serbian",
        "nativeName": "српски језик"
    },
    {
        "code": "gd",
        "value": "Scottish Gaelic; Gaelic",
        "label": "Scottish Gaelic; Gaelic",
        "nativeName": "Gàidhlig"
    },
    {
        "code": "sn",
        "value": "Shona",
        "label": "Shona",
        "nativeName": "chiShona"
    },
    {
        "code": "si",
        "value": "Sinhala, Sinhalese",
        "label": "Sinhala, Sinhalese",
        "nativeName": "සිංහල"
    },
    {
        "code": "sk",
        "value": "Slovak",
        "label": "Slovak",
        "nativeName": "slovenčina"
    },
    {
        "code": "sl",
        "value": "Slovene",
        "label": "Slovene",
        "nativeName": "slovenščina"
    },
    {
        "code": "so",
        "value": "Somali",
        "label": "Somali",
        "nativeName": "Soomaaliga, af Soomaali"
    },
    {
        "code": "st",
        "value": "Southern Sotho",
        "label": "Southern Sotho",
        "nativeName": "Sesotho"
    },
    {
        "code": "su",
        "value": "Sundanese",
        "label": "Sundanese",
        "nativeName": "Basa Sunda"
    },
    {
        "code": "sw",
        "value": "Swahili",
        "label": "Swahili",
        "nativeName": "Kiswahili"
    },
    {
        "code": "ss",
        "value": "Swati",
        "label": "Swati",
        "nativeName": "SiSwati"
    },
    {
        "code": "sv",
        "value": "Swedish",
        "label": "Swedish",
        "nativeName": "svenska"
    },
    {
        "code": "ta",
        "value": "Tamil",
        "label": "Tamil",
        "nativeName": "தமிழ்"
    },
    {
        "code": "te",
        "value": "Telugu",
        "label": "Telugu",
        "nativeName": "తెలుగు"
    },
    {
        "code": "tg",
        "value": "Tajik",
        "label": "Tajik",
        "nativeName": "тоҷикӣ, toğikī, تاجیکی‎"
    },
    {
        "code": "th",
        "value": "Thai",
        "label": "Thai",
        "nativeName": "ไทย"
    },
    {
        "code": "ti",
        "value": "Tigrinya",
        "label": "Tigrinya",
        "nativeName": "ትግርኛ"
    },
    {
        "code": "bo",
        "value": "Tibetan Standard, Tibetan, Central",
        "label": "Tibetan Standard, Tibetan, Central",
        "nativeName": "བོད་ཡིག"
    },
    {
        "code": "tk",
        "value": "Turkmen",
        "label": "Turkmen",
        "nativeName": "Türkmen, Түркмен"
    },
    {
        "code": "tl",
        "value": "Tagalog",
        "label": "Tagalog",
        "nativeName": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
    },
    {
        "code": "tn",
        "value": "Tswana",
        "label": "Tswana",
        "nativeName": "Setswana"
    },
    {
        "code": "to",
        "value": "Tonga (Tonga Islands)",
        "label": "Tonga (Tonga Islands)",
        "nativeName": "faka Tonga"
    },
    {
        "code": "tr",
        "value": "Turkish",
        "label": "Turkish",
        "nativeName": "Türkçe"
    },
    {
        "code": "ts",
        "value": "Tsonga",
        "label": "Tsonga",
        "nativeName": "Xitsonga"
    },
    {
        "code": "tt",
        "value": "Tatar",
        "label": "Tatar",
        "nativeName": "татарча, tatarça, تاتارچا‎"
    },
    {
        "code": "tw",
        "value": "Twi",
        "label": "Twi",
        "nativeName": "Twi"
    },
    {
        "code": "ty",
        "value": "Tahitian",
        "label": "Tahitian",
        "nativeName": "Reo Tahiti"
    },
    {
        "code": "ug",
        "value": "Uighur, Uyghur",
        "label": "Uighur, Uyghur",
        "nativeName": "Uyƣurqə, ئۇيغۇرچە‎"
    },
    {
        "code": "uk",
        "value": "Ukrainian",
        "label": "Ukrainian",
        "nativeName": "українська"
    },
    {
        "code": "ur",
        "value": "Urdu",
        "label": "Urdu",
        "nativeName": "اردو"
    },
    {
        "code": "uz",
        "value": "Uzbek",
        "label": "Uzbek",
        "nativeName": "zbek, Ўзбек, أۇزبېك‎"
    },
    {
        "code": "ve",
        "value": "Venda",
        "label": "Venda",
        "nativeName": "Tshivenḓa"
    },
    {
        "code": "vi",
        "value": "Vietnamese",
        "label": "Vietnamese",
        "nativeName": "Tiếng Việt"
    },
    {
        "code": "vo",
        "value": "Volapük",
        "label": "Volapük",
        "nativeName": "Volapük"
    },
    {
        "code": "wa",
        "value": "Walloon",
        "label": "Walloon",
        "nativeName": "Walon"
    },
    {
        "code": "cy",
        "value": "Welsh",
        "label": "Welsh",
        "nativeName": "Cymraeg"
    },
    {
        "code": "wo",
        "value": "Wolof",
        "label": "Wolof",
        "nativeName": "Wollof"
    },
    {
        "code": "fy",
        "value": "Western Frisian",
        "label": "Western Frisian",
        "nativeName": "Frysk"
    },
    {
        "code": "xh",
        "value": "Xhosa",
        "label": "Xhosa",
        "nativeName": "isiXhosa"
    },
    {
        "code": "yi",
        "value": "Yiddish",
        "label": "Yiddish",
        "nativeName": "ייִדיש"
    },
    {
        "code": "yo",
        "value": "Yoruba",
        "label": "Yoruba",
        "nativeName": "Yorùbá"
    },
    {
        "code": "za",
        "value": "Zhuang, Chuang",
        "label": "Zhuang, Chuang",
        "nativeName": "Saɯ cueŋƅ, Saw cuengh"
    }
];

/* List of countries */
export const countries = [{
        "code": "GB",
        "label": "United Kingdom",
        "value": "United Kingdom"
    },
    {
        "code": "NZ",
        "label": "New Zealand",
        "value": "New Zealand"
    },
    {
        "code": "AU",
        "label": "Australia",
        "value": "Australia"
    },
    {
        "code": "ZA",
        "label": "South Africa",
        "value": "South Africa"
    },
    {
        "code": "US",
        "label": "United States of America",
        "value": "United States of America"
    },
    {
        "code": "CA",
        "label": "Canada",
        "value": "Canada"
    },
    {
        "code": "HR",
        "label": "Croatia",
        "value": "Croatia"
    },
    {
        "code": "DE",
        "label": "Germany",
        "value": "Germany"
    },
    {
        "code": "FR",
        "label": "France",
        "value": "France"
    },
    {
        "code": "ES",
        "label": "Spain",
        "value": "Spain"
    },
    {
        "code": "PT",
        "label": "Portugal",
        "value": "Portugal"
    },
    {
        "code": "IT",
        "label": "Italy",
        "value": "Italy"
    },
    {
        "code": "SE",
        "label": "Sweden",
        "value": "Sweden"
    },
    {
        "code": "PL",
        "label": "Poland",
        "value": "Poland"
    },
    {
        "code": "DK",
        "label": "Denmark",
        "value": "Denmark"
    },
    {
        "code": "PH",
        "label": "Philippines",
        "value": "Philippines"
    },

    {
        "code": "AF",
        "label": "Afghanistan",
        "value": "Afghanistan"
    },
    {
        "code": "AX",
        "label": "Åland Islands",
        "value": "Åland Islands"
    },
    {
        "code": "AL",
        "label": "Albania",
        "value": "Albania"
    },
    {
        "code": "DZ",
        "label": "Algeria",
        "value": "Algeria"
    },
    {
        "code": "AS",
        "label": "American Samoa",
        "value": "American Samoa"
    },
    {
        "code": "AD",
        "label": "Andorra",
        "value": "Andorra"
    },
    {
        "code": "AO",
        "label": "Angola",
        "value": "Angola"
    },
    {
        "code": "AI",
        "label": "Anguilla",
        "value": "Anguilla"
    },
    {
        "code": "AQ",
        "label": "Antarctica",
        "value": "Antarctica"
    },
    {
        "code": "AG",
        "label": "Antigua and Barbuda",
        "value": "Antigua and Barbuda"
    },
    {
        "code": "AR",
        "label": "Argentina",
        "value": "Argentina"
    },
    {
        "code": "AM",
        "label": "Armenia",
        "value": "Armenia"
    },
    {
        "code": "AW",
        "label": "Aruba",
        "value": "Aruba"
    },
    {
        "code": "AT",
        "label": "Austria",
        "value": "Austria"
    },
    {
        "code": "AZ",
        "label": "Azerbaijan",
        "value": "Azerbaijan"
    },
    {
        "code": "BS",
        "label": "Bahamas",
        "value": "Bahamas"
    },
    {
        "code": "BH",
        "label": "Bahrain",
        "value": "Bahrain"
    },
    {
        "code": "BD",
        "label": "Bangladesh",
        "value": "Bangladesh"
    },
    {
        "code": "BB",
        "label": "Barbados",
        "value": "Barbados"
    },
    {
        "code": "BY",
        "label": "Belarus",
        "value": "Belarus"
    },
    {
        "code": "BE",
        "label": "Belgium",
        "value": "Belgium"
    },
    {
        "code": "BZ",
        "label": "Belize",
        "value": "Belize"
    },
    {
        "code": "BJ",
        "label": "Benin",
        "value": "Benin"
    },
    {
        "code": "BM",
        "label": "Bermuda",
        "value": "Bermuda"
    },
    {
        "code": "BT",
        "label": "Bhutan",
        "value": "Bhutan"
    },
    {
        "code": "BO",
        "label": "Bolivia",
        "value": "Bolivia"
    },
    {
        "code": "BA",
        "label": "Bosnia and Herzegovina",
        "value": "Bosnia and Herzegovina"
    },
    {
        "code": "BW",
        "label": "Botswana",
        "value": "Botswana"
    },
    {
        "code": "BV",
        "label": "Bouvet Island",
        "value": "Bouvet Island"
    },
    {
        "code": "BR",
        "label": "Brazil",
        "value": "Brazil"
    },
    {
        "code": "IO",
        "label": "British Indian Ocean Territory",
        "value": "British Indian Ocean Territory"
    },
    {
        "code": "BN",
        "label": "Brunei Darussalam",
        "value": "Brunei Darussalam"
    },
    {
        "code": "BG",
        "label": "Bulgaria",
        "value": "Bulgaria"
    },
    {
        "code": "BF",
        "label": "Burkina Faso",
        "value": "Burkina Faso"
    },
    {
        "code": "BI",
        "label": "Burundi",
        "value": "Burundi"
    },
    {
        "code": "KH",
        "label": "Cambodia",
        "value": "Cambodia"
    },
    {
        "code": "CM",
        "label": "Cameroon",
        "value": "Cameroon"
    },
    {
        "code": "CV",
        "label": "Cape Verde",
        "value": "Cape Verde"
    },
    {
        "code": "KY",
        "label": "Cayman Islands",
        "value": "Cayman Islands"
    },
    {
        "code": "CF",
        "label": "Central African Republic",
        "value": "Central African Republic"
    },
    {
        "code": "TD",
        "label": "Chad",
        "value": "Chad"
    },
    {
        "code": "CL",
        "label": "Chile",
        "value": "Chile"
    },
    {
        "code": "CN",
        "label": "China",
        "value": "China"
    },
    {
        "code": "CX",
        "label": "Christmas Island",
        "value": "Christmas Island"
    },
    {
        "code": "CC",
        "label": "Cocos (Keeling) Islands",
        "value": "Cocos (Keeling) Islands"
    },
    {
        "code": "CO",
        "label": "Colombia",
        "value": "Colombia"
    },
    {
        "code": "KM",
        "label": "Comoros",
        "value": "Comoros"
    },
    {
        "code": "CG",
        "label": "Congo",
        "value": "Congo"
    },
    {
        "code": "CD",
        "label": "Congo, The Democratic Republic of the",
        "value": "Congo, The Democratic Republic of the"
    },
    {
        "code": "CK",
        "label": "Cook Islands",
        "value": "Cook Islands"
    },
    {
        "code": "CR",
        "label": "Costa Rica",
        "value": "Costa Rica"
    },
    {
        "code": "CI",
        "label": "Cote D'Ivoire",
        "value": "Cote D'Ivoire"
    },
    {
        "code": "CU",
        "label": "Cuba",
        "value": "Cuba"
    },
    {
        "code": "CY",
        "label": "Cyprus",
        "value": "Cyprus"
    },
    {
        "code": "CZ",
        "label": "Czech Republic",
        "value": "Czech Republic"
    },
    {
        "code": "DJ",
        "label": "Djibouti",
        "value": "Djibouti"
    },
    {
        "code": "DM",
        "label": "Dominica",
        "value": "Dominica"
    },
    {
        "code": "DO",
        "label": "Dominican Republic",
        "value": "Dominican Republic"
    },
    {
        "code": "EC",
        "label": "Ecuador",
        "value": "Ecuador"
    },
    {
        "code": "EG",
        "label": "Egypt",
        "value": "Egypt"
    },
    {
        "code": "SV",
        "label": "El Salvador",
        "value": "El Salvador"
    },
    {
        "code": "GQ",
        "label": "Equatorial Guinea",
        "value": "Equatorial Guinea"
    },
    {
        "code": "ER",
        "label": "Eritrea",
        "value": "Eritrea"
    },
    {
        "code": "EE",
        "label": "Estonia",
        "value": "Estonia"
    },
    {
        "code": "ET",
        "label": "Ethiopia",
        "value": "Ethiopia"
    },
    {
        "code": "FK",
        "label": "Falkland Islands (Malvinas)",
        "value": "Falkland Islands (Malvinas)"
    },
    {
        "code": "FO",
        "label": "Faroe Islands",
        "value": "Faroe Islands"
    },
    {
        "code": "FJ",
        "label": "Fiji",
        "value": "Fiji"
    },
    {
        "code": "FI",
        "label": "Finland",
        "value": "Finland"
    },
    {
        "code": "GF",
        "label": "French Guiana",
        "value": "French Guiana"
    },
    {
        "code": "PF",
        "label": "French Polynesia",
        "value": "French Polynesia"
    },
    {
        "code": "TF",
        "label": "French Southern Territories",
        "value": "French Southern Territories"
    },
    {
        "code": "GA",
        "label": "Gabon",
        "value": "Gabon"
    },
    {
        "code": "GM",
        "label": "Gambia",
        "value": "Gambia"
    },
    {
        "code": "GE",
        "label": "Georgia",
        "value": "Georgia"
    },
    {
        "code": "GH",
        "label": "Ghana",
        "value": "Ghana"
    },
    {
        "code": "GI",
        "label": "Gibraltar",
        "value": "Gibraltar"
    },
    {
        "code": "GR",
        "label": "Greece",
        "value": "Greece"
    },
    {
        "code": "GL",
        "label": "Greenland",
        "value": "Greenland"
    },
    {
        "code": "GD",
        "label": "Grenada",
        "value": "Grenada"
    },
    {
        "code": "GP",
        "label": "Guadeloupe",
        "value": "Guadeloupe"
    },
    {
        "code": "GU",
        "label": "Guam",
        "value": "Guam"
    },
    {
        "code": "GT",
        "label": "Guatemala",
        "value": "Guatemala"
    },
    {
        "code": "GG",
        "label": "Guernsey",
        "value": "Guernsey"
    },
    {
        "code": "GN",
        "label": "Guinea",
        "value": "Guinea"
    },
    {
        "code": "GW",
        "label": "Guinea-Bissau",
        "value": "Guinea-Bissau"
    },
    {
        "code": "GY",
        "label": "Guyana",
        "value": "Guyana"
    },
    {
        "code": "HT",
        "label": "Haiti",
        "value": "Haiti"
    },
    {
        "code": "HM",
        "label": "Heard Island and Mcdonald Islands",
        "value": "Heard Island and Mcdonald Islands"
    },
    {
        "code": "VA",
        "label": "Holy See (Vatican City State)",
        "value": "Holy See (Vatican City State)"
    },
    {
        "code": "HN",
        "label": "Honduras",
        "value": "Honduras"
    },
    {
        "code": "HK",
        "label": "Hong Kong",
        "value": "Hong Kong"
    },
    {
        "code": "HU",
        "label": "Hungary",
        "value": "Hungary"
    },
    {
        "code": "IS",
        "label": "Iceland",
        "value": "Iceland"
    },
    {
        "code": "IN",
        "label": "India",
        "value": "India"
    },
    {
        "code": "ID",
        "label": "Indonesia",
        "value": "Indonesia"
    },
    {
        "code": "IR",
        "label": "Iran, Islamic Republic Of",
        "value": "Iran, Islamic Republic Of"
    },
    {
        "code": "IQ",
        "label": "Iraq",
        "value": "Iraq"
    },
    {
        "code": "IE",
        "label": "Ireland",
        "value": "Ireland"
    },
    {
        "code": "IM",
        "label": "Isle of Man",
        "value": "Isle of Man"
    },
    {
        "code": "IL",
        "label": "Israel",
        "value": "Israel"
    },
    {
        "code": "JM",
        "label": "Jamaica",
        "value": "Jamaica"
    },
    {
        "code": "JP",
        "label": "Japan",
        "value": "Japan"
    },
    {
        "code": "JE",
        "label": "Jersey",
        "value": "Jersey"
    },
    {
        "code": "JO",
        "label": "Jordan",
        "value": "Jordan"
    },
    {
        "code": "KZ",
        "label": "Kazakhstan",
        "value": "Kazakhstan"
    },
    {
        "code": "KE",
        "label": "Kenya",
        "value": "Kenya"
    },
    {
        "code": "KI",
        "label": "Kiribati",
        "value": "Kiribati"
    },
    {
        "code": "KP",
        "label": "Korea, Democratic People's Republic of",
        "value": "Korea, Democratic People's Republic of"
    },
    {
        "code": "KR",
        "label": "Korea, Republic of",
        "value": "Korea, Republic of"
    },
    {
        "code": "KW",
        "label": "Kuwait",
        "value": "Kuwait"
    },
    {
        "code": "KG",
        "label": "Kyrgyzstan",
        "value": "Kyrgyzstan"
    },
    {
        "code": "LA",
        "label": "Lao People's Democratic Republic",
        "value": "Lao People's Democratic Republic"
    },
    {
        "code": "LV",
        "label": "Latvia",
        "value": "Latvia"
    },
    {
        "code": "LB",
        "label": "Lebanon",
        "value": "Lebanon"
    },
    {
        "code": "LS",
        "label": "Lesotho",
        "value": "Lesotho"
    },
    {
        "code": "LR",
        "label": "Liberia",
        "value": "Liberia"
    },
    {
        "code": "LY",
        "label": "Libyan Arab Jamahiriya",
        "value": "Libyan Arab Jamahiriya"
    },
    {
        "code": "LI",
        "label": "Liechtenstein",
        "value": "Liechtenstein"
    },
    {
        "code": "LT",
        "label": "Lithuania",
        "value": "Lithuania"
    },
    {
        "code": "LU",
        "label": "Luxembourg",
        "value": "Luxembourg"
    },
    {
        "code": "MO",
        "label": "Macao",
        "value": "Macao"
    },
    {
        "code": "MK",
        "label": "Macedonia, The Former Yugoslav Republic of",
        "value": "Macedonia, The Former Yugoslav Republic of"
    },
    {
        "code": "MG",
        "label": "Madagascar",
        "value": "Madagascar"
    },
    {
        "code": "MW",
        "label": "Malawi",
        "value": "Malawi"
    },
    {
        "code": "MY",
        "label": "Malaysia",
        "value": "Malaysia"
    },
    {
        "code": "MV",
        "label": "Maldives",
        "value": "Maldives"
    },
    {
        "code": "ML",
        "label": "Mali",
        "value": "Mali"
    },
    {
        "code": "MT",
        "label": "Malta",
        "value": "Malta"
    },
    {
        "code": "MH",
        "label": "Marshall Islands",
        "value": "Marshall Islands"
    },
    {
        "code": "MQ",
        "label": "Martinique",
        "value": "Martinique"
    },
    {
        "code": "MR",
        "label": "Mauritania",
        "value": "Mauritania"
    },
    {
        "code": "MU",
        "label": "Mauritius",
        "value": "Mauritius"
    },
    {
        "code": "YT",
        "label": "Mayotte",
        "value": "Mayotte"
    },
    {
        "code": "MX",
        "label": "Mexico",
        "value": "Mexico"
    },
    {
        "code": "FM",
        "label": "Micronesia, Federated States of",
        "value": "Micronesia, Federated States of"
    },
    {
        "code": "MD",
        "label": "Moldova, Republic of",
        "value": "Moldova, Republic of"
    },
    {
        "code": "MC",
        "label": "Monaco",
        "value": "Monaco"
    },
    {
        "code": "MN",
        "label": "Mongolia",
        "value": "Mongolia"
    },
    {
        "code": "MS",
        "label": "Montserrat",
        "value": "Montserrat"
    },
    {
        "code": "MA",
        "label": "Morocco",
        "value": "Morocco"
    },
    {
        "code": "MZ",
        "label": "Mozambique",
        "value": "Mozambique"
    },
    {
        "code": "MM",
        "label": "Myanmar",
        "value": "Myanmar"
    },
    {
        "code": "NA",
        "label": "Namibia",
        "value": "Namibia"
    },
    {
        "code": "NR",
        "label": "Nauru",
        "value": "Nauru"
    },
    {
        "code": "NP",
        "label": "Nepal",
        "value": "Nepal"
    },
    {
        "code": "NL",
        "label": "Netherlands",
        "value": "Netherlands"
    },
    {
        "code": "AN",
        "label": "Netherlands Antilles",
        "value": "Netherlands Antilles"
    },
    {
        "code": "NC",
        "label": "New Caledonia",
        "value": "New Caledonia"
    },
    {
        "code": "NI",
        "label": "Nicaragua",
        "value": "Nicaragua"
    },
    {
        "code": "NE",
        "label": "Niger",
        "value": "Niger"
    },
    {
        "code": "NG",
        "label": "Nigeria",
        "value": "Nigeria"
    },
    {
        "code": "NU",
        "label": "Niue",
        "value": "Niue"
    },
    {
        "code": "NF",
        "label": "Norfolk Island",
        "value": "Norfolk Island"
    },
    {
        "code": "MP",
        "label": "Northern Mariana Islands",
        "value": "Northern Mariana Islands"
    },
    {
        "code": "NO",
        "label": "Norway",
        "value": "Norway"
    },
    {
        "code": "OM",
        "label": "Oman",
        "value": "Oman"
    },
    {
        "code": "PK",
        "label": "Pakistan",
        "value": "Pakistan"
    },
    {
        "code": "PW",
        "label": "Palau",
        "value": "Palau"
    },
    {
        "code": "PS",
        "label": "Palestinian Territory, Occupied",
        "value": "Palestinian Territory, Occupied"
    },
    {
        "code": "PA",
        "label": "Panama",
        "value": "Panama"
    },
    {
        "code": "PG",
        "label": "Papua New Guinea",
        "value": "Papua New Guinea"
    },
    {
        "code": "PY",
        "label": "Paraguay",
        "value": "Paraguay"
    },
    {
        "code": "PE",
        "label": "Peru",
        "value": "Peru"
    },
    {
        "code": "PN",
        "label": "Pitcairn",
        "value": "Pitcairn"
    },
    {
        "code": "PR",
        "label": "Puerto Rico",
        "value": "Puerto Rico"
    },
    {
        "code": "QA",
        "label": "Qatar",
        "value": "Qatar"
    },
    {
        "code": "RE",
        "label": "Reunion",
        "value": "Reunion"
    },
    {
        "code": "RO",
        "label": "Romania",
        "value": "Romania"
    },
    {
        "code": "RU",
        "label": "Russian Federation",
        "value": "Russian Federation"
    },
    {
        "code": "RW",
        "label": "Rwanda",
        "value": "Rwanda"
    },
    {
        "code": "SH",
        "label": "Saint Helena",
        "value": "Saint Helena"
    },
    {
        "code": "KN",
        "label": "Saint Kitts and Nevis",
        "value": "Saint Kitts and Nevis"
    },
    {
        "code": "LC",
        "label": "Saint Lucia",
        "value": "Saint Lucia"
    },
    {
        "code": "PM",
        "label": "Saint Pierre and Miquelon",
        "value": "Saint Pierre and Miquelon"
    },
    {
        "code": "VC",
        "label": "Saint Vincent and the Grenadines",
        "value": "Saint Vincent and the Grenadines"
    },
    {
        "code": "WS",
        "label": "Samoa",
        "value": "Samoa"
    },
    {
        "code": "SM",
        "label": "San Marino",
        "value": "San Marino"
    },
    {
        "code": "ST",
        "label": "Sao Tome and Principe",
        "value": "Sao Tome and Principe"
    },
    {
        "code": "SA",
        "label": "Saudi Arabia",
        "value": "Saudi Arabia"
    },
    {
        "code": "SN",
        "label": "Senegal",
        "value": "Senegal"
    },
    {
        "code": "CS",
        "label": "Serbia and Montenegro",
        "value": "Serbia and Montenegro"
    },
    {
        "code": "SC",
        "label": "Seychelles",
        "value": "Seychelles"
    },
    {
        "code": "SL",
        "label": "Sierra Leone",
        "value": "Sierra Leone"
    },
    {
        "code": "SG",
        "label": "Singapore",
        "value": "Singapore"
    },
    {
        "code": "SK",
        "label": "Slovakia",
        "value": "Slovakia"
    },
    {
        "code": "SI",
        "label": "Slovenia",
        "value": "Slovenia"
    },
    {
        "code": "SB",
        "label": "Solomon Islands",
        "value": "Solomon Islands"
    },
    {
        "code": "SO",
        "label": "Somalia",
        "value": "Somalia"
    },
    {
        "code": "GS",
        "label": "South Georgia and the South Sandwich Islands",
        "value": "South Georgia and the South Sandwich Islands"
    },
    {
        "code": "LK",
        "label": "Sri Lanka",
        "value": "Sri Lanka"
    },
    {
        "code": "SD",
        "label": "Sudan",
        "value": "Sudan"
    },
    {
        "code": "SR",
        "label": "Surilabel",
        "value": "Surilabel"
    },
    {
        "code": "SJ",
        "label": "Svalbard and Jan Mayen",
        "value": "Svalbard and Jan Mayen"
    },
    {
        "code": "SZ",
        "label": "Swaziland",
        "value": "Swaziland"
    },
    {
        "code": "CH",
        "label": "Switzerland",
        "value": "Switzerland"
    },
    {
        "code": "SY",
        "label": "Syrian Arab Republic",
        "value": "Syrian Arab Republic"
    },
    {
        "code": "TW",
        "label": "Taiwan",
        "value": "Taiwan"
    },
    {
        "code": "TJ",
        "label": "Tajikistan",
        "value": "Tajikistan"
    },
    {
        "code": "TZ",
        "label": "Tanzania, United Republic of",
        "value": "Tanzania, United Republic of"
    },
    {
        "code": "TH",
        "label": "Thailand",
        "value": "Thailand"
    },
    {
        "code": "TL",
        "label": "Timor-Leste",
        "value": "Timor-Leste"
    },
    {
        "code": "TG",
        "label": "Togo",
        "value": "Togo"
    },
    {
        "code": "TK",
        "label": "Tokelau",
        "value": "Tokelau"
    },
    {
        "code": "TO",
        "label": "Tonga",
        "value": "Tonga"
    },
    {
        "code": "TT",
        "label": "Trinidad and Tobago",
        "value": "Trinidad and Tobago"
    },
    {
        "code": "TN",
        "label": "Tunisia",
        "value": "Tunisia"
    },
    {
        "code": "TR",
        "label": "Turkey",
        "value": "Turkey"
    },
    {
        "code": "TM",
        "label": "Turkmenistan",
        "value": "Turkmenistan"
    },
    {
        "code": "TC",
        "label": "Turks and Caicos Islands",
        "value": "Turks and Caicos Islands"
    },
    {
        "code": "TV",
        "label": "Tuvalu",
        "value": "Tuvalu"
    },
    {
        "code": "UG",
        "label": "Uganda",
        "value": "Uganda"
    },
    {
        "code": "UA",
        "label": "Ukraine",
        "value": "Ukraine"
    },
    {
        "code": "AE",
        "label": "United Arab Emirates",
        "value": "United Arab Emirates"
    },
    {
        "code": "UM",
        "label": "United States Minor Outlying Islands",
        "value": "United States Minor Outlying Islands"
    },
    {
        "code": "UY",
        "label": "Uruguay",
        "value": "Uruguay"
    },
    {
        "code": "UZ",
        "label": "Uzbekistan",
        "value": "Uzbekistan"
    },
    {
        "code": "VU",
        "label": "Vanuatu",
        "value": "Vanuatu"
    },
    {
        "code": "VE",
        "label": "Venezuela",
        "value": "Venezuela"
    },
    {
        "code": "VN",
        "label": "Vietnam",
        "value": "Vietnam"
    },
    {
        "code": "VG",
        "label": "Virgin Islands, British",
        "value": "Virgin Islands, British"
    },
    {
        "code": "VI",
        "label": "Virgin Islands, U.S.",
        "value": "Virgin Islands, U.S."
    },
    {
        "code": "WF",
        "label": "Wallis and Futuna",
        "value": "Wallis and Futuna"
    },
    {
        "code": "EH",
        "label": "Western Sahara",
        "value": "Western Sahara"
    },
    {
        "code": "YE",
        "label": "Yemen",
        "value": "Yemen"
    },
    {
        "code": "ZM",
        "label": "Zambia",
        "value": "Zambia"
    },
    {
        "code": "ZW",
        "label": "Zimbabwe",
        "value": "Zimbabwe"
    }
];

/* List of nationalities */
export const nationalities = [
    { value: "Afghan" },
    { value: "Albanian" },
    { value: "Algerian" },
    { value: "American" },
    { value: "Andorran" },
    { value: "Angolan" },
    { value: "Anguillan" },
    { value: "Argentine" },
    { value: "Armenian" },
    { value: "Australian" },
    { value: "Austrian" },
    { value: "Azerbaijani" },
    { value: "Bahamian" },
    { value: "Bahraini" },
    { value: "Bangladeshi" },
    { value: "Barbadian" },
    { value: "Belarusian" },
    { value: "Belgian" },
    { value: "Belizean" },
    { value: "Beninese" },
    { value: "Bermudian" },
    { value: "Bhutanese" },
    { value: "Bolivian" },
    { value: "Botswanan" },
    { value: "Brazilian" },
    { value: "British" },
    { value: "British Virgin Islander" },
    { value: "Bruneian" },
    { value: "Bulgarian" },
    { value: "Burkinan" },
    { value: "Burmese" },
    { value: "Burundian" },
    { value: "Cambodian" },
    { value: "Cameroonian" },
    { value: "Canadian" },
    { value: "Cape Verdean" },
    { value: "Cayman Islander" },
    { value: "Central African" },
    { value: "Chadian" },
    { value: "Chilean" },
    { value: "Chinese" },
    { value: "Citizen of Antigua and Barbuda" },
    { value: "Citizen of Bosnia and Herzegovina" },
    { value: "Citizen of Guinea-Bissau" },
    { value: "Citizen of Kiribati" },
    { value: "Citizen of Seychelles" },
    { value: "Citizen of the Dominican Republic" },
    { value: "Citizen of Vanuatu " },
    { value: "Colombian" },
    { value: "Comoran" },
    { value: "Congolese (Congo)" },
    { value: "Congolese (DRC)" },
    { value: "Cook Islander" },
    { value: "Costa Rican" },
    { value: "Croatian" },
    { value: "Cuban" },
    { value: "Cymraes" },
    { value: "Cymro" },
    { value: "Cypriot" },
    { value: "Czech" },
    { value: "Danish" },
    { value: "Djiboutian" },
    { value: "Dominican" },
    { value: "Dutch" },
    { value: "East Timorese" },
    { value: "Ecuadorean" },
    { value: "Egyptian" },
    { value: "Emirati" },
    { value: "English" },
    { value: "Equatorial Guinean" },
    { value: "Eritrean" },
    { value: "Estonian" },
    { value: "Ethiopian" },
    { value: "Faroese" },
    { value: "Fijian" },
    { value: "Filipino" },
    { value: "Finnish" },
    { value: "French" },
    { value: "Gabonese" },
    { value: "Gambian" },
    { value: "Georgian" },
    { value: "German" },
    { value: "Ghanaian" },
    { value: "Gibraltarian" },
    { value: "Greek" },
    { value: "Greenlandic" },
    { value: "Grenadian" },
    { value: "Guamanian" },
    { value: "Guatemalan" },
    { value: "Guinean" },
    { value: "Guyanese" },
    { value: "Haitian" },
    { value: "Honduran" },
    { value: "Hong Konger" },
    { value: "Hungarian" },
    { value: "Icelandic" },
    { value: "Indian" },
    { value: "Indonesian" },
    { value: "Iranian" },
    { value: "Iraqi" },
    { value: "Irish" },
    { value: "Israeli" },
    { value: "Italian" },
    { value: "Ivorian" },
    { value: "Jamaican" },
    { value: "Japanese" },
    { value: "Jordanian" },
    { value: "Kazakh" },
    { value: "Kenyan" },
    { value: "Kittitian" },
    { value: "Kosovan" },
    { value: "Kuwaiti" },
    { value: "Kyrgyz" },
    { value: "Lao" },
    { value: "Latvian" },
    { value: "Lebanese" },
    { value: "Liberian" },
    { value: "Libyan" },
    { value: "Liechtenstein citizen" },
    { value: "Lithuanian" },
    { value: "Luxembourger" },
    { value: "Macanese" },
    { value: "Macedonian" },
    { value: "Malagasy" },
    { value: "Malawian" },
    { value: "Malaysian" },
    { value: "Maldivian" },
    { value: "Malian" },
    { value: "Maltese" },
    { value: "Marshallese" },
    { value: "Martiniquais" },
    { value: "Mauritanian" },
    { value: "Mauritian" },
    { value: "Mexican" },
    { value: "Micronesian" },
    { value: "Moldovan" },
    { value: "Monegasque" },
    { value: "Mongolian" },
    { value: "Montenegrin" },
    { value: "Montserratian" },
    { value: "Moroccan" },
    { value: "Mosotho" },
    { value: "Mozambican" },
    { value: "Namibian" },
    { value: "Nauruan" },
    { value: "Nepalese" },
    { value: "New Zealander" },
    { value: "Nicaraguan" },
    { value: "Nigerian" },
    { value: "Nigerien" },
    { value: "Niuean" },
    { value: "North Korean" },
    { value: "Northern Irish" },
    { value: "Norwegian" },
    { value: "Omani" },
    { value: "Pakistani" },
    { value: "Palauan" },
    { value: "Palestinian" },
    { value: "Panamanian" },
    { value: "Papua New Guinean" },
    { value: "Paraguayan" },
    { value: "Peruvian" },
    { value: "Pitcairn Islander" },
    { value: "Polish" },
    { value: "Portuguese" },
    { value: "Prydeinig" },
    { value: "Puerto Rican" },
    { value: "Qatari" },
    { value: "Romanian" },
    { value: "Russian" },
    { value: "Rwandan" },
    { value: "Salvadorean" },
    { value: "Sammarinese" },
    { value: "Samoan" },
    { value: "Sao Tomean" },
    { value: "Saudi Arabian" },
    { value: "Scottish" },
    { value: "Senegalese" },
    { value: "Serbian" },
    { value: "Sierra Leonean" },
    { value: "Singaporean" },
    { value: "Slovak" },
    { value: "Slovenian" },
    { value: "Solomon Islander" },
    { value: "Somali" },
    { value: "South African" },
    { value: "South Korean" },
    { value: "South Sudanese" },
    { value: "Spanish" },
    { value: "Sri Lankan" },
    { value: "St Helenian" },
    { value: "St Lucian" },
    { value: "Stateless" },
    { value: "Sudanese" },
    { value: "Surinamese" },
    { value: "Swazi" },
    { value: "Swedish" },
    { value: "Swiss" },
    { value: "Syrian" },
    { value: "Taiwanese" },
    { value: "Tajik" },
    { value: "Tanzanian" },
    { value: "Thai" },
    { value: "Togolese" },
    { value: "Tongan" },
    { value: "Trinidadian" },
    { value: "Tristanian" },
    { value: "Tunisian" },
    { value: "Turkish" },
    { value: "Turkmen" },
    { value: "Turks and Caicos Islander" },
    { value: "Tuvaluan" },
    { value: "Ugandan" },
    { value: "Ukrainian" },
    { value: "Uruguayan" },
    { value: "Uzbek" },
    { value: "Vatican citizen" },
    { value: "Venezuelan" },
    { value: "Vietnamese" },
    { value: "Vincentian" },
    { value: "Wallisian" },
    { value: "Welsh" },
    { value: "Yemeni" },
    { value: "Zambian" },
    { value: "Zimbabwean" },
]

/* List of all skills */
export const skills = [
    {
        value: "Provisioning",
        group: "Admin",
    },
    {
        value: "Crew Training",
        group: "Admin",
    },
    {
        value: "Management",
        group: "Admin",
    },
    {
        value: "Accounting",
        group: "Admin",
    },
    {
        value: "Budgeting",
        group: "Admin",
    },
    {
        value: "Crew Placement",
        group: "Admin",
    },
    {
        value: "Maritime Labour Convention",
        group: "Admin",
    },
    {
        value: "Crew Employment",
        group: "Admin",
    },
    {
        value: "Crew Payroll",
        group: "Admin",
    },
    {
        value: "Mini ISM Code",
        group: "Admin",
    },
    {
        value: "ISM Code",
        group: "Admin",
    },
    {
        value: "ISPS Code",
        group: "Admin",
    },
    {
        value: "Ship Security Plan",
        group: "Admin",
    },
    {
        value: "MARPOL & SOPEP",
        group: "Admin",
    },
    {
        value: "Garbage Management",
        group: "Admin",
    },
    {
        value: "SOLAS",
        group: "Admin",
    },
    {
        value: "COLREGS",
        group: "Admin",
    },
    {
        value: "Teamworking",
        group: "General",
    },
    {
        value: "Problem Solving",
        group: "General",
    },
    {
        value: "Time Management",
        group: "General",
    },
    {
        value: "Organisational",
        group: "General",
    },
    {
        value: "Video editing/production",
        group: "General",
    },
    {
        value: "Drone operation",
        group: "General",
    },
    {
        value: "ROV pilot",
        group: "General",
    },
    {
        value: "General Repairs & Maintenance",
        group: "Deck",
    },
    {
        value: "Cleaning & Detailing",
        group: "Deck",
    },
    {
        value: "Watchkeeping",
        group: "Deck",
    },
    {
        value: "Tender Driving",
        group: "Deck",
    },
    {
        value: "Line Handling",
        group: "Deck",
    },
    {
        value: "Sail Racing",
        group: "Deck",
    },
    {
        value: "Sail Repairs",
        group: "Deck",
    },
    {
        value: "Rigging",
        group: "Deck",
    },
    {
        value: "Whipping & Splicing",
        group: "Deck",
    },
    {
        value: "High Gloss Varnishing",
        group: "Deck",
    },
    {
        value: "Varnishing",
        group: "Deck",
    },
    {
        value: "Fibre Glassing",
        group: "Deck",
    },
    {
        value: "Gel Repairs",
        group: "Deck",
    },
    {
        value: "Crane Operation",
        group: "Deck",
    },
    {
        value: "Filling & Fairing",
        group: "Deck",
    },
    {
        value: "Carpentry",
        group: "Deck",
    },
    {
        value: "Painting",
        group: "Deck",
    },
    {
        value: "Water Jet Drive",
        group: "Deck",
    },
    {
        value: "Sail Power Drive",
        group: "Deck",
    },
    {
        value: "Azimuth Drive",
        group: "Deck",
    },
    {
        value: "Azipod Drive",
        group: "Deck",
    },
    {
        value: "ISM / ISPS Procedures",
        group: "Deck",
    },
    {
        value: "Anchor Watch",
        group: "Deck",
    },
    {
        value: "Guest Tender Driving",
        group: "Deck",
    },
    {
        value: "Jet Drive Tenders",
        group: "Deck",
    },
    {
        value: "Outboard Engine Tenders",
        group: "Deck",
    },
    {
        value: "Washdowns",
        group: "Deck",
    },
    {
        value: "Fender handling",
        group: "Deck",
    },
    {
        value: "Helideck Operations",
        group: "Deck",
    },
    {
        value: "Superstructure repair",
        group: "Deck",
    },
    {
        value: "Diesel Engines",
        group: "Engineering",
    },
    {
        value: "Diesel Electric",
        group: "Engineering",
    },
    {
        value: "Air Conditioning",
        group: "Engineering",
    },
    {
        value: "Water Makers",
        group: "Engineering",
    },
    {
        value: "AV/IT",
        group: "Engineering",
    },
    {
        value: "Outboard Engines",
        group: "Engineering",
    },
    {
        value: "Gas Turbines",
        group: "Engineering",
    },
    {
        value: "Electrical",
        group: "Engineering",
    },
    {
        value: "Switchgear",
        group: "Engineering",
    },
    {
        value: "High Voltage",
        group: "Engineering",
    },
    {
        value: "Lighting",
        group: "Engineering",
    },
    {
        value: "Welding",
        group: "Engineering",
    },
    {
        value: "Metal Work",
        group: "Engineering",
    },
    {
        value: "Plumbing",
        group: "Engineering",
    },
    {
        value: "Hydraulics",
        group: "Engineering",
    },
    {
        value: "Planned Maintenance",
        group: "Engineering",
    },
    {
        value: "Water Jet Drive",
        group: "Engineering",
    },
    {
        value: "Sail Power Drive",
        group: "Engineering",
    },
    {
        value: "Azimuth Drive",
        group: "Engineering",
    },
    {
        value: "Azipod Drive",
        group: "Engineering",
    },
    {
        value: "Hospitality",
        group: "Interior",
    },
    {
        value: "Laundry",
        group: "Interior",
    },
    {
        value: "Housekeeping",
        group: "Interior",
    },
    {
        value: "Silver Service",
        group: "Interior",
    },
    {
        value: "Detailing",
        group: "Interior",
    },
    {
        value: "Table Service & Settings",
        group: "Interior",
    },
    {
        value: "Child Care",
        group: "Interior",
    },
    {
        value: "Floristry",
        group: "Interior",
    },
    {
        value: "Napkin Folding",
        group: "Interior",
    },
    {
        value: "Floristry",
        group: "Interior",
    },
    {
        value: "Floral Arrangements",
        group: "Interior",
    },
    {
        value: "Bartending",
        group: "Interior",
    },
    {
        value: "Cocktail making",
        group: "Interior",
    },
    {
        value: "Sewing",
        group: "Interior",
    },
    {
        value: "Inventory",
        group: "Interior",
    },
    {
        value: "Fine Fabrics",
        group: "Interior",
    },
    {
        value: "Wardrobe management",
        group: "Interior",
    },
    {
        value: "Cigar Handling",
        group: "Interior",
    },
    {
        value: "Swedish Massage",
        group: "Massage",
    },
    {
        value: "Aromatherapy Massage",
        group: "Massage",
    },
    {
        value: "Hot Stone Massage",
        group: "Massage",
    },
    {
        value: "Deep Tissue Massage",
        group: "Massage",
    },
    {
        value: "Shiatsu Massage",
        group: "Massage",
    },
    {
        value: "Thai Massage",
        group: "Massage",
    },
    {
        value: "Pregnancy (Prenatal) Massage",
        group: "Massage",
    },
    {
        value: "Reflexology Massage",
        group: "Massage",
    },
    {
        value: "Sports Massage",
        group: "Massage",
    },
    {
        value: "Indian Head Massage",
        group: "Massage",
    },
    {
        value: "African Cuisine",
        group: "Culinary",
    },
    {
        value: "American Cuisine",
        group: "Culinary",
    },
    {
        value: "Oceanic Cuisine",
        group: "Culinary",
    },
    {
        value: "Fusion Cuisine",
        group: "Culinary",
    },
    {
        value: "Haute Cuisine",
        group: "Culinary",
    },
    {
        value: "Note by Note Cuisine",
        group: "Culinary",
    },
    {
        value: "Nouvelle Cuisine",
        group: "Culinary",
    },
    {
        value: "Vegan Cuisine",
        group: "Culinary",
    },
    {
        value: "Vegetarian Cuisine",
        group: "Culinary",
    },
    {
        value: "Paleo Diet",
        group: "Culinary",
    },
    {
        value: "Atkins Diet",
        group: "Culinary",
    },
    {
        value: "French Cuisine",
        group: "Culinary",
    },
    {
        value: "German Cuisine",
        group: "Culinary",
    },
    {
        value: "Chinese Cuisine",
        group: "Culinary",
    },
    {
        value: "Pacific Rim Cuisine",
        group: "Culinary",
    },
    {
        value: "Italian Cuisine",
        group: "Culinary",
    },
    {
        value: "Japanese Cuisine",
        group: "Culinary",
    },
    {
        value: "Indian Cuisine",
        group: "Culinary",
    },
    {
        value: "Mid Eastern Cuisine",
        group: "Culinary",
    },
    {
        value: "Caribbean Cuisine",
        group: "Culinary",
    },
    {
        value: "Greek Cuisine",
        group: "Culinary",
    },
    {
        value: "Mexican Cuisine",
        group: "Culinary",
    },
    {
        value: "Spanish Cuisine",
        group: "Culinary",
    },
    {
        value: "Kosher Cuisine",
        group: "Culinary",
    },
    {
        value: "Thai Cuisine",
        group: "Culinary",
    },
    {
        value: "Eastern European Cuisine",
        group: "Culinary",
    },
    {
        value: "Video Editing",
        group: "Software",
    },
    {
        value: "Photo Editing",
        group: "Software",
    },
    {
        value: "Photoshop",
        group: "Software",
    },
    {
        value: "Premiere Pro",
        group: "Software",
    },
    {
        value: "Final Cut Pro",
        group: "Software",
    },
    {
        value: "Illustrator",
        group: "Software",
    },
    {
        value: "Lightroom",
        group: "Software",
    },
    {
        value: "Publisher",
        group: "Software",
    },
    {
        value: "Microsoft Office",
        group: "Software",
    },
    {
        value: "Accountancy Software",
        group: "Software",
    },
    {
        value: "IDEA",
        group: "Software",
    },
    {
        value: "AMOS",
        group: "Software",
    },
    {
        value: "Triton",
        group: "Software",
    },
    {
        value: "Deep Blue",
        group: "Software",
    },
    {
        value: "Seahub",
        group: "Software",
    },
    {
        value: "Running Training",
        group: "Personal Trainer",
    },
    {
        value: "Weights Training",
        group: "Personal Trainer",
    },
    {
        value: "Circuit Training",
        group: "Personal Trainer",
    },
    {
        value: "Nutrition Plans",
        group: "Personal Trainer",
    },
    {
        value: "Boxing Training",
        group: "Personal Trainer",
    },
    {
        value: "Martial Arts Training",
        group: "Personal Trainer",
    },
    {
        value: "Interval Training",
        group: "Personal Trainer",
    },
    {
        value: "Cycling Training",
        group: "Personal Trainer",
    },
    {
        value: "Yoga Training",
        group: "Personal Trainer",
    },
    {
        value: "Pilates Training",
        group: "Personal Trainer",
    },
    {
        value: "Rowing Training",
        group: "Personal Trainer",
    },
    {
        value: "Swimming Training",
        group: "Personal Trainer",
    },
    {
        value: "Jet Skiing",
        group: "Watersport",
    },
    {
        value: "Skimboarding",
        group: "Watersport",
    },
    {
        value: "Skurfing",
        group: "Watersport",
    },
    {
        value: "Standup paddleboarding",
        group: "Watersport",
    },
    {
        value: "Surfing",
        group: "Watersport",
    },
    {
        value: "Jetsurfing",
        group: "Watersport",
    },
    {
        value: "Wakeboarding",
        group: "Watersport",
    },
    {
        value: "Wakeskating",
        group: "Watersport",
    },
    {
        value: "Wakesurfing",
        group: "Watersport",
    },
    {
        value: "Waterskiing",
        group: "Watersport",
    },
    {
        value: "White water rafting",
        group: "Watersport",
    },
    {
        value: "Windsurfing",
        group: "Watersport",
    },
    {
        value: "Boat racing",
        group: "Watersport",
    },
    {
        value: "Waterskiing",
        group: "Watersport",
    },
    {
        value: "Bodyboarding",
        group: "Watersport",
    },
    {
        value: "Cable skiing",
        group: "Watersport",
    },
    {
        value: "Canoeing",
        group: "Watersport",
    },
    {
        value: "Fishing",
        group: "Watersport",
    },
    {
        value: "Flyboard",
        group: "Watersport",
    },
    {
        value: "Flowrider",
        group: "Watersport",
    },
    {
        value: "Kayaking",
        group: "Watersport",
    },
    {
        value: "Kiteboating",
        group: "Watersport",
    },
    {
        value: "Kitesurfing",
        group: "Watersport",
    },
    {
        value: "Kneeboarding",
        group: "Watersport",
    },
    {
        value: "Paddleboarding",
        group: "Watersport",
    },
    {
        value: "Parasailing",
        group: "Watersport",
    },
    {
        value: "Rowing",
        group: "Watersport",
    },
    {
        value: "Sailing",
        group: "Watersport",
    },
    {
        value: "Deep diving",
        group: "Watersport",
    },
    {
        value: "Snorkelling",
        group: "Watersport",
    },
    {
        value: "Freediving",
        group: "Watersport",
    },
    {
        value: "Ice diving",
        group: "Watersport",
    },
    {
        value: "Spearfishing",
        group: "Watersport",
    },
    {
        value: "Underwater archaeology",
        group: "Watersport",
    },
    {
        value: "Underwater photography",
        group: "Watersport",
    },
    {
        value: "Underwater videography",
        group: "Watersport",
    },
    {
        value: "Swimming",
        group: "Watersport",
    },
];

/* List of equipment */
export const equipment = [
        {
            value: "IDEA",
            group: "ETO Equipment",
        },
        {
            value: "Windows Exchange Server",
            group: "ETO Equipment",
        },
        {
            value: "Cisco",
            group: "ETO Equipment",
        },
        {
            value: "VLAN",
            group: "ETO Equipment",
        },
        {
            value: "PABX",
            group: "ETO Equipment",
        },
        {
            value: "VoIP Systems",
            group: "ETO Equipment",
        },
        {
            value: "SeaTel VSAT",
            group: "ETO Equipment",
        },
        {
            value: "Sailor VSAT Systems",
            group: "ETO Equipment",
        },
        {
            value: "TVRO",
            group: "ETO Equipment",
        },
        {
            value: "Windows Servers & NAS",
            group: "ETO Equipment",
        },
        {
            value: "VMware Server",
            group: "ETO Equipment",
        },
        {
            value: "VM Cluster Management",
            group: "ETO Equipment",
        },
        {
            value: "VHF / GMDSS",
            group: "ETO Equipment",
        },
        {
            value: "CCTV Network",
            group: "ETO Equipment",
        },
        {
            value: "Paxton Doors",
            group: "ETO Equipment",
        },
        {
            value: "Kaleidescape",
            group: "ETO Equipment",
        },
        {
            value: "Crestron",
            group: "ETO Equipment",
        },
        {
            value: "KNX Lighting",
            group: "ETO Equipment",
        },
        {
            value: "Apple TV",
            group: "ETO Equipment",
        },
        {
            value: "iTunes Server",
            group: "ETO Equipment",
        },
        {
            value: "SoundWeb Signal Processors",
            group: "ETO Equipment",
        },
        {
            value: "PHP",
            group: "ETO Equipment",
        },
        {
            value: "Perl",
            group: "ETO Equipment",
        },
        {
            value: "Javascript",
            group: "ETO Equipment",
        },
        {
            value: "MySQL",
            group: "ETO Equipment",
        },
        {
            value: "Transas Navigation",
            group: "ETO Equipment",
        },
        {
            value: "PLC",
            group: "ETO Equipment",
        },
        {
            value: "Kerio",
            group: "ETO Equipment",
        },
        {
            value: "Linux",
            group: "ETO Equipment",
        },
        {
            value: "PLEX",
            group: "ETO Equipment",
        },
        {
            value: "Lutron",
            group: "ETO Equipment",
        },
        {
            value: "PfSense",
            group: "ETO Equipment",
        },
        {
            value: "NMEA",
            group: "ETO Equipment",
        },
        {
            value: "G2 ECDIS",
            group: "ETO Equipment",
        },
        {
            value: "OSX Server",
            group: "ETO Equipment",
        },
        {
            value: "Synology",
            group: "ETO Equipment",
        },
        {
            value: "RAID",
            group: "ETO Equipment",
        },
        {
            value: "AMX",
            group: "ETO Equipment",
        },
        {
            value: "Sperry",
            group: "ETO Equipment",
        },
        {
            value: "Transas",
            group: "ETO Equipment",
        },
        {
            value: "Kymeta",
            group: "ETO Equipment",
        },
        {
            value: "MTU 2000 Engines",
            group: "Engines/Generators",
        },
        {
            value: "MTU 4000 Engines",
            group: "Engines/Generators",
        },
        {
            value: "Caterpillar Engines",
            group: "Engines/Generators",
        },
        {
            value: "Northern Lights generators",
            group: "Engines/Generators",
        },
        {
            value: "Kohler Generators",
            group: "Engines/Generators",
        },
    
]

/* List of Admin active Sign Ups paths */
export const signUpsPaths = ["/", "/sign-ups", "/sign-ups/completed", "/sign-ups/incomplete"];

export const crewPaths = ["/crew"];