import {
    SET_REFERENCE,
    RESET_REFERENCE,
} from "../types/reference";

const initialState = {
    data: {
        referenceName: "",
        companyName: "",
        position: "",
        contactNumber: "", 
        email: "",
    },
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_REFERENCE:

            let ops = {};

            action.data.forEach(item => {
                ops[item.propName] = item.value;
            });
        
            return {
                ...state,
                data: {
                    ...state.data,
                    ...ops,
                },
            };

        case RESET_REFERENCE:

            return {
                ...state,
                data: {
                    referenceName: "",
                    companyName: "",
                    position: "",
                    contactNumber: "", 
                    email: "",
                },
            }
        
        default:
            return state;

    }

};