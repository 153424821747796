import React from "react";
import "./index.css";

export default ({ active, col1, col2, col3, col4, col5, status }) => {

    return(
        <div className="sign-ups-user-rows-header-container margin-top-20px">
            {col1 &&
                <div className="signee-details-container admin-col-1">
                    <p>{col1}</p>
                </div>
            }
            {col2 &&
                <div className="signee-data-container admin-col-2">
                    <p>{col2}</p>
                </div>
            }
            {col3 &&
                <div className="signee-data-container admin-col-3">
                    <p>{col3}</p>
                </div>
            }
            {col4 &&
                <div className="signee-data-container admin-col-4">
                    <p>{col4}</p>
                </div>
            }
            {col5 && status &&
                <div className="signee-data-container admin-col-5" style={active ? {} : {width: 144}}>
                    <p>{col5}</p>
                </div>
            }
        </div>
    );

}