import React from "react";
import "./index.css";

export default ({ icon, placeholder, hide, error, value, onChangeText, disabled }) => {

    return(
        <div className="text-input-with-icon-container" style={{borderColor: !error ? "#DDDDDD" : "#F55D5D"}}>
            {icon &&
                <div className="text-input-icon-container">
                    <img src={icon} alt="" />
                </div>
            }
            <div className="text-input-with-icon-input-container">
                <input value={value} onChange={(e) => onChangeText(e.target.value)} required={true} type={hide ? "password" : "text"} className="text-input-with-icon-input" disabled={disabled} />
                <span className="text-input-with-icon-floating-placeholder">{placeholder}</span>
            </div>
            {error &&
                <div className="text-input-error-icon-container">
                    <img src={require("../../assets/svgs/error-circle.svg")} alt="" />
                </div>
            }
        </div>
    );

}