import React from "react";
import "./index.css";

export default ({ profile, propName, label, options, toggle }) => {

    return(
        <div className="regular-toggle-button-master-container margin-top-20px">
            <div className="regular-toggle-button-title-container">
                <p>{label}</p>
            </div>
            <div className="regular-toggle-button-container">
                {renderOptions(profile, propName, options, toggle)}
            </div>
        </div>
    );

}

const renderOptions = (profile, propName, options, toggle) => {

    let els = [];

    options.forEach((option, index) => {

        els = [...els, 
            <div key={index} onClick={() => handleToggle(propName, toggle, option)} className="regular-toggle-button-item-container" style={{backgroundColor: profile[propName] === (option.bool !== undefined ? option.bool : option.name) ? "#1377FF" : "white"}}>
                <p className="regular-toggle-button-item-text" style={{color: profile[propName] === (option.bool !== undefined ? option.bool : option.name) ? "white" : "#333333"}}>{option.name}</p>
            </div>
        ];

        if (options.length-1 !== index) {
            els = [...els,
                <div key={index+"-separator"} className="regular-toggle-button-items-separator"></div>
            ];
        }
    });

    return els;

}

const handleToggle = (propName, toggle, option) => {

    const value = option.bool !== undefined ? option.bool : option.name;

    return toggle([{ propName: propName, value: value }], true);

}