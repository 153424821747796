import React from "react";
import DashboardSectionBase from "../../../components/DashboardSectionBase";
import Toggle from "../Toggle";
import TextBox from "../TextBox";
import Footer from "../Footer";
import Title from "../Title";

export default ({ title, steps, step, updateState, punctuality, extra, state }) => {

    return(
        <>
            <Title title={title} />
            <DashboardSectionBase title={"Punctuality"} steps={steps} step={step} overflow={true}>
                <div className="margin-top-20px">
                    <Toggle title={"How punctual was he/she?"} options={["Excellent", "Good", "Poor"]} updateState={updateState} selected={punctuality} propName={"punctuality"} />
                </div>
                <div className="margin-top-20px">
                    <TextBox title={"Any further details you would like to add (Optional)"} updateState={updateState} propName={"punctualityExtra"} value={extra} />
                </div>
            </DashboardSectionBase>
            <div className="margin-top-20px">
                <Footer title={"Save & Continue"} icon={require("../../../assets/svgs/continue.svg")} back={true} updateState={updateState} step={step} state={state} propNames={["punctuality"]} />
            </div>
        </>
    );

}