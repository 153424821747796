import React from "react";
import DashboardSectionBase from "../../../components/DashboardSectionBase";
import Toggle from "../Toggle";
import TextBox from "../TextBox";
import Footer from "../Footer";
import Title from "../Title";

export default ({ title, steps, step, updateState, employability, extra, state }) => {

    return(
        <>
            <Title title={title} />
            <DashboardSectionBase title={"Employability"} steps={steps} step={step} overflow={true}>
                <div className="margin-top-20px">
                    <Toggle title={"Would you recommend or rehire him/her?"} options={["Yes", "No"]} updateState={updateState} selected={employability} propName={"employability"} />
                </div>
                <div className="margin-top-20px">
                    <TextBox title={"Any further details you would like to add (Optional)"} updateState={updateState} propName={"employabilityExtra"} value={extra} />
                </div>
            </DashboardSectionBase>
            <div className="margin-top-20px">
                <Footer title={"Save & Continue"} icon={require("../../../assets/svgs/continue.svg")} back={true} updateState={updateState} step={step} state={state} propNames={["employability"]} />
            </div>
        </>
    );

}