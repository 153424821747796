import React from "react";
import "./index.css";

export default ({ children, title, description }) => {

    return(
        <div className="initial-setup-base-container margin-top-2x">
            <div className="initial-setup-base-title-contianer">
                <p className="initial-setup-base-title">{title}</p>
            </div>
            {description &&
                <div className="initial-setup-base-description-contianer margin-top-20px">
                    <p className="initial-setup-base-description">{description}</p>
                </div>
            }
            {children}
        </div>
    );

}