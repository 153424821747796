import React from "react";
import { Text, View, Image, Link } from "@react-pdf/renderer";
import { styles } from "./styles";

export const TextRow = ({ propName, value, type, whatsapp }) => {

    return(
        <View style={styles.textRowContainer}>
            <View style={styles.textRowPropContainer}>
                <Text style={styles.textRowPropName}>{propName}:</Text>
            </View>
            <View style={styles.textRowValueContainer}>
                {!type &&
                    <Text style={styles.textRowValue}>{value}</Text>
                }
                {type === "email" &&
                    <Link wrap={true} style={styles.textRowValueLink} href={`mailto:${value}`}>{value}</Link>
                }
                {type === "visa" &&
                    <View style={styles.textRowMultipleTextContainer}>
                        {value?.b1b2?.status &&
                            <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Text style={styles.textRowMultipleText}>B1/B2</Text>
                                {value?.b1b2?.expiryDate ?
                                    <Text style={styles.textRowMultipleDate}>(exp. {value.b1b2.expiryDate})</Text>
                                :
                                    <View></View>
                                }
                            </View>
                        }
                        {value?.c1d?.status &&
                            <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Text style={styles.textRowMultipleText}>C1/D</Text>
                                {value?.c1d?.expiryDate ?
                                    <Text style={styles.textRowMultipleDate}>(exp. {value.c1d.expiryDate})</Text>
                                :
                                    <View></View>
                                }
                            </View>
                        }
                        {value?.schengen?.status &&
                            <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Text style={styles.textRowMultipleText}>Schengen</Text>
                                {value?.schengen?.expiryDate ?
                                    <Text style={styles.textRowMultipleDate}>(exp. {value.schengen.expiryDate})</Text>
                                :
                                    <View></View>
                                }
                            </View>
                        }
                        {value?.greenCard?.status &&
                            <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Text style={styles.textRowMultipleText}>Green Card</Text>
                                {value?.greenCard?.expiryDate ?
                                    <Text style={styles.textRowMultipleDate}>(exp. {value.greenCard.expiryDate})</Text>
                                :
                                    <View></View>
                                }
                            </View>
                        }
                    </View>
                }
            </View>
            {whatsapp &&
                <Image style={styles.textRowContainerImage} source={require("../../assets/pngs/whatsapp.png")} />
            }   
        </View>
    );

}

export const SectionSeparator = ({ title }) => {

    return(
        <View style={styles.sectionSeparatorContainer}>
            <Text style={styles.sectionSeparatorTitle}>{title}</Text>
        </View>
    );  

}