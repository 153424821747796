import {
    EMAIL_JUST_VERIFIED,
    TOGGLE_BURGER_STATUS,
    TOGGLE_BURGER_MENU,
    ADJUST_PROFILE_IMAGE,
    SET_PROFILE_BUILDER_FINISH_LINE,
    SET_CV_PREVIEW,
    SET_DELETE_ACCOUNT,
    SET_DELETE_LOCAL_YACHT_ACCOUNT,
    SET_PASSWORD_CHANGED,
    SET_DETAILS_CHANGED,
    SET_LOG_OUT,
    SET_ALERT,
    VIEW_LISTING,
} from "../types/misc";

export const setEmailJustVerified = (data, history) => dispatch => {

    return dispatch({
        type: EMAIL_JUST_VERIFIED,
        data,
        history,
    });
    
}

export const toggleBurgerStatus = (data) => dispatch => {

    return dispatch({
        type: TOGGLE_BURGER_STATUS,
        data,
    });
    
}

export const toggleBurgerMenu = (data) => dispatch => {

    return dispatch({
        type: TOGGLE_BURGER_MENU,
        data,
    });
    
}

export const adjustProfileImage = (data) => dispatch => {

    return dispatch({
        type: ADJUST_PROFILE_IMAGE,
        data,
    });
    
}

export const setProfileBuilderFinishLine = (data) => dispatch => {

    return dispatch({
        type: SET_PROFILE_BUILDER_FINISH_LINE,
        data,
    });
    
}

export const setCVPreview = (data, job) => dispatch => {

    return dispatch({
        type: SET_CV_PREVIEW,
        data,
        job,
    });
    
}

export const setViewListing = (data) => dispatch => {

    return dispatch({
        type: VIEW_LISTING,
        data,
    });
    
}

export const setDeleteAccount = (status, admin) => dispatch => {

    return dispatch({
        type: SET_DELETE_ACCOUNT,
        status,
        admin,
    });
    
}

export const setDeleteLocalYachtAccount = (status, id) => dispatch => {

    return dispatch({
        type: SET_DELETE_LOCAL_YACHT_ACCOUNT,
        status,
        id,
    });
    
}

export const setPasswordChanged = (data) => dispatch => {

    return dispatch({
        type: SET_PASSWORD_CHANGED,
        data,
    });
    
}

export const setDetailsChanged = (data) => dispatch => {

    return dispatch({
        type: SET_DETAILS_CHANGED,
        data,
    });
    
}

export const setLogOut = (data) => dispatch => {

    return dispatch({
        type: SET_LOG_OUT,
        data,
    });
    
}

export const setAlert = (message, style, status) => dispatch => {

    return dispatch({
        type: SET_ALERT,
        message,
        style,
        status,
    });
    
}