import { combineReducers } from "redux";

/* Reducers */
import login from "./login";
import register from "./register";
import resetPassword from "./reset-password";
import user from "./user";
import initialSetup from "./initial-setup";
import idealPosition from "./ideal-position";
import experience from "./experience";
import education from "./education";
import reference from "./reference";
import job from "./job";
import application from "./application";
import admin from "./admin";
import misc from "./misc";

export default combineReducers({
    login,
    register,
    resetPassword,
    user,
    initialSetup,
    idealPosition,
    experience,
    education,
    reference,
    job,
    application,
    admin,
    misc,
});