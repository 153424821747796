
import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";
import { setReference, resetReference } from "../../../../redux/actions/reference";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import RegularTextInput from "../../../../components/RegularTextInput";
import SaveButton from "../../../../components/SaveButton";
import SaveCancel from "../../../../components/SaveCancel";
import Accordion from "../../../../components/Accordion";
import Button from "../../../../components/Button";

/* Constants */
import { candidateReferencesProps } from "../../../../constants";

class References extends Component {

    constructor(props) {
        super(props);
        this.referenceFormRef = React.createRef();

        this.state = {
            showRef: false,
            expandedRef: [],
            cancelData: null,
        };

    }

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    saveRef = (referenceName, companyName, position, contactNumber, email) => {

        if (referenceName && companyName && position && (contactNumber || email)) {

            const reference = { referenceName, companyName, position, contactNumber, email };

            const value = [...this.props.user.data.profile.references, reference];

            this.props.updateUserProfile([{ propName: "references", value: value }], true);

            let dashboard = document.getElementById("Dashboard");
            let y = document.getElementById("References").offsetTop - 80;
            dashboard.scroll(0, y);

            this.setState({ showRef: false, cancelData: null });

            return this.props.resetReference();
        

        } else {
            return alert("Reference name, company name, position and phone number or email are required.");
        }

    }

    cancelRef = (data) => {

        if (!data) return this.setState({ showRef: false }, () => { return this.props.resetReference() });

        return this.setState({ showRef: false }, () => {

            const value = [...this.props.user.data.profile.references, data];
                
            this.props.updateUserProfile([{ propName: "references", value: value }], false);

            let dashboard = document.getElementById("Dashboard");
            let y = document.getElementById("References").offsetTop - 80;
            dashboard.scroll(0, y);

            this.setState({ cancelData: null });
            
            return this.props.resetReference();
            
            
        });

    }

    handleRefExpand = (index) => {

        if (!this.state.expandedRef.includes(index)) {
            return this.setState({ expandedRef: [...this.state.expandedRef, index] });
        } else {
            return this.setState({ expandedRef: this.state.expandedRef.filter(item => item !== index) });
        }
        
    }

    handleEdit = (item, index) => {

        this.props.updateUserProfile([ { propName: "references", value: this.props.user.data.profile.references.filter((_, idx) => idx !== index) } ]);

        return this.setState({ showRef: true, cancelData: item }, () => {
            this.props.setReference([
                { propName: "referenceName", value: item.referenceName },
                { propName: "companyName", value: item.companyName },
                { propName: "position", value: item.position },
                { propName: "contactNumber", value: item.contactNumber },
                { propName: "email", value: item.email },
            ]);
            return setTimeout(() => {
                return this.referenceFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 125);
        });

    }

    handleRemove = (index) => {

        const value = this.props.user.data.profile.references.filter((_, idx) => idx !== index);

        return this.props.updateUserProfile([ { propName: "references", value: value } ], true); 

    }

    handleArchive = (index) => {

        const value = this.props.user.data.profile.references.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    archived: true,
                }
            } else {
                return item;
            }
        });

        return this.props.updateUserProfile([ { propName: "references", value: value } ], true);

    }

    handleAddingNewReference = (showRef) => {
        this.setState({ showRef: !showRef }, () => {
            if (!showRef) this.referenceFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }

    render() {

        const { profile } = this.props.user.data;
        const { reference } = this.props;

        return(
            <>
                <DashboardSectionBase title={"References"} description={"Add and edit your references."}>
                    <div className="margin-top-20px" id="References">
                        <Button type={"tertiary"} title={this.state.showRef ? "Hide Adding Reference" : "Add Reference"} onClick={() => this.handleAddingNewReference(this.state.showRef)} />
                    </div>
                    {profile?.references?.length !== 0 &&
                        <div className="margin-top-2x">
                            <Accordion propName="references" items={profile.references} expanded={this.state.expandedRef} onExpand={this.handleRefExpand} edit={this.handleEdit} remove={this.handleRemove} archive={this.handleArchive} />
                        </div>
                    }
                    <div ref={this.referenceFormRef}></div>
                    {this.state.showRef &&
                        <>
                            <div className="margin-top-2x">
                                <RegularTextInput label={"Referee Name"} placeholder={"Type..."} value={reference.data.referenceName} propName={"referenceName"} onChangeText={this.props.setReference} />
                            </div>
                            <div className="margin-top-20px">
                                <RegularTextInput label={"Referee Position"} placeholder={"Type..."} value={reference.data.position} propName={"position"} onChangeText={this.props.setReference} />
                            </div>
                            <div className="margin-top-20px">
                                <RegularTextInput label={"Yacht, Ship or Company name"} placeholder={"Type..."} value={reference.data.companyName} propName={"companyName"} onChangeText={this.props.setReference} />
                            </div>
                            <div className="margin-top-20px">
                                <RegularTextInput label={"Referee Email Address"} placeholder={"Type..."} value={reference.data.email} propName={"email"} onChangeText={this.props.setReference} />
                            </div>
                            <div className="margin-top-20px">
                                <RegularTextInput label={"Phone Number"} placeholder={"Type..."} value={reference.data.contactNumber} propName={"contactNumber"} onChangeText={this.props.setReference} />
                            </div>
                            <div className="margin-top-20px">
                                <SaveCancel onSave={() => this.saveRef(reference.data.referenceName, reference.data.companyName, reference.data.position, reference.data.contactNumber, reference.data.email)} onCancel={() => this.cancelRef(this.state.cancelData)} />
                            </div>
                        </>
                    }
                </DashboardSectionBase>
                <SaveButton propNames={candidateReferencesProps} profile={profile} updateUserProfile={this.props.updateUserProfile} previous={"/profile/experience"} next="/profile/documents" history={this.props.history} />
            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        reference: state.reference,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile, setReference, resetReference }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(References);