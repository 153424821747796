import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

/* Components */
import { SidebarMenuButton, SidebarMenuSubButton } from "../SidebarMenuButtons";
import SidebarDownloads from "../SidebarDownloads";

/* Constants */
import { signUpsPaths } from "../../constants";

/* Actions */
import { setListing } from "../../redux/actions/admin";
import { setDeleteAccount, setAlert, setDeleteLocalYachtAccount, setViewListing } from "../../redux/actions/misc";

/* API requests */
import { updateCandidate } from "../../api-requests/candidate";
import { handlePutForward, rejectApplication, restoreApplication } from "../../api-requests/application";
import { archiveJob, getJob } from "../../api-requests/job";

class SidebarAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            downloads: false,
        };

    }

    handleReject = (user, history) => {

        let message = `${user.firstName} ${user.lastName} rejected!`;
        let body = [
            { propName: "requestedVerification", value: false },
            { propName: "requestedVerificationDate", value: null },
            { propName: "isRejected", value: true },
        ];

        return updateCandidate(user.profile._id, body)
            .then(_ => {
                history.push("/sign-ups");
                this.props.setAlert(message, "success", true);

                setTimeout(() => {
                    return this.props.setAlert(message, "success", false);
                }, 2700);

                return setTimeout(() => {
                    return setAlert("", "success", false);
                }, 3000);
            });

    }

    handleVerify = (user, history) => {

        let message = `${user.firstName} ${user.lastName} verified!`;
        let body = [
            { propName: "isVerified", value: true },
        ];

        return updateCandidate(user.profile._id, body)
            .then(_ => {
                history.push("/sign-ups");
                this.props.setAlert(message, "success", true);

                setTimeout(() => {
                    return this.props.setAlert(message, "success", false);
                }, 2700);

                return setTimeout(() => {
                    return setAlert("", "success", false);
                }, 3000);
            });

    }

    putForward = (_id) => {

        return handlePutForward(_id)
            .then(result => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}/${result.nextStage}`);
                } else {
                    this.props.history.push(`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}/${result.nextStage}`);
                }
                this.props.setAlert("Candidate put forward successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Candidate put forward successfully!", "success", false);
                }, 2700);
            });

    }

    rejectApplication = (_id) => {

        return rejectApplication(_id)
            .then(_ => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}`);
                } else {
                    this.props.history.push(`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}`);
                }
                this.props.setAlert("Candidate rejected successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Candidate rejected successfully!", "success", false);
                }, 2700);
            });

    }

    restoreApplication = (_id) => {

        return restoreApplication(_id)
            .then(_ => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}`);
                } else {
                    this.props.history.push(`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}`);
                }
                this.props.setAlert("Application restored successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Application restored successfully!", "success", false);
                }, 2700);
            });

    }

    archiveListing = (_id) => {

        return archiveJob(_id)
            .then(_ => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/archived`);
                } else {
                    this.props.history.push(`/listings/archived`);
                }
                this.props.setAlert("Listing archived successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Listing archived successfully!", "success", false);
                }, 2700);
            });

    }

    viewListing = (_id) => {

        return getJob(_id)
            .then(result => {
                this.props.setListing(result);
                return this.props.setViewListing(true);
            });

    }

    render() {

        return(
            <div className="sidebar-admin-container">
                <SidebarMenuButton active={signUpsPaths.includes(this.props.match.path) || this.props.match.path.includes("/sign-ups/profile")} title={"Sign Ups"} path={"/sign-ups"} userType={"Admin"} num={this.props.admin.completedUsersCount} />
                <div className="margin-top-20px">
                    <SidebarMenuButton active={this.props.match.path.includes("/crew")} title={"Crew"} path={"/crew"} />
                </div>
                <div className="margin-top-20px">
                    <SidebarMenuButton active={this.props.match.path.includes("/yachts")} title={"Yachts"} path={this.props.match.params.yachtId ? "/yachts/edit/"+this.props.match.params.yachtId : "/yachts"} />
                    {(this.props.match.path.includes("/yachts/edit") || this.props.match.path.includes("/yachts/listings")) &&
                        <>
                            <SidebarMenuSubButton active={this.props.match.path.includes("/yachts/listings")} title={"Listings"} path={"/yachts/listings/"+this.props.match.params.yachtId} />
                            <SidebarMenuSubButton active={this.props.match.path.includes("/yachts/edit")} title={"Yacht Details"} path={"/yachts/edit/"+this.props.match.params.yachtId} />
                        </>
                    }
                </div>
                <div className="margin-top-20px">
                    <SidebarMenuButton active={this.props.match.path.includes("/listings") && !this.props.match.path.includes("/yachts")} title={"Listings"} path={"/listings"} />
                </div>
                <div className="margin-top-20px">
                    <SidebarMenuButton active={this.props.match.path === "/stats"} title={"Stats"} path={"/stats"} />
                </div>
                <div className="margin-top-20px">
                    <SidebarMenuButton active={this.props.match.path === "/references"} title={"References"} path={"/references"} />
                </div>
                <div className="margin-top-20px">
                    <SidebarMenuButton active={this.props.match.path === "/jobs"} title={"Jobs"} path={"/jobs"} />
                </div>
                {(this.props.match.path.includes("/sign-ups/profile/") || this.props.match.path.includes("/crew/profile/")) && this.props.admin.cvProfile &&
                    <>
                        <div className="sidebar-admin-actions-container margin-top-20px">
                            <div className="sidebar-admin-action-header-container">
                                <div className="sidebar-admin-action-wrapper">
                                    <p>Candidate Actions</p>
                                </div>
                            </div>
                            {!this.props.admin.cvProfile.profile.isVerified &&
                                <div className="sidebar-admin-action-button-container" onClick={() => this.handleVerify(this.props.admin.cvProfile, this.props.history)}>
                                    <div className="sidebar-admin-action-wrapper">
                                        <img src={require("../../assets/svgs/verify.svg")} alt="" />
                                        <p style={{color: "#1377FF"}}>Verify Candidate</p>
                                    </div>
                                </div>
                            }
                            {this.props.admin.cvProfile.profile.requestedVerification && !this.props.admin.cvProfile.profile.isVerified &&
                                <div className="sidebar-admin-action-button-container" onClick={() => this.handleReject(this.props.admin.cvProfile, this.props.history)}>
                                    <div className="sidebar-admin-action-wrapper">
                                        <img src={require("../../assets/svgs/reject.svg")} alt="" />
                                        <p style={{color: "#333333"}}>Reject Candidate</p>
                                    </div>
                                </div>
                            }
                            <div className="sidebar-admin-action-button-container" onClick={() => this.props.setDeleteAccount(true, true)}>
                                <div className="sidebar-admin-action-wrapper">
                                    <img src={require("../../assets/svgs/trash.svg")} alt="" />
                                    <p style={{color: "#EB5757"}}>Delete Account</p>
                                </div>
                            </div>
                        </div>
                        <div className="margin-top-20px">
                            <SidebarDownloads admin={true} show={this.state.downloads} toggle={() => this.setState({ downloads: !this.state.downloads })} user={this.props.admin.cvProfile} originalCV={this.props.admin.cvProfile.profile.cv} />
                        </div>
                        <div className="sidebar-admin-return-button-container margin-top-10px">
                            <Link to={this.props.admin?.cvProfile?.profile?.isVerified ? "/crew/" : "/sign-ups/"} className="sidebar-admin-return-button-wrapper">
                                <img src={require("../../assets/svgs/return.svg")} alt="" />
                                <p>{this.props.admin?.cvProfile?.profile?.isVerified ? "Return to Crew" : "Return to Sign Ups"}</p>
                            </Link>
                        </div>
                    </>
                }
                {this.props.match.params.tab === "application" && this.props.match.params.applicationId && this.props.admin.cvProfile && this.props.admin.cvApplication &&
                    <>
                        <div className="sidebar-admin-actions-container margin-top-20px">
                            <div className="sidebar-admin-action-header-container">
                                <div className="sidebar-admin-action-wrapper">
                                    <p>Candidate Actions</p>
                                </div>
                            </div>
                            {this.props.admin.cvApplication.status !== "hired" && !this.props.admin.cvApplication.rejected &&
                                <div className="sidebar-admin-action-button-container">
                                    <div className="sidebar-admin-action-wrapper" onClick={() => this.putForward(this.props.match.params.applicationId)}>
                                        <img src={require("../../assets/svgs/put-forward.svg")} alt="" />
                                        <p style={{color: "#1377FF"}}>Put Forward</p>
                                    </div>
                                </div>
                            }
                            {this.props.admin.cvApplication.rejected &&
                                <div className="sidebar-admin-action-button-container">
                                    <div className="sidebar-admin-action-wrapper" onClick={() => this.restoreApplication(this.props.match.params.applicationId)}>
                                        <img src={require("../../assets/svgs/put-forward.svg")} alt="" />
                                        <p style={{color: "#1377FF"}}>Restore Application</p>
                                    </div>
                                </div>
                            }
                            {!this.props.admin.cvApplication.rejected &&
                                <div className="sidebar-admin-action-button-container">
                                    <div className="sidebar-admin-action-wrapper" onClick={() => this.rejectApplication(this.props.match.params.applicationId)}>
                                        <img src={require("../../assets/svgs/trash.svg")} alt="" />
                                        <p style={{color: "#F55D5D"}}>Reject Candidate</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="margin-top-20px">
                            <SidebarDownloads admin={true} show={this.state.downloads} toggle={() => this.setState({ downloads: !this.state.downloads })} user={this.props.admin.cvProfile} originalCV={this.props.admin.cvProfile.profile.cv} />
                        </div>
                    </>
                }
                {this.props.match.path.includes("/yachts/") && this.props.match.params.yachtId  && this.props.match.params.status !== "applications" &&
                    <div className="delete-local-yacht-account-button margin-top-20px" onClick={() => this.props.setDeleteLocalYachtAccount(true, this.props.match.params.yachtId)}>
                        <div className="delete-local-yacht-account-button-icon-container">
                            <img src={require("../../assets/svgs/trash.svg")} alt="" />
                        </div>
                        <div className="delete-local-yacht-account-button-title-container">
                            <p>Delete Account</p>
                        </div>
                    </div>
                }
                {(this.props.match.path === "/yachts/create" || this.props.match.path.includes("/yachts/edit") || this.props.match.path.includes("/yachts/listings")) && this.props.match.params.status !== "add" && this.props.match.params.status !== "applications" &&
                    <div className="sidebar-admin-return-button-container margin-top-10px">
                        <Link to={"/yachts"} className="sidebar-admin-return-button-wrapper">
                            <img src={require("../../assets/svgs/return.svg")} alt="" />
                            <p>Return to Yachts</p>
                        </Link>
                    </div>
                }
                {(this.props.match.params.status === "add" || this.props.match.params.status === "applications") && !this.props.match.params.applicationId && this.props.match.params.jobId && this.props.match.params.tab !== "application" && this.props.match.params.yachtId &&
                    <>
                        <div className="sidebar-admin-actions-container margin-top-20px">
                            <div className="sidebar-admin-action-header-container">
                                <div className="sidebar-admin-action-wrapper">
                                    <p>Listing Actions</p>
                                </div>
                            </div>
                            <div className="sidebar-admin-action-button-container" onClick={() => this.props.history.push(this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.props.match.params.yachtId}/add?job=${this.props.match.params.jobId}&status=active` : `/listings/add/${this.props.match.params.yachtId}?job=${this.props.match.params.jobId}&status=active`)}>
                                <div className="sidebar-admin-action-wrapper">
                                    <img src={require("../../assets/svgs/edit-dark.svg")} alt="" />
                                    <p style={{color: "#333333"}}>Edit Listing</p>
                                </div>
                            </div>
                            <div className="sidebar-admin-action-button-container">
                                <div className="sidebar-admin-action-wrapper" onClick={() => this.archiveListing(this.props.match.params.jobId)}>
                                    <img src={require("../../assets/svgs/end-listing.svg")} alt="" />
                                    <p style={{color: "#333333"}}>End & Archive Listing</p>
                                </div>
                            </div>
                            <div className="sidebar-admin-action-button-container">
                                <div className="sidebar-admin-action-wrapper" onClick={() => this.viewListing(this.props.match.params.jobId)}>
                                    <img src={require("../../assets/svgs/eye.svg")} alt="" />
                                    <p style={{color: "#333333"}}>View Listing</p>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {this.props.match.path.includes("/yachts/") && (this.props.match.params.status === "add" || this.props.match.params.status === "applications") && !this.props.match.params.applicationId && this.props.match.params.tab !== "application" && this.props.match.params.yachtId &&
                    <div className="sidebar-admin-return-button-container margin-top-10px">
                        <Link to={`/yachts/listings/${this.props.match.params.yachtId}`} className="sidebar-admin-return-button-wrapper">
                            <img src={require("../../assets/svgs/return.svg")} alt="" />
                            <p>Return to Listings</p>
                        </Link>
                    </div>
                }
                {!this.props.match.path.includes("/yachts/") && this.props.match.path.includes("/listings/") && (this.props.match.params.status === "add" || this.props.match.params.status === "applications") && this.props.match.params.tab !== "application" && this.props.match.params.yachtId &&
                    <div className="sidebar-admin-return-button-container margin-top-10px">
                        <Link to={`/listings`} className="sidebar-admin-return-button-wrapper">
                            <img src={require("../../assets/svgs/return.svg")} alt="" />
                            <p>Return to Listings</p>
                        </Link>
                    </div>
                }
                {this.props.match.path.includes("/yachts/") && this.props.match.params.yachtId && this.props.match.params.jobId && this.props.match.params.applicationId && this.props.match.params.tab === "application" &&
                    <div className="sidebar-admin-return-button-container margin-top-10px">
                        <Link to={`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}`} className="sidebar-admin-return-button-wrapper">
                            <img src={require("../../assets/svgs/return.svg")} alt="" />
                            <p>Return to Applications</p>
                        </Link>
                    </div>
                }
                {!this.props.match.path.includes("/yachts/") && this.props.match.params.yachtId && this.props.match.params.jobId && this.props.match.params.applicationId && this.props.match.params.tab === "application" &&
                    <div className="sidebar-admin-return-button-container margin-top-10px">
                        <Link to={`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}`} className="sidebar-admin-return-button-wrapper">
                            <img src={require("../../assets/svgs/return.svg")} alt="" />
                            <p>Return to Applications</p>
                        </Link>
                    </div>
                }
                <div style={{ height: 40 }}>
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setDeleteAccount, setAlert, setDeleteLocalYachtAccount, setViewListing, setListing }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAdmin);