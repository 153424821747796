import {
    SET_JOBS,
    REMOVE_JOB,
} from "../types/job";

const initialState = {
    isFetching: true,
    jobs: [],
};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_JOBS:

            return {
                ...state,
                isFetching: false,
                jobs: action.data,
            }

        case REMOVE_JOB:

            return {
                ...state,
                jobs: state.jobs.filter(job => job._id !== action.job._id),
            }
        
        default:
            return state;

    }

};