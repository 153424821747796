export const updateEmployer = (id, data) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id,
            data,
        }),
    };

    return fetch("/api/employers/update/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}