import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

export const SidebarMenuButton = ({ active, title, expandable, path, collapse, userType, num }) => {

    return(
        <Link to={path} onClick={() => handleCollpase(collapse)}>
            <div className="sidebar-menu-button-container" style={{backgroundColor: active ? "#1377FF" : "#FFFFFF"}}>
                <div className="sidebar-menu-button-icon-container">
                    {handleIcon(path, active, userType)}
                </div>
                <div className="sidebar-menu-button-title-container">
                    <p className="sidebar-menu-button-title" style={{color: active ? "#FFFFFF" : "#333333"}}>{title}</p>
                </div>
                {num > 0 &&
                    <div className="sidebar-menu-number">
                        <span>{num}</span>
                    </div>
                }
                {expandable &&
                    <div className="sidebar-menu-button-icon-container">
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 9L12 15L18 9" stroke={active ? "#FFFFFF" : "#333333"} transform={active ? "rotate(180 12 12)" : "rotate(0 12 12)"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                }
            </div>
        </Link>
    );

}

export const SidebarMenuSubButton = ({ active, title, path, logout, handleLogout, collapse }) => {

    if (logout) {
        return(
            <div className="sidebar-menu-button-container" onClick={() => handleLogout(true)}>
                <div className="sidebar-menu-button-icon-container">
                    <img src={require("../../assets/svgs/log-out.svg")} alt="" />
                </div>
                <div className="sidebar-menu-button-title-container">
                    <p className="sidebar-menu-button-title" style={{color: active ? "#1377FF" : "#333333"}}>{title}</p>
                </div>
            </div>
        );
    } else {
        return(
            <Link to={path} onClick={() => handleCollpase(collapse)}>
                <div className="sidebar-menu-button-container">
                    <div className="sidebar-menu-button-icon-container">
                        {handleSubIcon(path, active)}
                    </div>
                    <div className="sidebar-menu-button-title-container">
                        <p className="sidebar-menu-button-title" style={{color: active ? "#1377FF" : "#333333"}}>{title}</p>
                    </div>
                </div>
            </Link>
        );   
    }

}

const handleCollpase = (collapse) => {

    if (collapse) {
        return collapse(false);
    }
    return;

}

const handleIcon = (path, active, userType) => {

    if (path === "/") {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 3H14C15.1046 3 16 3.89543 16 5V6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8C2 6.89543 2.89543 6 4 6H8V5C8 3.89543 8.89543 3 10 3ZM4 8H8H16H20V13H13H11H4V8ZM4 19V15H11V16H13V15H20V19H4ZM14 5V6H10V5H14Z" fill={active ? "#FFFFFF" : "#333333"} />
            </svg>
        );    
    }

    if (path === "/sign-ups") {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 21V19C16 16.7909 14.2091 15 12 15H5C2.79086 15 1 16.7909 1 19V21" stroke={active ? "#FFFFFF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z" stroke={active ? "#FFFFFF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 8V14" stroke={active ? "#FFFFFF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M23 11H17" stroke={active ? "#FFFFFF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (path.includes("/profile")) {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 21V19C20 16.7909 18.2091 15 16 15H8C5.79086 15 4 16.7909 4 19V21" stroke={active ? "#FFFFFF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke={active ? "#FFFFFF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (path.includes("/crew")) {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM19.3995 17.1246C20.4086 15.6703 21 13.9042 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.9042 3.59138 15.6703 4.6005 17.1246C5.72595 15.6381 8.3706 15 12 15C15.6294 15 18.274 15.6381 19.3995 17.1246ZM17.9647 18.7398C17.672 17.6874 15.5694 17 12 17C8.43062 17 6.328 17.6874 6.03532 18.7398C7.6233 20.1462 9.71194 21 12 21C14.2881 21 16.3767 20.1462 17.9647 18.7398ZM12 15C9.76086 15 8 13.4274 8 10C8 7.75576 9.5791 6 12 6C14.4142 6 16 7.92158 16 10.2C16 13.4796 14.2181 15 12 15ZM10 10C10 12.2693 10.8182 13 12 13C13.1777 13 14 12.2984 14 10.2C14 8.95042 13.2157 8 12 8C10.7337 8 10 8.81582 10 10Z" fill={active ? "#FFFFFF" : "#333333"} />
            </svg>
        );
    }

    if (path.includes("/yachts")) {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7 8V11.5L3 14.5V20H7C7 21.1046 7.89543 22 9 22H11V23H13V22H15C16.1046 22 17 21.1046 17 20H21V14.5L17 11.5V8C17 4.84392 15.0864 1 12 1C8.91356 1 7 4.84392 7 8ZM13 20H15V10V8C15 5.80724 13.6025 3 12 3C10.3975 3 9 5.80724 9 8V10V20H11V14H13V20ZM5 15.5L7 14V18H5V15.5ZM17 18V14L19 15.5V18H17ZM12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 10.1046 13.1046 11 12 11Z" fill={active ? "white" : "#333333"} />
            </svg>
        );
    }

    if (path.includes("/listings")) {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 3H14C15.1046 3 16 3.89543 16 5V6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8C2 6.89543 2.89543 6 4 6H8V5C8 3.89543 8.89543 3 10 3ZM4 8H8H16H20V13H13H11H4V8ZM4 19V15H11V16H13V15H20V19H4ZM14 5V6H10V5H14Z" fill={active ? "white" : "#333333"} />
            </svg>
        );
    }

    if (path.includes("/stats")) {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23 6L13.5 15.5L8.5 10.5L1 18" stroke={active ? "white" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 6H23V12" stroke={active ? "white" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (path.includes("/references")) {
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.0868 18L6 21.8042V18H4C2.89543 18 2 17.1046 2 16V4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V16C22 17.1046 21.1046 18 20 18H12.0868ZM8 18.1958L11.5132 16H20V4H4V16H8V18.1958ZM11 6V9H8V11H11V14H13V11H16V9H13V6H11Z" fill={active ? "white" : "#333333"} />
            </svg>
        );
    }

    if (path.includes("/jobs")) {
        return(
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 3.10457 0.89543 4 2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2ZM6 1H20V3H6V1ZM20 7H6V9H20V7ZM6 15H20V13H6V15ZM0 14C0 15.1046 0.89543 16 2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.89543 12 0 12.8954 0 14ZM2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10Z" fill={active ? "white" : "#333333"} />
            </svg>
        );
    }

}

const handleSubIcon = (path, active) => {

    if (path === "/profile") {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 13.66V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H10.34" stroke={active ? "#1377FF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18 2L22 6L12 16H8V12L18 2V2Z" stroke={active ? "#1377FF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (path === "/profile/cv") {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke={active ? "#1377FF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke={active ? "#1377FF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (path === "/settings") {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke={active ? "#1377FF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.4 15C19.1277 15.6171 19.2583 16.3378 19.73 16.82L19.79 16.88C20.1656 17.2551 20.3766 17.7642 20.3766 18.295C20.3766 18.8258 20.1656 19.3349 19.79 19.71C19.4149 20.0856 18.9058 20.2966 18.375 20.2966C17.8442 20.2966 17.3351 20.0856 16.96 19.71L16.9 19.65C16.4178 19.1783 15.6971 19.0477 15.08 19.32C14.4755 19.5791 14.0826 20.1724 14.08 20.83V21C14.08 22.1046 13.1846 23 12.08 23C10.9754 23 10.08 22.1046 10.08 21V20.91C10.0642 20.2327 9.63587 19.6339 9 19.4C8.38291 19.1277 7.66219 19.2583 7.18 19.73L7.12 19.79C6.74486 20.1656 6.23582 20.3766 5.705 20.3766C5.17418 20.3766 4.66514 20.1656 4.29 19.79C3.91445 19.4149 3.70343 18.9058 3.70343 18.375C3.70343 17.8442 3.91445 17.3351 4.29 16.96L4.35 16.9C4.82167 16.4178 4.95235 15.6971 4.68 15.08C4.42093 14.4755 3.82764 14.0826 3.17 14.08H3C1.89543 14.08 1 13.1846 1 12.08C1 10.9754 1.89543 10.08 3 10.08H3.09C3.76733 10.0642 4.36613 9.63587 4.6 9C4.87235 8.38291 4.74167 7.66219 4.27 7.18L4.21 7.12C3.83445 6.74486 3.62343 6.23582 3.62343 5.705C3.62343 5.17418 3.83445 4.66514 4.21 4.29C4.58514 3.91445 5.09418 3.70343 5.625 3.70343C6.15582 3.70343 6.66486 3.91445 7.04 4.29L7.1 4.35C7.58219 4.82167 8.30291 4.95235 8.92 4.68H9C9.60447 4.42093 9.99738 3.82764 10 3.17V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3V3.09C14.0026 3.74764 14.3955 4.34093 15 4.6C15.6171 4.87235 16.3378 4.74167 16.82 4.27L16.88 4.21C17.2551 3.83445 17.7642 3.62343 18.295 3.62343C18.8258 3.62343 19.3349 3.83445 19.71 4.21C20.0856 4.58514 20.2966 5.09418 20.2966 5.625C20.2966 6.15582 20.0856 6.66486 19.71 7.04L19.65 7.1C19.1783 7.58219 19.0477 8.30291 19.32 8.92V9C19.5791 9.60447 20.1724 9.99738 20.83 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H20.91C20.2524 14.0026 19.6591 14.3955 19.4 15Z" stroke={active ? "#1377FF" : "#333333"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (path.includes("/yachts/edit")) {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2 6C2 7.10457 2.89543 8 4 8C5.10457 8 6 7.10457 6 6C6 4.89543 5.10457 4 4 4C2.89543 4 2 4.89543 2 6ZM8 5H22V7H8V5ZM22 11H8V13H22V11ZM8 19H22V17H8V19ZM2 18C2 19.1046 2.89543 20 4 20C5.10457 20 6 19.1046 6 18C6 16.8954 5.10457 16 4 16C2.89543 16 2 16.8954 2 18ZM4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14Z" fill={active ? "#1377FF" : "#333333"} />
            </svg>
        );
    }

    if (path.includes("/yachts/listings")) {
        return(
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 3H14C15.1046 3 16 3.89543 16 5V6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8C2 6.89543 2.89543 6 4 6H8V5C8 3.89543 8.89543 3 10 3ZM4 8H8H16H20V13H13H11H4V8ZM4 19V15H11V16H13V15H20V19H4ZM14 5V6H10V5H14Z" fill={active ? "#1377FF" : "#333333"} />
            </svg>
        );
    }

}