import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";

/* Actions */
import { updateUserProfile } from "../../redux/actions/user";

/* API requests */
import { updateCandidate } from "../../api-requests/candidate";

class SelectLocation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            address: "",
        };

    }

    componentDidMount() {

        if (this.props.user.data.profile.currentLocation) {
            return this.setState({ address: this.props.user.data.profile.currentLocation.name });
        }

    }

    handleChange = (value) => {
        return this.setState({ address: value });
    };
    
    handleSelect = (id, address) => {

        return geocodeByAddress(address)
            .then(results => {
                return getLatLng(results[0])
                    .then(data => {

                        const splitAddress = results[0].formatted_address.split(", ");
                        
                        const country = splitAddress[splitAddress.length-1],
                            name = results[0].formatted_address;

                        const body = [
                            {
                                propName: "currentLocation", value: {
                                    country: country,
                                    name: name,
                                    lat: data.lat,
                                    lng: data.lng,
                                },
                            },
                        ];

                        return this.setState({ address: name }, () => {
                            return updateCandidate(id, body)
                                .then(result => {
                                    return this.props.updateUserProfile(result.data);
                                });
                        });
                    });
            })
            .catch(error => {
                return console.log(error);
            });

    };

    render() {

        const { profile } = this.props.user.data;

        return(
            <PlacesAutocomplete
                    value={this.state.address}
                    onChange={(value) => this.handleChange(value)}
                    onSelect={(value) => this.handleSelect(profile._id, value)}
                >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="select-location-dropdown-master-container">
                        <div className={suggestions.length < 1 ? "select-location-dropdown-container" : "select-location-dropdown-container select-location-dropdown-open-container"}>
                            <div className="select-location-dropdown-icon-container">
                                <img src={require("../../assets/svgs/pin.svg")} alt="" />
                            </div>
                            <div className="select-location-dropdown-main-container">
                                <div className="select-location-dropdown-main-item-title-container">
                                    <p className="select-location-dropdown-main-label">Your Location</p>
                                </div>
                                <div className="select-location-dropdown-main-item-value-container">
                                    <input {...getInputProps({placeholder: "Search..." })} className="select-location-value-input" />
                                </div>
                            </div>
                        </div>
                        <div className="select-location-autocomplete-dropdown-container" style={{display: suggestions.length < 1 ? "none" : "flex"}}>
                            {suggestions.map((suggestion, index) => {
                                return(
                                    <div className="select-location-autocomplete-dropdown-item-container" {...getSuggestionItemProps(suggestion)} style={index < 1 ? {border: "none"} : {}} key={index}>
                                        <p>{suggestion.description}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        ); 

    }  

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocation);