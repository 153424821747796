import React from "react";
import "./index.css";

export default ({ options, updateState, selected, propName }) => {

    return(
        <div className="reference-verification-checkboxes-container">
            <div className="reference-verification-checkboxes-title-container">
                <p>What was the reason for leaving?</p>
            </div>
            <div className="reference-verification-checkbox-list-container">
                {renderCheckboxes(options, selected, propName, updateState)}
            </div>
        </div>
    );

}

const renderCheckboxes = (options, selected, propName, updateState) => {

    return options.map((option, index) => {
        return(
            <div className="reference-verification-checkbox-container margin-top-1x" key={index}>
                <div className="reference-verification-checkbox-button-container" style={option === selected ? {backgroundColor: "#1377FF"} : {backgroundColor: "#FFFFFF"}} onClick={() => updateState([propName], [option], true)}>
                    {option === selected &&
                        <img src={require("../../../assets/svgs/tick.svg")} alt="" />
                    }          
                </div>
                <div className="reference-verification-checkbox-title-container">
                    <p>{option}</p>
                </div>
            </div>
        );
    });

}