import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

/* Constants */
import { candidateProfileHeaderLinks, candidateJobsHeaderLinks } from "../../constants";

/* Components */
import EditPhoto from "../EditPhoto";
import { compareArchived } from "../../helpers/job";

export default ({ user, match, editPhoto, newJobs, allJobs, applications }) => {

    return(
        <>
            <div className="candidate-header-container">
                <div className="candidate-header-data-container">
                    <div style={{paddingRight: 30}}>
                        <EditPhoto profileImage={user?.data?.profile?.profileImage} editPhoto={editPhoto} size={96} />
                    </div>
                    <div className="candidate-header-data-text-wrapper">
                        <div className="candidate-header-data-name-wrapper">
                            <p className="candidate-header-data-name">{user.data.profile.firstName} {user.data.profile.lastName}</p>
                            {user.data.profile.isVerified &&
                                <img src={require("../../assets/svgs/verified.svg")} alt="" />
                            }
                        </div>
                        {user.data.profile?.lookingFor?.desiredRoles &&
                            <p className="candidate-header-data-title">{user.data.profile.lookingFor.desiredRoles[0]?.position[0]?.name || ""}</p>
                        }   
                    </div>
                </div>
                <div className="candidate-header-menu-container">
                    <div className="candidate-header-menu-wrapper">
                        {match.path.includes("/profile") ?
                            renderMenuLinks(candidateProfileHeaderLinks, match.path, "profile")
                        :
                            renderMenuLinks(candidateJobsHeaderLinks, match.path, "jobs", applications)
                        }
                    </div>
                </div>
            </div>
            <div className="candidate-mobile-header-container">
                <div className="candidate-mobile-header-title-container">
                    <p className="candidate-mobile-header-title">{match.path.includes("/profile") ? "Profile" : "Jobs"}</p>
                </div>
                {match.path.includes("/profile") ?
                    renderMobileMenuLinks(candidateProfileHeaderLinks, match.path)
                :
                    renderMobileMenuLinks(candidateJobsHeaderLinks, match.path)
                }
            </div>
        </>
    );

}

const renderMenuLinks = (links, path, type, applications) => {

    return links.map((link, index) => {
        return(
            <Link to={link.path} key={index}>
                <div className={type === "profile" ? "candidate-header-menu-item-container" : "candidate-header-menu-job-item-container"}>
                    <p className="candidate-header-menu-item-text">{link.name}{handleNumberOfItems(link.path, applications)}</p>
                    {path === link.path &&
                        <div className="candidate-header-menu-item-active"></div>
                    }
                </div>
            </Link>
        );
    });

}

const renderMobileMenuLinks = (links, path) => {

    return links.map((link, index) => {
        return(
            <Link to={link.path} key={index}>
                <div className="candidate-mobile-item-title-container" style={path === link.path ? {backgroundColor: "#F5F5F5"} : {}}>
                    <p className="candidate-mobile-item-title" style={{color: path === link.path ? "#1377FF" : "#333333"}}>{link.name}</p>
                </div>
            </Link>
        );
    });

}

const handleNumberOfItems = (path, applications) => {
    if (path === "/jobs/applications" && applications.length !== 0) {

        applications = applications.filter(application => {
            return !compareArchived(application.job.archived);
        });

        return ` (${applications.length})`;
    };

    return;

}