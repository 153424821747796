import React from "react";
import "./index.css";

export const ProgressBar = ({ type, profile, updateStep }) => {

    if (type === "Candidate") {
        return(
            <div className="initial-setup-progress-bar-container">
                <Step title={"Photo"} completed={profile.initialSetupCompleted} currentStep={profile.initialSetupStep} index={1} updateStep={updateStep} />
                <div className="initial-setup-progress-bar-point-separator"></div>
                <Step title={"Info"} completed={profile.initialSetupCompleted} currentStep={profile.initialSetupStep} index={2} updateStep={updateStep} />
                <div className="initial-setup-progress-bar-point-separator"></div>
                <Step title={"Position"} completed={profile.initialSetupCompleted} currentStep={profile.initialSetupStep} index={3} updateStep={updateStep} />
                <div className="initial-setup-progress-bar-point-separator"></div>
                <Step title={"Qualifications"} completed={profile.initialSetupCompleted} currentStep={profile.initialSetupStep} index={4} updateStep={updateStep} />                    
            </div>
        );
    } else {
        return(
            <div className="initial-setup-progress-bar-container">
                <Step title={"Yacht Details"} completed={profile.initialSetupCompleted} currentStep={profile.initialSetupStep} index={1} updateStep={updateStep} />
                <div className="initial-setup-progress-bar-point-separator-long"></div>
                <Step title={"More Details"} completed={profile.initialSetupCompleted} currentStep={profile.initialSetupStep} index={2} updateStep={updateStep} />
                <div className="initial-setup-progress-bar-point-separator-long"></div>
                <Step title={"Contact Details"} completed={profile.initialSetupCompleted} currentStep={profile.initialSetupStep} index={3} updateStep={updateStep} />                   
            </div>
        );
    }

}

const Step = ({ title, completed, currentStep = 1, index, updateStep }) => {

    return(
        <div className="initial-setup-progress-bar-point-container" onClick={() => handleClick(completed, index, currentStep, updateStep)} style={{cursor: (completed || index < currentStep) ? "pointer" : "default"}}>
            <div className="initial-setup-progress-bar-point-outline" style={{backgroundColor: (completed || index < currentStep) ? "#1377FF" : "white" }}>
                {currentStep === index && !completed &&
                    <div className="initial-setup-progress-bar-point-inline">
                    </div>
                }
                {(completed || index < currentStep) &&
                    <img src={require("../../../../assets/svgs/tick.svg")} alt="" />
                }
            </div>
            <div className="initial-setup-progress-bar-point-title-container">
                <p className="initial-setup-progress-bar-point-title">{title}</p>
            </div>
        </div>
    );

}

const handleClick = (completed, index, currentStep, updateStep) => {

    if (completed || index < currentStep) {
        updateStep([ { propName: "initialSetupStep", value: index } ]);
        return window.scrollTo(0, 0);
    }

    return;

}