import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

/* Actions */
import { toggleResendEmail } from "../../redux/actions/initial-setup";

/* Components */
import DashboardCandidate from "../DashboardCandidate";
import DashboardAdmin from "../DashboardAdmin";
import Page from "../../components/Page";

class Home extends Component {

    render() {

        let { data, isFetching } = this.props.user;

        if (data && !isFetching && data.userType === "Candidate") {
            if ((data.profile.profileType === "upload" || data.profile.profileType === undefined) && (!data.isVerified || !data.profile.initialSetupCompleted)) return <Redirect to={"/setup"} />;
            else return <DashboardCandidate {...this.props} />;
        }

        if (data && !isFetching && data.userType === "Employer") {
            if (!data.isVerified || !data.profile.initialSetupCompleted) return <Redirect to={"/setup"} />
            else return <Page linear={true}></Page>;
        }

        if (data && !isFetching && data.userType === "Admin") {
            return <DashboardAdmin {...this.props} />;
        }

        return <Page linear={true}></Page>;

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
        user: state.user,
        job: state.job,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ toggleResendEmail }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);