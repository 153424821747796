import React, { Component } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

/* API requests */
import { getYacht } from "../../../../api-requests/yacht";
import { getApplicationsForJob, rejectApplication, restoreApplication, forwardApplication, hireApplication } from "../../../../api-requests/application";

/* Helpers */
import { getJobMatch, timeSinceLastActive } from "../../../../helpers/job";

import { setAlert } from "../../../../redux/actions/misc";
import { getJob, matchUserToJob, sendJobAlerts, getEmailList } from "../../../../api-requests/job";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng, 
} from "react-places-autocomplete";

class Applications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            yacht: {},
            job: {},
            isFetching: true,
            active: [],
            rejected: [],
            forwarded: [],
            hired: [],
            findCandidates: false,
            filterTab: "results",
            filterResults: [],
            isFetchingSearchResults: false,
            sent: [],
            selectedForSending: [],
            allSelected: false,
            resultsSortType: "JOB_MATCH_BEST",
            /* Filters */
            name: "",          
            position: false,
            jobType: false,
            yachtType: false,
            couples: "Include Couples",
            qualifications: false,
            visas: false,
            location: "",
            coords: null,
            nationality: "",
            country: "",
            lastActive: "Don't Match",
            availability: "Don't Match",
            tattoo: false,
            smoking: false,
            yachtLengthMin: "",
            yachtLengthMax: "",
            yachtLengthAny: true,
            ageMin: "",
            ageMax: "",
            ageAny: true,
            salaryMin: "",
            salaryMax: "",
            salaryAny: true,
            salaryCurrency: null,
            profileKeywords: [],
            profileKeyword: "",
            /* Opens */
            positionOpen: false,
            contractOpen: false,
            yachtTypeOpen: false,
            coupleOpen: false,
            qualificationsOpen: false,
            visasOpen: false,
            lastActiveOpen: false,
            availabilityOpen: false,
            tattooOpen: false,
            smokingOpen: false,
            salaryCurrencyOpen: false,
        };

        this.searchTimeOut = null;

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        return getYacht(this.props.match.params.yachtId)
            .then(yacht => {
                return yacht;
            })
            .then(yacht => {
                return getJob(this.props.match.params.jobId)
                    .then(job => { 
                        return job;
                    })
                    .then(job => {
                        return getApplicationsForJob(this.props.match.params.jobId)
                            .then(applications => {
                                return this.setState({ job: job, yacht: yacht, active: applications.active, rejected: applications.rejected, forwarded: applications.forwarded, hired: applications.hired, isFetching: false, }, () => {
                                    return getEmailList(job.emailList)
                                        .then(sent => {
                                            return this.setState({ sent });
                                        });
                                });
                            });
                    });
            });

    }

    checkIfSearching = () => {

        if (this.state.location) return true;
        if (this.state.name && this.state.name.length > 4) return true;
        if (this.state.nationality && this.state.nationality.length > 4) return true;
        if (this.state.country && this.state.country.length > 4) return true;
        if (this.state.name && this.state.name > 4) return true;
        if (this.state.name && this.state.name > 4) return true;
        if (this.state.position) return true;
        if (this.state.jobType) return true;
        if (this.state.yachtType) return true;
        if (this.state.qualifications) return true;
        if (this.state.visas) return true;
        if (this.state.tattoo) return true;
        if (this.state.smoking) return true;
        if (this.state.jobType) return true;
        if (this.state.couples !== "Include Couples") return true;
        if (this.state.lastActive !== "Don't Match") return true;
        if (this.state.availability !== "Don't Match") return true;
        if (this.state.yachtLengthMin || this.state.yachtLengthMax) return true;
        if (this.state.ageMin || this.state.ageMax) return true;
        if (this.state.salaryMin || this.state.salaryMax) return true;
        if (this.state.salaryCurrency) return true;
        if (this.state.profileKeywords && this.state.profileKeywords.length > 0) return true;
        
        return false;

    }

    handleCurrency = (currency) => {

        if (currency === "eur") return "€";
        if (currency === "usd") return "$";
        if (currency === "gbp") return "£";

        return "";

    }

    handleReject = (_id) => {

        return rejectApplication(_id)
            .then(result => {
                if (result.success) {
                    return getApplicationsForJob(this.props.match.params.jobId)
                        .then(data => {
                            return this.setState({ active: data.active, rejected: data.rejected, forwarded: data.forwarded, hired: data.hired  }, () => {
                                this.props.setAlert("Candidate rejected!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Candidate rejected!", "success", false);
                                }, 2700);
                            });
                        });
                }
                return;
            });

    }

    handleRestore = (_id) => {

        return restoreApplication(_id)
            .then(result => {
                if (result.success) {
                    return getApplicationsForJob(this.props.match.params.jobId)
                        .then(data => {
                            return this.setState({ active: data.active, rejected: data.rejected, forwarded: data.forwarded, hired: data.hired  }, () => {
                                this.props.setAlert("Candidate restored!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Candidate restored!", "success", false);
                                }, 2700);
                            });
                        });
                }
                return;
            });

    }

    handleForward = (_id) => {

        return forwardApplication(_id)
            .then(result => {
                if (result.success) {
                    return getApplicationsForJob(this.props.match.params.jobId)
                        .then(data => {
                            return this.setState({ active: data.active, rejected: data.rejected, forwarded: data.forwarded, hired: data.hired }, () => {
                                this.props.setAlert("Candidate forwarded!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Candidate forwarded!", "success", false);
                                }, 2700);
                            });
                        });
                }
                return;
            });

    }

    handleHire = (_id) => {

        return hireApplication(_id)
            .then(result => {
                if (result.success) {
                    return getApplicationsForJob(this.props.match.params.jobId)
                        .then(data => {
                            return this.setState({ active: data.active, rejected: data.rejected, forwarded: data.forwarded, hired: data.hired }, () => {
                                this.props.setAlert("Candidate hired!", "success", true);
                                return setTimeout(() => {
                                    return this.props.setAlert("Candidate hired!", "success", false);
                                }, 2700);
                            });
                        });
                }
                return;
            });

    }

    newOnesFirst = (a, b) => {

        if (a.status && b.status) {
            
            if (a.status < b.status) return -1;
            if (a.status > b.status) return 1;

            if (a.dateApplied > b.dateApplied) return -1;
            if (a.dateApplied < b.dateApplied) return 1;
    
        }   
    
        return 0;
    
    }

    renderRows = (applications, status) => {

        applications = applications.sort(this.newOnesFirst);

        return applications.map((application, index) => {

            let jobMatch = getJobMatch(application.job, application.candidate.profile);

            return(
                <div key={index} className="application-row-container">
                    <Link className="application-row-clickable-container" to={this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.state.yacht._id}/applications/${this.state.job._id}/application/${application._id}` : `/listings/applications/${this.state.yacht._id}/job/${this.state.job._id}/application/${application._id}`}>
                        <div className="application-row-details-container">
                            <div className="application-row-image-container">
                                <img src={application.candidate.profile.profileImage || require("../../../../assets/svgs/avatar-placeholder.svg")} onError={(e) => e.target.src = require("../../../../assets/svgs/avatar-placeholder.svg")} alt="" />
                            </div>
                            <div className="application-row-name-container">
                                <div className="application-row-name-team-container">
                                    <p>{application.candidate.profile.firstName} {application.candidate.profile.lastName}</p>
                                    {application?.candidate?.profile?.couple?.status === "linked" &&
                                        <strong>TEAM</strong>
                                    }
                                </div>
                                {application.status === "applied" && !application.rejected &&
                                    <div className="application-row-image-new-sticker">
                                        <p>New application</p>
                                    </div>
                                } 
                                <div className="application-row-applied-ago-container" style={application.status === "applied" && !application.rejected ? {marginTop: 5} : {}}>
                                    <span>{application.dateApplied}</span>
                                </div>
                            </div>
                        </div>
                        <div className="application-desired-salary-container">
                            <p>{application?.candidate?.profile?.lookingFor?.idealSalaryCurrency && application?.candidate?.profile?.lookingFor?.idealSalary ? this.handleCurrency(application.candidate.profile.lookingFor.idealSalaryCurrency)+application.candidate.profile.lookingFor.idealSalary+" per month"  : "N/A" }</p>
                        </div>
                        <div className="application-job-match-container">
                            <CircularProgressbarWithChildren
                                className="progress-bar"
                                value={jobMatch}
                                maxValue={100}
                                strokeWidth={4}
                                styles={buildStyles({
                                    pathColor: "#1377FF",
                                })}
                            >
                                <div className="progress-bar-inside-container">
                                    <span>{jobMatch}%</span>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="application-profile-completion-container">
                            <CircularProgressbarWithChildren
                                className="progress-bar"
                                value={application.candidate.percent}
                                maxValue={100}
                                strokeWidth={4}
                                styles={buildStyles({
                                    pathColor: "#1377FF",
                                })}
                            >
                                <div className="progress-bar-inside-container">
                                    <span>{application.candidate.percent}%</span>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </Link>
                    {status === "active" &&
                        <div className="application-row-actions-container">
                            <div className="application-row-actions-reject-button-container" onClick={() => this.handleReject(application._id)}>
                                <p>Reject</p>
                            </div>
                            <div className="application-row-actions-reject-next-stage-container" onClick={() => this.handleForward(application._id)}>
                                <p>Next Stage</p>
                            </div>
                        </div>
                    }
                    {status === "rejected" &&
                        <div className="application-row-actions-container">
                            <div className="application-row-actions-reject-next-stage-container" onClick={() => this.handleRestore(application._id)}>
                                <p>Restore</p>
                            </div>
                        </div>
                    }
                    {status === "forwarded" &&
                        <div className="application-row-actions-container">
                            <div className="application-row-actions-reject-button-container" onClick={() => this.handleReject(application._id)}>
                                <p>Reject</p>
                            </div>
                            <div className="application-row-actions-reject-next-stage-container" onClick={() => this.handleHire(application._id)}>
                                <p>Hire</p>
                            </div>
                        </div>
                    }
                </div>
            );
        });
    
    }

    sortByType = (users, type) => {

        if (type === "JOB_MATCH_BEST") return users.sort(this.jobMatchBest);
        if (type === "JOB_MATCH_WORST") return users.sort(this.jobMatchWorst);
        if (type === "LAST_ACTIVE_LATEST") return users.sort(this.lastActiveLatest);
        if (type === "LAST_ACTIVE_EARLIEST") return users.sort(this.lastActiveEarliest);

        return users;

    }

    lastActiveLatest = (a, b) => {
     
        if (a.lastActive > b.lastActive) return -1;
        if (a.lastActive < b.lastActive) return 1;
            
        return 0;
    
    }

    lastActiveEarliest = (a, b) => {
     
        if (a.lastActive < b.lastActive) return -1;
        if (a.lastActive > b.lastActive) return 1;
            
        return 0;
    
    }

    jobMatchBest = (a, b) => {
     
        if (a.jobMatch > b.jobMatch) return -1;
        if (a.jobMatch < b.jobMatch) return 1;
            
        return 0;
    
    }

    jobMatchWorst = (a, b) => {
     
        if (a.jobMatch < b.jobMatch) return -1;
        if (a.jobMatch > b.jobMatch) return 1;
            
        return 0;
    
    }

    renderFilterResults = (users, job) => {

        let results = users.map(user => {
            return {
                ...user,
                jobMatch: getJobMatch(job, user),
            };
        });

        results = this.sortByType(results, this.state.resultsSortType);

        return results.map((user, index) => {

            return(
                <div key={index} className="application-row-container">
                    <div className="application-row-select-email-contianer">
                        {this.state.selectedForSending.includes(user._id) ?
                            <div className="application-row-select-email-outer-active-contianer" onClick={() => this.setState({ allSelected: false, selectedForSending: this.state.selectedForSending.filter(item => item !== user._id) })}>
                                <img src={require("../../../../assets/svgs/tick.svg")} alt="" />
                            </div>
                        :
                            <div className="application-row-select-email-outer-contianer" onClick={() => this.setState({ selectedForSending: [...this.state.selectedForSending, user._id] })}>
                            </div>
                        }
                    </div>
                    <a href={"/crew/profile/"+user._id} target={"_blank"} rel="noopener noreferrer" className="application-row-clickable-container">
                        <div className="application-row-details-container">
                            <div className="application-row-image-container">
                                <img src={user.profileImage || require("../../../../assets/svgs/avatar-placeholder.svg")} onError={(e) => e.target.src = require("../../../../assets/svgs/avatar-placeholder.svg")} alt="" />
                            </div>
                            <div className="application-row-name-container">
                                <p>{user.firstName} {user.lastName}</p>
                            </div>
                        </div>
                        <div className="application-desired-salary-container">
                            <p>{user?.lastActive ? timeSinceLastActive(user.lastActive)+" ago" : "N/A"}</p>
                        </div>
                        <div className="application-desired-salary-container">
                            <p>{user?.lookingFor?.idealSalaryCurrency && user?.lookingFor?.idealSalary ? this.handleCurrency(user.lookingFor.idealSalaryCurrency)+user.lookingFor.idealSalary+" per month"  : "N/A" }</p>
                        </div>
                        <div className="application-job-match-container">
                            <CircularProgressbarWithChildren
                                className="progress-bar"
                                value={user.jobMatch}
                                maxValue={100}
                                strokeWidth={4}
                                styles={buildStyles({
                                    pathColor: "#1377FF",
                                })}
                            >
                                <div className="progress-bar-inside-container">
                                    <span>{user.jobMatch}%</span>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="application-profile-completion-container">
                            <CircularProgressbarWithChildren
                                className="progress-bar"
                                value={user.percent}
                                maxValue={100}
                                strokeWidth={4}
                                styles={buildStyles({
                                    pathColor: "#1377FF",
                                })}
                            >
                                <div className="progress-bar-inside-container">
                                    <span>{user.percent}%</span>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </a>
                </div>
            );
        });
    
    }

    renderSent = (users, job) => {

        let results = users.map(user => {
            return {
                ...user,
                jobMatch: getJobMatch(job, user),
            };
        });

        results = this.sortByType(results, this.state.resultsSortType);

        return results.map((user, index) => {

            return(
                <div key={index} className="application-row-container">
                    <a href={"/crew/profile/"+user._id} target={"_blank"} rel="noopener noreferrer" className="application-row-clickable-container">
                        <div className="application-row-details-container">
                            <div className="application-row-image-container">
                                <img src={user.profileImage || require("../../../../assets/svgs/avatar-placeholder.svg")} onError={(e) => e.target.src = require("../../../../assets/svgs/avatar-placeholder.svg")} alt="" />
                            </div>
                            <div className="application-row-name-container">
                                <p>{user.firstName} {user.lastName}</p>
                            </div>
                        </div>
                        <div className="application-desired-salary-container">
                            <p>{user?.lastActive ? timeSinceLastActive(user.lastActive)+" ago" : "N/A"}</p>
                        </div>
                        <div className="application-desired-salary-container">
                            <p>{user?.lookingFor?.idealSalaryCurrency && user?.lookingFor?.idealSalary ? this.handleCurrency(user.lookingFor.idealSalaryCurrency)+user.lookingFor.idealSalary+" per month"  : "N/A" }</p>
                        </div>
                        <div className="application-job-match-container">
                            <CircularProgressbarWithChildren
                                className="progress-bar"
                                value={user.jobMatch}
                                maxValue={100}
                                strokeWidth={4}
                                styles={buildStyles({
                                    pathColor: "#1377FF",
                                })}
                            >
                                <div className="progress-bar-inside-container">
                                    <span>{user.jobMatch}%</span>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </a>
                </div>
            );
        });

    }

    handleSelectAll = () => {

        let selected = [];

        this.state.filterResults.forEach(item => {
            if (!this.state.selectedForSending.includes(item._id)) {
                selected = [...selected, item._id];
            }
        });

        return this.setState({ allSelected: true, selectedForSending: [...this.state.selectedForSending, ...selected] });

    }

    filterSearch(propName, value, job) {

        return this.setState({ [propName]: value, filterTab: "results" }, () => {

                let isSearching = this.checkIfSearching();

                let time = 60;

                if (propName === "name") if (!isSearching && this.state.name.length < 5) return;
                if (propName === "country") if (!isSearching && this.state.country.length < 5) return;
                if (propName === "nationality") if (!isSearching && this.state.nationality.length < 5) return;
                if (["name", "country", "nationality"].includes(propName)) time = 1000;

                if (!isSearching) return this.resetState();

                return this.setState({ isFetchingSearchResults: true }, () => {

                    if (this.searchTimeOut) {  
                        clearTimeout(this.searchTimeOut);
                    }

                    if (propName === "location") {
                        return geocodeByAddress(value)
                            .then(results => {
                                return getLatLng(results[0])
                                    .then(data => {
                                        return this.setState({ coords: data }, () => {
                                            return matchUserToJob(this.state, job)
                                                .then(results => {
                        
                                                    let allApplications = [...this.state.active, ...this.state.rejected, ...this.state.forwarded, ...this.state.hired];
                                                    let emails = allApplications.map(item => {
                                                        return item.candidate.email;
                                                    });
                        
                                                    return this.setState({
                                                        filterResults: results.filter(result => {
                                                            return !emails.includes(result.email);
                                                        }),
                                                        isFetchingSearchResults: false,
                                                    });
                                                }); 
                                        });
                                    });  
                            });
                    } else {
                        this.searchTimeOut = setTimeout(() => {
                            return matchUserToJob(this.state, job)
                                .then(results => {
        
                                    let allApplications = [...this.state.active, ...this.state.rejected, ...this.state.forwarded, ...this.state.hired];
                                    let emails = allApplications.map(item => {
                                        return item.candidate.email;
                                    });
        
                                    return this.setState({
                                        filterResults: results.filter(result => {
                                            return !emails.includes(result.email);
                                        }),
                                        isFetchingSearchResults: false,
                                    });
                                });         
                        }, time);
                    }

                });
                   
        });

    }

    resetState =  () => {

        return this.setState({
            filterTab: "results",
            filterResults: [],
            sent: [],
            selectedForSending: [],
            allSelected: false,  
            /* Filters */
            name: "",          
            position: false,
            jobType: false,
            yachtType: false,
            couples: "Include Couples",
            qualifications: false,
            visas: false,
            location: "",
            coords: null,
            nationality: "",
            country: "",
            lastActive: "Don't Match",
            availability: "Don't Match",
            tattoo: false,
            smoking: false,
            yachtLengthMin: "",
            yachtLengthMax: "",
            yachtLengthAny: true,
            ageMin: "",
            ageMax: "",
            ageAny: true,
            salaryMin: "",
            salaryMax: "",
            salaryAny: true,
            salaryCurrency: null,
            profileKeywords: [],
            profileKeyword: "",
            /* Opens */
            positionOpen: false,
            contractOpen: false,
            yachtTypeOpen: false,
            coupleOpen: false,
            qualificationsOpen: false,
            visasOpen: false,
            lastActiveOpen: false,
            availabilityOpen: false,
            tattooOpen: false,
            smokingOpen: false,
            salaryCurrencyOpen: false,
        });

    }

    handleSendJobAlerts = (_id, list) => {

        list = [...list, ...this.state.selectedForSending];

        return sendJobAlerts(_id, list, this.state.selectedForSending)
            .then(result => {
                this.resetState();
                return this.setState({ sent: result }, () => {

                    this.props.setAlert("Emails have been sent out!", "success", true);
                    setTimeout(() => {
                        return this.props.setAlert("Emails have been sent out!", "success", false);
                    }, 2700);

                    return getJob(this.props.match.params.jobId)
                        .then(job => { 
                            return this.setState({ job });
                        });
                });
            });

    }

    renderPlaceholders = () => {

        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
            return(
                <div className="listing-applications-list-item-placeholder" key={index}>
                </div>
            );
        });

    }

    handleSalaryCurrency = (curr) => {

        if (curr === "usd") return "Dollars ($)";
        if (curr === "eur") return "Euros (€)";
        if (curr === "gbp") return "Pounds (£)";

        return "Don't Match";

    }

    addProfileKeyword = (e, keyword) => {

        if (e.key === "Enter") {
            return this.setState({ profileKeywords: [...this.state.profileKeywords, keyword] }, () => {
                return this.setState({ profileKeyword: "" }, () => {
                    return this.filterSearch("profileKeywords", this.state.profileKeywords, this.state.job)
                });
            });
        }

    }

    removeKeyword = (item) => {

        return this.setState({ profileKeywords: this.state.profileKeywords.filter(keyword => keyword !== item) }, () => {
            return this.filterSearch("profileKeywords", this.state.profileKeywords, this.state.job);
        });

    }

    render() {

        let isSearching = this.checkIfSearching();

        return(
            <div className="listings-applications-master-container">
                <div className="yacht-listings-name-container">
                    {this.state.yacht &&
                        <p>{this.state?.yacht?.yachtType === "Motor Yacht" ? "M/Y" : "S/Y"} {this.state?.yacht?.name} - {this.state?.job?.position?.name || ""}</p>
                    }
                </div>
                <div className="margin-top-10px" style={{display: "flex", flexDirection: "row"}}>
                    <div className="find-candidates-button" onClick={() => this.setState({ findCandidates: !this.state.findCandidates })}>
                        <p>{this.state.findCandidates ? "Hide Filters" : "Find Candidates"}</p>
                    </div>
                    <div style={{width: 20}}></div>
                    {this.state.findCandidates &&
                        <div className="find-candidates-button" style={{backgroundColor: "#1377FF"}} onClick={() => this.resetState()}>
                            <p style={{color: "white"}}>Reset Filters</p>
                        </div>
                    }
                    {this.state.isFetchingSearchResults && 
                        <div className="sign-ups-header-search-loading-container" style={{paddingLeft: 20}}>
                            <img src={require("../../../../assets/animations/blue-spinner.svg")} alt="" />
                        </div>
                    }
                </div>
                {!this.state.findCandidates ?
                    <>
                        <div className="yacht-listings-header-container margin-top-20px">
                            <div className="yacht-listings-header-items-container">
                                <Link to={this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}` : `/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}`}>
                                    <div className="yacht-listings-header-item-container">
                                        <p style={(!this.props.match.params.tab || this.props.match.params.tab === "rejected") ?  {color: "#1377FF"} : {}}>Applications{(this.state.active.length+this.state.rejected.length) > 0 && " ("+parseInt(this.state.active.length+this.state.rejected.length, 10)+")"}</p>
                                        {(!this.props.match.params.tab || this.props.match.params.tab === "rejected") &&
                                            <div className="yacht-listings-header-item-active"></div>
                                        }
                                    </div>
                                </Link>
                                <Link to={this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}/forwarded` : `/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}/forwarded`}>
                                    <div className="yacht-listings-header-item-container">
                                        <p style={this.props.match.params.tab === "forwarded" ?  {color: "#1377FF"} : {}}>Forwarded{this.state.forwarded.length > 0 && " ("+this.state.forwarded.length+")"}</p>
                                        {this.props.match.params.tab === "forwarded" &&
                                            <div className="yacht-listings-header-item-active"></div>
                                        }
                                    </div>
                                </Link>
                                <Link to={this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}/hired` : `/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}/hired`}>
                                    <div className="yacht-listings-header-item-container">
                                        <p style={this.props.match.params.tab === "hired" ?  {color: "#1377FF"} : {}}>Hired{this.state.hired.length > 0 && " ("+this.state.hired.length+")"}</p>
                                        {this.props.match.params.tab === "hired" &&
                                            <div className="yacht-listings-header-item-active"></div>
                                        }
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {(!this.props.match.params.tab || this.props.match.params.tab === "rejected") &&
                            <div className="yacht-listings-subheader-container">
                                <Link className="yacht-listings-subheader-item-container" to={this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}` : `/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}`}>
                                    <p style={!this.props.match.params.tab ?  {color: "#1377FF"} : {}}>Active{this.state.active.length > 0 && " ("+this.state.active.length+")"}</p>
                                    {!this.props.match.params.tab &&
                                        <div className="yacht-listings-subheader-item-active"></div>
                                    }
                                </Link>
                                <Link className="yacht-listings-subheader-item-container" to={this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}/rejected` : `/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}/rejected`}>
                                    <p style={this.props.match.params.tab === "rejected" ?  {color: "#1377FF"} : {}}>Rejected{this.state.rejected.length > 0 && " ("+this.state.rejected.length+")"}</p>
                                    {this.props.match.params.tab === "rejected" &&
                                        <div className="yacht-listings-subheader-item-active"></div>
                                    }
                                </Link>
                            </div>
                        }
                        <div className="yacht-listing-list-contianer margin-top-20px">
                            {this.state[this.props.match.params.tab ? this.props.match.params.tab : "active"].length > 0 ?
                                <div className="yacht-listing-list-header-container">
                                    <div className="yacht-listing-list-header-position-container">
                                        <p>Details</p>
                                    </div>
                                    <div className="yacht-listing-list-header-start-date-container">
                                        <p>Desired Salary</p>
                                    </div>
                                    <div className="yacht-listing-list-header-job-match-container">
                                        <p>Job Match</p>
                                    </div>
                                    <div className="yacht-listing-list-header-profile-completion-container">
                                        <p>Profile</p>
                                    </div>
                                    {this.props.match.params.tab !== "hired" &&
                                        <div className="yacht-listing-list-header-actions-container">
                                            <p>Actions</p>
                                        </div>
                                    }
                                </div>
                            :
                                !this.state.isFetching ?
                                    <p className="no-applications-text">There are no {this.props.match.params.tab ? this.props.match.params.tab : "active"} applications.</p>
                                :
                                    <div></div>
                            }
                            {this.state.isFetching &&
                                this.renderPlaceholders()
                            }
                            {!this.props.match.params.tab && !this.state.isFetching &&
                                this.renderRows(this.state.active, "active")
                            }
                            {this.props.match.params.tab === "rejected" && !this.state.isFetching &&
                                this.renderRows(this.state.rejected, "rejected")
                            }
                            {this.props.match.params.tab === "forwarded" && !this.state.isFetching &&
                                this.renderRows(this.state.forwarded, "forwarded")
                            }
                            {this.props.match.params.tab === "hired" && !this.state.isFetching &&
                                this.renderRows(this.state.hired, "hired")
                            }
                        </div>
                    </>
                :
                    <>
                        <div className="find-candidates-filters-container margin-top-20px">
                            <div className="find-candidates-filter-side-container">
                                <div className="find-candidates-filter-search-container">
                                    <div className="find-candidates-filter-search-input-container">
                                        <input type={"text"} placeholder={"Search candidate name"} value={this.state.name} onChange={(e) => this.filterSearch("name", e.target.value, this.state.job)} />
                                    </div>
                                    <div className="find-candidates-filter-search-icon-container">
                                        <img src={require("../../../../assets/svgs/search.svg")} alt="" />
                                    </div>
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ positionOpen: !this.state.positionOpen })} style={this.state.positionOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Desired Position"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.position ? "Match" : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.positionOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.positionOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ positionOpen: false }, () => this.filterSearch("position", true, this.state.job))}>
                                                <p>Match</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ positionOpen: false }, () => this.filterSearch("position", false, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ contractOpen: !this.state.contractOpen })} style={this.state.contractOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Contract Type"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.jobType ? "Match" : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.contractOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.contractOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ contractOpen: false }, () => this.filterSearch("jobType", true, this.state.job))}>
                                                <p>Match</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ contractOpen: false }, () => this.filterSearch("jobType", false, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ yachtTypeOpen: !this.state.yachtTypeOpen })} style={this.state.yachtTypeOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Yacht Type"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.yachtType ? "Match" : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.yachtTypeOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.yachtTypeOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ yachtTypeOpen: false }, () => this.filterSearch("yachtType", true, this.state.job))}>
                                                <p>Match</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ yachtTypeOpen: false }, () => this.filterSearch("yachtType", false, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ coupleOpen: !this.state.coupleOpen })} style={this.state.coupleOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Couple Status"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.couples}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.coupleOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.coupleOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ coupleOpen: false }, () => this.filterSearch("couples", "Include Couples", this.state.job))}>
                                                <p>Include Couples</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ coupleOpen: false }, () => this.filterSearch("couples", "No Couples", this.state.job))}>
                                                <p>No Couples</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ coupleOpen: false }, () => this.filterSearch("couples", "Only Couples", this.state.job))}>
                                                <p>Only Couples</p>
                                            </div>
                                        </div>
                                    }
                                </div> 
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ qualificationsOpen: !this.state.qualificationsOpen })} style={this.state.qualificationsOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Qualifications"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.qualifications ? "Match" : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.qualificationsOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.qualificationsOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ qualificationsOpen: false }, () => this.filterSearch("qualifications", true, this.state.job))}>
                                                <p>Match</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ qualificationsOpen: false }, () => this.filterSearch("qualifications", false, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ visasOpen: !this.state.visasOpen })} style={this.state.visasOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Visas"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.visas ? "Match" : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.visasOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.visasOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ visasOpen: false }, () => this.filterSearch("visas", true, this.state.job))}>
                                                <p>Match</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ visasOpen: false }, () => this.filterSearch("visas", false, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="margin-top-10px">
                                    <p className="find-candidates-range-title">Desired Yacht Length</p>
                                </div>
                                <div className="find-candidates-range-input-container margin-top-10px">
                                    <div className="find-candidates-range-input-textbox-container">
                                        <input disabled={this.state.yachtLengthAny} type={"text"} value={this.state.yachtLengthMin} placeholder={"45"} onChange={(e) => this.filterSearch("yachtLengthMin", e.target.value, this.state.job)} />
                                    </div>
                                    <div className="find-candidates-range-input-separator-container">
                                        <div className="find-candidates-range-input-separator"></div>
                                    </div>
                                    <div className="find-candidates-range-input-textbox-container">
                                        <input disabled={this.state.yachtLengthAny} type={"text"} value={this.state.yachtLengthMax} placeholder={"55"} onChange={(e) => this.filterSearch("yachtLengthMax", e.target.value, this.state.job)} />
                                    </div>
                                    <div style={{width: 25}}></div>
                                    <div className="find-candidates-range-input-button" onClick={() => this.setState({yachtLengthMin: "", yachtLengthMax: ""}, () => this.filterSearch("yachtLengthAny", !this.state.yachtLengthAny, this.state.job))}>
                                        <p>{this.state.yachtLengthAny ? "Set" : "Any"}</p>
                                    </div>
                                </div>
                                <div className="margin-top-10px">
                                    <p className="find-candidates-range-title">Desired Age</p>
                                </div>
                                <div className="find-candidates-range-input-container margin-top-10px">
                                    <div className="find-candidates-range-input-textbox-container">
                                        <input disabled={this.state.ageAny} type={"text"} value={this.state.ageMin} placeholder={"25"} onChange={(e) => this.filterSearch("ageMin", e.target.value, this.state.job)} />
                                    </div>
                                    <div className="find-candidates-range-input-separator-container">
                                        <div className="find-candidates-range-input-separator"></div>
                                    </div>
                                    <div className="find-candidates-range-input-textbox-container">
                                        <input disabled={this.state.ageAny} type={"text"} value={this.state.ageMax} placeholder={"30"} onChange={(e) => this.filterSearch("ageMax", e.target.value, this.state.job)} />
                                    </div>
                                    <div style={{width: 25}}></div>
                                    <div className="find-candidates-range-input-button" onClick={() => this.setState({ageMin: "", ageMax: ""}, () => this.filterSearch("ageAny", !this.state.ageAny, this.state.job))}>
                                        <p>{this.state.ageAny ? "Set" : "Any"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="find-candidates-filter-side-container">
                                <div className="find-candidates-filter-search-container">
                                    <div className="find-candidates-filter-search-input-container-with-tags">
                                        {this.state.profileKeywords.map((item, index) => {
                                            return(
                                                <div className="profile-keyword-tag" style={this.state.profileKeywords.length-1 === index ? { marginRight: 0 } : { marginRight: 10 }} key={index}>
                                                    <span>{item}</span>
                                                    <img onClick={() => this.removeKeyword(item)} src={require("../../../../assets/svgs/x-thick.svg")} alt="" />
                                                </div>
                                            );
                                        })}
                                        <input type={"text"} placeholder={"Search profile keywords"} value={this.state.profileKeyword} onChange={(e) => this.setState({ profileKeyword: e.target.value })} onKeyDown={(e) => this.addProfileKeyword(e, this.state.profileKeyword)} />
                                    </div>
                                </div>
                                <div className="find-candidates-filter-search-container margin-top-10px">
                                    <div className="find-candidates-filter-search-input-container">
                                        <PlacesAutocomplete
                                                value={this.state.location}
                                                onChange={(value) => this.setState({ location: value })}
                                                onSelect={(value) => this.filterSearch("location", value, this.state.job)}
                                            >
                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                <div className="find-candidates-location-master-container">
                                                    <div className="find-candidates-location-input-container">
                                                        <input {...getInputProps({placeholder: "Location" })} />
                                                    </div>
                                                    <div className="find-candidates-location-dropdown-container" style={{display: suggestions.length < 1 ? "none" : "block"}}>
                                                        {suggestions.map((suggestion, index) => {
                                                            return(
                                                                <div className="find-candidates-location-dropdown-item-container" {...getSuggestionItemProps(suggestion)} style={index < 1 ? {border: "none"} : {}} key={index}>
                                                                    <p>{suggestion.description}</p>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>                                    
                                    </div>
                                </div>
                                <div className="find-candidates-filter-search-container margin-top-10px">
                                    <div className="find-candidates-filter-search-input-container">
                                        <input type={"text"} placeholder={"Nationality"} value={this.state.nationality} onChange={(e) => this.filterSearch("nationality", e.target.value, this.state.job)} />
                                    </div>
                                </div>
                                <div className="find-candidates-filter-search-container margin-top-10px">
                                    <div className="find-candidates-filter-search-input-container">
                                        <input type={"text"} placeholder={"Country of Residence"} value={this.state.country} onChange={(e) => this.filterSearch("country", e.target.value, this.state.job)} />
                                    </div>
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ lastActiveOpen: !this.state.lastActiveOpen })} style={this.state.lastActiveOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Last Active"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.lastActive}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.lastActiveOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.lastActiveOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ lastActiveOpen: false }, () => this.filterSearch("lastActive", "Last Week", this.state.job))}>
                                                <p>Last Week</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ lastActiveOpen: false }, () => this.filterSearch("lastActive", "Last Month", this.state.job))}>
                                                <p>Last Month</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ lastActiveOpen: false }, () => this.filterSearch("lastActive", "Last 2 Months", this.state.job))}>
                                                <p>Last 2 Months</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ lastActiveOpen: false }, () => this.filterSearch("lastActive", "Don't Match", this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div> 
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ availabilityOpen: !this.state.availabilityOpen })} style={this.state.availabilityOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Availability"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.availability}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.availabilityOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.availabilityOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ availabilityOpen: false }, () => this.filterSearch("availability", "Immediate", this.state.job))}>
                                                <p>Immediate</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ availabilityOpen: false }, () => this.filterSearch("availability", "Available in 1 week", this.state.job))}>
                                                <p>Available in 1 week</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ availabilityOpen: false }, () => this.filterSearch("availability", "Available in 2 weeks", this.state.job))}>
                                                <p>Available in 2 weeks</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ availabilityOpen: false }, () => this.filterSearch("availability", "Available in 4+ weeks", this.state.job))}>
                                                <p>Available in 4+ weeks</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ availabilityOpen: false }, () => this.filterSearch("availability", "Don't Match", this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ tattooOpen: !this.state.tattooOpen })} style={this.state.tattooOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Tattoos Status"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.tattoo ? "Match" : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.tattooOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.tattooOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ tattooOpen: false }, () => this.filterSearch("tattoo", true, this.state.job))}>
                                                <p>Match</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ tattooOpen: false }, () => this.filterSearch("tattoo", false, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ smokingOpen: !this.state.smokingOpen })} style={this.state.smokingOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Smoking Status"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.smoking ? "Match" : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.smokingOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.smokingOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ smokingOpen: false }, () => this.filterSearch("smoking", true, this.state.job))}>
                                                <p>Match</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ smokingOpen: false }, () => this.filterSearch("smoking", false, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="find-candidates-dropdown-filter-master-container margin-top-10px">
                                    <div className="find-candidates-dropdown-filter-container" onClick={() => this.setState({ salaryCurrencyOpen: !this.state.salaryCurrencyOpen })} style={this.state.salaryCurrencyOpen ? {borderRadius: "4px 4px  0 0"} : {}}>
                                        <div className="find-candidates-dropdown-filter-content-container">
                                            <div className="find-candidates-dropdown-filter-label-container">
                                                <p>{"Salary Currency"}</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-value-container">
                                                <p>{this.state.salaryCurrency ? this.handleSalaryCurrency(this.state.salaryCurrency) : "Don't Match"}</p>
                                            </div>
                                        </div>
                                        <div className="find-candidates-dropdown-filter-icon-container">
                                            <img style={this.state.salaryCurrencyOpen ? {transform: "rotate(180deg)"} : {}} src={require("../../../../assets/svgs/chevron-down.svg")} alt="" />
                                        </div>
                                    </div>
                                    {this.state.salaryCurrencyOpen &&
                                        <div className="find-candidates-dropdown-filter-list-container">
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ salaryCurrencyOpen: false }, () => this.filterSearch("salaryCurrency", "usd", this.state.job))}>
                                                <p>Dollars ($)</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ salaryCurrencyOpen: false }, () => this.filterSearch("salaryCurrency", "eur", this.state.job))}>
                                                <p>Euros (€)</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ salaryCurrencyOpen: false }, () => this.filterSearch("salaryCurrency", "gbp", this.state.job))}>
                                                <p>Pounds (£)</p>
                                            </div>
                                            <div className="find-candidates-dropdown-filter-list-item-container" onClick={() => this.setState({ salaryCurrencyOpen: false }, () => this.filterSearch("salaryCurrency", null, this.state.job))}>
                                                <p>Don't Match</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="margin-top-10px">
                                    <p className="find-candidates-range-title">Desired Monthly Salary</p>
                                </div>
                                <div className="find-candidates-range-input-container margin-top-10px">
                                    <div className="find-candidates-range-input-textbox-container">
                                        <input disabled={this.state.salaryAny} type={"text"} value={this.state.salaryMin} placeholder={"4000"} onChange={(e) => this.filterSearch("salaryMin", e.target.value, this.state.job)} />
                                    </div>
                                    <div className="find-candidates-range-input-separator-container">
                                        <div className="find-candidates-range-input-separator"></div>
                                    </div>
                                    <div className="find-candidates-range-input-textbox-container">
                                        <input disabled={this.state.salaryAny} type={"text"} value={this.state.salaryMax} placeholder={"6000"} onChange={(e) => this.filterSearch("salaryMax", e.target.value, this.state.job)} />
                                    </div>
                                    <div style={{width: 25}}></div>
                                    <div className="find-candidates-range-input-button" onClick={() => this.setState({salaryMin: "", salaryMax: ""}, () => this.filterSearch("salaryAny", !this.state.salaryAny, this.state.job))}>
                                        <p>{this.state.salaryAny ? "Set" : "Any"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="yacht-listings-header-container margin-top-20px">
                            <div className="yacht-listings-header-items-container">
                                <div className="yacht-listings-header-item-container" onClick={() => this.setState({ filterTab: "results" })}>
                                    <p style={this.state.filterTab === "results" ? {color: "#1377FF"} : {}}>Results{this.state.filterResults.length > 0 && " ("+this.state.filterResults.length+")"}</p>
                                    {this.state.filterTab === "results" &&
                                        <div className="yacht-listings-header-item-active"></div>
                                    }
                                </div>
                                <div className="yacht-listings-header-item-container" onClick={() => this.setState({ filterTab: "sent" })}>
                                    <p style={this.state.filterTab === "sent" ? {color: "#1377FF"} : {}}>Sent{this.state.sent.length > 0 && " ("+this.state.sent.length+")"}</p>
                                    {this.state.filterTab === "sent" &&
                                        <div className="yacht-listings-header-item-active"></div>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.filterResults.length > 0 &&
                            <div className="find-candidates-select-all-master-container margin-top-20px">
                                <div className="find-candidates-select-all-button" onClick={(this.state.allSelected || this.state.filterResults.length === this.state.selectedForSending.length) ? () => this.setState({ allSelected: false, selectedForSending: [] }) : () => this.handleSelectAll()}>
                                    <p>{(this.state.allSelected || this.state.filterResults.length === this.state.selectedForSending.length) ? "Deselect All" : "Select All"}</p>
                                </div>
                                {this.state.selectedForSending.length > 0 &&
                                    <div className="find-candidates-send-all-button" onClick={() => this.handleSendJobAlerts(this.state.job._id, this.state.job.emailList)}>
                                        <div className="find-candidates-send-all-button-icon">
                                            <img src={require("../../../../assets/svgs/send-selected.svg")} alt="" />
                                        </div>
                                        <div className="find-candidates-send-all-button-text">
                                            <p>Send Selected</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className="margin-top-20px">
                            {this.state.filterTab === "results" ?
                                <>
                                    <div className="yacht-listing-list-header-container">
                                        <div className="yacht-listing-list-header-position-container">
                                            {this.state.isFetchingSearchResults && 
                                                <img style={{width: 24, height: 24, marginRight: 10}} src={require("../../../../assets/animations/blue-spinner.svg")} alt="" />
                                            }
                                            <p>Details</p>
                                        </div>
                                        <div className="yacht-listing-list-header-start-date-container" style={{ cursor: "pointer" }} onClick={() => this.setState({ resultsSortType: this.state.resultsSortType === "LAST_ACTIVE_LATEST" ? "LAST_ACTIVE_EARLIEST" : "LAST_ACTIVE_LATEST" })}>
                                            <p>Last Active</p>
                                            {(this.state.resultsSortType === "LAST_ACTIVE_LATEST" || this.state.resultsSortType === "LAST_ACTIVE_EARLIEST") &&
                                                <img src={require("../../../../assets/svgs/up-arrow.svg")} style={this.state.resultsSortType === "LAST_ACTIVE_LATEST" ? { transform: "rotate(180deg)" } : {} }  alt="" />
                                            }
                                        </div>
                                        <div className="yacht-listing-list-header-start-date-container">
                                            <p>Desired Salary</p>
                                        </div>
                                        <div className="yacht-listing-list-header-job-match-container" style={{ cursor: "pointer" }} onClick={() => this.setState({ resultsSortType: this.state.resultsSortType === "JOB_MATCH_BEST" ? "JOB_MATCH_WORST" : "JOB_MATCH_BEST" })}>
                                            <p>Job Match</p>
                                            {(this.state.resultsSortType === "JOB_MATCH_BEST" || this.state.resultsSortType === "JOB_MATCH_WORST") &&
                                                <img src={require("../../../../assets/svgs/up-arrow.svg")} style={this.state.resultsSortType === "JOB_MATCH_BEST" ? { transform: "rotate(180deg)" } : {} } alt="" />
                                            }
                                        </div>
                                        <div className="yacht-listing-list-header-job-match-container">
                                            <p>Profile</p>
                                        </div>
                                    </div>
                                    {this.renderFilterResults(this.state.filterResults, this.state.job)}
                                    {this.state.filterResults.length < 1 && !this.state.isFetchingSearchResults && isSearching &&
                                        <p className="no-results-found-description">No candidates were found matching your search request.</p>
                                    }
                                    {!isSearching &&
                                        <p className="no-results-found-description">Select filter in order to start the search.</p>
                                    }
                                </>
                            :
                                <>
                                    <div className="yacht-listing-list-header-container">
                                        <div className="yacht-listing-list-header-position-container">
                                            <p>Details</p>
                                        </div>
                                        <div className="yacht-listing-list-header-start-date-container" style={{ cursor: "pointer" }} onClick={() => this.setState({ resultsSortType: this.state.resultsSortType === "LAST_ACTIVE_LATEST" ? "LAST_ACTIVE_EARLIEST" : "LAST_ACTIVE_LATEST" })}>
                                            <p>Last Active</p>
                                            {(this.state.resultsSortType === "LAST_ACTIVE_LATEST" || this.state.resultsSortType === "LAST_ACTIVE_EARLIEST") &&
                                                <img src={require("../../../../assets/svgs/up-arrow.svg")} style={this.state.resultsSortType === "LAST_ACTIVE_LATEST" ? { transform: "rotate(180deg)" } : {} } alt="" />
                                            }
                                        </div>
                                        <div className="yacht-listing-list-header-start-date-container">
                                            <p>Desired Salary</p>
                                        </div>
                                        <div className="yacht-listing-list-header-job-match-container" style={{ cursor: "pointer" }} onClick={() => this.setState({ resultsSortType: this.state.resultsSortType === "JOB_MATCH_BEST" ? "JOB_MATCH_WORST" : "JOB_MATCH_BEST" })}>
                                            <p>Job Match</p>
                                            {(this.state.resultsSortType === "JOB_MATCH_BEST" || this.state.resultsSortType === "JOB_MATCH_WORST") &&
                                                <img src={require("../../../../assets/svgs/up-arrow.svg")} style={this.state.resultsSortType === "JOB_MATCH_BEST" ? { transform: "rotate(180deg)" } : {} } alt="" />
                                            }
                                        </div>
                                    </div>
                                    {this.renderSent(this.state.sent, this.state.job)}
                                </>
                            }
                        </div>
                    </>
                }
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Applications);