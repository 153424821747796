import React, { Component } from "react";

/* Components */
import NavigationBar from "../../components/NavigationBar";

class Ghost extends Component {

    render() {

        return(
            <div className="page-container">
                <NavigationBar />
            </div>
        );

    }

}

export default Ghost;