import React from "react";
import "./index.css";

export default ({ title }) => {

    return(
        <div className="reference-verification-title-contianer">
            <p>{title}</p>
        </div>
    );

}