import React from "react";
import "./index.css";

export default ({ title, icon, back, updateState, step, completed, state, propNames }) => {

    return(
        <div className="reference-verification-footer-container">
            <div className="reference-verification-footer-primary-button-container" onClick={() => proceed(completed, step, state, propNames, updateState)}>
                <p>{title}</p>
                <img src={icon} alt="" />
            </div>
            <div style={{width: 20, height: 20}}></div>
            {back &&
                <div className="reference-verification-footer-secondary-button-container" onClick={() => updateState(["step"], [step-1], true)}>
                    <p>Previous Step</p>
                </div>
            }
        </div>
    );

}

const proceed = (completed, step, state, propNames, updateState) => {

    for (let propName in propNames) {
        if (!state[propNames[propName]]) return alert("Please fill in required fields.");
    }    

    if (completed) return updateState(["step", "completed"], [step+1, true], true);

    return updateState(["step"], [step+1], true)

}