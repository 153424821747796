
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile, updateUserDocuments } from "../../../../redux/actions/user";
import { setProfileBuilderFinishLine } from "../../../../redux/actions/misc";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import DocumentSection from "../../../../components/DocumentSection";
import SaveButton from "../../../../components/SaveButton";

/* Helpers */
import { bytesToSize } from "../../../../helpers/misc";

/* API requests */
import { uploadUpdatedCV, uploadDocument, updateCandidate } from "../../../../api-requests/candidate";

class Documents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentlyLoading: "",
            name: "",
            extension: "",
            size: "",
        };

    }

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    handleDocumentRemove = (propName, index) => {

        const { profile } = this.props.user.data;

        let body;

        if (propName !== "cv") {
            const newArray = profile[propName].filter((_, idx) => idx !== index);
                body = [ { propName: propName, value: newArray } ];
        } else {
            body = [ { propName: propName, value: null } ];
        }

        return updateCandidate(profile._id, body)
            .then(result => {
                return this.props.updateUserProfile(result.data);
            });

    }

    handleDocumentUpload = (files, propName) => {

        if (files && files[0]) {

            const filesArray = [...files];

            if (propName !== "cv") {

                const placeholders = filesArray.map((file, index) => {

                    const dotIndex = file.name.indexOf(".");

                    const name = file.name.slice(0, dotIndex),
                        extension = file.name.slice(dotIndex),
                        size = bytesToSize(file.size);

                    return {
                        id: index,
                        loading: true,
                        name,
                        extension,
                        size,
                    };

                });

                this.props.updateUserProfile([{ propName: propName, value: [...this.props.user.data.profile[propName], ...placeholders] }]);

            } else {

                const dotIndex = filesArray[0].name.indexOf(".");

                const name = filesArray[0].name.slice(0, dotIndex),
                    extension = filesArray[0].name.slice(dotIndex),
                    size = bytesToSize(filesArray[0].size);

                this.props.updateUserProfile([{ propName: propName, value: { name: name, extension: extension, size: size, loading: true } }]);

            }

            filesArray.forEach((file, index) => {

                const dotIndex = file.name.indexOf(".");

                const name = file.name.slice(0, dotIndex),
                    extension = file.name.slice(dotIndex),
                    size = bytesToSize(file.size);

                if (propName !== "cv") {
                    return uploadDocument(this.props.user.data.profile._id, name, extension, size, propName, file)
                        .then(result => {
                            return this.handleResult(propName, result, index);
                        });
                } else {
                    return uploadUpdatedCV(this.props.user.data.profile._id, name, extension, size, file)
                        .then(result => {
                            return this.handleResult(propName, result);
                        });
                }

            });

        }

    }

    handleResult = (propName, result, index) => {

        const { profile } = this.props.user.data;

        if (propName !== "cv") {
            if (result.status === 201) {
                return this.props.updateUserDocuments(propName, index, result.data);
            } else {
                alert("Document upload failed. Please try again.");
                const newArray = profile[propName].reverse().filter((_, idx) => idx !== index);
                return this.props.updateUserProfile([{ propName: propName, value: newArray }]);
            }
        } else {
            if (result.status === 201) {
                return this.props.updateUserProfile([{ propName: propName, value: result.data }]);
            } else {
                alert("CV upload failed. Please try again.");
                return this.props.updateUserProfile([{ propName: propName, value: null }]);
            }
        }

    }

    render() {

        const { profile } = this.props.user.data;

        return(
            <>
                <DashboardSectionBase title={"Documents"} description={"Please add your updated certificates and documents that are relevant to yachting."}>
                    <DocumentSection title={"Updated CV"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"cv"} handleDocumentRemove={this.handleDocumentRemove} />
                    <DocumentSection title={"Travel Documents (Passport/Visas)"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"travelDocument"} handleDocumentRemove={this.handleDocumentRemove} />
                    <DocumentSection title={"STCW Basic Safety Training"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"stcwBasic"} handleDocumentRemove={this.handleDocumentRemove} />
                    <DocumentSection title={"ENG 1"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"eng1"} handleDocumentRemove={this.handleDocumentRemove} />
                    <DocumentSection title={"Licenses & CoCs (If Applicable)"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"license"} handleDocumentRemove={this.handleDocumentRemove} />
                    <DocumentSection title={"STCW Advanced Safety Training (If Applicable)"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"stcwAdvanced"} handleDocumentRemove={this.handleDocumentRemove} />
                    <DocumentSection title={"Written References"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"writtenReferences"} handleDocumentRemove={this.handleDocumentRemove} />
                    <DocumentSection title={"Other Certificates/Documents"} profile={profile} state={this.state} handleDocumentUpload={this.handleDocumentUpload} propName={"allDocuments"} handleDocumentRemove={this.handleDocumentRemove} />
                </DashboardSectionBase>
                <SaveButton propNames={[]} profile={profile} updateUserProfile={this.props.updateUserProfile} previous={"/profile/references"} next={"/profile/documents"} history={this.props.history} complete={true} setFinishLine={this.props.setProfileBuilderFinishLine} />
            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile, updateUserDocuments, setProfileBuilderFinishLine }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents);