import React from "react";
import "./index.css"

export default ({ title, updateState, propName, value }) => {

    return(
        <div className="reference-verification-textbox-container">
            <div className="reference-verification-textbox-title-container">
                <p>{title}</p>
            </div>
            <div className="reference-verification-textbox-input-container">
                <textarea placeholder={"Type..."} onChange={(e) => updateState([propName], [e.target.value], false)} onBlur={(e) => updateState([propName], [e.target.value], true)} value={value}></textarea>
            </div>
        </div>
    );

}