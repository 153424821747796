import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

/* Components */
import TextInputWithIcon from "../../../../components/TextInputWithIcon";

/* Actions */
import { setRequestedEmail, setRequestPasswordSuccess, setRequestPasswordError, setNewPassword } from "../../../../redux/actions/reset-password";

/* Helpers */
import { convertQueryStringToObject } from "../../../../helpers/reset-password";

/* API requests */
import { requestPasswordReset, resetPassword } from "../../../../api-requests/user";

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: false,
        };

    }

    componentDidMount() {

        const data = convertQueryStringToObject(this.props.queryString);

        return this.setState({ data });

    }

    componentWillUnmount() {

        this.clearData();

    }

    handleTitle = (success, code) => {

        if (code && success) return "Password Successfully Reset";
        if (code) return "Reset Your Password";
        if (success) return "Instructions Sent!";

        return "Password Recovery";

    }

    handleDescription = (success, code) => {

        if (code && success) return "You can now log in with your new password";
        if (code) return "Please create a new password";
        if (success) return "We have sent your password reset instructions, please check your email.";

        return "Enter your email address and we’ll send your instructions to reset your password";

    }

    requestPasswordReset = (email) => {

        if (!email) return this.props.setRequestPasswordError(true, "Email is a required field.");

        return this.setState({ loading: true }, () => {
            return requestPasswordReset(email)
                .then(result => {
                    if (result.error) {
                        return this.setState({ loading: false }, () => {
                            return this.props.setRequestPasswordError(true, result.error_message);
                        });
                    }
                    return this.props.setRequestPasswordSuccess(true);
                });
        });

    }

    resetPassword = (newPassword, data) => {

        if (!data) return this.props.setRequestPasswordError(true, "Something went wrong. Please try again.");
        if (!newPassword) return this.props.setRequestPasswordError(true, "Password is a required field.");
        if (newPassword.length < 6) return this.props.setRequestPasswordError(true, "Password must be at least 6 characters.");

        return this.setState({ loading: true }, () => {
            return resetPassword(data.code, newPassword)
                .then(result => {
                    if (result.error) {
                        return this.setState({ loading: false }, () => {
                            return this.props.setRequestPasswordError(true, result.error_message);
                        });
                    }
                    return this.setState({ loading: true }, () => {
                        return this.props.setRequestPasswordSuccess(true);
                    });
                });

        });

    }

    clearData = () => {

        this.props.setNewPassword("");
        this.props.setRequestedEmail("");
        this.props.setRequestPasswordSuccess(false);
        this.props.setRequestPasswordError(false, "");
        return this.setState({ data: null, loading: false });

    }

    handleKeyPress = (e, email, newPassword, data) => {

        if (e.key === "Enter") {
            if (email) {
                return this.requestPasswordReset(email);
            } else {
                return this.resetPassword(newPassword, data);
            }
        }
        return;

    }

    render() {

        return(
            <div className="reset-password-form-container" tabIndex={0} onKeyPress={(e) => this.handleKeyPress(e, this.props.resetPassword.email, this.props.resetPassword.newPassword, this.state.data)}>
                <div className="reset-password-form-header-container">
                    <span className="reset-password-form-header-title">{this.handleTitle(this.props.resetPassword.success, this.props.queryString)}</span>
                </div>
                <div className="reset-password-form-subheader-container margin-top-2x">
                    <p className="reset-password-form-subheader-text">{this.handleDescription(this.props.resetPassword.success, this.props.queryString)}</p>
                </div>
                {!this.props.resetPassword.success && !this.props.queryString &&
                    <>
                        <div className="margin-top-2x">
                            <TextInputWithIcon value={this.props.resetPassword.email} onChangeText={this.props.setRequestedEmail} icon={require("../../../../assets/svgs/mail.svg")} placeholder={"Email"} hide={false} error={this.props.resetPassword.error} />
                        </div>
                        <div className="reset-password-error-container">
                            {this.props.resetPassword.error && this.props.resetPassword.errorMessage && 
                                <p className="reset-password-error-text">{this.props.resetPassword.errorMessage}</p>
                            }
                        </div>
                        {
                            !this.state.loading ?
                                <div className="reset-password-button-container" onClick={() => this.requestPasswordReset(this.props.resetPassword.email)}>
                                    <span className="reset-password-button-title">Send Instructions</span>
                                </div>
                            :
                                <div className="reset-password-button-container reset-password-button-container-disabled">
                                    <span className="reset-password-button-title">Send Instructions</span>
                                </div>
                        }
                    </>
                }
                {this.props.queryString &&
                    <>
                        {!this.props.resetPassword.success &&
                            <>
                                <div className="margin-top-2x">
                                    <TextInputWithIcon value={this.props.resetPassword.newPassword} onChangeText={this.props.setNewPassword} icon={require("../../../../assets/svgs/password.svg")} placeholder={"Password"} hide={true} error={this.props.resetPassword.error} />
                                </div>
                                <div className="reset-password-error-container">
                                    {this.props.resetPassword.error && this.props.resetPassword.errorMessage && 
                                        <p className="reset-password-error-text">{this.props.resetPassword.errorMessage} {this.state.expired ? <Link onClick={() => this.clearData()} to={"/reset-password"}>Request a new link.</Link> : ""}</p>
                                    }
                                </div>
                            </>
                        }
                        {
                            !this.props.resetPassword.success ?
                                !this.state.loading ?
                                    <div className="reset-password-button-container" onClick={() => this.resetPassword(this.props.resetPassword.newPassword, this.state.data)}>
                                        <span className="reset-password-button-title">Reset Password</span>
                                    </div>
                                :
                                    <div className="reset-password-button-container reset-password-button-container-disabled">
                                        <span className="reset-password-button-title">Reset Password</span>
                                    </div>
                            :
                                <Link to={"/sign-in"}>
                                    <div className="reset-password-button-container margin-top-2x">
                                        <span className="reset-password-button-title">Log In</span>
                                    </div>
                                </Link>
                        }
                    </>
                }
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        resetPassword: state.resetPassword,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setRequestedEmail, setRequestPasswordSuccess, setRequestPasswordError, setNewPassword }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);