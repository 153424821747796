import React from "react";
import "./index.css";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";

import { VerificationDocument } from "../../../../../ReferenceVerification/ReferenceVerificationPDF";

export default ({ propName, admin, phoneNumber, value, type, whatsapp, candidate }) => {

    let verifiedPDF;

    if (type === "file" && value.completed) verifiedPDF = <VerificationDocument candidate={candidate} reference={value} />;

    if (propName) {
        return(
            <div className="text-row-container" style={whatsapp ? {alignItems: "center"} : {}}>
                <div className="text-row-prop-container" style={{minWidth: 144, maxWidth: 144}}>
                    <p>{propName}:</p>
                </div>
                <div className="text-row-value-container">
                    {!type &&
                        <p>{value}</p>
                    }
                    {type === "mobile" && admin &&
                        <a href={value} target={"_blank"} rel="noopener noreferrer">{phoneNumber}</a>
                    }
                    {type === "mobile" && !admin &&
                        <p>{value}</p>
                    }
                    {type === "email" &&
                        <a href={`mailto:${value}`} className="wrapword">{value}</a>
                    }
                    {type === "visa" &&
                        <div className="text-row-multiple-container">
                            {value?.b1b2?.status &&
                                <p className="text-row-multiple-text">B1/B2 {value?.b1b2?.expiryDate && <span className="text-row-multiple-date">(exp. {value?.b1b2?.expiryDate})</span>}</p>
                            }
                            {value?.c1d?.status &&
                                <p className="text-row-multiple-text">C1/D {value?.c1d?.expiryDate && <span className="text-row-multiple-date">(exp. {value?.c1d?.expiryDate})</span>}</p>
                            }
                            {value?.schengen?.status &&
                                <p className="text-row-multiple-text">Schengen {value?.schengen?.expiryDate && <span className="text-row-multiple-date">(exp. {value?.schengen?.expiryDate})</span>}</p>
                            }
                            {value?.greenCard?.status &&
                                <p className="text-row-multiple-text">Green Card {value?.greenCard?.expiryDate && <span className="text-row-multiple-date">(exp. {value?.greenCard?.expiryDate})</span>}</p>
                            }
                        </div>
                    }
                    {type === "file" &&
                        <p style={{cursor: "pointer", color: "#1377FF"}} onClick={() => generate(verifiedPDF, value)}>{`${value.referenceName} Reference.pdf`}</p>
                    }
                </div>
                {whatsapp &&
                    <img src={require("../../../../../../assets/svgs/whatsapp.svg")} alt="" />
                }   
            </div>
        );
    } else {
        return(
            <div className="text-row-container">
                <div className="text-row-prop-container" style={{minWidth: 144, maxWidth: 144}}>
                    <div className="text-row-prop-placeholder"></div>
                </div>
                <div className="text-row-value-container">
                    <div className="text-row-value-placeholder"></div>
                </div> 
            </div>
        );
    }

}

const generate = (document, reference) => {

    let blobPdf = pdf(document);
    blobPdf.updateContainer(document);
    
    return blobPdf.toBlob()
        .then(result => {
            return saveAs(result, `${reference.referenceName} Reference.pdf`);
        });
}