import {
    SET_COMPLETED_USERS,
    SET_INCOMPLETE_USERS,
    SET_USERS_COUNT,
    REMOVE_CANDIDATE_FROM_THE_LIST,
    SET_CV_PROFILE,
    UPDATE_CV_PROFILE,
    SET_CV_APPLICATION,
    SET_CREW,
    ADD_CREW,
    SET_YACHTS,
    UPDATE_LOCAL_YACHT,
    SET_LISTING,
} from "../types/admin";

export const setUsersCount = (data) => dispatch => {

    return dispatch({
        type: SET_USERS_COUNT,
        completedUsersCount: data.completedUsersCount,
    });
    
}

export const setCompletedUsers = (data) => dispatch => {

    return dispatch({
        type: SET_COMPLETED_USERS,
        data,
    });
    
}

export const setIncompleteUsers = (data) => dispatch => {

    return dispatch({
        type: SET_INCOMPLETE_USERS,
        data,
    });
    
}

export const removeCandidateFromTheList = (propName, pid) => dispatch => {

    return dispatch({
        type: REMOVE_CANDIDATE_FROM_THE_LIST,
        propName,
        pid,
    });
    
}

export const setCVProfile = (data) => dispatch => {

    return dispatch({
        type: SET_CV_PROFILE,
        data,
    });
    
}

export const updateCVProfile = (data) => dispatch => {

    return dispatch({
        type: UPDATE_CV_PROFILE,
        data,
    });
    
}

export const setListing = (data) => dispatch => {

    return dispatch({
        type: SET_LISTING,
        data,
    });
    
}

export const setCVApplication = (data) => dispatch => {

    return dispatch({
        type: SET_CV_APPLICATION,
        data,
    });
    
}

export const setCrew = (data) => dispatch => {

    return dispatch({
        type: SET_CREW,
        data,
    });
    
}

export const addCrew = (data) => dispatch => {

    return dispatch({
        type: ADD_CREW,
        data,
    });
    
}

export const setYachts = (data) => dispatch => {

    return dispatch({
        type: SET_YACHTS,
        data,
    });
    
}

export const updateLocalYacht = (data) => dispatch => {

    return dispatch({
        type: UPDATE_LOCAL_YACHT,
        data,
    });
    
}