import "./index.css";

import React, { Component } from "react";
import {
    Link,
} from "react-router-dom";

/* Components */
import Page from "../../../components/Page";
import NavigationBar from "../../../components/NavigationBar";

class ContactUs extends Component {

    componentDidMount() {
        return window.scrollTo(0, 0);
    }

    render() {

        return(
            <Page match={this.props.match} hideNavbar={true}>
                <div className="page-intro-linear-container">
                    <NavigationBar { ...this.props } />
                    <div style={{ height: 30 }}></div>
                    <h4>Contact</h4>
                </div>
                <div style={{height: 75}}></div>
                <div className="about-us-team-header-container">
                    <p>Get in touch with us</p>
                </div>
                <div className="about-us-text">
                    <p style={{ textAlign: "center" }}>If you are <Link to={"/looking-for-crew"}>looking for crew</Link> please contact us using the details below, and we will get back to you right away. If you have any questions about our vacancies or roles, or would like to have an informal chat about your yachting career, please feel free to contact us.</p>
                    <div style={{ height: 30 }}></div>
                    <p style={{ textAlign: "center" }}>General Enquries: <a href="mailto:crew@j4crew.com" target="_blank" rel="noopener noreferrer">crew@j4crew.com</a></p>
                    <div style={{ height: 30 }}></div>
                </div>
                <div style={{ height: 50 }}></div>
                <div className="about-us-team-member-container">
                    <div className="about-us-team-member-image-container">
                        <img src={require("../../../assets/images/chay.webp")} alt="" />
                    </div>
                    <div className="about-us-team-member-info-container">
                        <p className="about-us-team-member-name">Chay Hodgson</p>
                        <p className="about-us-team-member-position">Director and Recruitment Consultant</p>
                        <div className="about-us-text">
                            <p>Email: <a href="mailto:chay@j4crew.com" target="_blank" rel="noopener noreferrer">chay@j4crew.com</a></p>
                            <p>Phone: <a href="https://wa.me/447857138274" target={"_blank"} rel="noopener noreferrer">+44 7857 138 274</a> (WhatsApp)</p>
                        </div>
                        <div className="web-page-home-about-us-cell-row-socials-container">
                            <a href={"https://www.linkedin.com/in/chayhodgson/"} target={"_blank"} rel="noopener noreferrer">
                                <img src={require("../../../assets/svgs/linkedin.svg")} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="about-us-team-member-container">
                    <div className="about-us-team-member-image-container">
                        <img src={require("../../../assets/images/jacob.webp")} alt="" />
                    </div>
                    <div className="about-us-team-member-info-container">
                        <p className="about-us-team-member-name">Jacob Hodgson</p>
                        <p className="about-us-team-member-position">Director and Recruitment Consultant</p>
                        <div className="about-us-text">
                            <p>Email: <a href="mailto:jacob@j4crew.com" target="_blank" rel="noopener noreferrer">jacob@j4crew.com</a></p>
                            <p>Phone: <a href="https://wa.me/447598382232" target={"_blank"} rel="noopener noreferrer">+44 7598 382 232</a>. (WhatsApp)</p>
                        </div>                        
                        <div className="web-page-home-about-us-cell-row-socials-container">
                            <a href={"https://www.linkedin.com/in/jacob-hodgson-259b8b120/"} target={"_blank"} rel="noopener noreferrer">
                                <img src={require("../../../assets/svgs/linkedin.svg")} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{ height: 50 }}></div>
                <div className="web-page-footer-socials-container">
                    <a href={"https://twitter.com/J4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/twitter-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.facebook.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/facebook-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.instagram.com/j4crewyachting"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/instagram-footer.svg")} alt="" />
                    </a>
                    <a href={"https://www.linkedin.com/company/5267269"} target={"_blank"} rel="noopener noreferrer">
                        <img src={require("../../../assets/svgs/linkedin-footer.svg")} alt="" />
                    </a>
                </div>
                <div className="web-page-footer-links-container" style={{ borderBottom: "1px solid rgb(225, 225, 225)" }}>
                    <p><Link to={"/terms-and-conditions"}>Terms</Link><Link to={"/privacy-policy"}>Privacy Policy</Link><Link to={"/contact-us"}>Get in Touch</Link><Link to={"/about-us"}>About Us</Link><Link to={"/mlc-2006"}>MLC 2006</Link></p>
                </div>
                <div style={{height: 10}}></div>
                <div className="web-page-footer-links-container">
                    <p>Copyright ©{new Date().getFullYear()} J4Crew LTD</p>
                </div>
                <div style={{height: 30}}></div>
            </Page>
        );

    }

}

export default ContactUs;