import React from "react";
import "./index.css";

export default ({ profileImage, editPhoto, size }) => {

    return(
        <div className="edit-photo-container">
            <div className="edit-photo-wrapper" style={{width: size, height: size, borderRadius: size / 2}} onClick={() => editPhoto(false, true)}>
                <img src={profileImage || require("../../assets/svgs/avatar-placeholder.svg")} onError={(e) => e.target.src = require("../../assets/svgs/avatar-placeholder.svg")} style={{width: size, height: size, borderRadius: size / 2, objectFit: "cover"}} alt="" />
                <div className="edit-photo-overlay">
                    <p>Edit<br />Photo</p>
                </div>
            </div>
        </div>
    );

}