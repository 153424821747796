import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import TextInputDropdown from "../TextInputDropdown";
import SelectedItems from "../SelectedItems";
import SaveCancel from "../SaveCancel";
import CheckBox from "../CheckBox";

/* Actions */
import { updateUserProfileLookingFor } from "../../redux/actions/user";
import { setIdealPosition, resetIdealPosition, setCancelData } from "../../redux/actions/ideal-position";

/* API requests */
import { getPositions } from "../../api-requests/position";

class AddIdealPosition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: "",
            show: false,
            positions: [],
        };

    }

    componentDidMount() {

        return getPositions()
            .then(result => {
                if (result.status !== 201) return;
                return this.setState({ positions: result.data });
            });

    }

    selectPosition = (role) => {

        return this.setState({ show: false }, () => {
            return this.props.setIdealPosition([{ propName: "position", value: [...this.props.idealPosition.data.position, role] }]);
        });

    }

    handleAddingPosition = (addedPositions, role) => {

        if (role.position.length < 1) return alert("Please select position");

        if (role.noYachtSizePreference || role.yachtSizeMin || role.yachtSizeMax) {

            if (role.yachtType.length < 1) return alert("Please select at least 1 yacht type.");
            if (role.jobType.length < 1) return alert("Please select at least one contract type.");

            this.props.setCancelData(null);
            this.props.resetIdealPosition();

            this.props.updateUserProfileLookingFor([{ propName: "desiredRoles", value: addedPositions ? [...addedPositions, role] : [role] }], true);

            if (this.props.hide) this.props.hide();

            return this.setState({ keyword: "", show: false }, () => {
                if (this.props.initialSetup) this.props.initialSetupHide();
            });

        } else {
            return alert("Please select yacht length range");
        }
        

    }

    handleYachtType = (type) => {

        type = type[0].value;

        if (this.props.idealPosition.data.yachtType.includes(type)) {
            const types = this.props.idealPosition.data.yachtType.filter(t => t !== type);
            return this.props.setIdealPosition([{ propName: "yachtType", value: types }]);
        } else {
            return this.props.setIdealPosition([{ propName: "yachtType", value: [...this.props.idealPosition.data.yachtType, type] }]);
        }

    }

    handleJobType = (type) => {

        let types = this.props.idealPosition.data.jobType;

        type = type[0].value;

        if (this.props.idealPosition.data.jobType.includes(type)) {
            types = types.filter(t => t !== type);
            return this.props.setIdealPosition([{ propName: "jobType", value: types }]);
        } else {
            return this.props.setIdealPosition([{ propName: "jobType", value: [...types, type] }]);
        }

    }

    cancel = (addedPositions, data) => {

        if (this.props.initialSetup) this.props.initialSetupHide();
        if (this.props.hide) this.props.hide();
        if (data) this.props.updateUserProfileLookingFor([{ propName: "desiredRoles", value: addedPositions ? [...addedPositions, data] : [data] }], false);
        
        this.props.setCancelData(null);
        return this.props.resetIdealPosition();

    }

    render() {

        const { data } = this.props.idealPosition;
        const { profile } = this.props.user.data;

        return(
            <>
                <div className="margin-top-20px">
                    <TextInputDropdown show={this.state.show} toggleShow={() => this.setState({ show: !this.state.show })} icon={require("../../assets/svgs/chevron-down.svg")} label={"Position(s)"} placeholder={"Search..."} value={this.state.keyword} values={data.position} options={this.state.positions} onChangeText={(e) => this.setState({ keyword: e })} propName={"position"} select={this.selectPosition} />
                </div>
                <SelectedItems items={data.position} propName="position" updateUserProfile={this.props.setIdealPosition} />
                <div className="ideal-position-subheader-container margin-top-20px">
                    <p className="ideal-position-subheader-title">Yacht Length Range</p>
                </div>
                <div className="margin-top-10px">
                    <CheckBox label={"I have no yacht length preference"} status={data.noYachtSizePreference} value={!data.noYachtSizePreference} propName="noYachtSizePreference" toggle={this.props.setIdealPosition} />
                </div>
                {!data.noYachtSizePreference &&
                    <div className="ideal-position-yacht-length-range-input-container margin-top-20px">
                        <div className="ideal-position-yacht-length-item-container">
                            <div className="ideal-position-yacht-length-item-input-container">
                                <input className="ideal-position-yacht-length-item-input" type="text" placeholder={"40"} maxLength={3} value={data.yachtSizeMin} onChange={(e) => this.props.setIdealPosition([{ propName: "yachtSizeMin", value: e.target.value }])} />
                            </div>
                            <div className="ideal-position-yacht-length-item-unit-container">
                                <p>metres</p>
                            </div>
                        </div>
                        <div className="ideal-position-yacht-length-range-separator-container">
                            <div className="ideal-position-yacht-length-range-separator"></div>
                        </div>
                        <div className="ideal-position-yacht-length-item-container">
                            <div className="ideal-position-yacht-length-item-input-container">
                                <input className="ideal-position-yacht-length-item-input" type="text" placeholder={"70"} maxLength={3} value={data.yachtSizeMax} onChange={(e) => this.props.setIdealPosition([{ propName: "yachtSizeMax", value: e.target.value }])} />
                            </div>
                            <div className="ideal-position-yacht-length-item-unit-container">
                                <p>metres</p>
                            </div>
                        </div>
                    </div>
                }
                <div className="ideal-position-subheader-container margin-top-20px">
                    <p className="ideal-position-subheader-title">Ideal Yacht Type(s)</p>
                </div>
                <div className="margin-top-10px">
                    <CheckBox label={"Motor Yacht"} status={data.yachtType.includes("Motor Yacht")} value={"Motor Yacht"} propName={"yachtType"} toggle={this.handleYachtType} />
                </div>
                <div className="margin-top-10px">
                    <CheckBox label={"Sailing Yacht"} status={data.yachtType.includes("Sailing Yacht")} value={"Sailing Yacht"} propName={"yachtType"} toggle={this.handleYachtType} />
                </div>
                <div className="ideal-position-subheader-container margin-top-20px">
                    <p className="ideal-position-subheader-title">Ideal Contract Type(s)</p>
                </div>
                <div className="margin-top-10px">
                    <CheckBox label={"Full time"} status={data.jobType.includes("Full time")} value={"Full time"} propName={"jobType"} toggle={this.handleJobType} />
                </div>
                <div className="margin-top-10px">
                    <CheckBox label={"Rotation"} status={data.jobType.includes("Rotation")} value={"Rotation"} propName={"jobType"} toggle={this.handleJobType} />
                </div>
                <div className="margin-top-10px">
                    <CheckBox label={"Temporary"} status={data.jobType.includes("Temporary")} value={"Temporary"} propName={"jobType"} toggle={this.handleJobType} />
                </div>
                <div className="margin-top-10px">
                    <CheckBox label={"Daywork"} status={data.jobType.includes("Daywork")} value={"Daywork"} propName={"jobType"} toggle={this.handleJobType} />
                </div>
                <div className="margin-top-20px">
                    <SaveCancel onSave={() => this.handleAddingPosition(profile.lookingFor?.desiredRoles || [], data)} onCancel={() => this.cancel(profile.lookingFor?.desiredRoles || [], this.props.idealPosition.cancelData)} />
                </div>
            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        idealPosition: state.idealPosition,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfileLookingFor, setIdealPosition, resetIdealPosition, setCancelData }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIdealPosition);