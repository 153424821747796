import { SET_APPLICATIONS, ADD_APPLICATION, REMOVE_APPLICATION } from "../types/application";

export let setApplications = (data) => dispatch => {

    return dispatch({
        type: SET_APPLICATIONS,
        data,
    });
    
}

export let addApplication = (data) => dispatch => {

    return dispatch({
        type: ADD_APPLICATION,
        data,
    });
    
}

export let removeApplication = (id) => dispatch => {

    return dispatch({
        type: REMOVE_APPLICATION,
        id,
    });
    
}