import React from "react";
import { Link } from "react-router-dom";
import DashboardSectionBase from "../../../components/DashboardSectionBase";
import Title from "../Title";
import "./index.css";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { VerificationDocument } from "../ReferenceVerificationPDF";

export default ({ title, candidate, reference }) => {

    const verifiedPDF = <VerificationDocument candidate={candidate} reference={reference} />;

    return(
        <> 
            <Title title={title} />
            <DashboardSectionBase title={"Done!"} description={"Thank you for taking the time to complete this reference, it is very much appreciated. You can download this reference for your own records or to send onwards as you see fit."} icon={require("../../../assets/svgs/done.svg")} overflow={true}>
                <div className="margin-top-20px">
                    <div className="reference-verification-download-button" onClick={() => generate(verifiedPDF, reference)}>
                        <img src={require("../../../assets/svgs/download.svg")} alt="" />
                        <p>Download Reference</p>
                    </div>
                </div>
                <div className="margin-top-2x">
                    <p className="reference-verification-completed-title">Looking for a new position?</p>
                    <p className="reference-verification-completed-description">If you are looking for a new position yourself please <Link to={"/sign-up"}>register</Link> with us for free.</p>
                </div>
                <div className="margin-top-2x">
                    <p className="reference-verification-completed-title">Looking for crew?</p>
                    <p className="reference-verification-completed-description">If you are looking for crew please send an email to <a href="mailto:crew@j4crew.com">crew@j4crew.com</a> with some details about the role.</p>
                </div>
            </DashboardSectionBase>
        </>
    );

}

const generate = (document, reference) => {

    let blobPdf = pdf(document);
    blobPdf.updateContainer(document);
    
    return blobPdf.toBlob()
        .then(result => {
            return saveAs(result, `${reference.referenceName} Reference.pdf`);
        });
}