import moment from "moment";

const validChars = ["/", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const formatTextToDDMMYYYY = (input) => {

    const lastChar = input[input.length-1];

    if (validChars.includes(lastChar)) {
        return input;
    }

    return input.slice(0, input.length-1);

}

export const handleTimePeriod = (fromDate, toDate, present, excludeMonths) => {

    let fromDateStr = fromDate;
    let toDateStr = toDate;

    if (fromDate) {

        let month,
        year = fromDate.slice(3);

        if (fromDate[1] === "/") fromDate = "0"+fromDate;
        if (fromDate[2] === "/") month = handleMonth(fromDate.slice(0, 2));
        if (!year || year.length !== 4) year = "Invalid Year";
        if (!month) month = "Invalid Month";

        if (present) {
            if (excludeMonths) {
                if (fromDateStr.length === 4) year = fromDateStr;
                return `${year} - Present`;
            }
            return `${month} ${year} - Present`;
        } else {
            
            let endMonth,
                endYear = toDate.slice(3);

            if (toDate[1] === "/") toDate = "0"+toDate;
            if (toDate[2] === "/") endMonth = handleMonth(toDate.slice(0, 2));
            if (!endYear || endYear.length !== 4) endYear = "Invalid Year";
            if (!endMonth) endMonth = "Invalid Month";

            if (excludeMonths) {
                if (fromDateStr.length === 4) year = fromDateStr;
                if (toDateStr.length === 4) endYear = toDateStr;
                return `${year} - ${endYear}`;
            }
            return `${month} ${year} - ${endMonth} ${endYear}`;
            
        } 
    } else {
        return "-";
    }  

}

const handleMonth = (month) => {

    if (month === "01") return "Jan";
    if (month === "02") return "Feb";
    if (month === "03") return "Mar";
    if (month === "04") return "Apr";
    if (month === "05") return "May";
    if (month === "06") return "June";
    if (month === "07") return "July";
    if (month === "08") return "Aug";
    if (month === "09") return "Sept";
    if (month === "10") return "Oct";
    if (month === "11") return "Nov";
    if (month === "12") return "Dec";

    return "Invalid Month";

}

export const formatUploadedDate = (date) => {

    let dd = moment(date).date(),
        mm = moment(date).month()+1,
        yy = moment(date).year();

    if (dd < 10) {
        dd = "0"+dd;
    }

    if (mm < 10) {
        mm = "0"+mm;
    }

    return `${dd}/${mm}/${yy}`;

}

export const latestFirst = (a, b) => {

    if (a.fromDate && b.fromDate) {
        let monthA = parseInt(a.fromDate.slice(0, 2), 10),
        yearA = parseInt(a.fromDate.slice(3, 7), 10),
        monthB = parseInt(b.fromDate.slice(0, 2), 10),
        yearB = parseInt(b.fromDate.slice(3, 7), 10);
    
        let dateA = new Date(yearA, monthA-1, 1),
            dateB = new Date(yearB, monthB-1, 1);

        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;

    }   

    return 0;

}

export const readDob = (dob) => {

    let mm = moment(dob).month()+1,
        dd = moment(dob).date(),
        yy = moment(dob).year();
    
    if (mm < 10) mm = "0"+mm;
    if (dd < 10) dd = "0"+dd;

    return `${dd}/${mm}/${yy}`;

}