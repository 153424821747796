import React from "react";
import "./index.css";

export default ({ show, toggle, categories, selected, select, propName, qualIndex, moreQuals, addAlternative }) => {

    return(
        <>
            <div className="categories-buttons-container">
                <div className="categories-toggle-button" onClick={toggle}>
                    <p className="categories-toggle-button-title">{show ? "Hide" : "Show"} all categories</p>
                </div>
                {propName === "moreQualifications" && moreQuals && moreQuals[0] && moreQuals[0].length > 0 &&
                    <div className="categories-add-alternative-button" onClick={addAlternative}>
                        <p className="categories-add-alternative-button-title">Add alternative</p>
                    </div>
                }                
            </div>
            {show &&
                <div className="all-categories-container">
                    {renderAllCategories(categories, selected, select, propName, qualIndex)}
                </div>
            }
        </>
    );

}

const renderAllCategories = (categories, selected, select, propName, qualIndex) => {

    let ids;
    let sections = {};

    if (propName === "qualifications" || propName === "moreQualifications") {
        ids = selected.map(select => select._id);
        categories = categories.filter(item => !ids.includes(item._id));
    } else {
        categories = categories.filter(item => !selected.includes(item.value));
    }

    categories.forEach(category => {
        if (sections[category.group]) {
            sections[category.group] = [...sections[category.group], category];
        } else {
            sections[category.group] = [category]; 
        }
    });

    const keys = Object.keys(sections);
    
    return keys.map((key, index) => {
        return(
            <div className="category-section-container margin-top-20px" key={index}>
                <div className="category-section-title-container">
                    <p>{key}</p>
                </div>
                <div className="category-section-items-container">
                    {renderItems(key, categories, propName, select, qualIndex)}
                </div>
            </div>
        );
    });

}

const renderItems = (key, categories, propName, select, qualIndex) => {

    categories = categories.filter(category => category.group === key);

    return categories.map((category, index) => {
        return(
            <div key={index} className="category-item-container" onClick={propName === "qualifications" || propName === "moreQualifications" ? () => select(category, propName, qualIndex) : () => select(category.value, propName)}>            
                <span>{category.name || category.value}</span>           
            </div>
        );
    });

}