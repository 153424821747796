import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { isMobile, isSafari, isChrome } from "react-device-detect";

/* Constants */
import { signUpsPaths } from "../../constants";

/* Actions */
import { adjustProfileImage, toggleBurgerStatus, toggleBurgerMenu, setProfileBuilderFinishLine, setCVPreview, setDeleteAccount, setPasswordChanged, setDetailsChanged, setLogOut, setAlert, setDeleteLocalYachtAccount, setViewListing } from "../../redux/actions/misc";
import { toggleEmailVerifiedAlert, toggleResendEmail } from "../../redux/actions/initial-setup";
import { updateUserProfile, setAuth, setUserData } from "../../redux/actions/user";
import { setJobs, removeJob } from "../../redux/actions/job";
import { setApplications } from "../../redux/actions/application";
import { removeCandidateFromTheList, setListing } from "../../redux/actions/admin";

/* Components */
import OnlineCV from "../../pages/DashboardCandidate/components/CV/components/OnlineCV";
import SelectAvailability from "../SelectAvailability";
import InitialSetupBase from "../InitialSetupBase";
import SidebarDownloads from "../SidebarDownloads";
import SelectLocation from "../SelectLocation";
import NavigationBar from "../NavigationBar";
import VerifyEmail from "../VerifyEmail";
import AdjustPhoto from "../AdjustPhoto";
import EditPhoto from "../EditPhoto";
import Overlay from "../Overlay";

import { SidebarMenuButton, SidebarMenuSubButton } from "../SidebarMenuButtons";

/* API requests */
import { uploadAvatar, updateCandidate } from "../../api-requests/candidate";
import { logoutUser, deleteUserCandidate } from "../../api-requests/user";
import { handlePutForward, rejectApplication, restoreApplication } from "../../api-requests/application";
import { deleteYacht } from "../../api-requests/yacht";
import { handleYachtIcon, handleYachtSize, handleYachtType, handleJobPostTitle, handleYachtAge, handleYachtBuildRegion, handleVisas, handleContractType, handleStartDate } from "../../helpers/job";
import { handleMultiple, handleMultipleQualifications } from "../../helpers/candidate";
import { archiveJob, getJob } from "../../api-requests/job";

class Page extends Component {

    constructor(props) {
        super(props);

        this.state = {
            downloads: false,
        };
    }

    handleImageSave = (editor, id) => {

        if (editor) {
            const canvasScaled = editor.getImageScaledToCanvas();
            return canvasScaled.toBlob((blob) => {
                return uploadAvatar(id, blob)
                    .then(result => {
                        const body = [
                            { propName: "profileImage", value: result.data },
                        ];
                        this.props.updateUserProfile(body);
                        return this.props.adjustProfileImage(false);
                    });
            },
            "image/jpeg", 1);
        }

    }

    handleEditPhoto = (burgerStatus, photoStatus) => {

        this.props.toggleBurgerMenu(burgerStatus);

        return setTimeout(() => {
            this.props.toggleBurgerStatus(burgerStatus);
            return this.props.adjustProfileImage(photoStatus);
        }, 300);

    }

    dismissBurgerMenu = (status) => {

        this.props.toggleBurgerMenu(status);

        return setTimeout(() => {
            return this.props.toggleBurgerStatus(status);
        }, 300);

    }

    handleLogout = (status, history) => {

        this.props.toggleBurgerStatus(status);
        this.props.toggleBurgerMenu(status);

        this.props.setLogOut(status);
        this.props.setUserData(null);
        this.props.setAuth(status);

        logoutUser();

        return history.push("/");

    } 

    handleApply = (history) => {

        if (history) history.push("/profile");
        
        return this.props.setCVPreview(false);

    }

    handleDeleteAccount = (user, admin) => {

        if (user.userType === "Candidate") {
            return deleteUserCandidate(user)
                .then(result => {
                    if (result.success) {
                        if (admin) {

                            let message = `${user.firstName} ${user.lastName} has been deleted!`;
                            
                            this.props.setAlert(message, "success", true)
                            this.props.setDeleteAccount(false, false);

                            if (user?.profile?.isVerified) {
                                this.props.history.push("/crew");
                            } else {
                                this.props.history.push("/sign-ups");
                            }

                            return setTimeout(() => {
                                return this.props.setAlert(message, "success", false)
                            }, 2700);

                        } else {
                            logoutUser();
                            this.props.setUserData(null);
                            this.props.setAuth(false);
                            this.props.history.push("/");
                            return this.props.setDeleteAccount(false);
                        }
                    }
                    return;
                });
        }

    }

    handleDeleteLocalYachtAccount = (id) => {

        return deleteYacht(id)
            .then(_ => {

                this.props.setDeleteLocalYachtAccount(false, "");
                this.props.history.push("/yachts");
                this.props.setAlert("Local yacht deleted successfully!", "success", true);
                
                return setTimeout(() => {
                    return this.props.setAlert("Local yacht deleted successfully!", "success", false);
                }, 3000);

            });

    }

    showDeleteAccountModal = () => {

        this.dismissBurgerMenu(false);

        return this.props.setDeleteAccount(true, true)

    }

    handleReject = (user, history) => {

        this.dismissBurgerMenu(false);

        let message = `${user.firstName} ${user.lastName} rejected!`;
        let body = [
            { propName: "requestedVerification", value: false },
            { propName: "requestedVerificationDate", value: null },
            { propName: "isRejected", value: true },
        ];

        return updateCandidate(user.profile._id, body)
            .then(_ => {
                history.push("/sign-ups");
                this.props.setAlert(message, "success", true);

                return setTimeout(() => {
                    return this.props.setAlert(message, "success", false);
                }, 2700);
            });

    }

    handleVerify = (user, history) => {

        this.dismissBurgerMenu(false);

        let message = `${user.firstName} ${user.lastName} verified!`;
        let body = [
            { propName: "isVerified", value: true },
        ];

        return updateCandidate(user.profile._id, body)
            .then(_ => {
                history.push("/sign-ups");
                this.props.setAlert(message, "success", true);

                return setTimeout(() => {
                    return this.props.setAlert(message, "success", false);
                }, 2700);

            });

    }

    archiveListing = (_id) => {

        this.dismissBurgerMenu(false);

        return archiveJob(_id)
            .then(_ => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/archived`);
                } else {
                    this.props.history.push(`/listings/archived`);
                }
                this.props.setAlert("Listing archived successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Listing archived successfully!", "success", false);
                }, 2700);
            });

    }

    viewListing = (_id) => {

        this.dismissBurgerMenu(false);

        return getJob(_id)
            .then(result => {
                this.props.setListing(result);
                return this.props.setViewListing(true);
            });

    }

    putForward = (_id) => {

        this.dismissBurgerMenu(false);

        return handlePutForward(_id)
            .then(result => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}/${result.nextStage}`);
                } else {
                    this.props.history.push(`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}/${result.nextStage}`);
                }
                this.props.setAlert("Candidate put forward successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Candidate put forward successfully!", "success", false);
                }, 2700);
            });

    }

    rejectApplication = (_id) => {

        this.dismissBurgerMenu(false);

        return rejectApplication(_id)
            .then(_ => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}/rejected`);
                } else {
                    this.props.history.push(`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}/rejected`);
                }
                this.props.setAlert("Candidate rejected successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Candidate rejected successfully!", "success", false);
                }, 2700);
            });

    }

    restoreApplication = (_id) => {

        this.dismissBurgerMenu(false);

        return restoreApplication(_id)
            .then(_ => {
                if (this.props.match.path.includes("/yachts")) {
                    this.props.history.push(`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}`);
                } else {
                    this.props.history.push(`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}`);
                }
                this.props.setAlert("Application restored successfully!", "success", true);
                return setTimeout(() => {
                    this.props.setAlert("Application restored successfully!", "success", false);
                }, 2700);
            });

    }

    render() {

        const { data } = this.props.user;
        const addExtraSpace = isMobile && (isSafari || isChrome);

        let visas;
        let contractType;
        let startDate;

        if (this.props.misc.viewListing) {
            visas = handleVisas(this.props.admin.listing);
            contractType = handleContractType(this.props.admin.listing);
            startDate = handleStartDate(this.props.admin.listing);
        }

        return(
            <div className="page-container" style={{ overflow: "hidden" }}>
                {!this.props.hideNavbar && <NavigationBar {...this.props} />}
                {this.props.children}
                <div className={this.props.misc.showAlert ? "email-verified-alert-container show" : "email-verified-alert-container"}>
                    <div className="email-verified-alert" onClick={() => this.props.setAlert(this.props.misc.alertMessage, "success", false)}>
                        <p>{this.props.misc.alertMessage}</p>
                    </div>
                </div>
                <div className={this.props.initialSetup.verified ? "email-verified-alert-container show" : "email-verified-alert-container"}>
                    <div className="email-verified-alert" onClick={() => this.props.toggleEmailVerifiedAlert()}>
                        <p>Email successfully verified!</p>
                    </div>
                </div>
                <div className={this.props.initialSetup.resend ? "email-verified-alert-container show" : "email-verified-alert-container"}>
                    <div className="email-verified-alert" onClick={() => this.props.toggleResendEmail(false)}>
                        <p>New email sent!</p>
                    </div>
                </div>
                <div className={this.props.misc.passwordChanged ? "email-verified-alert-container show" : "email-verified-alert-container"}>
                    <div className="email-verified-alert" onClick={() => this.props.setPasswordChanged(false)}>
                        <p>Password successfully updated!</p>
                    </div>
                </div>
                <div className={this.props.misc.detailsChanged ? "email-verified-alert-container show" : "email-verified-alert-container"}>
                    <div className="email-verified-alert" onClick={() => this.props.setDetailsChanged(false)}>
                        <p>Basic details successfully updated!</p>
                    </div>
                </div>
                {data?.profile?.profileType === "build" && !data?.isVerified &&
                    <Overlay>
                        <VerifyEmail email={data.email} userID={data._id} resend={this.props.toggleResendEmail} noexit={true} />
                    </Overlay>
                }
                {this.props.misc.adjustProfileImage &&
                    <Overlay>
                        <div className="edit-photo-modal-container">
                            <img className="edit-photo-x" src={require("../../assets/svgs/x.svg")} onClick={() => this.props.adjustProfileImage(false)} alt="" />
                            <InitialSetupBase title={"Edit Photo"} description={"You can adjust or replace your professional portrait photo."}>
                                <AdjustPhoto />
                                <div className="edit-photo-action-buttons-container margin-top-2x">
                                    <div className="edit-photo-action-buttons-save" onClick={() => this.handleImageSave(this.props.initialSetup.editor, this.props.user.data.profile._id)}>
                                        <p className="edit-photo-action-buttons-save-title">Save</p>
                                    </div>
                                    <div className="edit-photo-action-buttons-cancel">
                                        <p className="edit-photo-action-buttons-cancel-title" onClick={() => this.props.adjustProfileImage(false)}>Cancel</p>
                                    </div>
                                </div>
                            </InitialSetupBase>
                        </div>
                    </Overlay>
                }
                {this.props.misc.profileBuilderFinishLine &&
                    <Overlay>
                        <div className="profile-completed-modal-container">
                            <div className="profile-completed-modal-exit-container">
                                <img onClick={() => this.props.setProfileBuilderFinishLine(false)} src={require("../../assets/svgs/x.svg")} alt="" />
                            </div>
                            <div className="profile-completed-modal-title-container">
                                <p>Profile Completed!</p>
                            </div>
                            <div className="profile-completed-modal-description-container">
                                <p>Thanks for completing your profile! Feel free to start applying for jobs.</p>
                            </div>
                            <div className="profile-completed-modal-button-container">
                                <Link to={"/"} onClick={() => this.props.setProfileBuilderFinishLine(false)}>
                                    <div className="profile-completed-modal-button">
                                        <p>Apply for Jobs</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Overlay>
                }
                {this.props.misc.burgerStatus && !this.props.user.isLoggedIn &&
                    <Overlay>
                        <div className={this.props.misc.showBurger ? "burger-menu-master-container expanded" : "burger-menu-master-container"}>
                            <div className="burger-menu-container">
                                <div className="burger-menu-header-container">
                                    <div className="burger-menu-header-icon-container" onClick={() => this.dismissBurgerMenu(false)}>
                                        <img className="burger-menu-header-icon" src={require("../../assets/svgs/chevron-down.svg")} alt="" />
                                    </div>
                                </div>
                                <SidebarMenuSubButton active={this.props.match.path.includes("/find-jobs")} title={"Find Jobs"} path={"/find-jobs"} collapse={this.dismissBurgerMenu} />
                                <SidebarMenuSubButton active={this.props.match.path.includes("/looking-for-crew")} title={"Looking for Crew?"} path={"/looking-for-crew"} collapse={this.dismissBurgerMenu} />
                                <SidebarMenuSubButton active={this.props.match.path.includes("/about-us")} title={"About Us"} path={"/about-us"} collapse={this.dismissBurgerMenu} />
                                <SidebarMenuSubButton active={this.props.match.path.includes("/cv-advice")} title={"CV Advice"} path={"/cv-advice"} collapse={this.dismissBurgerMenu} />
                                <SidebarMenuSubButton active={this.props.match.path.includes("/contact-us")} title={"Contact Us"} path={"/contact-us"} collapse={this.dismissBurgerMenu} />    
                            </div>
                            <div className="burger-menu-retainer" onClick={() => this.dismissBurgerMenu(false)}></div>
                        </div>
                    </Overlay>
                }
                {this.props.misc.burgerStatus && this.props.user.isLoggedIn &&
                    <Overlay>
                        <div className={this.props.misc.showBurger ? "burger-menu-master-container expanded" : "burger-menu-master-container"}>
                            <div className="burger-menu-container">
                                <div className="burger-menu-header-container">
                                    <div className="burger-menu-header-icon-container" onClick={() => this.dismissBurgerMenu(false)}>
                                        <img className="burger-menu-header-icon" src={require("../../assets/svgs/chevron-down.svg")} alt="" />
                                    </div>
                                </div>
                                <div className="burger-menu-scroll-container">
                                    {data?.userType !== "Admin" &&
                                        <>
                                            <EditPhoto profileImage={data?.profile?.profileImage} editPhoto={this.handleEditPhoto} size={114} />
                                            <div className="burger-menu-spacer">
                                                <SelectAvailability />
                                            </div>
                                            <div className="burger-menu-spacer">
                                                <SelectLocation />
                                            </div>
                                            <div style={{padding: 20, paddingBottom: 0}}>
                                                <SidebarMenuButton active={this.props.match.path === "/" || ["/jobs", "/jobs/applications"].includes(this.props.match.path)} title={"Jobs"} icon={require("../../assets/svgs/jobs.svg")} path={"/"} collapse={this.dismissBurgerMenu} />
                                                <div className="margin-top-1x">
                                                    <SidebarMenuButton active={this.props.match.path.includes("/profile")} title={"Profile"} icon={require("../../assets/svgs/profile.svg")} expandable={true} path={"/profile"} />
                                                    {this.props.match.path.includes("/profile") &&
                                                        <>
                                                            <SidebarMenuSubButton active={this.props.match.path.includes("/profile") && !this.props.match.path.includes("/cv")} title={"Edit your Profile"} path={"/profile"} collapse={this.dismissBurgerMenu} />
                                                            <SidebarMenuSubButton active={this.props.match.path === "/profile/cv"} title={"See Your J4Crew CV"} path={"/profile/cv"} collapse={this.dismissBurgerMenu} />
                                                        </>
                                                    }   
                                                </div>
                                                <SidebarDownloads show={this.state.downloads} toggle={() => this.setState({ downloads: !this.state.downloads })} user={data} originalCV={data.profile.cv} />
                                                <div style={{marginTop: 10}}>
                                                    <SidebarMenuSubButton active={this.props.match.path.includes("/settings")} title={"Settings"} path={"/settings"} collapse={this.dismissBurgerMenu} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {data?.userType === "Admin" &&
                                        <div style={{paddingLeft: 20, paddingRight: 20}}>
                                            <SidebarMenuButton active={signUpsPaths.includes(this.props.match.path) || this.props.match.path.includes("/sign-ups/profile")} title={"Sign Ups"} path={"/sign-ups"} userType={"Admin"} num={this.props.admin.completedUsersCount} collapse={this.dismissBurgerMenu} />
                                            <div className="margin-top-20px">
                                                <SidebarMenuButton active={this.props.match.path.includes("/crew")} title={"Crew"} path={"/crew"} collapse={this.dismissBurgerMenu} />
                                            </div>
                                            <div className="margin-top-20px">
                                                <SidebarMenuButton active={this.props.match.path.includes("/yachts")} title={"Yachts"} path={this.props.match.params.yachtId ? "/yachts/edit/"+this.props.match.params.yachtId : "/yachts"} collapse={this.dismissBurgerMenu} />
                                                {(this.props.match.path.includes("/yachts/edit") || this.props.match.path.includes("/yachts/listings")) &&
                                                    <>
                                                        <SidebarMenuSubButton active={this.props.match.path.includes("/yachts/listings")} title={"Listings"} path={"/yachts/listings/"+this.props.match.params.yachtId} collapse={this.dismissBurgerMenu} />
                                                        <SidebarMenuSubButton active={this.props.match.path.includes("/yachts/edit")} title={"Yacht Details"} path={"/yachts/edit/"+this.props.match.params.yachtId} collapse={this.dismissBurgerMenu} />
                                                    </>
                                                }
                                            </div>
                                            <div className="margin-top-20px">
                                                <SidebarMenuButton active={this.props.match.path.includes("/listings") && !this.props.match.path.includes("/yachts")} title={"Listings"} path={"/listings"} collapse={this.dismissBurgerMenu} />
                                            </div>
                                            <div className="margin-top-20px">
                                                <SidebarMenuButton active={this.props.match.path === "/stats"} title={"Stats"} path={"/stats"} collapse={this.dismissBurgerMenu} />
                                            </div>
                                            <div className="margin-top-20px">
                                                <SidebarMenuButton active={this.props.match.path === "/references"} title={"References"} path={"/references"} collapse={this.dismissBurgerMenu} />
                                            </div>
                                            <div className="margin-top-20px">
                                                <SidebarMenuButton active={this.props.match.path === "/jobs"} title={"Jobs"} path={"/jobs"} collapse={this.dismissBurgerMenu} />
                                            </div>
                                            {(this.props.match.path.includes("/sign-ups/profile/") || this.props.match.path.includes("/crew/profile/")) && this.props.admin.cvProfile &&
                                                <>
                                                    <div className="sidebar-admin-actions-container margin-top-20px">
                                                        <div className="sidebar-admin-action-header-container">
                                                            <div className="sidebar-admin-action-wrapper">
                                                                <p>Candidate Actions</p>
                                                            </div>
                                                        </div>
                                                        {!this.props.admin.cvProfile.profile.isVerified &&
                                                            <div className="sidebar-admin-action-button-container" onClick={() => this.handleVerify(this.props.admin.cvProfile, this.props.history)}>
                                                                <div className="sidebar-admin-action-wrapper">
                                                                    <img src={require("../../assets/svgs/verify.svg")} alt="" />
                                                                    <p style={{color: "#1377FF"}}>Verify Candidate</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.props.admin.cvProfile.profile.requestedVerification && !this.props.admin.cvProfile.profile.isVerified &&
                                                            <div className="sidebar-admin-action-button-container" onClick={() => this.handleReject(this.props.admin.cvProfile, this.props.history)}>
                                                                <div className="sidebar-admin-action-wrapper">
                                                                    <img src={require("../../assets/svgs/reject.svg")} alt="" />
                                                                    <p style={{color: "#333333"}}>Reject Candidate</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="sidebar-admin-action-button-container" onClick={() => this.showDeleteAccountModal()}>
                                                            <div className="sidebar-admin-action-wrapper">
                                                                <img src={require("../../assets/svgs/trash.svg")} alt="" />
                                                                <p style={{color: "#EB5757"}}>Delete Account</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="margin-top-20px">
                                                        <SidebarDownloads admin={true} show={this.state.downloads} toggle={() => this.setState({ downloads: !this.state.downloads })} user={this.props.admin.cvProfile} originalCV={this.props.admin.cvProfile.profile.cv} />
                                                    </div>
                                                    <div className="sidebar-admin-return-button-container margin-top-10px">
                                                        <Link to={this.props.admin?.cvProfile?.profile?.isVerified ? "/crew/" : "/sign-ups/"} className="sidebar-admin-return-button-wrapper" onClick={() => this.dismissBurgerMenu(false)}>
                                                            <img src={require("../../assets/svgs/return.svg")} alt="" />
                                                            <p>{this.props.admin?.cvProfile?.profile?.isVerified ? "Return to Crew" : "Return to Sign Ups"}</p>
                                                        </Link>
                                                    </div>
                                                </>
                                            }
                                            {this.props.match.params.tab === "application" && this.props.match.params.applicationId && this.props.admin.cvProfile && this.props.admin.cvApplication &&
                                                <>
                                                    <div className="sidebar-admin-actions-container margin-top-20px">
                                                        <div className="sidebar-admin-action-header-container">
                                                            <div className="sidebar-admin-action-wrapper">
                                                                <p>Candidate Actions</p>
                                                            </div>
                                                        </div>
                                                        {this.props.admin.cvApplication.status !== "hired" && !this.props.admin.cvApplication.rejected &&
                                                            <div className="sidebar-admin-action-button-container">
                                                                <div className="sidebar-admin-action-wrapper" onClick={() => this.putForward(this.props.match.params.applicationId)}>
                                                                    <img src={require("../../assets/svgs/put-forward.svg")} alt="" />
                                                                    <p style={{color: "#1377FF"}}>Put Forward</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.props.admin.cvApplication.rejected &&
                                                            <div className="sidebar-admin-action-button-container">
                                                                <div className="sidebar-admin-action-wrapper" onClick={() => this.restoreApplication(this.props.match.params.applicationId)}>
                                                                    <img src={require("../../assets/svgs/put-forward.svg")} alt="" />
                                                                    <p style={{color: "#1377FF"}}>Restore Application</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {!this.props.admin.cvApplication.rejected &&
                                                            <div className="sidebar-admin-action-button-container">
                                                                <div className="sidebar-admin-action-wrapper" onClick={() => this.rejectApplication(this.props.match.params.applicationId)}>
                                                                    <img src={require("../../assets/svgs/trash.svg")} alt="" />
                                                                    <p style={{color: "#F55D5D"}}>Reject Candidate</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="margin-top-20px">
                                                        <SidebarDownloads admin={true} show={this.state.downloads} toggle={() => this.setState({ downloads: !this.state.downloads })} user={this.props.admin.cvProfile} originalCV={this.props.admin.cvProfile.profile.cv} />
                                                    </div>
                                                </>
                                            }
                                            {this.props.match.path.includes("/yachts/") && this.props.match.params.yachtId  && this.props.match.params.status !== "applications" &&
                                                <div className="delete-local-yacht-account-button margin-top-20px" onClick={() => this.props.setDeleteLocalYachtAccount(true, this.props.match.params.yachtId)}>
                                                    <div className="delete-local-yacht-account-button-icon-container">
                                                        <img src={require("../../assets/svgs/trash.svg")} alt="" />
                                                    </div>
                                                    <div className="delete-local-yacht-account-button-title-container">
                                                        <p>Delete Account</p>
                                                    </div>
                                                </div>
                                            }
                                            {(this.props.match.path === "/yachts/create" || this.props.match.path.includes("/yachts/edit") || this.props.match.path.includes("/yachts/listings")) && this.props.match.params.status !== "add" && this.props.match.params.status !== "applications" &&
                                                <div className="sidebar-admin-return-button-container margin-top-10px">
                                                    <Link to={"/yachts"} className="sidebar-admin-return-button-wrapper" onClick={() => this.dismissBurgerMenu(false)}>
                                                        <img src={require("../../assets/svgs/return.svg")} alt="" />
                                                        <p>Return to Yachts</p>
                                                    </Link>
                                                </div>
                                            }
                                            {(this.props.match.params.status === "add" || this.props.match.params.status === "applications") && !this.props.match.params.applicationId && this.props.match.params.jobId && this.props.match.params.tab !== "application" && this.props.match.params.yachtId &&
                                                <>
                                                    <div className="sidebar-admin-actions-container margin-top-20px">
                                                        <div className="sidebar-admin-action-header-container">
                                                            <div className="sidebar-admin-action-wrapper">
                                                                <p>Listing Actions</p>
                                                            </div>
                                                        </div>
                                                        <Link className="sidebar-admin-action-button-container" to={this.props.match.path.includes("/yachts") ? `/yachts/listings/${this.props.match.params.yachtId}/add?job=${this.props.match.params.jobId}&status=active` : `/listings/add/${this.props.match.params.yachtId}?job=${this.props.match.params.jobId}&status=active`} onClick={() => this.dismissBurgerMenu(false)}>
                                                            <div className="sidebar-admin-action-wrapper">
                                                                <img src={require("../../assets/svgs/edit-dark.svg")} alt="" />
                                                                <p style={{color: "#333333"}}>Edit Listing</p>
                                                            </div>
                                                        </Link>
                                                        <div className="sidebar-admin-action-button-container">
                                                            <div className="sidebar-admin-action-wrapper" onClick={() => this.archiveListing(this.props.match.params.jobId)}>
                                                                <img src={require("../../assets/svgs/end-listing.svg")} alt="" />
                                                                <p style={{color: "#333333"}}>End & Archive Listing</p>
                                                            </div>
                                                        </div>
                                                        <div className="sidebar-admin-action-button-container">
                                                            <div className="sidebar-admin-action-wrapper" onClick={() => this.viewListing(this.props.match.params.jobId)}>
                                                                <img src={require("../../assets/svgs/eye.svg")} alt="" />
                                                                <p style={{color: "#333333"}}>View Listing</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {this.props.match.path.includes("/yachts/") && (this.props.match.params.status === "add" || this.props.match.params.status === "applications") && !this.props.match.params.applicationId && this.props.match.params.tab !== "application" && this.props.match.params.yachtId &&
                                                <div className="sidebar-admin-return-button-container margin-top-10px">
                                                    <Link to={`/yachts/listings/${this.props.match.params.yachtId}`} className="sidebar-admin-return-button-wrapper" onClick={() => this.dismissBurgerMenu(false)}>
                                                        <img src={require("../../assets/svgs/return.svg")} alt="" />
                                                        <p>Return to Listings</p>
                                                    </Link>
                                                </div>
                                            }
                                            {!this.props.match.path.includes("/yachts/") && this.props.match.path.includes("/listings/") && (this.props.match.params.status === "add" || this.props.match.params.status === "applications") && this.props.match.params.tab !== "application" && this.props.match.params.yachtId &&
                                                <div className="sidebar-admin-return-button-container margin-top-10px">
                                                    <Link to={`/listings`} className="sidebar-admin-return-button-wrapper" onClick={() => this.dismissBurgerMenu(false)}>
                                                        <img src={require("../../assets/svgs/return.svg")} alt="" />
                                                        <p>Return to Listings</p>
                                                    </Link>
                                                </div>
                                            }
                                            {this.props.match.path.includes("/yachts/") && this.props.match.params.yachtId && this.props.match.params.jobId && this.props.match.params.applicationId && this.props.match.params.tab === "application" &&
                                                <div className="sidebar-admin-return-button-container margin-top-10px">
                                                    <Link to={`/yachts/listings/${this.props.match.params.yachtId}/applications/${this.props.match.params.jobId}`} className="sidebar-admin-return-button-wrapper" onClick={() => this.dismissBurgerMenu(false)}>
                                                        <img src={require("../../assets/svgs/return.svg")} alt="" />
                                                        <p>Return to Applications</p>
                                                    </Link>
                                                </div>
                                            }
                                            {!this.props.match.path.includes("/yachts/") && this.props.match.params.yachtId && this.props.match.params.jobId && this.props.match.params.applicationId && this.props.match.params.tab === "application" &&
                                                <div className="sidebar-admin-return-button-container margin-top-10px">
                                                    <Link to={`/listings/applications/${this.props.match.params.yachtId}/job/${this.props.match.params.jobId}`} className="sidebar-admin-return-button-wrapper" onClick={() => this.dismissBurgerMenu(false)}>
                                                        <img src={require("../../assets/svgs/return.svg")} alt="" />
                                                        <p>Return to Applications</p>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className="sidebar-candidate-menu-container burger-menu-spacer">
                                        <div style={{marginBottom: 10}}>
                                            <SidebarMenuSubButton title={"Log out"} logout={true} handleLogout={this.props.setLogOut} />
                                        </div>
                                    </div>
                                    {addExtraSpace &&
                                        <div style={{width: "100%", minHeight: 128}}></div>
                                    }
                                </div>
                            </div>
                            <div className="burger-menu-retainer" onClick={() => this.dismissBurgerMenu(false)}></div>
                        </div>
                    </Overlay>
                }
                {this.props.misc.showCVPreview &&
                    <Overlay>
                        <div className="cv-preview-modal-container">
                            <img className="cv-preview-x" src={require("../../assets/svgs/x.svg")} alt="" onClick={() => this.props.setCVPreview(false)} />
                            <p className="cv-preview-title">Review your profile <span role="img" aria-label="Check it out!">👇</span></p>
                            <p className="cv-preview-subtitle">Please look over your profile to check it is correct and complete for employers to view it.</p>
                            <div className="margin-top-2x">
                                <OnlineCV user={data} />
                            </div>
                            <div className="cv-preview-modal-buttons-container">
                                <div className="cv-preview-modal-edit-button-container" onClick={() => this.handleApply(this.props.history)}>
                                    <p>Edit Profile</p>
                                </div>
                                <div className="cv-preview-modal-buttons-separator"></div>
                                <div className="cv-preview-modal-confirm-button-container" onClick={() => this.handleApply()}>
                                    <p>Confirm</p>
                                </div>
                            </div>
                        </div>
                        <div style={{minHeight: 150, width: "100%"}}></div>
                    </Overlay>
                }
                {this.props.misc.viewListing &&
                    <Overlay>
                        <div className="view-listing-modal-container">
                            <img className="view-listing-x" src={require("../../assets/svgs/x.svg")} alt="" onClick={() => this.props.setViewListing(false)} />
                            <div className="yacht-listing-review-container margin-top-2x">
                                <div className="yacht-listing-review-row-container">
                                    <div className="yacht-listing-review-row-icon-container">
                                        <img src={handleYachtIcon(this.props.admin.listing.yacht.size, this.props.admin.listing.yacht.yachtType)} alt="" />
                                    </div>
                                    <div className="yacht-listing-review-row-type-container">
                                        <p>{handleYachtSize(this.props.admin.listing.yacht.size)} {handleYachtType(this.props.admin.listing.yacht.yachtType)}</p>
                                    </div>
                                    <div className="yacht-listing-review-row-position-container">
                                        <p>{handleJobPostTitle(this.props.admin.listing)}</p>
                                    </div>
                                </div>
                                <div className="yacht-listing-review-section-title-container margin-top-20px">
                                    <p>Yacht Details</p>
                                </div>
                                {this.props.admin.listing.yacht.yachtStatus &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-1x">
                                        <p>Charter Status: <span>{this.props.admin.listing.yacht.yachtStatus}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.age &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Yacht Age: <span>{handleYachtAge(this.props.admin.listing.yacht.age)}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.yachtBuilderCountry &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Build: <span>{handleYachtBuildRegion(this.props.admin.listing.yacht.yachtBuilderCountry)}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.couples !== undefined &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Couples Considered: <span>{this.props.admin.listing.yacht.couples ? "Yes" : "No"}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.itinerary.length > 0 &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Itinerary: <span>{handleMultiple(this.props.admin.listing.yacht.itinerary)}</span></p>
                                    </div>
                                }
                                {visas &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Visas Required: <span>{visas}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht?.currentLocation?.country &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Current Location: <span>{this.props.admin.listing.yacht.currentLocation.country}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht?.homePort?.country &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Home Port: <span>{this.props.admin.listing.yacht.homePort.country}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.smoking !== undefined &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Smoking Policy: <span>{this.props.admin.listing.yacht.smoking ? "Smoking Allowed" : "No Smoking"}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.tattoo &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Tattoo Policy: <span>{this.props.admin.listing.yacht.tattoo}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.dryBoat !== undefined &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Dry Boat: <span>{this.props.admin.listing.yacht.dryBoat ? "Yes" : "No"}</span></p>
                                    </div>
                                }
                                <div className="yacht-listing-review-section-title-container margin-top-20px">
                                    <p>Job Details</p>
                                </div>
                                {contractType &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Contract Type: <span>{contractType}</span></p>
                                    </div>
                                }
                                {startDate &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Start Date: <span>{startDate}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.cabinStatus &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Cabin Status: <span>{this.props.admin.listing.cabinStatus}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.experience &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Required Experience: <span>{this.props.admin.listing.experience}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.qualifications.length > 0 &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Qualifications: <span>{handleMultipleQualifications(this.props.admin.listing.qualifications.map(item => item.name), this.props.admin.listing.moreQualifications)}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.description &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>Job Description: <span>{this.props.admin.listing.description}</span></p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.notes &&
                                    <div className="yacht-listing-review-section-title-container margin-top-20px">
                                        <p style={{color: "#333333"}}>Yacht Notes</p>
                                    </div>
                                }
                                {this.props.admin.listing.yacht.notes &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>{this.props.admin.listing.yacht.notes}</p>
                                    </div>
                                }
                                {this.props.admin.listing.notes &&
                                    <div className="yacht-listing-review-section-title-container margin-top-20px">
                                        <p style={{color: "#333333"}}>Job Notes</p>
                                    </div>
                                }
                                {this.props.admin.listing.notes &&
                                    <div className="yacht-listing-review-section-data-row-container margin-top-10px">
                                        <p>{this.props.admin.listing.notes}</p>
                                    </div>
                                }
                            </div>
                            <div style={{height: 40, width: 40}}></div>
                        </div>
                    </Overlay>
                }
                {this.props.misc.showDeleteAccount &&
                    <Overlay>
                        <div className="delete-account-modal-container">
                            <img className="delete-account-x" src={require("../../assets/svgs/x.svg")} alt="" onClick={() => this.props.setDeleteAccount(false)} />
                            <div className="delete-account-title-container">
                                <p>{this.props.misc.deleteAccountAsAdmin ? "Delete Account" : "Delete Your Account"}</p>
                            </div>
                            <div className="delete-account-description-container margin-top-20px">
                                <p>{this.props.misc.deleteAccountAsAdmin ? "Are you sure you want to delete this account? All data will be wiped." : "Are you sure you want to delete your account? All data will be wiped."}</p>
                            </div>
                            <div className="delete-account-buttons-container">
                                <div className="delete-account-cancel-container" onClick={() => this.props.setDeleteAccount(false, false)}>
                                    <p>Cancel</p>
                                </div>
                                <div className="delete-account-buttons-separator"></div>
                                <div className="delete-account-confirm-container" onClick={this.props.misc.deleteAccountAsAdmin ? () => this.handleDeleteAccount(this.props.admin.cvProfile, true) : () => this.handleDeleteAccount(data, false)}>
                                    <p>Delete Account</p>
                                </div>
                            </div>
                        </div>
                    </Overlay>
                }
                {this.props.misc.showDeleteLocalYacht &&
                    <Overlay>
                        <div className="delete-account-modal-container">
                            <img className="delete-account-x" src={require("../../assets/svgs/x.svg")} alt="" onClick={() => this.props.setDeleteLocalYachtAccount(false, "")} />
                            <div className="delete-account-title-container">
                                <p>{"Delete Local Yacht Account"}</p>
                            </div>
                            <div className="delete-account-description-container margin-top-20px">
                                <p>{"Are you sure you want to delete this account? All data will be wiped."}</p>
                            </div>
                            <div className="delete-account-buttons-container">
                                <div className="delete-account-cancel-container" onClick={() => this.props.setDeleteLocalYachtAccount(false, "")}>
                                    <p>Cancel</p>
                                </div>
                                <div className="delete-account-buttons-separator"></div>
                                <div className="delete-account-confirm-container" onClick={() => this.handleDeleteLocalYachtAccount(this.props.misc.deletedYacht)}>
                                    <p>Delete Account</p>
                                </div>
                            </div>
                        </div>
                    </Overlay>
                }
                {this.props.misc.logOut &&
                    <Overlay>
                        <div className="delete-account-modal-container">
                            <img className="delete-account-x" src={require("../../assets/svgs/x.svg")} alt="" onClick={() => this.props.setLogOut(false)} />
                            <div className="delete-account-title-container">
                                <p>Log Out</p>
                            </div>
                            <div className="delete-account-description-container margin-top-20px">
                                <p>Are you sure you want to log out?</p>
                            </div>
                            <div className="delete-account-buttons-container">
                                <div className="delete-account-cancel-container" onClick={() => this.props.setLogOut(false)}>
                                    <p>Cancel</p>
                                </div>
                                <div className="delete-account-buttons-separator"></div>
                                <div className="delete-account-confirm-container" style={{border: "2px solid #1377FF"}} onClick={() => this.handleLogout(false, this.props.history)}>
                                    <p style={{color: "#1377FF"}}>Log Out</p>
                                </div>
                            </div>
                        </div>
                    </Overlay>
                }
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
        misc: state.misc,
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setJobs, setApplications, removeCandidateFromTheList, toggleEmailVerifiedAlert, toggleResendEmail, setAuth, setUserData, updateUserProfile, adjustProfileImage,  toggleBurgerStatus, toggleBurgerMenu, setProfileBuilderFinishLine, setCVPreview, removeJob, setDeleteAccount, setPasswordChanged, setDetailsChanged, setLogOut, setAlert, setDeleteLocalYachtAccount, setViewListing, setListing }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);