import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

/* API requests */
import { updateCandidate } from "../../api-requests/candidate";

export default ({ propNames, updateUserProfile, resetIdealPosition, profile, ideal, previous, next, history, complete, setFinishLine }) => {

    return(
        <div className="save-button-wrapper">
            <div className="save-button-container" onClick={() => update(next, history, propNames, updateUserProfile, resetIdealPosition, profile, ideal, setFinishLine)}>
                <p className="save-button-title">{complete ? "Save & Complete" : "Save & Continue"}</p>
                <img src={complete ? require("../../assets/svgs/tick-white.svg") : require("../../assets/svgs/continue.svg")} className="save-button-icon" alt="" />
            </div>
            {previous &&
                <Link to={previous}>
                    <div className="save-button-previous-container">
                        <p className="save-button-previous-title">Previous Step</p>
                    </div>
                </Link>
            }               
        </div>
    );

}

const update = (next, history, props, updateUserProfile, resetIdealPosition, profile, ideal, setFinishLine) => {

    let body;
    let complete = false;

    if (props.length < 1) {

        body = [
            { propName: "requestedVerification", value: true },
            { propName: "isRejected", value: false },
        ];

        complete = true;


    } else if (props.includes("lookingFor")) {

        const lookingFor = profile.lookingFor.desiredRoles === undefined || profile.lookingFor.desiredRoles.length < 1 ?
            {...profile.lookingFor, desiredRoles: ideal.position.length > 0 ? [ideal] : []}
        :
            ideal.position.length < 1 ?
                profile.lookingFor
            :
                {...profile.lookingFor, desiredRoles: [...profile.lookingFor.desiredRoles, ideal]};

        body = [
            { propName: "lookingFor", value: lookingFor },
        ];
            
    } else {
        body = props.map(prop => {
            return { propName: prop, value: profile[prop] };
        });
    }

    return updateCandidate(profile._id, body)
        .then(result => {
            updateUserProfile(result.data);
            if (props.includes("lookingFor")) resetIdealPosition();
            if (complete) setFinishLine(true);
            return history.push(next);
        });

}