import React, { Component } from "react";
import "./index.css";
import Page from "../../components/Page";
import Start from "./Start";
import Performance from "./Performance";
import Completed from "./Completed";
import InterpersonalSkills from "./InterpersonalSkills";
import Presentation from "./Presentation";
import Sobriety from "./Sobriety";
import Punctuality from "./Punctuality";
import Employability from "./Employability";
import ReasonForLeaving from "./ReasonForLeaving";
import { connect } from "react-redux";

import { getReference, updateReference } from "../../api-requests/reference";

class ReferenceVerification extends Component {

    constructor(props) {
        super(props);

        this.state = {
            candidate: null,
            isFetching: true,
            _id: "",
            title: "",
            referenceName: "",
            companyName: "",
            dateOfCompletion: (new Date()).toISOString(),
            email: "",
            contactNumber: "",
            position: "",
            step: 0,
            full: false,
            completed: false,
            steps: [1, 2, 3, 4, 5, 6, 7],
            // Results
            performance: "",
            performanceExtra: "",
            interactionWithGuests: "",
            interactionWithCrew: "",
            interpersonalSkillsExtra: "",
            presentation: "",
            presentationExtra: "",
            sobriety: "",
            sobrietyExtra: "",
            punctuality: "",
            punctualityExtra: "",
            employability: "",
            employabilityExtra: "",
            reasonForLeaving: "",
            reasonForLeavingExtra: "",
        };

    }

    componentDidMount() {

        if (this.props.match.params.id && !this.props.user.isLoggingIn) {
            return getReference(this.props.match.params.id)
                .then(result => {
                    return this.setState({
                        candidate: result.candidate,
                        _id: result.reference._id,
                        title: `${result.candidate.firstName}'s Reference`,
                        referenceName: result.reference.referenceName,
                        companyName: result.reference.companyName,
                        email: result.reference.email,
                        contactNumber: result.reference.contactNumber,
                        position: result.reference.position,
                        dateOfCompletion: result.reference.dateOfCompletion ? result.reference.dateOfCompletion : (new Date()).toISOString(),
                        step: result.reference.step,
                        full: result.reference.full,
                        completed: result.reference.completed,
                        performance: result.reference.performance,
                        performanceExtra: result.reference.performanceExtra,
                        interactionWithGuests: result.reference.interactionWithGuests,
                        interactionWithCrew: result.reference.interactionWithCrew,
                        interpersonalSkillsExtra: result.reference.interpersonalSkillsExtra,
                        presentation: result.reference.presentation,
                        presentationExtra: result.reference.presentationExtra,
                        sobriety: result.reference.sobriety,
                        sobrietyExtra: result.reference.sobrietyExtra,
                        punctuality: result.reference.punctuality,
                        punctualityExtra: result.reference.punctualityExtra,
                        employability: result.reference.employability,
                        employabilityExtra: result.reference.employabilityExtra,
                        reasonForLeaving: result.reference.reasonForLeaving,
                        reasonForLeavingExtra: result.reference.reasonForLeavingExtra,
                        isFetching: false,
                    });
                });
        }

    }

    updateState = (propNames, values, autoSave) => {

        return propNames.forEach((propName, index) => {
            return this.setState({ [propName]: values[index] }, () => {
                if (autoSave) {
                    return updateReference(this.state._id, [{ propName, value: values[index] }]);
                }
            });
        });

    }

    render() {

        if (!this.state.isFetching) {
            return(
                <Page {...this.props} linear={true}>
                    <div className="reference-verification-container">
                        {this.state.step === 0 &&
                            <Start title={this.state.title} name={this.state.referenceName} updateState={this.updateState} />
                        }
                        {this.state.step === 1 && !this.state.full && !this.state.completed &&
                            <Performance title={this.state.title} full={false} step={1} updateState={this.updateState} selected={this.state.performance} extra={this.state.performanceExtra} state={this.state} completed={true} />
                        }
                        {this.state.step === 1 && this.state.full && !this.state.completed &&
                            <Performance title={this.state.title} full={true} steps={this.state.steps} step={1} updateState={this.updateState} selected={this.state.performance} extra={this.state.performanceExtra} state={this.state} />
                        }
                        {this.state.step === 2 && !this.state.completed &&
                            <InterpersonalSkills title={this.state.title} steps={this.state.steps} step={2} updateState={this.updateState} interactionWithGuests={this.state.interactionWithGuests} interactionWithCrew={this.state.interactionWithCrew} extra={this.state.interpersonalSkillsExtra} state={this.state} />
                        }
                        {this.state.step === 3 && !this.state.completed &&
                            <Presentation title={this.state.title} steps={this.state.steps} step={3} updateState={this.updateState} presentation={this.state.presentation} extra={this.state.presentationExtra} state={this.state} />
                        }
                        {this.state.step === 4 && !this.state.completed &&
                            <Sobriety title={this.state.title} steps={this.state.steps} step={4} updateState={this.updateState} sobriety={this.state.sobriety} extra={this.state.sobrietyExtra} state={this.state} />
                        }
                        {this.state.step === 5 && !this.state.completed &&
                            <Punctuality title={this.state.title} steps={this.state.steps} step={5} updateState={this.updateState} punctuality={this.state.punctuality} extra={this.state.punctualityExtra} state={this.state} />
                        }
                        {this.state.step === 6 && !this.state.completed &&
                            <Employability title={this.state.title} steps={this.state.steps} step={6} updateState={this.updateState} employability={this.state.employability} extra={this.state.employabilityExtra} state={this.state} />
                        }
                        {this.state.step === 7 && !this.state.completed &&
                            <ReasonForLeaving title={this.state.title} steps={this.state.steps} step={7} updateState={this.updateState} reasonForLeaving={this.state.reasonForLeaving} extra={this.state.reasonForLeavingExtra} state={this.state} />
                        }
                        {this.state.completed &&
                            <Completed title={this.state.title} candidate={this.state.candidate} reference={this.state} />
                        }
                    </div>
                </Page>
            );  
        } else {
            return(
                <Page linear={true}>
                </Page>
            );  
        }

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(ReferenceVerification);