import React from "react";
import "./index.css";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import JSZip from "jszip";

/* API requests */
import { downloadCV, downloadFile } from "../../api-requests/download";

/* Components */
import { VerificationDocument } from "../../pages/ReferenceVerification/ReferenceVerificationPDF";
import { MyDocument } from "../CVPDF";

export default ({ admin, user, show, toggle, originalCV }) => {

    const OnlineCV = <MyDocument user={user} admin={admin} />;

    return(
        <div className="sidebar-downloads-buttons-container margin-top-1x">
            <div className="sidebar-downloads-button" onClick={toggle}>
                <div className="sidebar-downloads-button-icon-container">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18 17V19C20.7614 19 23 16.7614 23 14C23 11.485 21.1431 9.40363 18.7254 9.05224C17.875 6.10562 15.1586 4 12 4C9.65343 4 7.50902 5.16507 6.21989 7.05027C3.26703 7.43346 1 9.95877 1 13C1 15.973 3.16229 18.441 6 18.917V16.874C4.27477 16.4299 3 14.8638 3 13C3 10.8504 4.69934 9.08715 6.83966 9.00314L7.39066 8.98151L7.66642 8.50398C8.5543 6.96643 10.1924 6 12 6C14.4511 6 16.5303 7.77626 16.9309 10.1662L17.0738 11.0182L17.9375 11.0006C17.9531 11.0004 17.9608 11.0002 17.9686 11.0002C17.9765 11.0001 17.9843 11.0001 18 11C19.6569 11 21 12.3431 21 14C21 15.6569 19.6569 17 18 17ZM11 12V18.2533L8.70711 16.0093L7.29289 17.3933L12 22L16.7071 17.3933L15.2929 16.0093L13 18.2533V12H11Z" fill="#333333" />
                    </svg>
                </div>
                <div className="sidebar-downloads-button-title-container">
                    <p className="sidebar-downloads-button-title">{admin ? "Candidate Downloads" : "Downloads"}</p>
                </div>
                <div className="sidebar-downloads-button-icon-container">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="#333333" transform={show ? "rotate(180 12 12)" : "rotate(0 12 12)"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {show &&
                <>
                    <div className={originalCV ? "sidebar-downloads-button-item sidebar-downloads-button-item-hoverable" : "sidebar-downloads-button-item"} style={{borderTop: "1px solid #E5E5E5"}} onClick={() => downloadOriginalCV(originalCV, user)}>
                        <div className="sidebar-downloads-button-icon-container">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4142 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V6.58579L15.4142 1ZM5 3H13V7C13 8.10457 13.8954 9 15 9H19V21H5V3ZM15 7V3.41421L18.5858 7H15ZM11 10V14.5858L9.70711 13.2929L8.29289 14.7071L12 18.4142L15.7071 14.7071L14.2929 13.2929L13 14.5858V10H11Z" fill={originalCV ? "#333333" : "#969696"} />
                            </svg>
                        </div>
                        <div className="sidebar-downloads-button-title-container">
                            <p className="sidebar-downloads-button-title" style={{color: originalCV ? "#333333" : "#969696"}}>{originalCV ? `${admin ? "" : "Your "}Original CV` : "No CV uploaded"}</p>
                        </div>
                    </div>
                    <div className="sidebar-downloads-button-item sidebar-downloads-button-item-hoverable" onClick={() => generate(OnlineCV, user)}>
                        <div className="sidebar-downloads-button-icon-container">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4142 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V6.58579L15.4142 1ZM5 3H13V7C13 8.10457 13.8954 9 15 9H19V21H5V3ZM15 7V3.41421L18.5858 7H15ZM11 10V14.5858L9.70711 13.2929L8.29289 14.7071L12 18.4142L15.7071 14.7071L14.2929 13.2929L13 14.5858V10H11Z" fill="#333333" />
                            </svg>
                        </div>
                        <div className="sidebar-downloads-button-title-container">                    
                            <p className="sidebar-downloads-button-title" style={{color: "#333333"}}>{admin ? "" : "Your "}J4Crew CV (PDF)</p>
                        </div>
                    </div>
                    <div className="sidebar-downloads-button-item sidebar-downloads-button-item-hoverable" onClick={() => downloadDocuments(OnlineCV, user, admin)}>
                        <div className="sidebar-downloads-button-icon-container">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4142 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V6.58579L15.4142 1ZM5 3H13V7C13 8.10457 13.8954 9 15 9H19V21H5V3ZM15 7V3.41421L18.5858 7H15ZM11 10V14.5858L9.70711 13.2929L8.29289 14.7071L12 18.4142L15.7071 14.7071L14.2929 13.2929L13 14.5858V10H11Z" fill="#333333" />
                            </svg>
                        </div>
                        <div className="sidebar-downloads-button-title-container">
                            <p className="sidebar-downloads-button-title">{admin ? "" : "Your "}Documents (ZIP)</p>
                        </div>
                    </div>
                </>  
            }
        </div>        
    );

}

const downloadDocuments = (onlineCV, user, admin) => {

    let firstName = user.firstName,
        lastName = user.lastName;

    const hasOriginalCV = user?.profile?.cv,
        hasTravelDocuments = user?.profile?.travelDocument?.length > 0,
        hasStcwBasic = user?.profile?.stcwBasic?.length > 0,
        hasENG1 = user?.profile?.eng1?.length > 0,
        hasLicense = user?.profile?.license?.length > 0,
        hasStcwAdvanced = user?.profile?.stcwAdvanced?.length > 0,
        hasWrittenReferences = user?.profile?.writtenReferences?.length > 0,
        hasAllDocuments = user?.profile?.allDocuments?.length > 0;
    
    let zip = new JSZip();

    let CVs = zip.folder("CV's");

    let STCWBasic;
    let STCWAdvanced;
    let TravelDocuments;
    let ENG1;
    let Licenses;
    let WrittenReferences;
    let OtherDocuments;

    if (hasStcwBasic) STCWBasic = zip.folder("STCW Basic");
    if (hasStcwAdvanced) STCWAdvanced = zip.folder("STCW Advanced");
    if (hasTravelDocuments) TravelDocuments = zip.folder("Travel Documents");
    if (hasENG1) ENG1 = zip.folder("ENG 1");
    if (hasLicense) Licenses = zip.folder("Licenses");
    if (hasWrittenReferences) WrittenReferences = zip.folder("Written References");
    if (hasAllDocuments) OtherDocuments = zip.folder("Other Documents");

    if (hasOriginalCV) {
        let cv = downloadFile(user.profile.cv);
        let fileName = user.profile.cv.uploadDate ? `${firstName} ${lastName}${user.profile.cv.extension}` : `${firstName} ${lastName}${user.profile.cv.extension}`; 
        CVs.file(fileName, cv);
    }

    if (hasTravelDocuments) {
        user.profile.travelDocument.map((file) => {
            let result = downloadFile(file);
            return TravelDocuments.file(`${file.name}${file.extension}`, result);
        });
    }

    if (hasStcwBasic) {
        user.profile.stcwBasic.map((file) => {
            let result = downloadFile(file);
            return STCWBasic.file(`${file.name}${file.extension}`, result);
        });
    }

    if (hasENG1) {
        user.profile.eng1.map((file) => {
            let result = downloadFile(file);
            return ENG1.file(`${file.name}${file.extension}`, result);
        });
    }

    if (hasLicense) {
        user.profile.license.map((file) => {
            let result = downloadFile(file);
            return Licenses.file(`${file.name}${file.extension}`, result);
        });
    }

    if (hasStcwAdvanced) {
        user.profile.stcwAdvanced.map((file) => {
            let result = downloadFile(file);
            return STCWAdvanced.file(`${file.name}${file.extension}`, result);
        });
    }

    if (hasWrittenReferences) {

        user.profile.writtenReferences.map((file) => {
            let result = downloadFile(file);
            return WrittenReferences.file(`${file.name}${file.extension}`, result);
        });

    }

    if (hasAllDocuments) {
        user.profile.allDocuments.map((file) => {
            let result = downloadFile(file);            
            return OtherDocuments.file(`${file.name}${file.extension}`, result);
            
        });
    }

    let blobPdf = pdf(onlineCV);
        blobPdf.updateContainer(onlineCV);

    return blobPdf.toBlob()
        .then(generatedCV => {
            CVs.file(`${firstName} ${lastName} Generated CV.pdf`, generatedCV);

            let completedReferences = admin ? user.profile.references.filter(reference => reference.completed) : user.profile.references.filter(reference => reference.completed && !reference.archived);

            if (completedReferences.length < 1) {
                return zip.generateAsync({ type: "blob" })
                    .then((content) => {
                        return saveAs(content, `${firstName} ${lastName}'s Documents.zip`);
                    });
            }

            if (!WrittenReferences) WrittenReferences = zip.folder("Written References");

            let promises = completedReferences.map(reference => {

                const verificationDocument = <VerificationDocument candidate={user.profile} reference={reference} />;
        
                let verificationPdf = pdf(verificationDocument);
                    verificationPdf.updateContainer(verificationDocument);
        
                return verificationPdf.toBlob()
                    .then(generatedPdf => {

                        let filename = reference.archived ? `${reference.referenceName} Reference (Archived).pdf` : `${reference.referenceName} Reference.pdf` ;

                        return WrittenReferences.file(filename, generatedPdf);
                    });
        
            });

            return Promise.all(promises)
                .then(_ => {
                    return zip.generateAsync({ type: "blob" })
                        .then((content) => {
                            return saveAs(content, `${firstName} ${lastName}'s Documents.zip`);
                        });
                });

        });

}

const generate = (document, user) => {

    let firstName = user.firstName,
        lastName = user.lastName;

    let blobPdf = pdf(document);
    blobPdf.updateContainer(document);
    
    return blobPdf.toBlob()
        .then(result => {
            return saveAs(result, `${firstName} ${lastName} Generated CV.pdf`);
        });
}

const downloadOriginalCV = (file, user) => {

    if (file) {
        return downloadCV(file, user);
    }
    return;

}