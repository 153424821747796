import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* API requests */
import { updateCandidate, uploadCV } from "../../../../api-requests/candidate";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";
import { toggleCVPopup } from "../../../../redux/actions/initial-setup";

/* Helpers */
import { bytesToSize } from "../../../../helpers/misc";

class SelectProfileType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeButton: "",
            error: false,
            errorMessage: "",
        };

    }

    handleBuildFromScratch = (user) => {

        const body = [
            { propName: "profileType", value: "build"},
        ];

        return this.setState({ loading: true, activeButton: "build", error: false, errorMessage: "" }, () => {
            return updateCandidate(user.profile._id, body)
                .then(data => {
                    if (data.status === 201) {
                        this.props.updateUserProfile(body);
                        return this.props.history.push("/");
                    }
                    return this.setState({ loading: false, activeButton: "" });
                });
        });

    }

    handleCVUpload = (file, user) => {

        const dotIndex = file.name.indexOf(".");
        
        const name = file.name.slice(0, dotIndex),
            extension = file.name.slice(dotIndex),
            size = bytesToSize(file.size);

        if (file) {
            return this.setState({ loading: true, activeButton: "upload", error: false, errorMessage: "" }, () => {
                return uploadCV(user.profile._id, file, name, extension, size)
                    .then(data => {
                        if (data.status === 201) {
                            let body = [
                                { propName: "profileType", value: "upload"},
                                { propName: "initialSetupCompleted", value: false },
                                { propName: "initialSetupStep", value: 1 },
                            ];
                            this.props.updateUserProfile([...body, ...data.body]);
                            return this.props.toggleCVPopup();
                        }
                        return this.setState({ loading: false, activeButton: "", error: true, errorMessage: data.status === 500 ? "Uploading CV failed. You can upload another CV or build your profile from scratch." : data.error_message });
                    });
            });
        }   

    }

    render() {

        return(
            <div className="page-container">
                <div className="select-profile-type-main-container">
                    <div className="select-profile-type-main-wrapper">
                        <div className="select-profile-type-container">
                            <div className="select-profile-type-header-container">
                                <span className="select-profile-type-header-title">Create a J4Crew CV</span>
                            </div>
                            <div className="select-profile-type-subheader-container margin-top-2x">
                                <p className="select-profile-type-subheader-text">Hi {this.props.user.data && this.props.user.data.firstName}! Please choose an option below, uploading your current CV will save you time.</p>
                            </div>
                            <div className="select-profile-type-error-container">
                                {this.state.error && this.state.errorMessage &&
                                    <p className="select-profile-type-error-text">{this.state.errorMessage}</p>
                                }
                            </div>
                            <div className="select-profile-type-buttons-container">
                                {
                                    !this.state.loading ?
                                        <label className="select-profile-type-upload-cv-button-container">
                                            <input className="select-profile-type-upload-cv-input" hidden={true} type={"file"} name={"file"} onChange={(e) => this.handleCVUpload(e.target.files[0], this.props.user.data)} />
                                            <div className="select-profile-type-upload-cv-button-icon-container">
                                                <img className="select-profile-type-upload-cv-button-icon" src={require("../../../../assets/svgs/upload.svg")} alt="" />
                                            </div>
                                            <div className="select-profile-type-upload-cv-button-title-container">
                                                <p className="select-profile-type-upload-cv-button-title">Upload CV</p>
                                            </div>
                                            <div className="select-profile-type-upload-cv-button-icon-container">
                                            </div>
                                        </label>
                                    :
                                        <div className="select-profile-type-upload-cv-button-container" style={this.state.activeButton === "upload" ? {backgroundColor: "#BBD8FF"} : {backgroundColor: "#1377FF"}}>
                                            <div className="select-profile-type-upload-cv-button-icon-container">
                                                <img className="select-profile-type-upload-cv-button-icon" src={this.state.activeButton === "upload" ? require("../../../../assets/animations/white-spinner.svg") : require("../../../../assets/svgs/upload.svg")} alt="" />
                                            </div>
                                            <div className="select-profile-type-upload-cv-button-title-container">
                                                <p className="select-profile-type-upload-cv-button-title">{this.state.activeButton === "upload" ? "Processing CV" : "Upload CV"}</p>
                                            </div>
                                            <div className="select-profile-type-upload-cv-button-icon-container">
                                            </div>
                                        </div>
                                }
                                <div className="select-profile-type-buttons-separator"></div>
                                {
                                    !this.state.loading ?
                                        <div className="select-profile-type-from-scratch-button-container" onClick={() => this.handleBuildFromScratch(this.props.user.data)}>
                                            <p className="select-profile-type-from-scratch-button-title">Build From Scratch</p>
                                        </div>
                                    :
                                        <div className="select-profile-type-from-scratch-button-container" style={this.state.activeButton === "build" ? {border: "2px solid #9C9C9C"} : {}}>
                                            <p className="select-profile-type-from-scratch-button-title" style={this.state.activeButton === "build" ? {color: "#9C9C9C"} : {}}>Build From Scratch</p>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="select-profile-type-image-container">
                            <div className="select-profile-type-image-wrapper">
                                <img src={require("../../../../assets/svgs/yacht.svg")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile, toggleCVPopup }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProfileType);