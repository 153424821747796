import {
    SET_REGISTRATION_LOADING_STATE,
    SET_REGISTRATION_ACCOUNT_TYPE,
    SET_REGISTRATION_FIRST_NAME,
    SET_REGISTRATION_FIRST_NAME_ERROR,
    SET_REGISTRATION_LAST_NAME,
    SET_REGISTRATION_LAST_NAME_ERROR,
    SET_REGISTRATION_EMAIL,
    SET_REGISTRATION_EMAIL_ERROR,
    SET_REGISTRATION_PASSWORD,
    SET_REGISTRATION_PASSWORD_ERROR,
    CLEAR_REGISTRATION_FIELDS,
} from "../types/register";

const initialState = {
    loading: false,
    accountType: "Candidate",
    firstName: "",    
    lastName: "",
    email: "",
    password: "",

    firstNameError: false,
    firstNameErrorMessage: "",
    lastNameError: false,
    lastNameErrorMessage: "",
    emailError: false,
    emailErrorMessage: "",
    passwordError: false,
    passwordErrorMessage: "",

};

export default (state = initialState, action) => {

    switch (action.type) {

        case SET_REGISTRATION_LOADING_STATE:
        
            return {
                ...state,
                loading: action.data,
            };

        case SET_REGISTRATION_ACCOUNT_TYPE:
        
            return {
                ...state,
                accountType: action.data,
            };

        case SET_REGISTRATION_FIRST_NAME:

            return {
                ...state,
                firstName: action.data,
            };

        case SET_REGISTRATION_FIRST_NAME_ERROR:

            return {
                ...state,
                firstNameError: action.status,
                firstNameErrorMessage: action.message,
            };

        case SET_REGISTRATION_LAST_NAME:

            return {
                ...state,
                lastName: action.data,
            };

        case SET_REGISTRATION_LAST_NAME_ERROR:

            return {
                ...state,
                lastNameError: action.status,
                lastNameErrorMessage: action.message,
            };

        case SET_REGISTRATION_EMAIL:

            return {
                ...state,
                email: action.data,
            };

        case SET_REGISTRATION_EMAIL_ERROR:

            return {
                ...state,
                emailError: action.status,
                emailErrorMessage: action.message,
            };

        case SET_REGISTRATION_PASSWORD:

            return {
                ...state,
                password: action.data,
            };

        case SET_REGISTRATION_PASSWORD_ERROR:

            return {
                ...state,
                passwordError: action.status,
                passwordErrorMessage: action.message,
            };
        
        case CLEAR_REGISTRATION_FIELDS:

            return {
                ...state,
                accountType: "Candidate",
                firstName: "",    
                lastName: "",
                email: "",
                password: "",
            };
        
        default:
            return state;

    }

};