import React from "react";
import "./index.css";

export default ({ title, options, updateState, selected, propName }) => {

    return(
        <div className="reference-verification-toggle-container">
            <div className="reference-verification-toggle-title-container">
                <p>{title}</p>
            </div>
            <div className="reference-verification-toggle-options-container">
                {renderOptions(options, updateState, selected, propName)}
            </div>
        </div>
    );

}

const renderOptions = (options, updateState, selected, propName) => {

    return options.map((option, index) => {
        return(
            <div className="reference-verification-toggle-option-container" key={index}>
                <div className="reference-verification-toggle-option-button" style={{backgroundColor: selected === option ? "#1377FF" : "white"}} onClick={() => updateState([propName], [option], true)}>
                    <p style={{color: selected === option ? "white" : "#1377FF"}}>{option}</p>
                </div>                
                {index < options.length-1 &&
                    <div className="reference-verification-toggle-option-separator"></div>
                }
            </div>
        );
    });

}