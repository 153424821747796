import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import saveAs from "file-saver";
import XLSX from "xlsx";
import "./index.css";

/* Actions */
import { setUsersCount, setCompletedUsers, setIncompleteUsers, removeCandidateFromTheList } from "../../../../redux/actions/admin";
import { setAlert } from "../../../../redux/actions/misc";

/* Components */
import UsersListHeader from "../../../../components/UsersListHeader";
import UsersList from "../../../../components/UsersList";

/* API requests */
import { getSignUpsCount, getSignUps, searchSignUps } from "../../../../api-requests/admin";

class SignUps extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: true,
            keyword: "",
            searchResults: [],
            isFetchingSearchResults: false,
        };

        this.searchTimeOut = null;

    }

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        getSignUpsCount()
            .then(data => {
                return this.props.setUsersCount(data);
            });

        if (this.props.match.path.includes("incomplete")) {
            return this.setState({ active: false }, () => {
                return getSignUps(false)
                    .then(data => {
                        return this.props.setIncompleteUsers(data);
                    });
            });
        } else {
            return getSignUps(true)
                .then(data => {
                    return this.props.setCompletedUsers(data);
                });
        } 

    }

    exportXlsx = () => {

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        return fetch("/api/users/spreadsheet")
            .then(res => res.json())
            .then(json => {
                const ws = XLSX.utils.json_to_sheet(json);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });

                return saveAs(data, `Users${fileExtension}`);
            })
            .catch(err => console.log(err));

    }

    handleTabSwitch = (tab) => {

        return this.setState({ active: tab, keyword: "", searchResults: [] }, () => { 

            if (tab) {
                getSignUps(tab)
                    .then(data => {
                        return this.props.setCompletedUsers(data);
                    });
            } else {
                getSignUps(tab)
                    .then(data => {
                        return this.props.setIncompleteUsers(data);
                    });
            }

            return getSignUpsCount()
                .then(data => {
                    return this.props.setUsersCount(data);
                });

        });

    }

    handleSearch = (keyword, tab) => {

        return this.setState({ keyword }, () => {
            if (keyword.length >= 5) {
                return this.setState({ isFetchingSearchResults: true }, () => {

                    if (this.searchTimeOut) {  
                        clearTimeout(this.searchTimeOut);
                    }

                    this.searchTimeOut = setTimeout(() => {
                        return searchSignUps(tab, keyword)
                            .then(result => {
                                return this.setState({ searchResults: result.users, isFetchingSearchResults: false });
                            });
                    }, 1000);

                });
            }
            return this.setState({ searchResults: [], isFetchingSearchResults: false });
        });

    }

    handleUsersList = (completedUsers, inCompleteUsers, searchResults, state, keyword) => {

        if (searchResults.length > 0 && keyword.length >= 5) return searchResults;
        if (state) return completedUsers;
        return inCompleteUsers;

    }

    render() {

        return(
            <div className="sign-ups-container">
                <div className="sign-ups-header-container">
                    <div className="sign-ups-header-items-container">
                        <Link to={"/sign-ups/completed"}>
                            <div className="sign-ups-header-item-container" onClick={() => this.handleTabSwitch(true)}>
                                {this.props.admin.completedUsersCount > 0 &&
                                    <div className="sign-ups-header-users-counter">
                                        <span>{this.props.admin.completedUsersCount}</span>
                                    </div> 
                                }
                                <p style={!this.props.match.path.includes("incomplete") ?  {color: "#1377FF"} : {}}>Completed</p>
                                {!this.props.match.path.includes("incomplete") &&
                                    <div className="sign-ups-header-item-active"></div>
                                }
                            </div>
                        </Link>
                        <Link to={"/sign-ups/incomplete"}>
                            <div className="sign-ups-header-item-container" onClick={() => this.handleTabSwitch(false)}>
                                <p style={this.props.match.path.includes("incomplete") ?  {color: "#1377FF"} : {}}>Incomplete</p>
                                {this.props.match.path.includes("incomplete") &&
                                    <div className="sign-ups-header-item-active"></div>
                                }
                            </div>
                        </Link>
                    </div>
                    <div className="sign-ups-header-search-container">
                        {this.state.isFetchingSearchResults && 
                            <div className="sign-ups-header-search-loading-container">
                                <img src={require("../../../../assets/animations/blue-spinner.svg")} alt="" />
                            </div>
                        }
                        <div className="sign-ups-header-search-input-container">
                            <input type={"text"} placeholder={"Start typing..."} value={this.state.keyword} onChange={(e) => this.handleSearch(e.target.value, this.state.active)} />
                        </div>
                        <div className="sign-ups-header-search-icon-container">
                            <img src={require("../../../../assets/svgs/search.svg")} alt="" />
                        </div>
                    </div>
                </div>
                <div className="sign-ups-header-export-master">
                    <div className="sign-ups-header-export-container" onClick={() => this.exportXlsx()}>
                        <p className="sign-ups-header-export-title">Export spreadsheet</p>
                    </div>
                </div>
                <UsersListHeader
                    status={true}
                    active={this.state.active}
                    col1={"Basic Details"}
                    col2={"Signed Up"}
                    col3={this.state.active && "Requested"}
                    col4={"Profile"}
                    col5={"Status"} />
                {this.state.keyword.length > 4 && !this.state.isFetchingSearchResults && this.state.searchResults.length < 1 &&
                    <p className="no-results-found-description">No sign ups were found matching your search request.</p>
                }
                {this.state.keyword.length > 4 && !this.state.isFetchingSearchResults && this.state.searchResults.length > 0 &&
                    <UsersList
                        status={true}
                        setAlert={this.props.setAlert}
                        removeCandidateFromTheList={this.props.removeCandidateFromTheList}
                        active={this.state.active}
                        isFetching={this.state.active ? this.props.admin.isFetchingCompletedUsers : this.props.admin.isFetchingIncompleteUsers}
                        users={this.handleUsersList(this.props.admin.completedUsers, this.props.admin.incompleteUsers, this.state.searchResults, this.state.active, this.state.keyword)}
                        isFetchingSearchResults={this.state.isFetchingSearchResults} />
                }
                {this.state.keyword.length < 5 &&
                    <>
                        <UsersList
                            status={true}
                            setAlert={this.props.setAlert}
                            removeCandidateFromTheList={this.props.removeCandidateFromTheList}
                            active={this.state.active}
                            isFetching={this.state.active ? this.props.admin.isFetchingCompletedUsers : this.props.admin.isFetchingIncompleteUsers}
                            users={this.handleUsersList(this.props.admin.completedUsers, this.props.admin.incompleteUsers, this.state.searchResults, this.state.active, this.state.keyword)}
                            isFetchingSearchResults={this.state.isFetchingSearchResults}
                        />
                        {/* <div className="load-more-sign-ups-container">
                            <p>Load More Sign Ups</p>
                        </div> */}
                    </>
                }
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setUsersCount, setCompletedUsers, setIncompleteUsers, removeCandidateFromTheList, setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUps);