import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
    family: "SourceSansPro", fonts: [
        { src: require("../../assets/fonts/SourceSansPro-Regular.ttf") },
        { src: require("../../assets/fonts/SourceSansPro-Bold.ttf"), fontWeight: "bold" },
    ],
});

export const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 40,
    },
    metaContainer: {
        display: "flex",
        flexDirection: "row",
        marginBottom: -5,
    },
    metaContentContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    fullName: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontSize: 20,
        letterSpacing: 1,
        color: "#333333",
    },
    desiredPosition: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 10,
        marginBottom: 8,
        color: "#333333",
    },
    profileImageContainer: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 30,
    },
    profileImage: {
        width: 120,
        height: 120,
    },
    metaBasicInformationContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    textRowContainer: {
        position: "relative",
        flex: 0,
        display: "flex",
        flexDirection: "row",
        minHeight: 18,
        marginBottom: -4,
    },
    textRowContainerImage: {
        width: 11,
        height: 11,
        marginLeft: 8,
        marginTop: 1,
        objectFit: "contain",
    },
    textRowPropContainer: {
        display: "flex",
        flexDirection: "row",
        width: 112,
    },
    textRowPropName: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#7F8888",
    },
    textRowValueContainer: {
        display: "flex",
        flexDirection: "row",
    },
    textRowValue: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#333333",
    },
    textRowValueLink: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        textDecoration: "underline",
        color: "#0563C1",
    },
    textRowMultipleTextContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 5,
    },
    textRowMultipleText: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#333333",
    },
    textRowMultipleDate: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#333333",
        fontSize: 8,
        marginTop: 1,
        marginLeft: 6,
    },
    sectionSeparatorContainer: {
        paddingTop: 25,
        paddingBottom: 20,
        width: "100%",
        height: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    sectionSeparatorTitle: {
        letterSpacing: 1,
        backgroundColor: "#FFFFFF",
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 11,
        color: "#333333",
        textDecoration: "underline",
    },
    sectionMasterContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },  
    sectionContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginTop: -10,
    },
    sectionListContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    paragraph: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#333333",
    },
    listItemText: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#333333",
    },
    listItemContainer: {
        paddingBottom: 11,
        display: "flex",
        flexDirection: "row",
    },
    sectionDateContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: 84,
        paddingTop: 2,
        paddingRight: 15,
    },
    sectionDateText: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#333333",
    },
    sectionItemContentContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    sectionItemContentTitleContainer: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: 5,
    },
    sectionItemContentTitle: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 11,
        color: "#333333",
    },
    sectionItemContentDescriptionContainer: {
        display: "flex",
        flexDirection: "row",
    },
    sectionItemContentDescription: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#333333",
    },
    referenceItemContainer: {
        paddingBottom: 11,
    },
    referenceItemTitleContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: 7,
    },  
    referenceItemTitle: {
        fontFamily: "SourceSansPro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 11,
        color: "#333333",
    },
});