export const updateYacht = (id, data) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id,
            data,
        }),
    };

    return fetch("/api/yachts/update/", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getAllYachts = () => {

    return fetch("/api/yachts")
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            console.log(err);
            return err;
        });

}

export const searchYachts = (keyword) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            keyword,
        }),
    };

    return fetch("/api/yachts/search", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const addLocalYacht = (yacht) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ...yacht,
        }),
    };

    return fetch("/api/yachts/add-local", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const editLocalYacht = (data) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
    };

    return fetch("/api/yachts/edit-local", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}

export const getYacht = (_id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
        }),
    };

    return fetch("/api/yachts/find", request)
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });


}

export const deleteYacht = (_id) => {

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            _id,
        }),
    };

    return fetch("/api/yachts/delete", request)
        .then(res => res.json())
        .then(result => {
            console.log(result);
            return result;
        })
        .catch(err => {
            return err;
        });
        
}