export const SET_USERS_COUNT = "SET_USERS_COUNT";
export const SET_COMPLETED_USERS = "SET_COMPLETED_USERS";
export const SET_INCOMPLETE_USERS = "SET_INCOMPLETE_USERS";
export const REMOVE_CANDIDATE_FROM_THE_LIST = "REMOVE_CANDIDATE_FROM_THE_LIST";
export const SET_CV_PROFILE = "SET_CV_PROFILE";
export const UPDATE_CV_PROFILE = "UPDATE_CV_PROFILE";
export const SET_CV_APPLICATION = "SET_CV_APPLICATION";
export const SET_CREW = "SET_CREW";
export const ADD_CREW = "ADD_CREW";
export const SET_YACHTS = "SET_YACHTS";
export const UPDATE_LOCAL_YACHT = "UPDATE_LOCAL_YACHT";
export const SET_LISTING = "SET_LISTING";