import React from "react";
import "./index.css";

/* Components */
import UserRow from "../UserRow";

export default ({ users, type, active, isFetching, isFetchingSearchResults, removeCandidateFromTheList, setAlert, status }) => {

    if (isFetching) {
        return(
            <div className="sign-ups-list-container">
                {renderPlaceholderList(active, isFetching, status)}
            </div>
        );
    } else if (users.length < 1 && !isFetchingSearchResults) {
        return(
            <div className="sign-ups-list-container">
                <p className="no-users-to-show">There are currently no users to show. Check again later.</p>
            </div>
        );
    } else {
        return(
            <div className="sign-ups-list-container">
                {renderList(users, active, removeCandidateFromTheList, setAlert, status, type)}
            </div>
        );
    }

}

const renderPlaceholderList = (active, isFetching, status) => {

    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
        return <UserRow active={active} isFetching={isFetching} index={index} key={index} status={status} />;
    });

}

const renderList = (users, active, removeCandidateFromTheList, setAlert, status, type) => {

    return users.map((user, index) => {
        return <UserRow user={user} index={index} key={index} active={active} removeCandidateFromTheList={removeCandidateFromTheList} setAlert={setAlert} status={status} type={type} />;
    });

}