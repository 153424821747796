export const verifyEmail = (code) => {

    return fetch("/api/auth/verify-email/"+code)
        .then(res => res.json())
        .then(json => { 
            return json;
        })
        .catch(err => {
            return err;
        });
}

export const resendVerificationEmail = (id) => {

    const body = {
        id,
    };

    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch("/api/auth/resend-verification-email/", request)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            return err;
        });

}