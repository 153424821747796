import React, { Component } from "react";
import "./index.css";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile, isSafari, isChrome } from "react-device-detect";
import { Link }  from "react-router-dom";

/* Actions */
import { adjustProfileImage } from "../../redux/actions/misc";
import { setJobs } from "../../redux/actions/job";
import { setApplications } from "../../redux/actions/application";

/* Components */
import Page from "../../components/Page";
import SidebarCandidate from "../../components/SidebarCandidate";
import CandidateHeader from "../../components/CandidateHeader";
import Details from "./components/Details";
import IdealJob from "./components/IdealJob";
import Qualifications from "./components/Qualifications";
import Experience from "./components/Experience";
import References from "./components/References";
import Documents from "./components/Documents";
import CV from "./components/CV";
import Jobs from "./components/Jobs";
import SettingsCandidate from "../SettingsCandidate";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";

/* API requests */
import { getJobs } from "../../api-requests/job";
import { getApplications } from "../../api-requests/application";

/* Helpers */
import { fitNewJobs, fitJobs } from "../../helpers/job";
import AboutUs from "./components/AboutUs";
import MLC2006 from "./components/MLC2006";
import ComplaintsProcedure from "./components/ComplaintsProcedure";
import ReferenceVerification from "../ReferenceVerification";

class DashboardCandidate extends Component {

    componentDidMount() {

        return getApplications()
            .then(applications => {
                return getJobs()
                    .then(jobs => {
                        this.props.setApplications(applications);
                        return this.props.setJobs(jobs);
                    });
            });

    }

    sortJobsByTiers = (jobs, user) => {
        let ids = [];

        let js = jobs;

        let tier1 = js.filter((job) => {
            let jobsQuals = [job.qls, ...job.mqls];
            let userQuals = user.profile.qualifications.map(item => item._id);
            let qualsMatch = false;

            for (let qs of jobsQuals) {
                let m = [];
                for (let q of qs) {
                    if (userQuals.includes(q._id)) m.push(q);
                }
                if (m.length === qs.length) {
                    qualsMatch = true;
                    break;
                }
            }

            return qualsMatch && user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?._id === job?.position?._id &&
                user?.profile?.lookingFor?.desiredRoles[0]?.jobType?.includes(job?.contractType) &&
                    user?.profile?.lookingFor?.desiredRoles[0]?.yachtType?.includes(job?.yacht?.yachtType);
        });

        tier1.forEach((job) => {
            ids.push(job._id);
        });

        let tier2 = js.filter((job) => {
            return !ids.includes(job._id) && user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.positionType === job?.position?.positionType && 
                user?.profile?.lookingFor?.desiredRoles[0]?.jobType?.includes(job?.contractType) &&
                    user?.profile?.lookingFor?.desiredRoles[0]?.yachtType?.includes(job?.yacht?.yachtType);
        });

        tier2.forEach((job) => {
            ids.push(job._id);
        });

        let tier3 = js.filter((job) => {
            return !ids.includes(job._id) && user?.profile?.lookingFor?.desiredRoles[0]?.position[0]?.positionType === job?.position?.positionType;
        });

        tier3.forEach((job) => {
            ids.push(job._id);
        });

        let tier4 = js.filter((job) => {
            return !ids.includes(job._id);
        });

        return [...tier1, ...tier2, ...tier3, ...tier4];
    }

    render() {

        const { jobs, isFetching } = this.props.job;
        const { applications } = this.props.application;

        const addExtraSpace = isMobile && (isSafari || isChrome),
            newJobs = fitNewJobs(jobs, this.props.user.data.profile),
            allJobs = fitJobs(jobs, this.props.user.data.profile);

        if (!this.props.match.path.includes("/reference-verification/")) {
            return(
                <Page {...this.props} linear={true}>                
                    <div className="dashboard-container">
                        <div className="dashboard-sidebar-container">
                            <SidebarCandidate {...this.props} />
                        </div>
                        <div className="dashboard-main-container" id="Dashboard">
                            {!["/profile/cv", "/settings", "/terms-and-conditions", "/privacy-policy", "/contact-us", "/about-us", "/mlc-2006", "/complaints-procedure"].includes(this.props.match.path) &&
                                <CandidateHeader newJobs={newJobs} allJobs={allJobs} applications={applications} {...this.props} editPhoto={() => this.props.adjustProfileImage(true)} />
                            }
                            {this.props.match.path === "/" &&
                                <Jobs isFetching={isFetching} jobs={this.sortJobsByTiers(allJobs, this.props.user.data)} filter={true} type={"all"} title={"Jobs"} description={isFetching ? "" : allJobs.length < 1 ? <p className="jobs-description">Sorry, we have no more jobs for you at the moment, please check again later to find jobs which may be of interest.</p> : <p className="jobs-description">Here is a list of our positions, your <Link to={"/profile/ideal-job"}>ideal jobs</Link> will show at the top.</p>} />
                            }
                            {this.props.match.path === "/jobs/applications" &&
                                <Jobs isFetching={isFetching} jobs={applications} type={"applications"} title={"Active Applications"} description={isFetching ? "" : applications.length < 1 ? <p className="jobs-description">It seems that you don’t have any active applications, please check our <Link to={"/jobs"}>Jobs</Link> page and apply for jobs.</p> : <p className="jobs-description">Here are all of your active applications, you can check on your progress for each position.</p>} />
                            }
                            {this.props.match.path === "/profile" &&
                                <Details history={this.props.history} />
                            }
                            {this.props.match.path === "/profile/ideal-job" &&
                                <IdealJob history={this.props.history} />
                            }
                            {this.props.match.path === "/profile/qualifications" &&
                                <Qualifications history={this.props.history} />
                            }
                            {this.props.match.path === "/profile/experience" &&
                                <Experience history={this.props.history} />
                            }
                            {this.props.match.path === "/profile/references" &&
                                <References history={this.props.history} />
                            }
                            {this.props.match.path === "/profile/documents" &&
                                <Documents history={this.props.history} />
                            }
                            {this.props.match.path === "/profile/cv" &&
                                <CV history={this.props.history} user={this.props.user.data} />
                            }
                            {this.props.match.path === "/settings" &&
                                <SettingsCandidate history={this.props.history} />
                            }
                            {this.props.match.path === "/terms-and-conditions" &&
                                <TermsAndConditions history={this.props.history} />
                            }
                            {this.props.match.path === "/privacy-policy" &&
                                <PrivacyPolicy history={this.props.history} />
                            }
                            {this.props.match.path === "/contact-us" &&
                                <ContactUs history={this.props.history} />
                            }
                            {this.props.match.path === "/about-us" &&
                                <AboutUs history={this.props.history} />
                            }
                            {this.props.match.path === "/mlc-2006" &&
                                <MLC2006 history={this.props.history} />
                            }
                            {this.props.match.path === "/complaints-procedure" &&
                                <ComplaintsProcedure history={this.props.history} />
                            }
                            {addExtraSpace &&
                                <div style={{width: "100%", height: 128}}></div>
                            }
                        </div>
                    </div>
                    <div className="web-page-footer-container">
                        <p>© {moment().year()} J4Crew - <Link to={"/terms-and-conditions"}>Terms & Conditions</Link> - <Link to={"/privacy-policy"}>Privacy Policy</Link> - <Link to={"/contact-us"}>Get in Touch</Link> - <Link to={"/about-us"}>About Us</Link> - <Link to={"/mlc-2006"}>MLC 2006</Link></p>
                    </div>
                </Page>
            );
        } else {
            return <ReferenceVerification {...this.props} />
        }

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ adjustProfileImage, setJobs, setApplications }, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(DashboardCandidate);