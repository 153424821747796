import React from "react";
import "./index.css";

/* Helpers */
import { handleMultiple } from "../../helpers/candidate";

export default ({ label, type, expandedFilter, propName, handleOptionClick, handleFilterClick, selectedItems, options, single, input, keyword, changeText }) => {

    return(
        <div className="filter-search-container" style={type === expandedFilter ? {borderRadius: "4px 4px 0 0"} : {borderRadius: 4}} onClick={() => handleFilterClick(type, expandedFilter, input)}>
            <div className="filter-search-input-container">
                <div className="filter-label-container">
                    <p>{label}</p>
                </div>
                {input &&
                    <div className="filter-input-container">
                        <input type={"text"} placeholder={"Type..."} value={keyword} onChange={(e) => changeText(e.target.value, propName)} />
                    </div>
                }
                {!input &&
                    <div className="filter-value-container">
                        {selectedItems.length < 1 ?
                            <p>Select...</p>
                        :
                            <p>{handleMultiple(selectedItems)}</p>
                        }
                    </div>
                }
            </div>
            <div className="filter-search-icon-container">
                <img src={input ? require("../../assets/svgs/search.svg") : require("../../assets/svgs/chevron-down.svg")} style={type === expandedFilter ? {transform: "rotate(180deg)"} : {}} alt="" />
            </div>
            {type === expandedFilter && !input &&
                <div className="filter-search-dropdown-list-container">
                    {renderItems(options, selectedItems, propName, handleOptionClick, single)}
                </div>
            }
        </div>
    );

}

const renderItems = (items, selectedItems, propName, handleOptionClick, single) => {

    if (propName === "selectedPositions" || propName === "selectedQualifications") items = items.map(item => item.name);

    return items.map((item, index) => {
        return(
            <div className="filter-search-dropdown-list-item-container" key={index} onClick={() => handleOptionClick(propName, item, single)}>
                <div className="filter-search-dropdown-list-item-title-container">
                    <p>{item}</p>
                </div>
                {selectedItems.includes(item) &&
                    <div className="filter-search-dropdown-list-item-button-container">
                        <img src={require("../../assets/svgs/tick.svg")} alt="" />
                    </div>
                }
            </div>
        );  
    });

}