import {
    SET_IDEAL_POSITION,
    RESET_IDEAL_POSITION,
    SET_CANCEL_DATA,
} from "../types/ideal-position";

export let setIdealPosition = (data) => dispatch => {

    return dispatch({
        type: SET_IDEAL_POSITION,
        data,
    });
    
}

export let resetIdealPosition = () => dispatch => {

    return dispatch({
        type: RESET_IDEAL_POSITION,
    });
    
}

export let setCancelData = (data) => dispatch => {

    return dispatch({
        type: SET_CANCEL_DATA,
        data,
    });
    
}