export const EMAIL_JUST_VERIFIED = "EMAIL_JUST_VERIFIED";
export const TOGGLE_BURGER_MENU = "TOGGLE_BURGER_MENU";
export const TOGGLE_BURGER_STATUS = "TOGGLE_BURGER_STATUS";
export const ADJUST_PROFILE_IMAGE = "ADJUST_PROFILE_IMAGE";
export const SET_PROFILE_BUILDER_FINISH_LINE = "SET_PROFILE_BUILDER_FINISH_LINE";
export const SET_CV_PREVIEW = "SET_CV_PREVIEW";
export const SET_DELETE_ACCOUNT = "SET_DELETE_ACCOUNT";
export const SET_DELETE_LOCAL_YACHT_ACCOUNT = "SET_DELETE_LOCAL_YACHT_ACCOUNT";
export const SET_PASSWORD_CHANGED = "SET_PASSWORD_CHANGED";
export const SET_DETAILS_CHANGED = "SET_DETAILS_CHANGED";
export const SET_LOG_OUT = "SET_LOG_OUT";
export const SET_ALERT = "SET_ALERT";
export const VIEW_LISTING = "VIEW_LISTING";