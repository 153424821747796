import {
    SET_EDUCATION,
    RESET_EDUCATION,
} from "../types/education";

export let setEducation = (data) => dispatch => {

    return dispatch({
        type: SET_EDUCATION,
        data,
    });
    
}

export let resetEducation = () => dispatch => {

    return dispatch({
        type: RESET_EDUCATION,
    });
    
}