export const getPositions = () => {

    return fetch("/api/positions")
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });

}