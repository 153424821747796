import React from "react";
import "./index.css";

export default ({ onSave, onCancel }) => {

    return(
        <div className="save-cancel-container">
            <div className="save-cancel-button-1-container" onClick={onSave}>
                <p className="save-cancel-button-1-text">Save</p>
            </div>
            <div className="save-cancel-button-2-container">
                <p className="save-cancel-button-2-text" onClick={onCancel}>Cancel</p>
            </div>
        </div>
    );

}