import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { setCVProfile, updateCVProfile } from "../../../../redux/actions/admin";
import { setAlert } from "../../../../redux/actions/misc";

/* API requests */
import { getUserData } from "../../../../api-requests/user";
import { verifyReference } from "../../../../api-requests/candidate";

/* Components */
import OnlineCV from "../../../DashboardCandidate/components/CV/components/OnlineCV";
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import { updateCandidate } from "../../../../api-requests/candidate";

class Profile extends Component {

    componentDidMount() {

        document.getElementById("Dashboard").scrollTo(0, 0);

        if (this.props.match.params.id) {
            return getUserData(this.props.match.params.id)
                .then(user => {
                    return this.props.setCVProfile(user);
                });
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.setCVProfile(null);
            document.getElementById("Dashboard").scrollTo(0, 0);
            return getUserData(this.props.match.params.id)
                .then(user => {
                    return this.props.setCVProfile(user);
                });
        }
    }

    componentWillUnmount() {
        return this.props.setCVProfile(null);
    }

    saveCandidateNotes = (_id, value) => {

        return updateCandidate(_id, [{ propName: "notes", value: value }])
            .then(_ => {
                this.props.setAlert("Candidate notes updated!", "success", true);
                return setTimeout(() => {
                    return this.props.setAlert("Candidate notes updated!", "success", false);
                }, 2700);
            });

    }

    verifyReference = (profile, reference) => {

        console.log(profile);
        console.log(reference);

        if ((reference.referenceName && reference.companyName && reference.position && reference.email) && (reference.referenceName !== "-" && reference.companyName !== "-" && reference.position !== "-" && reference.email !== "-")) {
            return verifyReference(profile._id, reference.email)
                .then(_ => {
                    return getUserData(this.props.match.params.id)
                        .then(user => {
                            return this.props.setCVProfile(user);
                        });
                });
        } else {
            return alert("In order to verify reference, reference name, company name, position and email are required. Please make sure the information is correct.");
        }

    }

    render() {

        return(
            <div className="application-main-container">
                <div className="profile-master-container margin-top-20px">
                    <OnlineCV user={this.props.admin.cvProfile} admin={true} verifyReference={this.verifyReference} />
                </div>
                <DashboardSectionBase title={"Candidate Notes"} description={"Any relevant information about this candidate."}>
                    <div className="application-candidate-notes-container margin-top-20px">
                        <textarea placeholder={"Type..."} value={this.props.admin.cvProfile?.profile?.notes || ""} onChange={(e) => this.props.updateCVProfile([{ propName: "notes", value: e.target.value }])}></textarea>
                    </div>
                    <div className="application-candidate-notes-save-button margin-top-20px" onClick={() => this.saveCandidateNotes(this.props.admin.cvProfile.profile._id, this.props.admin.cvProfile.profile.notes)}>
                        <p>Save</p>
                    </div>
                </DashboardSectionBase>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        admin: state.admin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setCVProfile, updateCVProfile, setAlert }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);