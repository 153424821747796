import React, { Component } from "react";

/* Components */
import Home from "../WebPage/Home";
import LookingForCrew from "../WebPage/LookingForCrew";
import AboutUs from "../WebPage/AboutUs";
import CVAdvice from "../WebPage/CVAdvice";
import ContactUs from "../WebPage/ContactUs";
import Terms from "../WebPage/Terms";
import Privacy from "../WebPage/Privacy";
import MLC2006 from "../WebPage/MLC2006";
import Complaints from "../WebPage/Complaints";
import FindJobs from "../WebPage/FindJobs";
import ReferenceVerification from "../ReferenceVerification";

class WebPage extends Component {

    render() {

        if (this.props.match.path === "/") return <Home {...this.props} />;
        if (this.props.match.path === "/find-jobs") return <FindJobs {...this.props} />;
        if (this.props.match.path === "/looking-for-crew") return <LookingForCrew {...this.props} />;
        if (this.props.match.path === "/about-us") return <AboutUs {...this.props} />;
        if (this.props.match.path === "/cv-advice") return <CVAdvice {...this.props} />;
        if (this.props.match.path === "/contact-us") return <ContactUs {...this.props} />;
        if (this.props.match.path === "/terms-and-conditions") return <Terms {...this.props} />;
        if (this.props.match.path === "/privacy-policy") return <Privacy {...this.props} />;
        if (this.props.match.path === "/mlc-2006") return <MLC2006 {...this.props} />;
        if (this.props.match.path === "/complaints-procedure") return <Complaints {...this.props} />;
        if (this.props.match.path.includes("/reference-verification")) return <ReferenceVerification {...this.props} />;

        return "";
    }

}

export default WebPage;