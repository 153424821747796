import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/analytics";

/* Components */
import Ghost from "./pages/Ghost";
import Init from "./pages/Init";
import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import InitialSetup from "./pages/InitialSetup";

/* Actions */
import { setLogging, setAuth, setUserData } from "./redux/actions/user";
import { setRegistrationLoadingState } from "./redux/actions/register";
import { setEmailJustVerified } from "./redux/actions/misc";

/* API requests */
import { getUserSession } from "./api-requests/user";

const config = {
    apiKey: "AIzaSyBJVKqYmYO8ShAwpRuBfloJL_91PIfAkWs",
    authDomain: "crew-board.firebaseapp.com",
    databaseURL: "https://crew-board.firebaseio.com",
    projectId: "crew-board",
    storageBucket: "crew-board.appspot.com",
    messagingSenderId: "628944417643",
    appId: "1:628944417643:web:32d11877d767e8fedd922e",
    measurementId: "G-HPZ9YWL4G5",
};

class Routes extends Component {

    componentDidMount() {

        firebase.initializeApp(config);
        firebase.analytics();

        return getUserSession()
            .then(data => {
                if (data?.user) {
                    this.props.setUserData(data.user);
                    if (data.user.profile.profileType === "upload" && this.props.misc.emailJustVerified) {
                        this.props.misc.history.push("/profile/cv");
                        this.props.setEmailJustVerified(false, null);
                    }
                    this.props.setAuth(true);
                }
                return this.props.setLogging(false);
            });

    }

    render() {

        if (this.props.user.isLoggingIn) {
            return(
                <Ghost />
            );
        } else {
            if (this.props.user.isLoggedIn) {
                if (this.props.user?.data?.userType === "Candidate") {
                    return(
                        <Switch>
                            <Route exact={true} path="/" component={Init} />
                            <Route exact={true} path="/jobs" component={Init} />
                            <Route exact={true} path="/jobs/applications" component={Init} />
                            <Route exact={true} path="/profile" component={Init} />
                            <Route exact={true} path="/profile/cv" component={Init} />
                            <Route exact={true} path="/profile/ideal-job" component={Init} />
                            <Route exact={true} path="/profile/qualifications" component={Init} />
                            <Route exact={true} path="/profile/experience" component={Init} />
                            <Route exact={true} path="/profile/references" component={Init} />
                            <Route exact={true} path="/profile/documents" component={Init} />
                            <Route exact={true} path="/settings" component={Init} />
                            <Route exact={true} path="/setup" component={InitialSetup} />
                            <Route exact={true} path="/about-us" component={Init} />
                            <Route exact={true} path="/contact-us" component={Init} />
                            <Route exact={true} path="/terms-and-conditions" component={Init} />
                            <Route exact={true} path="/privacy-policy" component={Init} />
                            <Route exact={true} path="/mlc-2006" component={Init} />
                            <Route exact={true} path="/complaints-procedure" component={Init} />
                            <Route exact={true} path="/reference-verification/:id" component={Init} />
                            <Route component={NotFound} />
                        </Switch>
                    );
                } else if (this.props.user?.data?.userType === "Admin") {
                    return(
                        <Switch>
                            <Route exact={true} path="/" component={Init} />
                            <Route exact={true} path="/sign-ups" component={Init} />
                            <Route exact={true} path="/sign-ups/profile/:id" component={Init} />
                            <Route exact={true} path="/sign-ups/completed" component={Init} />
                            <Route exact={true} path="/sign-ups/incomplete" component={Init} />
                            <Route exact={true} path="/crew" component={Init} />
                            <Route exact={true} path="/crew/profile/:id" component={Init} />
                            <Route exact={true} path="/yachts" component={Init} />
                            <Route exact={true} path="/yachts/create" component={Init} />
                            <Route exact={true} path="/yachts/edit/:yachtId" component={Init} />
                            <Route exact={true} path="/yachts/listings/:yachtId" component={Init} />
                            <Route exact={true} path="/yachts/listings/:yachtId/:status" component={Init} />
                            <Route exact={true} path="/yachts/listings/:yachtId/:status/:jobId" component={Init} />
                            <Route exact={true} path="/yachts/listings/:yachtId/:status/:jobId/:tab" component={Init} />
                            <Route exact={true} path="/yachts/listings/:yachtId/:status/:jobId/:tab/:applicationId" component={Init} />
                            <Route exact={true} path="/listings" component={Init} />
                            <Route exact={true} path="/listings/:status" component={Init} />
                            <Route exact={true} path="/listings/:status/:yachtId" component={Init} />
                            <Route exact={true} path="/listings/:status/:yachtId/job/:jobId" component={Init} />
                            <Route exact={true} path="/listings/:status/:yachtId/job/:jobId/:tab" component={Init} />
                            <Route exact={true} path="/listings/:status/:yachtId/job/:jobId/:tab/:applicationId" component={Init} />
                            <Route exact={true} path="/reference-verification/:id" component={Init} />
                            <Route exact={true} path="/stats" component={Init} />
                            <Route exact={true} path="/references" component={Init} />
                            <Route exact={true} path="/jobs" component={Init} />
                            <Route component={NotFound} />
                        </Switch>
                    );
                } else {
                    return(
                        <Switch>
                            <Route component={NotFound} />
                        </Switch>
                    );
                }
            } else {
                return(
                    <Switch>
                        <Route exact={true} path="/" component={Init} />
                        <Route exact={true} path="/find-jobs" component={Init} />
                        <Route exact={true} path="/looking-for-crew" component={Init} />
                        <Route exact={true} path="/about-us" component={Init} />
                        <Route exact={true} path="/cv-advice" component={Init} />
                        <Route exact={true} path="/contact-us" component={Init} />
                        <Route exact={true} path="/terms-and-conditions" component={Init} />
                        <Route exact={true} path="/privacy-policy" component={Init} />
                        <Route exact={true} path="/contact-us" component={Init} />
                        <Route exact={true} path="/mlc-2006" component={Init} />
                        <Route exact={true} path="/complaints-procedure" component={Init} />
                        <Route exact={true} path="/reference-verification/:id" component={Init} />
                        <Route exact={true} path="/sign-in" component={Auth} />
                        <Route exact={true} path="/sign-up" component={Auth} />
                        <Route exact={true} path="/reset-password" component={Auth} />
                        <Route component={NotFound} />
                    </Switch>
                );
            }
        }

    }

}

const mapStateToProps = (state) => {
    return {
        misc: state.misc,
        user: state.user,
        register: state.register,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setRegistrationLoadingState, setLogging, setAuth, setUserData, setEmailJustVerified }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);