import React, { Component } from "react";

class AboutUs extends Component {

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        return(
            <>
                <div className="about-us-intro-container">
                    <p>About Us</p>
                </div>
                <div style={{height: 30}}></div>
                <div className="about-us-text">
                    <p>J4Crew is a family run business formed over 20 years ago placing crew on large yachts, first appearing on the scene as just4engineers in 1999 which was developed from grass roots by Joe Hodgson, a Chief Engineer who was still working on yachts at the time.</p>
                </div>
                <div className="about-us-text">
                    <p>Starting with only four floppy disks holding the CV’s of a handful of friends in the industry and a basic website, Joe grew in prominence with a regular engineering column in a well-known yacht magazine and also producing and maintaining what was known as the engineers list. These were the days when CV’s arrived on the bridge via a barely legible fax, crew had virtually no marine qualifications or safety training. The key to advancement was having good experience and longevity which remains true today.</p>
                </div>
                <div className="about-us-text">
                    <p>The hiring of crew was either a local affair or a risk taken by flying someone in and trusting that the crew agent had done their job to present the right person. Captains would also fly thousands of miles to Florida or Antibes to interview dozens of crew and fill key positions. 50 metre yachts were marvelled as the biggest in the industry. Rotation was unheard of and time off was negotiated in dribs and drabs between charters and voyages.</p>
                </div>
                <div className="about-us-text">
                    <p>Technology and legislation has changed beyond recognition since those early days. Yachts are much larger, commercialising the industry and forcing the requirement for formal qualifications and STCW’s. Suddenly yachting was considered a sustainable and incredible career path for highly qualified and highly motivated crew.</p>
                </div>
                <div className="about-us-text">
                    <p>Jacob and Chay who are both trained Marine Officers in their own right have arrived with commercial and yachting experience and have started to cut their teeth in a fast moving and competitive industry.</p>
                </div>
                <div className="about-us-text">
                    <p>We have established ourselves as an industry leader and have built long term relationships with our clients and crew. We are committed to providing the highest standards and focus on providing quality over quantity.</p>
                </div>
                <div className="about-us-separator"></div>
                <div className="about-us-team-header-container">
                    <p>Meet the team</p>
                </div>
                <div className="about-us-team-member-container">
                    <div className="about-us-team-member-image-container">
                        <img src={require("../../../../assets/images/chay.jpg")} alt="" />
                    </div>
                    <div className="about-us-team-member-info-container">
                        <p className="about-us-team-member-name">Chay Hodgson</p>
                        <div className="about-us-text">
                            <p>Chay started his yachting career aged 17 working as a deckhand and learning the ropes as an engineer. He then went on to complete an engineering cadetship at Warsash Maritime College working on various cruise ships. Coming back into the industry he was able to secure a position as 2nd engineer on a prestigious yacht based in the IYCA where he stayed for a number of years. He joined the family business in 2017 with good experience and continues to serve clients in the best way possible.</p>
                        </div>
                        <div className="about-us-text">
                            <p>You can contact him directly at <a href="mailto:chay@j4crew.com" target="_blank" rel="noopener noreferrer">chay@j4crew.com</a> or call <a href="https://wa.me/447857138274" target={"_blank"} rel="noopener noreferrer">+44 (0) 7857 138 274</a></p>
                        </div>
                    </div>
                </div>
                <div className="about-us-team-member-container">
                    <div className="about-us-team-member-image-container">
                        <img src={require("../../../../assets/images/jacob.jpg")} alt="" />
                    </div>
                    <div className="about-us-team-member-info-container">
                        <p className="about-us-team-member-name">Jacob Hodgson</p>
                        <div className="about-us-text">
                            <p>Jacob is the latest member to join the team at j4crew in 2018. He grew up in both England and the South of France so was exposed to the yachting industry from a young age. He holds an OOW ticket and a foundation degree in Marine Operations obtained through the Trinity House cadetship scheme and has served as Mate on both offshore vessels and super yachts. His desire is to help grow j4crew further in the industry.</p>
                        </div>
                        <div className="about-us-text">
                            <p>You can contact him directly at <a href="mailto:jacob@j4crew.com" target="_blank" rel="noopener noreferrer">jacob@j4crew.com</a> or call <a href="https://wa.me/447598382232" target={"_blank"} rel="noopener noreferrer">+44 (0) 7598 382 232</a>.</p>
                        </div>
                    </div>
                </div>
                <div className="about-us-separator"></div>
                <div className="about-us-team-header-container">
                    <p>Testimonials</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“I began using Joe’s, just4engineers recruitment more than 10 years ago and he has been my primary contact whenever I am seeking new roles on large yachts. In 2008, when I was seeking my first large yacht role as a 2nd Engineer, at that time, I was an experienced sole engineer on private 40m sailing yachts, but still unlicensed. Undeterred, Joe was convinced that my experience would speak for itself and put me forward towards a 2nd Engineer’s role aboard a 53m Perini Navi, which required a Y4 ticket. After a successful interview, the Captain petitioned Flag state to allow for a dispensation and I sailed aboard her for a year before returning to University to finish my degree in Marine Engineering and EOOW qualifications. Following University, Joe continued to offer tailored roles complementing my experience and interests, while I sought a different career in Oil and Gas, Joe was always willing to promptly answer emails and offer advice. Truly, best-in-class service!”</i>
                    <p>Simon Glass</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“Since I joined Yachting in 2008, Joe and just4crew have always play a primordial role, providing an outstanding and personal service, fulfilling my personal needs, and placing me in the correct yacht every time. I got my first job in yachting thanks to Joe, who continue placing me on another boats afterwards. I thanks to him and his team to help me to rise my career and progress within the Industry, since the early days on the lower ranks, to nowadays.”</i>
                    <p>Ignacio Bordenave</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>The service I received from Joe was great and very helpful.</i>
                    <p>Mike Newland</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“J4crew (just4engineers) give that personal touch not just send me your CV company, Highly recommended.”</i>
                    <p>Mike Norton</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“J4Crew have placed me on several boats over the last 10 years. Great service and always quick to respond to phonecalls and emails. No ridiculously long forms full of impersonal and irrelevant questions. would recommend to anyone in the yachting industry”</i>
                    <p>Tom Pearson</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“The service provided was good, was my first introduction from the merchant navy into yachting”</i>
                    <p>Luke Buxton</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“Joe and Jacob placed me on a luxury 50m yacht in 2010. They ensured that all paperwork was in place and I was a perfect match for both the yacht owner and crew. Although my path lead me back to the merchant marine industry, I really appreciated J4Crew's professionalism and ability to recuit and place top notch mariners into the yachting industry. Thank you J4Crew for the amazing experience!”</i>
                    <p>Greg Crossman</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“I would not hesitate to recommend J4Crew as the best engineering recruitment agency. I have been helped through your professional and dedicated work to find my ideal engineer position. Great service best option for beginners, friends and old colleagues looking for new amazing job opportunities.”</i>
                    <p>Juan Riauba - Y1 MCA Engineer</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“Excellent job and very professional”</i>
                    <p>Nadine LeBlanc</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“I had that pleasure to be recruited by Joe back in the day; it was pure pleasure to work with him.”</i>
                    <p>Josko Tomic</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“I would be happy to say that I have always had pleasant dealings with Joe and find J4crew a reliable, professionally run, yacht recruitment agency.”</i>
                    <p>Richard Baillie</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“J4Crew do a great job of keeping me up to date with new positions and placing the right people to the right roles. Joe placed me with SY Athena and it was a great role that kept me challenged the whole time. Keep up the good work.”</i>
                    <p>Keith Bennett</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“J4crew (just4engineers) give that personal touch not just send me your CV company, Highly recommended.”</i>
                    <p>Mike Norton</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“I would like to thank J4crew and the team Jacob, Chay and Joe for their very dedicated and professional service given to me over the years, placing me on a boat which perfectly matched my experience and qualifications. They were always very responsive and did a great job outlining what the position entailed and making the perfect match. I wish the team at J4Crew the best for the future and can highly recommend them.”</i>
                    <p>Simon Bradshaw</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>"Used J4crew for over 10 years now and have always been 100% satisfied with the services provided."</i>
                    <p>Rich Swansbury</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“The service I received from Joe was great and very helpful.”</i>
                    <p>Mike Newland</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“Joe helped me make my transition from merchant to yachting back in 2008. He found me the right boat at the right time. Excellent!”</i>
                    <p>Joe Durkin</p>
                </div>
                <div className="about-us-testimonial-container">
                    <i>“I am happy to recommend J4crew from my own past experience. Joe Hodgson found me a relief job very quickly once I made him aware I was interested and available after taking some time off to visit home. The service from Joe was great as he is easy to talk to, has an engineering background so he understands the engineer’s role onboard and what skills and candidate would be suitable and in my case he acted very quickly and I had a job sooner than I had expected. I would always go to J4crew in the future if I needed a new job.”</i>
                    <p>Thomas Masterson, 14 years in yachting</p>
                </div>
            </>
        );

    }

}

export default AboutUs;