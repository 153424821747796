
import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";

/* Constants */
import { spokenLanguages, countries, nationalities, candidateDetailsProps } from "../../../../constants";

/* Components */
import DashboardSectionBase from "../../../../components/DashboardSectionBase";
import RegularTextInput from "../../../../components/RegularTextInput";
import RegularToggleButton from "../../../../components/RegularToggleButton";
import TextInputDropdown from "../../../../components/TextInputDropdown";
import SelectedItems from "../../../../components/SelectedItems";
import TextArea from "../../../../components/TextArea";
import CheckBox from "../../../../components/CheckBox";
import SaveButton from "../../../../components/SaveButton";

class Details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            spokenLanguage: "",
            nationality: "",
        };

    }

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        const { profile } = this.props.user.data;

        return(
            <>
                <DashboardSectionBase title={"Personal Details"}>
                    <div className="margin-top-2x">
                        <RegularTextInput label={"Date of birth"} placeholder="DD/MM/YYYY" value={profile.dob || ""} propName={"dob"} onChangeText={this.props.updateUserProfile} profile={profile} />
                    </div>
                    <div className="margin-top-20px">
                        <RegularToggleButton label={"Gender"} propName={"gender"} options={[{ name: "Male" }, { name: "Female" }]} toggle={this.props.updateUserProfile} profile={profile} />
                    </div>
                    <div className="margin-top-20px">
                        <RegularTextInput label={"Height"} placeholder="Type..." value={profile.height || ""} propName={"height"} unit={"centimetres"} onChangeText={this.props.updateUserProfile} profile={profile} />
                    </div>
                    <div className="margin-top-20px">
                        <TextInputDropdown label={"Languages Spoken"} placeholder="Type..." value={this.state.spokenLanguage} propName={"languages"} values={profile.languages} options={spokenLanguages} onChangeText={(e) => this.setState({ spokenLanguage: e })} select={this.props.updateUserProfile} />
                    </div>
                    <SelectedItems items={profile.languages} propName={"languages"} updateUserProfile={this.props.updateUserProfile} />
                    <div className="margin-top-20px">
                        <TextInputDropdown label={"Nationality"} placeholder="Type..." value={this.state.nationality} values={profile.nationality} options={nationalities} onChangeText={(e) => this.setState({ nationality: e })} propName={"nationality"} select={this.props.updateUserProfile} />
                    </div>
                    <SelectedItems items={profile.nationality} propName={"nationality"} updateUserProfile={this.props.updateUserProfile} />
                    <div className="margin-top-20px">
                        <TextInputDropdown label={"Country of Residence"} placeholder="Type..." value={profile.country} values={[profile.country]} options={countries} onChangeText={(e) => this.props.updateUserProfile([{ propName: "country", value: e }])} propName={"country"} select={this.props.updateUserProfile} single={true} />
                    </div>
                    <div className="margin-top-20px">
                        <RegularToggleButton label={"Are you a smoker?"} propName={"smoking"} options={[{ name: "Non Smoker", bool: false }, { name: "Smoker", bool: true }]} toggle={this.props.updateUserProfile} profile={profile} />
                    </div>
                    <div className="margin-top-20px">
                        <RegularToggleButton label={"Do you have tattoos?"} propName={"tattoo"} options={[{ name: "No Tattoos" }, { name: "No Visible Tattoos" }, { name: "Visible Tattoos" }]} toggle={this.props.updateUserProfile} profile={profile} />
                    </div>
                    <div className="margin-top-20px">
                        <RegularToggleButton label={"Relationship Status"} propName={"relationshipStatus"} options={[{ name: "Married" }, { name: "Single" }, { name: "In a Relationship" }]} toggle={this.props.updateUserProfile} profile={profile} />
                    </div>
                </DashboardSectionBase>
                <DashboardSectionBase title={"Visas Held"}>
                    <div className="margin-top-20px">
                        <CheckBox label={"B1/B2"} status={profile?.b1b2?.status} propName={"b1b2"} value={profile?.b1b2} toggle={this.props.updateUserProfile} profile={profile} />
                        {profile?.b1b2?.status &&
                            <div className="margin-top-1x">
                                <RegularTextInput label={"Expiry Date"} placeholder="DD/MM/YYYY" value={profile?.b1b2?.expiryDate || ""} onChangeText={this.props.updateUserProfile} profile={profile} propName={"b1b2"} />
                            </div>
                        }
                    </div>
                    <div className="margin-top-20px">
                        <CheckBox label={"C1/D"} status={profile?.c1d?.status} propName={"c1d"} value={profile?.c1d} toggle={this.props.updateUserProfile} profile={profile} />
                        {profile?.c1d?.status &&
                            <div className="margin-top-1x">
                                <RegularTextInput label={"Expiry Date"} placeholder="DD/MM/YYYY" value={profile?.c1d?.expiryDate || ""} onChangeText={this.props.updateUserProfile} profile={profile} propName={"c1d"} />
                            </div>
                        }
                    </div>
                    <div className="margin-top-20px">
                        <CheckBox label={"Schengen"} status={profile?.schengen?.status} propName={"schengen"} value={profile?.schengen} toggle={this.props.updateUserProfile} profile={profile} />
                        {profile?.schengen?.status &&
                            <div className="margin-top-1x">
                                <RegularTextInput label={"Expiry Date"} placeholder="DD/MM/YYYY" value={profile?.schengen?.expiryDate || ""} onChangeText={this.props.updateUserProfile} profile={profile} propName={"schengen"} />
                            </div>
                        }
                    </div>
                    <div className="margin-top-20px">
                        <CheckBox label={"Green Card"} status={profile?.greenCard?.status} propName={"greenCard"} value={profile?.greenCard} toggle={this.props.updateUserProfile} profile={profile} />
                        {profile?.greenCard?.status &&
                            <div className="margin-top-1x">
                                <RegularTextInput label={"Expiry Date"} placeholder="DD/MM/YYYY" value={profile?.greenCard?.expiryDate || ""} onChangeText={this.props.updateUserProfile} profile={profile} propName={"greenCard"} />
                            </div>
                        }
                    </div>
                </DashboardSectionBase>
                <DashboardSectionBase title={"Contact Details"}>
                    <div className="contact-details-container margin-top-20px">
                        <RegularTextInput label={"Phone Number"} placeholder="Type..." value={profile.primaryPhone || ""} propName={"primaryPhone"} onChangeText={this.props.updateUserProfile} profile={profile} />
                        <div className="contact-details-checkbox-container">
                            <CheckBox label={"WhatsApp"} icon={require("../../../../assets/svgs/whatsapp.svg")} status={profile.primaryPhoneWhatsApp} propName={"primaryPhoneWhatsApp"} value={!profile.primaryPhoneWhatsApp} toggle={this.props.updateUserProfile} profile={profile} />
                        </div>
                    </div>
                    <div className="contact-details-container margin-top-20px">
                        <RegularTextInput label={"Other Phone Number (Optional)"} placeholder="Type..." value={profile.secondaryPhone || ""} propName={"secondaryPhone"} onChangeText={this.props.updateUserProfile} profile={profile} />
                        <div className="contact-details-checkbox-container">
                            <CheckBox label={"WhatsApp"} icon={require("../../../../assets/svgs/whatsapp.svg")} status={profile.secondaryPhoneWhatsApp} value={!profile.secondaryPhoneWhatsApp} propName={"secondaryPhoneWhatsApp"} toggle={this.props.updateUserProfile} profile={profile} />
                        </div>
                    </div>
                </DashboardSectionBase>
                <DashboardSectionBase title={"Profile Summary"} description={"Tell us more about yourself. What makes you a great asset onboard?"}>
                    <div className="margin-top-20px">
                        <TextArea value={profile.aboutMe} propName={"aboutMe"} placeholder={"Start Typing..."} onChangeText={this.props.updateUserProfile} />
                    </div>
                </DashboardSectionBase>
                <DashboardSectionBase title={"Hobbies & Interests"} description={"Let us know how you like to spend your spare time."}>
                    <div className="margin-top-20px">
                        <TextArea value={profile.hobbies} propName={"hobbies"} placeholder={"Start Typing..."} onChangeText={this.props.updateUserProfile} />
                    </div>
                </DashboardSectionBase>
                <SaveButton propNames={candidateDetailsProps} profile={profile} updateUserProfile={this.props.updateUserProfile} next="/profile/ideal-job" history={this.props.history} />
            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);