import {
    SET_PROFILE_PICTURE_EDITOR,
    SET_PROFILE_PICTURE,
    SET_PROFILE_PICTURE_SCALE,
    SET_PROFILE_PICTURE_DEGREE,
    TOGGLE_CV_UPLOAD_SUCCESS_POP_UP,
    TOGGLE_INITIAL_SETUP_COMPLETED,
    TOGGLE_EMAIL_VERIFIED_ALERT,
    TOGGLE_RESEND_EMAIL,
 } from "../types/initial-setup";

export let setProfilePictureEditor = (data) => dispatch => {

    return dispatch({
        type: SET_PROFILE_PICTURE_EDITOR,
        data,
    });
    
}

export let setProfilePicture = (data) => dispatch => {

    return dispatch({
        type: SET_PROFILE_PICTURE,
        data,
    });
    
}

export let setProfilePictureScale = (data) => dispatch => {

    return dispatch({
        type: SET_PROFILE_PICTURE_SCALE,
        data,
    });
    
}

export let setProfilePictureDegree = (data) => dispatch => {

    return dispatch({
        type: SET_PROFILE_PICTURE_DEGREE,
        data,
    });
    
}

export let toggleCVPopup = () => dispatch => {

    return dispatch({
        type: TOGGLE_CV_UPLOAD_SUCCESS_POP_UP,
    });
    
}

export let toggleVerifyEmailPopup = (data) => dispatch => {

    return dispatch({
        type: TOGGLE_INITIAL_SETUP_COMPLETED,
        data,
    });
    
}

export let toggleEmailVerifiedAlert = (data) => dispatch => {

    return dispatch({
        type: TOGGLE_EMAIL_VERIFIED_ALERT,
        data,
    });
    
}

export let toggleResendEmail = (data) => dispatch => {

    return dispatch({
        type: TOGGLE_RESEND_EMAIL,
        data,
    });
    
}