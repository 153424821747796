import React, { Component } from "react";

/* Components */
import InitialSetupBase from "../../../../components/InitialSetupBase";
import AdjustPhoto from "../../../../components/AdjustPhoto";

class UploadPhoto extends Component {

    render() {

        return(
            <InitialSetupBase title={"Initial Set Up - Upload Your Photo"} description={"Please upload a professional portrait photo of yourself."}>
                <AdjustPhoto />
            </InitialSetupBase>
        );

    }

}

export default UploadPhoto;