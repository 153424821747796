import React, { Component } from "react";

class PrivacyPolicy extends Component {

    componentDidMount() {

        return document.getElementById("Dashboard").scrollTo(0, 0);

    }

    render() {

        return(
            <div className="terms-container">
                <div className="terms-title-container">
                    <p>Privacy Policy</p>
                </div>
                <div className="terms-text-container margin-top-1x">
                    <p>J4Crew aims to protect the privacy and all users of this website. Please read through our privacy policy to learn more about which data we collect and how we process it.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Cookies</p>
                </div>
                <div className="terms-text-container">
                    <p>This website uses Google software to monitor visitors and for tracking and analytics. This software uses cookies. We also use Facebook software to track visitors and show relevant adverts.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Information that we collect</p>
                </div>
                <div className="terms-text-container">
                    <p>We collect a wide variety of information from both employers and candidates in order to provide the best service possible. We do not sell any data to third parties, all information is designed to assist in the process of matching crew and employers together.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Candidate data collection</p>
                </div>
                <div className="terms-text-container">
                    <p>We collect personal details such as but not limited to email address, date of Birth, gender, languages spoken, nationality, phone number and address. We have a profile section where you can introduce yourself to employers. We collect information on your ideal position, qualifications, education, work experience, skills, equipment knowledge, details of your references and also hobbies and interests. There are a number of documents that we require. We also collect information on your current location and availability.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Data storage</p>
                </div>
                <div className="terms-text-container">
                    <p>All information provided is stored on secure servers. Your password must be kept confidential and we ask that you do not share this password. We take all necessary precautions to ensure that your data is treated securely and in accordance with this privacy policy.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Uses of information</p>
                </div>
                <div className="terms-text-container">
                    <p>The purpose of the information collected is to provide you with our services and other information that we feel may be of interest to you, where you have consented to such communications. We also use it to carry out any duties or obligations that are required from us.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Disclosure of information</p>
                </div>
                <div className="terms-text-container">
                    <p>We will not disclose any personal information to a third party without your prior consent unless the following cases apply. In the case that sell or buy any business or assets, we may disclose your personal data to the prospective seller or buyer of such business or assets. If J4Crew or its assets are acquired by a third party, your personal data will be one of the transferred assets. If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions of supply or software licence and other agreements. To protect the rights, property, or safety of J4Crew, our users, service providers or others, for example, exchanging information with other organisations for fraud protection and credit risk reduction.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Access to information</p>
                </div>
                <div className="terms-text-container">
                    <p>You have the right to request access to information held about you. This may incur a small administration fee.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Changes to our privacy policy</p>
                </div>
                <div className="terms-text-container">
                    <p>Any changes we make to the privacy policy will be posted here and where appropriate, notified to you via email.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Transfer of data outside of the European Economic Area:</p>
                </div>
                <div className="terms-text-container">
                    <p>On occasion your data may be disclosed to client employers outside of the European economic area. this may happen, for example, if you apply for a position where the employer is based outside the European Economic Area. by registering and using our website, you consent to this disclosure.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Acceptance:</p>
                </div>
                <div className="terms-text-container">
                    <p>By accessing our website and registering with j4crew you will be deemed to have accepted j4crew’s use and disclosure of your data as set out in this privacy statement.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>How long will we keep your personal data?</p>
                </div>
                <div className="terms-text-container">
                    <p>We will hold your information for as long as is necessary to comply with our statutory and contractual obligations and in accordance with our legitimate interests as a data controller. We will use reasonable endeavours to ensure that your personal data is maintained and up to date. you are requested to use your best endeavours to inform us of any and all changes to your personal data to ensure that it is up to date and we will update or delete your personal data accordingly.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Your rights</p>
                </div>
                <div className="terms-text-container">
                    <p>You have the right to see the information that we have about you and you have the right to have any inaccurate information we have about you corrected. to exercise these rights please write or email to the address given above and mark your correspondence for the attention of the data protection officer. please note that we will need to satisfy ourselves that you are the data subject before making any disclosure to you, and that there may be a small charge (£10) for this service.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Complaint resolution</p>
                </div>
                <div className="terms-text-container">
                    <p>Should you have any concerns or complaints about our website or data protection issues, please write in the first instance to our data protection officer at the above address. the data protection officer will review your concern or complaint and try to resolve the problem.</p>
                </div>
                <div className="terms-subtitle-container">
                    <p>Contact</p>
                </div>
                <div className="terms-text-container">
                    <p>You may contact us with any questions regarding our privacy policy by emailing <a href="mailto:crew@j4crew.com" target="_blank" rel="noopener noreferrer">crew@j4crew.com</a>.</p>
                </div>
            </div>
        );

    }   

}

export default PrivacyPolicy;