import React, { Component } from "react";
import "../YachtDetails/index.css";
import "../BasicInformation/index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Components */
import InitialSetupBase from "../../../../components/InitialSetupBase";

/* Actions */
import { updateUserProfile } from "../../../../redux/actions/user";
import TextInputWithIcon from "../../../../components/TextInputWithIcon";

class ContactDetails extends Component {

    render() {

        return(
            <InitialSetupBase title={"Initial Set Up - Contact Details"} description={"Please provide a contact number in case we need to contact you."}>
                <div className="margin-top-20px">
                    <TextInputWithIcon value={this.props.user.data.profile.primaryPhone || ""} onChangeText={(e) => this.props.updateUserProfile([{ propName: "primaryPhone", value: e }])} placeholder={"Phone Number"} hide={false} />
                </div>
                <div className="margin-top-20px">
                    <TextInputWithIcon value={this.props.user.data.profile.secondaryPhone || ""} onChangeText={(e) => this.props.updateUserProfile([{ propName: "secondaryPhone", value: e }])} placeholder={"Other Phone Number (Optional)"} hide={false} />
                </div>
            </InitialSetupBase>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);