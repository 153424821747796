import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile, isSafari, isChrome } from "react-device-detect";

/* Actions */
import { setUsersCount, addCrew } from "../../redux/actions/admin";

/* Components */
import SidebarAdmin from "../../components/SidebarAdmin";
import YachtListings from "./components/YachtListings";
import CreateYacht from "./components/CreateYacht";
import SignUps from "./components/SignUps";
import Profile from "./components/Profile";
import Yachts from "./components/Yachts";
import Page from "../../components/Page";
import Crew from "./components/Crew";

/* API requests */
import { getSignUpsCount, getMoreCrew } from "../../api-requests/admin";

/* Constants */
import { signUpsPaths, crewPaths } from "../../constants";
import AddListing from "./components/AddListings";
import Applications from "./components/Applications";
import Application from "./components/Application";
import Listings from "./components/Listings";
import Stats from "./components/Stats";
import ReferenceVerification from "../ReferenceVerification";
import References from "./components/References";
import AdminJobs from "./components/AdminJobs";


class DashboardAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoadingMoreCrew: false,
        };

    }

    componentDidMount() {

        return getSignUpsCount()
            .then(result => {
                return this.props.setUsersCount(result);
            })

    }

    handleScroll = (path) => {

        if (path === "/crew") {

            let dashboard = document.getElementById("Dashboard");
            let scrollPos = dashboard.scrollTop;
            let endPos = dashboard.scrollHeight - dashboard.offsetHeight - 128;
            
            if (scrollPos >= endPos) {
                if (!this.state.isLoadingMoreCrew) {
                    return this.setState({ isLoadingMoreCrew: true }, () => {
                        return getMoreCrew(this.props.admin.skipCrew)
                            .then(data => {
                                this.props.addCrew(data);
                                return this.setState({ isLoadingMoreCrew: false });
                            });
                    });
                }
            }

        }

    }

    render() {

        const addExtraSpace = isMobile && (isSafari || isChrome);

        if (!this.props.match.path.includes("/reference-verification/")) {
            return(
                <Page {...this.props} linear={true}>
                    <div className="dashboard-container">
                        <div className="dashboard-sidebar-container">
                            <SidebarAdmin {...this.props} />
                        </div>
                        <div className="dashboard-main-container" id="Dashboard" onScroll={() => this.handleScroll(this.props.match.path)}>
                            {signUpsPaths.includes(this.props.match.path) &&
                                <SignUps match={this.props.match} />
                            }
                            {crewPaths.includes(this.props.match.path) &&
                                <Crew match={this.props.match} />
                            }
                            {(this.props.match.path.includes("/sign-ups/profile") || this.props.match.path.includes("/crew/profile")) &&
                                <Profile match={this.props.match} />
                            }
                            {this.props.match.path === "/yachts" &&
                                <Yachts match={this.props.match} />
                            }
                            {this.props.match.path === "/yachts/create" &&
                                <CreateYacht match={this.props.match} history={this.props.history} />
                            }
                            {this.props.match.path.includes("/yachts/edit") &&
                                <CreateYacht match={this.props.match} history={this.props.history} />
                            }
                            {this.props.match.path.includes("/yachts/listings") && !["add", "applications"].includes(this.props.match.params.status) &&
                                <YachtListings match={this.props.match} history={this.props.history} />
                            }
                            {this.props.match.path.includes("/yachts") && this.props.match.params.yachtId && this.props.match.params.status === "add" &&
                                <AddListing match={this.props.match} history={this.props.history} />
                            }
                            {this.props.match.params.yachtId && this.props.match.params.status === "applications" && this.props.match.params.jobId && this.props.match.params.tab !== "application" && 
                                <Applications match={this.props.match} history={this.props.history} />
                            }
                            {this.props.match.params.tab === "application" && this.props.match.params.applicationId &&
                                <Application match={this.props.match} history={this.props.history} />
                            }
                            {this.props.match.path.includes("/listings") && !this.props.match.path.includes("/yachts") && this.props.match.params.status !== "add" && !this.props.match.params.jobId &&
                                <Listings match={this.props.match} history={this.props.history} />
                            }
                            {this.props.match.path.includes("/listings") && !this.props.match.path.includes("/yachts") && this.props.match.params.status === "add" && !this.props.match.params.jobId &&
                                <AddListing match={this.props.match} history={this.props.history} />
                            }
                            {addExtraSpace &&
                                <div style={{width: "100%", height: 128}}></div>
                            }
                            {this.props.match.path === "/stats" &&
                                <Stats />
                            }
                            {this.props.match.path === "/references" &&
                                <References />
                            }
                            {this.props.match.path === "/jobs" &&
                                <AdminJobs />
                            }
                        </div>
                    </div>
                </Page>
            );
        } else {
            return <ReferenceVerification {...this.props} />
        }

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ setUsersCount, addCrew }, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(DashboardAdmin);