import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* Actions */
import { updateUserProfile, updateUserProfileLookingFor, updateUserProfileYacht } from "../../../../redux/actions/user";
import { toggleVerifyEmailPopup } from "../../../../redux/actions/initial-setup";
import { resetIdealPosition } from "../../../../redux/actions/ideal-position";

/* API requests */
import { uploadAvatar, updateCandidate } from "../../../../api-requests/candidate";
import { updateEmployer } from "../../../../api-requests/employer";
import { updateYacht } from "../../../../api-requests/yacht";

class ProceedButton extends Component {

    handleImageSave = (editor, id) => {

        if (editor) {
            const canvasScaled = editor.getImageScaledToCanvas();
            return canvasScaled.toBlob((blob) => {
                return uploadAvatar(id, blob)
                    .then(result => {
                        const body = [
                            { propName: "profileImage", value: result.data },
                            { propName: "initialSetupStep", value: 2 },
                        ];
                        return this.props.updateUserProfile(body);
                    });
            },
            "image/jpeg", 1);
        }

    }

    handleProfileUpdate = (user, step, ideal) => {

        let body;

        if (step === 1 && user.profile.yacht && user.profile.yacht.name && user.profile.yacht.yachtType && user.profile.yacht.size && user.profile.yacht.yachtBuilderName && user.profile.yacht.yachtBuilderCountry) {
            body = [
                { propName: "name", value: user.profile.yacht.name },
                { propName: "yachtType", value: user.profile.yacht.yachtType },
                { propName: "size", value: user.profile.yacht.size },
                { propName: "age", value: user.profile.yacht.age },
                { propName: "yachtBuilderName", value: user.profile.yacht.yachtBuilderName },
                { propName: "yachtBuilderCountry", value: user.profile.yacht.yachtBuilderCountry },
            ];
        }

        if (step === 2 && user.userType === "Candidate" && user.profile.currentLocation && user.profile.lookingFor && user.profile.lookingFor.idealSalaryCurrency && user.profile.lookingFor.idealSalary) {
            
            body = [
                { propName: "isAvailable", value: user.profile.isAvailable },
                { propName: "hasNotice", value: user.profile.hasNotice },
                { propName: "noticePeriod", value: user.profile.noticePeriod },
                { propName: "currentLocation", value: user.profile.currentLocation },
                { propName: "lookingFor", value: user.profile.lookingFor },
                { propName: "initialSetupStep", value: 3 },
            ];

        } else if (step === 2 && user.userType === "Candidate" && (!user?.profile?.currentLocation || !user?.profile?.lookingFor?.idealSalary ||!user?.profile?.lookingFor?.idealSalaryCurrency)) {

            if (!user?.profile?.currentLocation) return alert("Please add your current location.");
            if (!user?.profile?.lookingFor?.idealSalaryCurrency) return alert("Please add your desired monthly salary currency.");
            if (!user?.profile?.lookingFor?.idealSalary) return alert("Please add your desired monthly salary.");

        }

        if (step === 2 && user.userType === "Employer" && user.profile.yacht.homePort && user.profile.yacht.currentLocation && user.profile.yacht.itinerary.length > 0
            && user.profile.yacht.couples !== undefined && user.profile.yacht.smoking !== undefined && user.profile.yacht.tattoo !== undefined && user.profile.yacht.dryBoat !== undefined) {
            
            body = [
                { propName: "homePort", value: user.profile.yacht.homePort },
                { propName: "currentLocation", value: user.profile.yacht.currentLocation },
                { propName: "itinerary", value: user.profile.yacht.itinerary },
                { propName: "couples", value: user.profile.yacht.couples },
                { propName: "smoking", value: user.profile.yacht.smoking },
                { propName: "tattoo", value: user.profile.yacht.tattoo },
                { propName: "dryBoat", value: user.profile.yacht.dryBoat },
            ];

        }

        if (step === 3 && user.userType === "Candidate" && (user.profile.lookingFor.desiredRoles.length !== 0 || ideal.position.length !== 0)) {

            const lookingFor = user.profile.lookingFor.desiredRoles.length < 1 ?
                {...user.profile.lookingFor, desiredRoles: [ideal]}
            :
                ideal.position.length < 1 ?
                    user.profile.lookingFor
                :
                    {...user.profile.lookingFor, desiredRoles: [...user.profile.lookingFor.desiredRoles, ideal]};

            body = [
                { propName: "lookingFor", value: lookingFor },
                { propName: "initialSetupStep", value: 4 },
            ];

        } else if (step === 3 && user.userType === "Candidate" && (user?.profile?.lookingFor?.desiredRoles?.length < 1 || ideal.position.length < 1)) {
            return alert("Please add at least 1 ideal position.");
        }

        if (step === 3 && user.userType === "Employer" && user.profile.primaryPhone) {

            body = [
                { propName: "primaryPhone", value: user.profile.primaryPhone },
                { propName: "secondaryPhone", value: user.profile.secondaryPhone },
                { propName: "initialSetupCompleted", value: true },
            ];

        }

        if (step === 4 && user.userType === "Candidate") {

            body = [
                { propName: "qualifications", value: user.profile.qualifications },
                { propName: "eng1Info", value: user.profile.eng1Info },
                { propName: "stcwBasicInfo", value: user.profile.stcwBasicInfo },
                { propName: "stcwAdvancedInfo", value: user.profile.stcwAdvancedInfo },
                { propName: "initialSetupCompleted", value: true },
                { propName: "requestedVerification", value: true },
            ];            

        }

        if (body && user.userType === "Candidate") {
            return updateCandidate(user.profile._id, body)
                .then(result => {
                    this.props.updateUserProfile(result.data);
                    if (step === 3) this.props.resetIdealPosition();
                    if (step === 4 && !user.isVerified) this.props.toggleVerifyEmailPopup(true);
                    if (step === 4 && user.isVerified) this.props.history.push("/");
                    return window.scrollTo(0, 0);
                });
        }

        if (body && user.userType === "Employer" && step !== 3) {
            return updateYacht(user.profile.yacht._id, body)
                .then(result_yacht => {
                    return updateEmployer(user.profile._id, [ { propName: "initialSetupStep", value: step+1 } ])
                        .then(result_profile => {
                            this.props.updateUserProfileYacht(result_yacht.data);
                            this.props.updateUserProfile(result_profile.data);
                            return window.scrollTo(0, 0);
                        });
                });
        }

        if (body && user.userType === "Employer" && step === 3) {
            return updateEmployer(user.profile._id, body)
                .then(result => {
                    this.props.updateUserProfile(result.data);
                    if (!user.isVerified) this.props.toggleVerifyEmailPopup(true);
                    if (user.isVerified) this.props.history.push("/");
                    return window.scrollTo(0, 0);
                });
        }

    }

    render() {

        if (this.props.user.data.profile.initialSetupStep === 1 || (this.props.user.data.profile.initialSetupStep === undefined && this.props.user.data.userType === "Employer" )) {
            return(
                <div onClick={this.props.user.data.userType === "Candidate" ? () => this.handleImageSave(this.props.initialSetup.editor, this.props.user.data.profile._id) : () => this.handleProfileUpdate(this.props.user.data, 1)} className="proceed-button-container" style={{display: this.props.initialSetup.image || this.props.user.data.profile.yacht ? "flex" : "none"}}>
                    <div className="proceed-button-title-container">
                        <p className="proceed-button-title">Save & Continue</p>
                    </div>
                    <img className="proceed-button-icon" src={require("../../../../assets/svgs/continue.svg")} alt="" />
                </div>
            );
        }

        if (this.props.user.data.profile.initialSetupStep === 2) {
            return(
                <div className="proceed-button-container" onClick={() => this.handleProfileUpdate(this.props.user.data, 2)}>
                    <div className="proceed-button-title-container">
                        <p className="proceed-button-title">Save & Continue</p>
                    </div>
                    <img className="proceed-button-icon" src={require("../../../../assets/svgs/continue.svg")} alt="" />
                </div>
            );
        }

        if (this.props.user.data.profile.initialSetupStep === 3 && this.props.user.data.userType === "Candidate") {
            return(
                <div className="proceed-button-container" onClick={() => this.handleProfileUpdate(this.props.user.data, 3, this.props.idealPosition.data)}>
                    <div className="proceed-button-title-container">
                        <p className="proceed-button-title">Save & Continue</p>
                    </div>
                    <img className="proceed-button-icon" src={require("../../../../assets/svgs/continue.svg")} alt="" />
                </div>
            );
        }

        if (this.props.user.data.profile.initialSetupStep === 3 && this.props.user.data.userType === "Employer") {
            return(
                <div className="proceed-button-container" onClick={() => this.handleProfileUpdate(this.props.user.data, 3)}>
                    <div className="proceed-button-title-container">
                        <p className="proceed-button-title">Save & Complete</p>
                    </div>
                    <img className="proceed-button-icon" src={require("../../../../assets/svgs/tick-white.svg")} alt="" />
                </div>
            );
        }

        if (this.props.user.data.profile.initialSetupStep === 4) {
            return(
                <div className="proceed-button-container" onClick={() => this.handleProfileUpdate(this.props.user.data, 4)}>
                    <div className="proceed-button-title-container">
                        <p className="proceed-button-title">Save & Complete</p>
                    </div>
                    <img className="proceed-button-icon" src={require("../../../../assets/svgs/tick-white.svg")} alt="" />
                </div>
            );
        }

    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        initialSetup: state.initialSetup,
        idealPosition: state.idealPosition,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ updateUserProfile, updateUserProfileLookingFor, updateUserProfileYacht, toggleVerifyEmailPopup, resetIdealPosition }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProceedButton);