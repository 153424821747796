import {
    SET_REGISTRATION_LOADING_STATE,
    SET_REGISTRATION_ACCOUNT_TYPE,
    SET_REGISTRATION_FIRST_NAME,
    SET_REGISTRATION_FIRST_NAME_ERROR,
    SET_REGISTRATION_LAST_NAME,
    SET_REGISTRATION_LAST_NAME_ERROR,
    SET_REGISTRATION_EMAIL,
    SET_REGISTRATION_EMAIL_ERROR,
    SET_REGISTRATION_PASSWORD,
    SET_REGISTRATION_PASSWORD_ERROR,
    CLEAR_REGISTRATION_FIELDS,
} from "../types/register";

export const setRegistrationLoadingState = (data) => dispatch => {

    return dispatch({
        type: SET_REGISTRATION_LOADING_STATE,
        data,
    });
    
}

export const setAccountType = (data) => dispatch => {

    return dispatch({
        type: SET_REGISTRATION_ACCOUNT_TYPE,
        data,
    });
    
}

export const setFirstName = (data) => dispatch => {

    dispatch({
        type: SET_REGISTRATION_FIRST_NAME_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_REGISTRATION_FIRST_NAME,
        data,
    });
    
}

export const setFirstNameError = (status, message) => dispatch => {

    return dispatch({
        type: SET_REGISTRATION_FIRST_NAME_ERROR,
        status,
        message,
    });
    
}

export const setLastName = (data) => dispatch => {

    dispatch({
        type: SET_REGISTRATION_LAST_NAME_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_REGISTRATION_LAST_NAME,
        data,
    });
    
}

export const setLastNameError = (status, message) => dispatch => {

    return dispatch({
        type: SET_REGISTRATION_LAST_NAME_ERROR,
        status,
        message,
    });
    
}

export const setEmail = (data) => dispatch => {

    dispatch({
        type: SET_REGISTRATION_EMAIL_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_REGISTRATION_EMAIL,
        data,
    });
    
}

export const setEmailError = (status, message) => dispatch => {

    return dispatch({
        type: SET_REGISTRATION_EMAIL_ERROR,
        status,
        message,
    });
    
}

export const setPassword = (data) => dispatch => {

    dispatch({
        type: SET_REGISTRATION_PASSWORD_ERROR,
        status: false,
        message: "",
    });

    return dispatch({
        type: SET_REGISTRATION_PASSWORD,
        data,
    });
    
}

export const setPasswordError = (status, message) => dispatch => {

    return dispatch({
        type: SET_REGISTRATION_PASSWORD_ERROR,
        status,
        message,
    });
    
}

export const clearRegistrationFields = () => dispatch => {

    return dispatch({
        type: CLEAR_REGISTRATION_FIELDS,
    });
    
}